"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var getConReceiveState = function (state) { return state.receiveState; };
var ɵ0 = getConReceiveState;
exports.ɵ0 = ɵ0;
var getTransportDetails = function (state) { return state.transportDetails; };
var ɵ1 = getTransportDetails;
exports.ɵ1 = ɵ1;
var getConsignmentNo = function (state) { return state.consignmentNo; };
var ɵ2 = getConsignmentNo;
exports.ɵ2 = ɵ2;
var getAccountDetails = function (state) { return state.accountDetails; };
var ɵ3 = getAccountDetails;
exports.ɵ3 = ɵ3;
var getBoxDetails = function (state) { return state.boxDetails; };
var ɵ4 = getBoxDetails;
exports.ɵ4 = ɵ4;
exports.getTransportDetailsStatus = store_1.createSelector(getConReceiveState, getTransportDetails);
exports.getConsigmentNoStatus = store_1.createSelector(getConReceiveState, getConsignmentNo);
exports.getAccountStatus = store_1.createSelector(getConReceiveState, getAccountDetails);
exports.getBoxDetailsStatus = store_1.createSelector(getConReceiveState, getBoxDetails);
