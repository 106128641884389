"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ConReceiveActions = require("../actions/con-receive.action");
exports.initialState = {
    transportDetails: null,
    consignmentNo: "",
    accountDetails: null,
    boxDetails: []
};
function ConReceiveReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case ConReceiveActions.ReceiveActionTypes.SET_TRANSPORTER_DETAILS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { transportDetails: action.payload });
        case ConReceiveActions.ReceiveActionTypes.SET_CONSIGNMENT_NO:
            return tslib_1.__assign(tslib_1.__assign({}, state), { consignmentNo: action.payload });
        case ConReceiveActions.ReceiveActionTypes.SET_ACCOUNT_DETAILS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { accountDetails: action.payload });
        case ConReceiveActions.ReceiveActionTypes.SET_BOX_DETAILS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { boxDetails: action.payload });
        default:
            return state;
    }
}
exports.ConReceiveReducer = ConReceiveReducer;
