"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../../shared/services/api.service");
var config_1 = require("../../shared/configuration/config");
var store_1 = require("@ngrx/store");
var selectors_1 = require("../../wholesale/reducers/selectors");
var i0 = require("@angular/core");
var i1 = require("../../shared/services/api.service");
var i2 = require("../../shared/configuration/config");
var i3 = require("@ngrx/store");
var ConsignmentReceiveService = /** @class */ (function () {
    function ConsignmentReceiveService(api, config, store) {
        this.api = api;
        this.config = config;
        this.store = store;
        this.getWarehouseId();
    }
    ConsignmentReceiveService.prototype.getWarehouseId = function () {
        var _this = this;
        this.store.pipe(store_1.select(selectors_1.getAccountIdStatus)).subscribe(function (result) {
            _this.wareHouseId = result;
        });
    };
    /**
      * @author Nidhi Raturi
      * @param Object - params
      * @description Api call to add Consignment REceive Details(Page 1 and 2)
      * @return Observable<any> -
  
      */
    ConsignmentReceiveService.prototype.addConsignReceiveDetails = function (params, requestType) {
        return this.api[requestType](this.config.UrlObj.addConsignmentReceiveDetail, params);
    };
    /**
    * @author Nidhi Raturi
    * @params Object - params
    * @description Api call to add Consignment Box Details(Page 3)
    * @return Observable<any> -
  
    */
    ConsignmentReceiveService.prototype.addBoxDetails = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.config.UrlObj.addConsignmentBoxDetails, params);
    };
    /**
   * @author Nidhi Raturi
   * @params Object - params
   * @description Api call to cancel Consignment
   * @return Observable<any> -
  
   */
    ConsignmentReceiveService.prototype.cancelConsignment = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.put(this.config.UrlObj.cancelConsignment, params);
    };
    /**
    * @author Nidhi Raturi
    * @params Object - params
    * @description Api call to get Bags Details(Page 4)
    * @return Observable<any> -
  
    */
    ConsignmentReceiveService.prototype.getBagsDetails = function (consignmentNo) {
        return this.api.get(this.config.UrlObj.getBagListInward + encodeURIComponent(encodeURIComponent(consignmentNo)) + '/boxDetails');
    };
    /**
   * @author Nidhi Raturi
   * @params Object - params
   * @description Api call to update Consignment Box Details(Page 3)
   * @return Observable<any> -
  
   */
    ConsignmentReceiveService.prototype.updateBoxDetails = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.put(this.config.UrlObj.updateBoxDetails, params);
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to update Image Url
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.updateImageUrlDetails = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.put(this.config.UrlObj.updateImageUrlInbound, params);
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to add Consignment Box Details(Page 3)
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.markConsignmntReceive = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.config.UrlObj.markConsignmentReceive, params);
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to get Barcode Details(Page 3)
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.getBarcodeDetails = function (boxes) {
        return this.api.post(this.config.UrlObj.getBarcodeDetailsApi +
            boxes +
            "&warehouseId=" +
            this.wareHouseId, {});
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description get Account Name List
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.getAccountNameList = function () {
        return this.api.get(this.config.UrlObj.getAccountNameList + "page=1&perPage=1000");
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description get Consignmnt No by Account ID
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.getConsignmentByAccountId = function (accountId, refConsignmentNo) {
        return this.api.get(this.config.UrlObj.getConsignmentByAccountId +
            accountId +
            "&refConsignmentNumber=" +
            encodeURIComponent(encodeURIComponent(refConsignmentNo)));
    };
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description get Consignmnt No by Account ID
  * @return Observable<any> -
  
  */
    ConsignmentReceiveService.prototype.getConsignmentReceiveDetails = function (consignmnentNo) {
        return this.api.get(this.config.UrlObj.getConsignmentReceiveDetails +
            encodeURIComponent(encodeURIComponent(consignmnentNo)) +
            "/receiveDetails");
    };
    ConsignmentReceiveService.ngInjectableDef = i0.defineInjectable({ factory: function ConsignmentReceiveService_Factory() { return new ConsignmentReceiveService(i0.inject(i1.ApiService), i0.inject(i2.Configuration), i0.inject(i3.Store)); }, token: ConsignmentReceiveService, providedIn: "root" });
    return ConsignmentReceiveService;
}());
exports.ConsignmentReceiveService = ConsignmentReceiveService;
