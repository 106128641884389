"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("@app/shared/services");
var store_1 = require("@ngrx/store");
var moment = require("moment");
var wholesale_actions_1 = require("@app/wholesale/actions/wholesale.actions");
var constants_1 = require("@app/shared/constants/constants");
var constants_2 = require("@app/shared/constants/constants");
var router_1 = require("@angular/router");
var PicklistsComponent = /** @class */ (function () {
    function PicklistsComponent(printService, store, router, alertService, wholesaleService) {
        this.printService = printService;
        this.store = store;
        this.router = router;
        this.alertService = alertService;
        this.wholesaleService = wholesaleService;
        this.endDate = moment(new Date())
            .add(7, "d")
            .format("YYYY-MM-DD");
        this.startDate = moment(new Date())
            .subtract(7, "d")
            .format("YYYY-MM-DD");
        this.summaryList = [];
        this.picklistAssociates = [];
        this.uniquePicklist = [];
        this.showPicklist = false;
    }
    PicklistsComponent.prototype.ngOnInit = function () {
        this.getConsolidatedPicklistSummary();
    };
    PicklistsComponent.prototype.getConsolidatedPicklistSummary = function () {
        var _this = this;
        var params = {
            expectedShipDateFrom: moment(this.startDate).format("YYYY-MM-DD"),
            expectedShipDateTo: moment(this.endDate).format("YYYY-MM-DD")
        };
        if (params.expectedShipDateFrom > params.expectedShipDateTo) {
            this.alertService.showError(constants_1.CONSTANTS.ALERT_MESSAGES.DATE_RANGE_ERROR);
        }
        else {
            this.getConsolidatedData$ = this.wholesaleService
                .getConsolidatedPicklist(params)
                .subscribe(function (res) {
                console.log(res);
                if (res) {
                    _this.uniquePicklist = res;
                    _this.sortPicklistByPickingDate();
                }
            });
        }
    };
    PicklistsComponent.prototype.onPrintInvoice = function (index, picklistId) {
        var picklistIds = [picklistId];
        if (picklistId) {
            this.printService.printDocument("invoice", picklistIds);
        }
        else {
            this.store.dispatch(new wholesale_actions_1.SetConsolidatedPicklist(this.uniquePicklist[index]));
            this.printService.printDocument("invoice");
        }
    };
    PicklistsComponent.prototype.onPrintSheet = function (picklistId) {
        var picklistIds = [picklistId];
        if (picklistId) {
            this.printService.printDocument("trip-sheet", picklistIds);
        }
    };
    PicklistsComponent.prototype.getAssociateNameById = function (id) {
        var associateList = this.wholesaleService.getAssociateName(this.picklistAssociates, id);
        if (associateList.length > 0) {
            return associateList[0].name;
        }
        return null;
    };
    PicklistsComponent.prototype.sortPicklistByPickingDate = function () {
        this.uniquePicklist.sort(function (a, b) {
            return moment(a["pickingDate"]) < moment(b["pickingDate"])
                ? 1
                : moment(b["pickingDate"]) < moment(a["pickingDate"])
                    ? -1
                    : 0;
        });
    };
    PicklistsComponent.prototype.trackByFn = function (index) {
        return index;
    };
    PicklistsComponent.prototype.togglePicklist = function (index) {
        this.showPicklist =
            this.selectedPicklistIndex === index ? !this.showPicklist : true;
        this.selectedPicklistIndex = index;
    };
    PicklistsComponent.prototype.hopToShipment = function (pickListId) {
        this.router.navigate([constants_2.ROUTES.WHOLESALE_SHIPMENT], {
            queryParams: { id: pickListId }
        });
    };
    PicklistsComponent.prototype.ngOnDestroy = function () {
        if (this.getConsolidatedData$) {
            this.getConsolidatedData$.unsubscribe();
        }
    };
    return PicklistsComponent;
}());
exports.PicklistsComponent = PicklistsComponent;
