import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

import { Shipment, PackageType } from "@app/_models/shipment-models";
import { Order } from "@app/_models/order-models";
import { Configuration } from "../configuration/config";

@Injectable({
  providedIn: "root"
})
export class ShipmentService {
  constructor(private http: HttpClient, private config: Configuration) { }
  getAllShipmentsByKey(date): Observable<Shipment[]> {
    return this.http
      .get<Shipment[]>(`${this.config.UrlObj.getShipment}${date}`)
      .pipe(
        map(res => res),
        catchError(this.handleError)
      );
  }

  private handleError(error) {
    if (error.status && error.status != null && error.status > 0) {
      return throwError(error || { message: "Server error" });
    }
    return throwError(error || "Server error");
  }

  getOrderDetails(volumeId: string): Observable<Order> {
    return this.http.get<Order>(`${this.config.UrlObj.getOrder}${volumeId}`);
  }

  searchOrders(queryTitle: string): Observable<Shipment> {
    return this.http
      .get<Shipment>(`${this.config.UrlObj.searchOrders}${queryTitle}`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  searchTrackingId(queryTitle: string): Observable<Shipment> {
    return this.http
      .get<Shipment>(`${this.config.UrlObj.searchByTrackingId}${queryTitle}`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getPackageType(): Observable<PackageType[]> {
    return this.http
      .get<PackageType[]>(`${this.config.UrlObj.getPackageType}`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getTodoList(params) {
    console.log(params);
    return this.http
      .get(`${this.config.UrlObj.getTodoList}${params}`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  updateShipment(data, shipmentId): Observable<Shipment> {
    const bodyText = JSON.stringify(data);
    return this.http
      .put<Shipment>(
        `${this.config.UrlObj.updateShipment}${shipmentId}`,
        bodyText
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  PrintInvoice(path: string) {
    return this.http
      .get<Shipment>(`${this.config.UrlObj.printInvoice}${path}`)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }
}
