import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Http } from "@angular/http";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { environment } from "@env/environment";
import { Configuration } from "../configuration/config";
import { AppState } from "@app/app.state";
import { getAccountIdStatus } from "@app/wholesale/reducers/selectors";
import { of } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class WholesaleService {
  private API_URL = environment.API_URL;
  wareHouseId = null;

  constructor(
    private api: ApiService,
    private config: Configuration,
    private store: Store<AppState>
  ) {
    this.getWarehouseId();
  }

  getRouteSummary(params = {}) {
    return this.api.post(`${this.API_URL}routeSummary`, params);
  }
  getCompleteConsignmentList(params = {}) {
    return this.api.post(`${this.API_URL}routeSummary`, params);
  }

  getDeliveryAssociates() {
    return this.api.get(`${this.API_URL}picklistAssociates`);
    // return of([])
  }

  getSimulatedSummary(params = {}) {
    return this.api.post(`${this.API_URL}simulateRoute`, params);
  }

  getPicklistSummary(params = {}) {
    return this.api.post(`${this.API_URL}startPicking`, params);
  }

  getPicklistById(id) {
    // var ApiUrl = 'picklist/';
    var ApiUrl = 'picklistByCustomer/'
    this.getWarehouseId();
    // if( this.wareHouseId.toUpperCase() == "WBLT" ){
    //   ApiUrl = 'picklistByCustomer/'
    // }
    return this.api.get(`${this.API_URL}${ApiUrl}${id}`);
  }

  getConsolidatedPicklist(params) {
    // return of([])
    return this.api.get(
      this.API_URL +
      "fetchConsolidatedPicklists?dateFrom=" +
      params["expectedShipDateFrom"] +
      "&dateTo=" +
      params["expectedShipDateTo"]
    );
  }
  updatePohAttachmentUrl(params = {}, id) {
    return this.api.put(
      `${this.config.UrlObj.updatePohAttachment}${id}`,
      params
    );
  }

  getWarehouseList() {
    return this.api.get(this.config.UrlObj.warehouseList);
  }

  getAssociateName(list, id) {
    return list.filter(items => {
      return items.id === id;
    });
  }
  getId(list) {
    return list.map(item => item.id);
  }

  getAssociatesListFromId(associateList, id) {
    return associateList.filter(associates => associates.id === id);
  }

  addDeliveryAssociate(params = {}) {
    return this.api.post(this.config.UrlObj.addAssociates, params);
  }

  deleteDeliveryAssociate(id) {
    return this.api.delete(`${this.config.UrlObj.deleteAssociate}${id}`);
  }

  printLabels(url) {
    return this.api.getFile(url);
  }
  deletePOH(id) {
    return this.api.delete(`${this.config.UrlObj.deletePOH}${id}`);
  }

  getWarehouseId() {
    this.store.pipe(select(getAccountIdStatus)).subscribe(result => {
      console.log(result);
      if (result) {
        this.wareHouseId = result;
        return this.wareHouseId;
      }

      // window.location.reload();
    });
  }

  getProductsByTitle(params = {}) {
    return this.api.post(this.config.UrlObj.searchProductByTitle, params);
  }

  getTitles() {
    return this.api.get(this.config.UrlObj.getTitles);
  }

  getTripSheetDetail(picklistCode) {
    return this.api.get(`${this.config.UrlObj.getTripSheetDetail}${picklistCode}`);
  }

  getAttributesListing(asin) {
    return this.api.get(`${this.config.UrlObj.attributeApi}/${this.wareHouseId}/${asin}`);
  }
  changeData(postData) {
    return this.api.post(`${this.config.UrlObj.postApi}/${this.wareHouseId}`, postData);
  }

  getRecallPickListDetails(pickListId) {
    const APIURL = `${this.config.UrlObj.getRecallPickListDetails}${pickListId}`
    return this.api.get(APIURL);
  }

  getRecallChallanDetails(picklistId) {
    const requestParams = {
      "page": 1,
      "perPage": 25,
      "orderNumber": picklistId
    }
    const APIURL = `${this.config.UrlObj.getRecallChallanDetails}`
    return this.api.get(APIURL, { params: requestParams })
  }

  // getAsinData(url){
  //   const body ={

  //     "client_id":"oNO2EvstgenqKn69qR6wyDzbjeVgYOsE",

  //    "client_secret":"ge6DMMczDVi5MjE2fOtiMjUMkS_2scp-ho1qqGm1CLCSEfDfZjjZA-KI6WYPXnAB",

  //     "grant_type":"password",

  //     "scope": "openid",

  //     "username":"amzb2b@eshopbox.com",

  //     "password":"amzb2b@1234",

  //     "audience":"https://wms.myeshopbox.com"

  //    };
  //   return this.api.get(url,body);
  // }

}
