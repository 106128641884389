"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var i0 = require("@angular/core");
// import filestack from "filestack-js";
// import { environment } from "@env/environment";
// const client = filestack.init(environment.FILESTACK_APIKEY);
var SharedService = /** @class */ (function () {
    function SharedService() {
        this.loaderSource = new Subject_1.Subject();
        this.loaderListner = this.loaderSource.asObservable();
    }
    SharedService.prototype.showHideLoader = function (status) {
        this.loaderSource.next({ status: status });
    };
    SharedService.ngInjectableDef = i0.defineInjectable({ factory: function SharedService_Factory() { return new SharedService(); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
exports.SharedService = SharedService;
