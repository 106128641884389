"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GetTruncatedName = /** @class */ (function () {
    function GetTruncatedName() {
    }
    GetTruncatedName.prototype.transform = function (value) {
        var stringToSplit = value;
        if (stringToSplit.length > 10) {
            stringToSplit = stringToSplit.substr(0, 10) + "...";
        }
        return stringToSplit;
    };
    return GetTruncatedName;
}());
exports.GetTruncatedName = GetTruncatedName;
