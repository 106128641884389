<main class="right-container">
  <div class="main-container">
    <section class="wholesale-section">
      <div class="section-heading">
        <p>Picklists</p>
        <div class="section-filters">
          <ul>
            <li class="date-filter">
              <span class="filter-title">Start Date</span>
              <span class="calender-wrapper">
                <input matInput [matDatepicker]="startDatepicker" placeholder="Choose a date" class="btn btn-filter"
                  (click)="startDatepicker.open()" readonly [(ngModel)]="startDate" />
                <mat-datepicker-toggle matPrefix [for]="startDatepicker" class="calender-toggle">
                </mat-datepicker-toggle>
                <mat-datepicker #startDatepicker [startAt]="startDate"></mat-datepicker>
              </span>
            </li>
            <li class="date-filter">
              <span class="filter-title">End Date</span>
              <span class="calender-wrapper">
                <input matInput [matDatepicker]="endDatepicker" placeholder="Choose a date" class="btn btn-filter"
                  (click)="endDatepicker.open()" readonly [(ngModel)]="endDate" />
                <mat-datepicker-toggle matPrefix [for]="endDatepicker" class="calender-toggle"></mat-datepicker-toggle>
                <mat-datepicker #endDatepicker [startAt]="endDate"></mat-datepicker>
              </span>
            </li>
            <li>
              <button class="btn btn-filter" (click)="getConsolidatedPicklistSummary()">
                Apply
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="section-body" *ngIf="uniquePicklist?.length > 0; else noDataBlock">
        <table class="summary-table pciklist-table">
          <thead>
            <tr>
              <th>Pick-List</th>
              <th>Route</th>
              <th>Associate</th>
              <th>Picking Date</th>
              <!-- <th>ExSD</th> -->
              <th>Status</th>
              <th>Print</th>
              <th>Trip sheet</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let pickList of uniquePicklist;
                let i = index;
                trackBy: trackByFn
              ">
              <td colspan="100%" class="row-wrap">
            <tr class="parent-row">
              <td>
                <a (click)="togglePicklist(i)"
                  href="javascript:void(0)">{{ pickList?.pickingDate | date: "dd-MM-yyyy" }} picklist
                  summary consolidated</a>
              </td>
              <td>All({{ pickList?.picklists?.length }})</td>
              <td>
                {{ getAssociateNameById(pickList?.picklistAssociateId) }}
              </td>
              <td>{{ pickList?.pickingDate | date: "dd-MM-yyyy" }}</td>
              <!-- <td>{{ pickList.value?.expectedShipDate }}</td> -->
              <td>N/A</td>
              <td>
                <img src="assets/images/print.png" alt="" (click)="onPrintInvoice(i)" />
              </td>
              <td>

              </td>
            </tr>
            <ng-template [ngIf]="showPicklist && i === selectedPicklistIndex">

              <tr class="child-row" *ngFor="
                      let pickLists of pickList.picklists;
                      let i = index;
                      trackBy: trackByFn
                    ">
                <td>
                  <a href="javascript:void(0)"
                    (click)="hopToShipment(pickLists?.picklistCode)">{{ pickLists.picklistCode }}</a>
                </td>
                <td>
                  {{ pickLists.routeID }}
                </td>
                <td>
                  {{ pickLists.picklistAssociate }}
                </td>
                <!-- <td>{{ pickList.value?.expectedShipDate }}</td> -->
                <td>
                  {{ pickLists.pickingDate }}
                </td>
                <td>
                  {{ pickLists.status }}
                </td>
                <td>
                  <img src="assets/images/print.png" alt="" (click)="onPrintInvoice(i, pickLists?.picklistCode)" />
                </td>
                <td>
                  <img src="assets/images/print.png" alt="" (click)="onPrintSheet(pickLists?.picklistCode)" />
                </td>
              </tr>
            </ng-template>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-template #noDataBlock>
        <div class="no-data" style="display:block">
          No Data Found
        </div>
      </ng-template>
    </section>
  </div>
</main>
