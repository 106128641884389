import { Component, OnInit } from '@angular/core';
import { PrintService, WholesaleService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
    selector: "app-recall-sheet",
    templateUrl: "./recall-sheet.component.html",
    styleUrls: ['./recall-sheet.component.scss']
})
export class RecallSheetComponent implements OnInit {
    picklistId: any;
    recallSheetData: any;
    pickingDate = new Date()
    numberOfAsins: any;

    constructor(private printService: PrintService,
        private route: ActivatedRoute,
        private wholesaleService: WholesaleService,
        private datePipe: DatePipe) { }

    ngOnInit() {
        this.picklistId = this.route.snapshot.params["picklistId"];
        let encodedPicklistId = encodeURIComponent(this.picklistId)
        this.wholesaleService.getRecallPickListDetails(encodeURIComponent(encodedPicklistId)).subscribe(
            (response) => {
                if (response) {
                    this.numberOfAsins = response['total']
                    if (response['data']) {
                        this.recallSheetData = response['data']
                        this.printService.onDataReady()
                    }
                }
            }
        )
    }
}