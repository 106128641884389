import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recall-pack-item-layout',
  templateUrl: './recall-pack-item-layout.component.html',
  styleUrls: ['./recall-pack-item-layout.component.sass']
})
export class RecallPackItemLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
