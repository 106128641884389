"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var dialog_1 = require("@angular/material/dialog");
var consignment_recall_action_1 = require("../../actions/consignment-recall.action");
var selector_1 = require("../../reducers/selector");
var alert_service_1 = require("../../../shared/services/alert.service");
var shared_service_1 = require("../../../shared/services/shared.service");
var constants_1 = require("../../../shared/constants/constants");
var console_logger_service_1 = require("../../../shared/services/console-logger.service");
var bag_action_1 = require("../../../bags/bag.action");
var bag_selectors_1 = require("../../../bags/bag-selectors");
var services_1 = require("../../../shared/services");
var filestack = require("filestack-js");
var environment_1 = require("@env/environment");
var client = filestack.init(environment_1.environment.FILESTACK_APIKEY);
var RecallListComponent = /** @class */ (function () {
    function RecallListComponent(store, alertService, sharedService, router, datePipe, dialog, loggerService, printService, wholesaleService) {
        this.store = store;
        this.alertService = alertService;
        this.sharedService = sharedService;
        this.router = router;
        this.datePipe = datePipe;
        this.dialog = dialog;
        this.loggerService = loggerService;
        this.printService = printService;
        this.wholesaleService = wholesaleService;
        this.activeTab = "CREATED";
        this.activeTabIndex = 0;
        this.consignmentList = [];
        this.consignmentCount = 0;
        this.getListRequestBody = {
            page: 1,
            perPage: 100,
            status: this.activeTab
        };
        this.ROUTES = constants_1.ROUTES;
        this.actionPopup = false;
        this.actionPackBagsPopup = false;
        this.actionDispatchBagsPopup = false;
        this.actionFormGroup = new forms_1.FormGroup({
            containerQty: new forms_1.FormControl(""),
            handoverURL: new forms_1.FormControl("")
        });
        this.filterOrderNo = new forms_1.FormControl("");
        this.filterVendorCode = new forms_1.FormControl("");
        this.filterInventoryType = new forms_1.FormControl("");
        this.filterExpProcessingFrom = new forms_1.FormControl("");
        this.filterExpProcessingTo = new forms_1.FormControl("");
        this.filterExpCompletionFrom = new forms_1.FormControl("");
        this.filterExpCompletionTo = new forms_1.FormControl("");
        this.filterProcessingFrom = new forms_1.FormControl("");
        this.filterProcessingTo = new forms_1.FormControl("");
        this.filterCompletionFrom = new forms_1.FormControl("");
        this.filterCompletionTo = new forms_1.FormControl("");
        this.masterInventoryTypeList = [
            { label: "Good", value: "GOOD" },
            { label: "Bad", value: "BAD" },
            { label: "QC Rejected", value: "QC_REJECTED" },
            { label: "Transfer", value: "TRANSFER" }
        ];
        this.dateFilterType = [
            "Yesterday",
            "Last 7 Days",
            "Last 30 Days",
            "Last 90 Days",
            "This month",
            "Last month"
        ];
        this.bagListToBeDispatched = [];
        this.scannedBagBarcode = new forms_1.FormControl("");
        this.dispatchedBagsCount = 0;
        this.navigateToListDetails = false; //For Preserving Tab Selection If User Navigates Back From Details Page
        this.isFilterTypeTransfer = false;
        this.warehouseIds = [646, 647, 648, 650, 651, 652];
    }
    RecallListComponent.prototype.ngOnInit = function () {
        this.getWarehouseDetails();
        if (this.sharedService.activeTabIndex != null) {
            this.activeTabIndex = this.sharedService.activeTabIndex;
            this.changeActiveTab(this.activeTabIndex);
        }
        else {
            this.getConsignmentList();
        }
    };
    RecallListComponent.prototype.getWarehouseDetails = function () {
        var _this = this;
        this.isLoading = true;
        this.wholesaleService.getWarehouseList().subscribe(function (response) {
            var selWarehouse = localStorage.getItem('selectedWarehouseID');
            _this.isLoading = false;
            if (response && response['data'] && selWarehouse) {
                _this.warehouseObj = response['data'].find(function (obj) { return obj.externalWarehouseID === selWarehouse; });
            }
        });
    };
    // Open filter popup
    RecallListComponent.prototype.showFilter = function (formcontrolName) {
        this.activeFilter = formcontrolName;
    };
    /**
     * Reset filters
     */
    RecallListComponent.prototype.resetFilter = function (formcontrolName) {
        // Reset formcontrols
        switch (formcontrolName) {
            case "filterOrderNo":
            case "filterVendorCode":
            case "filterInventoryType":
                this[formcontrolName].reset();
                break;
            case "filterExpProcessingFromTo":
                this.filterExpProcessingFrom.reset();
                this.filterExpProcessingTo.reset();
                break;
            case "filterExpCompletion":
                this.filterExpCompletionFrom.reset();
                this.filterExpCompletionTo.reset();
                break;
            case "filterProcessedFromTo":
                this.filterProcessingFrom.reset();
                this.filterProcessingTo.reset();
                break;
            case "filterCompletedFromTo":
                this.filterCompletionFrom.reset();
                this.filterCompletionTo.reset();
                break;
        }
        // Close all filters popup
        this.activeFilter = "";
        // Submit filters
        this.applyFilters();
    };
    /**
     * Set and submit filters
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.applyFilters = function () {
        this.activeFilter = "";
        // Set order number filter request
        if (this.filterOrderNo.value) {
            this.getListRequestBody["orderNumber"] = this.filterOrderNo.value;
        }
        else {
            delete this.getListRequestBody["orderNumber"];
        }
        // Set vendor code filter request
        if (this.filterVendorCode.value) {
            this.getListRequestBody["vendorCode"] = this.filterVendorCode.value;
        }
        else {
            delete this.getListRequestBody["vendorCode"];
        }
        // Set inventory type filter request
        if (this.filterInventoryType.value) {
            if (this.filterInventoryType.value == "TRANSFER") {
                this.isFilterTypeTransfer = true;
            }
            else {
                this.isFilterTypeTransfer = false;
                this.getListRequestBody["inventoryType"] = this.filterInventoryType.value;
            }
        }
        else {
            this.isFilterTypeTransfer = false;
            delete this.getListRequestBody["inventoryType"];
        }
        // Set expected processing date filter request
        if (this.filterExpProcessingFrom.value &&
            this.filterExpProcessingTo.value) {
            this.getListRequestBody["expProcessingFrom"] = this.datePipe.transform(this.filterExpProcessingFrom.value, "y-MM-dd");
            this.getListRequestBody["expProcessingTo"] = this.datePipe.transform(this.filterExpProcessingTo.value, "y-MM-dd");
        }
        else {
            delete this.getListRequestBody["expProcessingFrom"];
            delete this.getListRequestBody["expProcessingTo"];
        }
        // Set expected completion date filter request
        if (this.filterExpCompletionFrom.value &&
            this.filterExpCompletionTo.value) {
            this.getListRequestBody["expCompletionFrom"] = this.datePipe.transform(this.filterExpCompletionFrom.value, "y-MM-dd");
            this.getListRequestBody["expCompletionTo"] = this.datePipe.transform(this.filterExpCompletionTo.value, "y-MM-dd");
        }
        else {
            delete this.getListRequestBody["expCompletionFrom"];
            delete this.getListRequestBody["expCompletionTo"];
        }
        // Set processed date filter request
        if (this.filterProcessingFrom.value && this.filterProcessingTo.value) {
            this.getListRequestBody["processingFrom"] = this.datePipe.transform(this.filterProcessingFrom.value, "y-MM-dd");
            this.getListRequestBody["processingTo"] = this.datePipe.transform(this.filterProcessingTo.value, "y-MM-dd");
        }
        else {
            delete this.getListRequestBody["processingFrom"];
            delete this.getListRequestBody["processingTo"];
        }
        // Set completion date filter request
        if (this.filterCompletionFrom.value && this.filterCompletionTo.value) {
            this.getListRequestBody["completionFrom"] = this.datePipe.transform(this.filterCompletionFrom.value, "y-MM-dd");
            this.getListRequestBody["completionTo"] = this.datePipe.transform(this.filterCompletionTo.value, "y-MM-dd");
        }
        else {
            delete this.getListRequestBody["completionFrom"];
            delete this.getListRequestBody["completionTo"];
        }
        // Set order number filter request
        // if(this.filterExpProcessingFrom.value && this.filterExpProcessingTo.value){
        //   this.getListRequestBody['expProcessingFrom'] = this.filterExpProcessingFrom.value;
        //   this.getListRequestBody['expProcessingTo'] = this.filterExpProcessingTo.value;
        // }else{
        //   delete this.getListRequestBody['expProcessingFrom'];
        //   delete this.getListRequestBody['expProcessingTo'];
        // }
        this.getConsignmentList();
    };
    /**
     * Paginate functionality
     * @param pageEvent
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.onPaginate = function (pageEvent) {
        this.getListRequestBody.page = pageEvent.pageIndex + 1;
        this.getListRequestBody.perPage = pageEvent.pageSize;
        this.getConsignmentList();
    };
    // Method to Switch Consignment Color
    RecallListComponent.prototype.switchConsignmentColor = function (inventoryType) {
        switch (inventoryType) {
            case "GOOD":
                return "#BBFDD1";
            case "BAD":
                return "#FE9EB7";
            case "CUSTOMER-RETURN":
            case "QC_REJECTED":
                return "#FEFB98";
            default:
                return "#FDFBFB;";
        }
    };
    /**
     * @description Navigate to recall consignment detail page if status is not created
     * @param consignmentNumber
     * @param consignmentStatus
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.navigateToDetailPage = function (consignmentNumber, consignmentStatus) {
        if (consignmentStatus != "CREATED") {
            this.sharedService.activeTabIndex = this.activeTabIndex;
            this.navigateToListDetails = true;
            this.router.navigate([
                this.ROUTES.CONSIGNMENT_RECALL_DETAILS +
                    this.accountId +
                    "/" +
                    encodeURIComponent(consignmentNumber)
            ]);
        }
    };
    /**
     * Change active tab and call list
     * @param selectedTab
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.changeActiveTab = function (matTabEvent) {
        // Change active tab
        // if (matTabEvent.index || matTabEvent.index === 0) {
        // Set selected tab value
        switch (matTabEvent) {
            case 0:
                this.activeTab = "CREATED";
                break;
            case 1:
                this.activeTab = "PROCESSING";
                break;
            case 2:
                this.activeTab = "PACKED";
                break;
            case 3:
                this.activeTab = "DISPATCHED";
                break;
            case 4:
                this.activeTab = "";
                break;
        }
        this.getListRequestBody["status"] = this.activeTab;
        // Set selected tab index
        this.activeTabIndex = matTabEvent;
        // }
        // Call list
        this.getConsignmentList();
    };
    /**
     * Get consignment list
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.getConsignmentList = function () {
        var _this = this;
        this.consignmentList = [];
        if (this.getListRequestBody["status"] == "") {
            delete this.getListRequestBody["status"];
        }
        this.store.dispatch(new consignment_recall_action_1.GetConsignmentListAction(this.getListRequestBody));
        this.store.pipe(store_1.select(selector_1.getConsignmentListSelector)).subscribe(function (result) {
            if (!result) {
                return false;
            }
            _this.isLoading = false;
            if (_this.isFilterTypeTransfer) {
                _this.consignmentList = _this.getFilteredConsignment(result["data"]);
                if (result["data"] && result["data"].length != 0) {
                    _this.accountId = result["data"][0]["accountId"];
                }
                _this.consignmentCount = _this.consignmentList.length;
            }
            else {
                _this.filterInventoryType.value == "GOOD"
                    ? (_this.consignmentList = _this.getFilteredConsignment(result["data"]))
                    : (_this.consignmentList = result["data"]);
                if (result["data"] && result["data"].length != 0) {
                    _this.accountId = result["data"][0]["accountId"];
                }
                _this.filterInventoryType.value == "GOOD"
                    ? (_this.consignmentCount = _this.consignmentList.length)
                    : (_this.consignmentCount = result["total"]);
            }
        });
        this.store
            .pipe(store_1.select(selector_1.getConsignmentListErrorSelector))
            .subscribe(function (error) {
            if (error) {
                _this.isLoading = false;
            }
        });
        this.store
            .pipe(store_1.select(selector_1.getConsignmentListLoadingSelector))
            .subscribe(function (isLoading) {
            if (isLoading) {
                _this.isLoading = true;
            }
        });
        this.store
            .pipe(store_1.select(selector_1.getConsignmentListLoadedSelector))
            .subscribe(function (isLoaded) {
            if (isLoaded) {
                _this.isLoading = false;
            }
        });
    };
    RecallListComponent.prototype.getFilteredConsignment = function (consigmentData) {
        var _this = this;
        var consignmentList = [];
        if (this.filterInventoryType.value == "TRANSFER") {
            consignmentList = consigmentData.filter(function (consignmentData) {
                return consignmentData.type == _this.filterInventoryType.value;
            });
        }
        else if (this.filterInventoryType.value == "GOOD") {
            consignmentList = consigmentData.filter(function (consignmentData) {
                return (consignmentData.inventoryType == _this.filterInventoryType.value &&
                    consignmentData.type != "TRANSFER");
            });
        }
        return consignmentList;
    };
    /**
     * Start Processing
     * Mark as Packed
     * Mark as Complete
     *
     * @param requiredAction
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.markAction = function (requiredAction, consignmentNumber, consignmentType) {
        this.uploadedFilename = "";
        this.actionFormGroup.reset();
        var updateRequestBody = {
            consignmentNumber: encodeURIComponent(consignmentNumber)
        };
        if (this.updateConsignment$) {
            this.updateConsignment$.unsubscribe();
        }
        switch (requiredAction) {
            case "START_PROCESSING":
                updateRequestBody["status"] = "PROCESSING";
                if (consignmentType) {
                    updateRequestBody["type"] = "transfer";
                }
                this.store.dispatch(new consignment_recall_action_1.UpdateConsignmentAction(updateRequestBody));
                this.updateConsignmentHandling();
                break;
            case "MARK_PACKED":
                updateRequestBody["status"] = "PACKED";
                if (consignmentType) {
                    updateRequestBody["type"] = "transfer";
                }
                this.store.dispatch(new consignment_recall_action_1.UpdateConsignmentAction(updateRequestBody));
                this.updateConsignmentHandling();
                break;
            case "MARK_COMPLETED":
                this.getBagList(consignmentNumber, "TO_BE_DISPATCHED");
                if (consignmentType) {
                    updateRequestBody["type"] = "transfer";
                }
                this.actionConsignmentNumber = consignmentNumber;
                this.actionName = "MARK_COMPLETED";
                break;
        }
    };
    /**
     * Action form submit
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.actionFormSubmit = function () {
        // Validations
        if (!this.actionFormGroup.value.containerQty &&
            !this.actionFormGroup.value.handoverURL &&
            this.bagListToBeDispatched.length > 0) {
            this.alertService.showError("Please enter required values");
            return false;
        }
        var updateRequestBody = {
            consignmentNumber: encodeURIComponent(this.actionConsignmentNumber)
        };
        // Mark as dispatched/completed
        if (this.actionName == "MARK_COMPLETED") {
            // Validation
            if (!this.actionFormGroup.value.handoverURL) {
                this.alertService.showError("Please upload handover document");
                return false;
            }
            updateRequestBody["status"] = "DISPATCHED";
            updateRequestBody["pohAttachmentUrl"] = this.actionFormGroup.value.handoverURL;
        }
        // Update request
        this.store.dispatch(new consignment_recall_action_1.UpdateConsignmentAction(updateRequestBody));
        this.updateConsignmentHandling();
    };
    /**
     * Update consignment handling
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.cm>
     */
    RecallListComponent.prototype.updateConsignmentHandling = function () {
        var _this = this;
        this.updateConsignment$ = this.store
            .pipe(store_1.select(selector_1.updateConsignmentSelector))
            .subscribe(function (result) {
            _this.loggerService.consoleLog(result);
            if (result) {
                if (result["unpackedBagDetails"] &&
                    result["unpackedBagDetails"].length > 0) {
                    _this.getBagList(result["unpackedBagDetails"][0]["refNumber"], "TO_BE_PACKED");
                }
                else if (result["status"]) {
                    // Success
                    // Mark processing
                    if (result["status"] == "PROCESSING") {
                        _this.alertService.showSuccess("Consignment successfully marked processing, now please process it.");
                        _this.getConsignmentList();
                        _this.actionPopup = false;
                    }
                    // Mark packed
                    else if (result["status"] == "PACKED") {
                        _this.alertService.showSuccess("Order Number " +
                            result["consignmentNumber"] +
                            " packed successfully.");
                        _this.getConsignmentList();
                        _this.actionPopup = false;
                    }
                    // Mark dispatched/completed
                    else if (result["status"] == "DISPATCHED") {
                        _this.actionDispatchBagsPopup = false;
                        _this.alertService.showSuccess("Order Number " +
                            result["consignmentNumber"] +
                            " dispatched successfully.");
                        _this.getConsignmentList();
                        _this.actionPopup = false;
                    }
                    else {
                        // Failure
                        _this.alertService.showError("Something went wrong please try again later.");
                    }
                }
                else {
                    // Error
                    console.log(result);
                }
            }
        });
        this.store.pipe(store_1.select(selector_1.updateConsignmentErrorSelector)).subscribe(function (error) {
            _this.isLoading = false;
        });
        this.store
            .pipe(store_1.select(selector_1.updatingConsignmentSelector))
            .subscribe(function (isUpdating) {
            if (isUpdating) {
                _this.isLoading = true;
            }
        });
        this.store.pipe(store_1.select(selector_1.updatedConsignmentSelector)).subscribe(function (isUpdated) {
            if (isUpdated) {
                _this.isLoading = false;
            }
        });
    };
    RecallListComponent.prototype.getBagList = function (recallConsignmentNumber, statusCase) {
        var _this = this;
        if (this.bagList$) {
            this.bagList$.unsubscribe();
        }
        var requestBody = {
            page: 1,
            perPage: 1000,
            recallConsignmentNumber: recallConsignmentNumber
        };
        if (statusCase == "TO_BE_DISPATCHED") {
            requestBody["status"] = "COMPLETE" + "," + "DISPATCHED";
        }
        else if (statusCase == "TO_BE_PACKED") {
            requestBody["status"] = "CREATED" + "," + "PROCESSING";
        }
        this.store.dispatch(new bag_action_1.GetBagList(requestBody));
        this.bagList$ = this.store.pipe(store_1.select(bag_selectors_1.bagListSelector)).subscribe(function (data) {
            if (data) {
                if (statusCase == "TO_BE_DISPATCHED") {
                    _this.actionDispatchBagsPopup = true;
                    _this.bagListToBeDispatched = data["data"];
                    _this.dispatchedBagsCount = data["data"].filter(function (element) {
                        return element.status == "DISPATCHED";
                    }).length;
                }
                else if (statusCase == "TO_BE_PACKED") {
                    _this.actionPackBagsPopup = true;
                    _this.bagListToBePacked = data["data"];
                    // If no records found for created and processing bags
                    // if(data['data'] && data['data'].length == 0){
                    //   this.actionPackBagsPopup = false;
                    //   this.bagListToBePacked = null;
                    //   this.markAction('MARK_PACKED', recallConsignmentNumber);
                    // }else{
                    //   this.actionPackBagsPopup = true;
                    //   this.bagListToBePacked = data['data'];
                    // }
                }
            }
        });
    };
    /**
     * Upload file on stack
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallListComponent.prototype.onFileStackUpload = function () {
        var _this = this;
        var fileStackOption = {
            maxFiles: 10,
            accept: [".pdf", ".jpg", ".xls", ".csv", ".xlsx", ".png", ".jpeg"],
            uploadInBackground: false,
            startUploadingWhenMaxFilesReached: true,
            fromSources: [
                "local_file_system",
                "imagesearch",
                "googledrive",
                "dropbox"
            ],
            onOpen: function () { },
            dropPane: {},
            storeTo: {
                location: "gcs",
                path: "/wms-frontend/"
            },
            onUploadDone: function (response) {
                console.log("response from filestack", response);
                if (response.filesUploaded && response.filesUploaded.length > 0) {
                    _this.actionFormGroup
                        .get("handoverURL")
                        .patchValue(response.filesUploaded[0].url);
                    _this.uploadedFilename = response.filesUploaded[0].filename;
                }
            }
        };
        client.picker(fileStackOption).open();
        // Can be removed after testing the upgraded version of filestack
        // this.sharedService
        //   .uploadDocument()
        //   .then(response => {
        //     if (response.filesUploaded && response.filesUploaded.length > 0) {
        //       this.actionFormGroup.get('handoverURL').patchValue(response.filesUploaded[0].url);
        //       this.uploadedFilename = response.filesUploaded[0].filename;
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
    };
    RecallListComponent.prototype.updateBagStatus = function (bagID, status) {
        var _this = this;
        if (this.updateBag$) {
            this.updateBag$.unsubscribe();
        }
        // Mark bag as dispatch
        if (status == "DISPATCH") {
            var findPackedBag = this.bagListToBeDispatched.filter(function (element) {
                return element["bagNumber"] == bagID && element["status"] == "COMPLETE";
            });
            if (findPackedBag) {
                this.store.dispatch(new bag_action_1.UpdateBag({
                    bagID: bagID,
                    status: "DISPATCHED"
                }));
                this.updateBag$ = this.store
                    .pipe(store_1.select(bag_selectors_1.updateBagSelector))
                    .subscribe(function (data) {
                    if (!data) {
                        return;
                    }
                    if (data.status == "DISPATCHED") {
                        _this.alertService.showSuccess("Bag " + data["bagNumber"] + " marked as scanned successfully");
                        _this.actionFormGroup.reset();
                        _this.scannedBagBarcode.reset();
                    }
                    _this.getBagList(data["refNumber"], "TO_BE_DISPATCHED");
                });
            }
        }
        else {
            // Mark bag as packed/complete
            this.store.dispatch(new bag_action_1.UpdateBag({
                bagID: bagID,
                status: status
            }));
            this.updateBag$ = this.store
                .pipe(store_1.select(bag_selectors_1.updateBagSelector))
                .subscribe(function (data) {
                if (!data) {
                    return;
                }
                if (data.status == status) {
                    if (data.status == "COMPLETE" && data.refNumber) {
                        _this.alertService.showSuccess("Bag " + data.bagNumber + " packed successfully");
                        _this.printService.printDocument("packed-bag", [
                            data["bagNumber"]
                        ]);
                        _this.store
                            .pipe(store_1.select(bag_selectors_1.bagItemDetailsSelector))
                            .subscribe(function (data) {
                            if (data && data["data"]) {
                                _this.getBagList(data["data"]["recallConsignmentNumber"], "TO_BE_PACKED");
                            }
                        });
                    }
                    else if (data.status == "DISCARDED") {
                        _this.alertService.showSuccess("Bag " + data.bagNumber + " discarded successfully");
                        _this.getBagList(data["refNumber"], "TO_BE_PACKED");
                    }
                }
            });
        }
    };
    RecallListComponent.prototype.ngOnDestroy = function () {
        if (this.bagList$) {
            this.bagList$.unsubscribe();
        }
        if (this.updateBag$) {
            this.updateBag$.unsubscribe();
        }
        if (this.updateConsignment$) {
            this.updateConsignment$.unsubscribe();
        }
        this.accountId = null;
        if (!this.navigateToListDetails) {
            this.sharedService.activeTabIndex = null;
        }
    };
    RecallListComponent.prototype.orderclick = function () {
        this.orderNo = !this.orderNo;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.vendorclick = function () {
        this.VendorCode = !this.VendorCode;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.Inventoryclick = function () {
        this.Inventory = true;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.Completedonclick = function () {
        this.Completedon = true;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.ProcessedFromclick = function () {
        this.ProcessedFrom = true;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.ExpProcessingclick = function () {
        this.ExpProcessing = true;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.Expcompletionbyclick = function () {
        this.Expcompletionby = true;
        this.fileroverlay = !this.fileroverlay;
    };
    RecallListComponent.prototype.overlayclick = function () {
        this.orderNo = false;
        this.VendorCode = false;
        this.Inventory = false;
        this.Inventory = false;
        this.Completedon = false;
        this.ProcessedFrom = false;
        this.ExpProcessing = false;
        this.fileroverlay = false;
        this.Expcompletionby = false;
    };
    return RecallListComponent;
}());
exports.RecallListComponent = RecallListComponent;
