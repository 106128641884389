"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var api_service_1 = require("./api.service");
var store_1 = require("@ngrx/store");
var auth0_lock_1 = require("auth0-lock");
var environment_1 = require("@env/environment");
var router_1 = require("@angular/router");
var constants_1 = require("@app/shared/constants/constants");
var actions_1 = require("../../actions");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("@ngrx/store");
var i3 = require("@angular/router");
var AuthService = /** @class */ (function () {
    /**
     * Creates an instance of AuthService
     * @param platformId - platformId
     * @param api - HTTP service to call the APIS
     * @param store - Store
     * */
    function AuthService(platformId, api, store, router) {
        this.platformId = platformId;
        this.api = api;
        this.store = store;
        this.router = router;
        // private _Auth0 = new auth0.WebAuth({
        //   clientID: environment.auth.clientID,
        //   domain: environment.auth.domain,
        //   responseType: "token",
        //   redirectUri: environment.auth.redirect,
        //   scope: environment.auth.scope
        // });
        this.auth0 = new auth0_lock_1.default(environment_1.environment.auth.clientID, environment_1.environment.auth.domain, {
            oidcConformant: true,
            autoclose: true,
            rememberLastLogin: false,
            allowSignUp: false,
            allowedConnections: ["Username-Password-Authentication"],
            auth: {
                autoParseHash: false,
                // redirectUrl: environment.production
                //   ? "https://" + window.location.hostname + "/callback"
                //   : "http://" + window.location.hostname + "4200/callback",
                redirectUrl: environment_1.environment.auth.redirect,
                responseType: "token id_token",
                audience: environment_1.environment.auth.audience,
                params: {
                    scope: "openid profile email app_metadata"
                }
            },
            theme: {
                logo: "assets/images/logo2.png",
                primaryColor: "#31324F",
                displayName: "Sign in"
            },
            languageDictionary: {
                emailInputPlaceholder: "Email",
                title: "WMS",
                passwordInputPlaceholder: "Password"
            }
        });
        // Track whether or not to renew token
        this._authFlag = "isLoggedIn";
        this._onAuthFailureUrl = "";
        this._logoutUrl = "http://localhost:4200";
        this._onAuthSuccessUrl =
            (window.location.hostname !== "warehouse.auperator.co" &&
                window.location.hostname !== "warehouse.eshopbox.com")
                ? constants_1.ROUTES.WHOLESALE
                : constants_1.ROUTES.INBOUND;
    }
    AuthService.prototype.handleAuthenticationWithHash = function () {
        var _this = this;
        this.auth0.resumeAuth(window.location.hash, function (err, authResult) {
            //alert('hi')
            console.log('hey1', authResult);
            if (authResult && authResult.accessToken && authResult.idToken) {
                // alert('nohi')
                _this.setAuth(authResult);
                _this.router.navigate([_this.authSuccessUrl]);
            }
            else if (err) {
                // alert(`Error: ${err.error}. Check the console for further details.`);
                _this.router.navigate([_this.authFailureUrl]);
            }
        });
    };
    AuthService.prototype.getAuthToken = function () {
        if (common_1.isPlatformBrowser(this.platformId)) {
            // alert();
            return localStorage.getItem("token");
        }
    };
    AuthService.prototype.isLoggedIn = function () {
        if (this.getAuthToken() == null) {
            return false;
        }
        return true;
    };
    Object.defineProperty(AuthService.prototype, "authSuccessUrl", {
        /**
         * Call the Login API and store the user in localStorage.
         * @param email - email of the user;
         * @param password - password of the user;
         * @returns user - User from the response of the API;
         */
        // login({ username, password }) {
        //   const params = { data: { username: username, password: password } };
        //   return this.api.post(apiUrl.login, params).pipe(
        //     map(user => {
        //       this.setAuthToken(JSON.stringify(user), "token");
        //       return user;
        //     })
        //   );
        // }
        get: function () {
            return this._onAuthSuccessUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authFailureUrl", {
        get: function () {
            return this._onAuthFailureUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "authenticated", {
        get: function () {
            return JSON.parse(localStorage.getItem(this._authFlag));
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.resetAuthFlag = function () {
        localStorage.removeItem(this._authFlag);
    };
    AuthService.prototype.login = function () {
        this.auth0.show();
    };
    AuthService.prototype.setAuth = function (authResult) {
        console.log(authResult);
        this._expiresAt = authResult.expiresIn * 1000 + Date.now();
        // Set flag in local storage stating this app is logged in
        localStorage.setItem(this._authFlag, JSON.stringify(true));
        localStorage.setItem("token", authResult.accessToken);
    };
    AuthService.prototype.logout = function () {
        // Set authentication status flag in local storage to false
        localStorage.clear();
        this.store.dispatch(new actions_1.Logout());
        // This does a refresh and redirects back to homepage
        // Make sure you have the logout URL in your Auth0
        // Dashboard Application settings in Allowed Logout URLs
        this.router.navigate([constants_1.ROUTES.LOGIN]);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
exports.AuthService = AuthService;
