import { MetaReducer, ActionReducer, ActionReducerMap } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

import { ActionTypes } from "./actions";
import { AppState } from "./app.state";
import * as fromAuth from "./auth/reducers/auth.reducer";
import * as fromUser from "./user/reducers/user.reducer";
import * as fromWholesale from "./wholesale/reducers/wholesale.reducer";
import * as fromConsignmentReceive from "./consignment-receive/reducers/con-receive.reducer";
import * as fromDashboard from "./dashboard/reducers/dashboard.reducer";
import * as fromShipmentModule from "./shipment/reducers/shipment-module.state";
import * as fromRecallReducer from "./consignment-recall/reducers/consignment-recall.reducer";
import * as fromBagReducer from './bags/bag.reducer';
import * as fromBrandReducer from './shared/stores/brand/brand.reducer';

// const reducers = {
//   auth: fromAuth.AuthReducer,
//   user: fromUser.UserReducer,
//   wholesale: fromWholesale.WholesaleReducer
// };

// export const developmentReducer: ActionReducer<AppState> = combineReducers(
//   reducers
// );

// export function AppReducer(state: any, action: any) {
//   return developmentReducer(state, action);
// }

export function defaultReducer<T>(state: T) {
  return state;
}
export const initialReducerMap = {
  auth: fromAuth.AuthReducer,
  user: fromUser.UserReducer,
  wholesale: fromWholesale.WholesaleReducer,
  receiveState: fromConsignmentReceive.ConReceiveReducer,
  dashboard: fromDashboard.DashboardReducer,
  consignmentRecallState: fromRecallReducer.ConsignmentRecallReducer,
  bagState: fromBagReducer.reducer,
  brandState: fromBrandReducer.reducer
  //shipment_module: fromShipmentModule.reducers
} as ActionReducerMap<AppState>;

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["wholesale"],
    rehydrate: true
  })(reducer);
}

export function getInitialState() {
  return {
    user: fromUser.initialState,
    auth: fromAuth.initialState,
    wholesale: fromWholesale.initialState,
    dashboard: fromDashboard.initialState,
    shipment_module: fromShipmentModule.initialState,
    receiveState: fromConsignmentReceive.initialState,
    consignmentRecallState: fromRecallReducer.initialState,
    bagState: fromBagReducer.initialState,
    brandState: fromBrandReducer.initialState
  } as AppState;
}

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  clearState
];
