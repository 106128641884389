import { Component, OnInit } from "@angular/core";
import { ROUTES } from '../../constants/constants';
import { environment } from "@env/environment";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;

  ROUTES = ROUTES;
  hostname;
  warehouseHostName = environment.production
    ? "warehouse.eshopbox.com"
    : "warehouse.auperator.co";

  constructor() {}

  ngOnInit() {
    this.hostname = window.location.hostname;
  }
}
