"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var store_1 = require("@ngrx/store");
var RecallAction = require("../actions/consignment-recall.action");
var consignment_recall_service_1 = require("../services/consignment-recall.service");
var operators_1 = require("rxjs/operators");
var ConsignmentEffects = /** @class */ (function () {
    function ConsignmentEffects(actions$, consignmentService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.consignmentService = consignmentService;
        this.store = store;
        /**
         * Effect to handle get consignment details api
         */
        this.getConsignmentList$ = this.actions$.pipe(effects_1.ofType(RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST), operators_1.mergeMap(function (action) {
            return _this.consignmentService.getConsignmentListService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        /**
         * Effect to handle update consignment api
         */
        this.updateConsignmentList$ = this.actions$.pipe(effects_1.ofType(RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT), operators_1.mergeMap(function (action) {
            return _this.consignmentService.updateConsignmentService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        /**
       * Effect to handle get grn items api
       */
        this.getGRNItemList$ = this.actions$.pipe(effects_1.ofType(RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS), operators_1.mergeMap(function (action) {
            return _this.consignmentService.getGRNItemListService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE,
                    payload: error['error']
                });
            }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], ConsignmentEffects.prototype, "getConsignmentList$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], ConsignmentEffects.prototype, "updateConsignmentList$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], ConsignmentEffects.prototype, "getGRNItemList$", void 0);
    return ConsignmentEffects;
}());
exports.ConsignmentEffects = ConsignmentEffects;
