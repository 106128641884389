import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  initialReducerMap,
  getInitialState,
  metaReducers
} from "./app.reducer";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SharedModule } from "./shared";
import { fakeBackendProvider } from "@shared/interceptors/fake-backend.interceptor";
import { responseProvider } from "@shared/interceptors/response.interceptor";
import { tokenProvider } from "@shared/interceptors/token.interceptor";
import { HomeComponent } from "./home/home.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppMaterialModule } from "./app-material/app-material.module";
import { PicklistsComponent } from "./components/picklists/picklists.component";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { WholesaleEffects } from "./wholesale/reducers/wholesale.effects";
import { HomeLayoutComponent } from "./components/_layouts/home-layout/home-layout.component";
import { LoginLayoutComponent } from "./components/_layouts/login-layout/login-layout.component";
import { ConsignReceiveLayoutComponent } from "./components/_layouts/consign-receive-layout/consign-receive-layout.component";
import { ReceiveHeaderComponent } from "./shared/components/receive-header/receive-header.component";
import { AmazonWholesaleComponent } from './components/amazon-wholesale/amazon-wholesale.component';
import { SearchProductComponent } from './components/search-product/search-product.component';
import { PackItemComponent } from './consignment-recall/components/pack-item/pack-item.component';
import { RecallPackItemLayoutComponent } from './components/_layouts/recall-pack-item-layout/recall-pack-item-layout.component';
import { RecallPackItemHeaderComponent } from './shared/components/recall-pack-item-header/recall-pack-item-header.component';
import { ConsignmentRecallModule } from './consignment-recall/consignment-recall.module';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AmazonWholesaleComponent,
    PicklistsComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    ConsignReceiveLayoutComponent,
    ReceiveHeaderComponent,
    SearchProductComponent,
    PackItemComponent,
    RecallPackItemLayoutComponent,
    RecallPackItemHeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // ReactiveFormsModule,
    // FormsModule,
    AppMaterialModule,
    AppRoutingModule,
    SharedModule,
    ConsignmentRecallModule,
    StoreModule.forRoot(initialReducerMap, { metaReducers }),
    EffectsModule.forRoot([WholesaleEffects]),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [tokenProvider, responseProvider, fakeBackendProvider, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
