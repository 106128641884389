"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["GET_CURRENT_USER"] = "[User Page] Get Current User";
    UserActionTypes["GET_ORDER"] = "[Order Page] Get Order";
    UserActionTypes["GET_USER_SUCCESS"] = "[User API] Get User Success";
    UserActionTypes["GET_ORDER_SUCCESS"] = "[Order API] Get Order Success";
    UserActionTypes["GET_USER_FAILED"] = "[User API] Get User Failed";
    UserActionTypes["GET_ORDER_FAILED"] = "[Order API] Get Order Failed";
    UserActionTypes["GET_SELECTED_SECTION"] = "[User Page] Get Selected Section";
    UserActionTypes["SET_SELECTED_SECTION"] = "[Section Effect] Set Selected Section";
})(UserActionTypes = exports.UserActionTypes || (exports.UserActionTypes = {}));
var GetUser = /** @class */ (function () {
    function GetUser() {
        this.type = UserActionTypes.GET_CURRENT_USER;
    }
    return GetUser;
}());
exports.GetUser = GetUser;
var GetOrder = /** @class */ (function () {
    function GetOrder(payload) {
        this.payload = payload;
        this.type = UserActionTypes.GET_ORDER;
    }
    return GetOrder;
}());
exports.GetOrder = GetOrder;
var GetUserSuccess = /** @class */ (function () {
    function GetUserSuccess(payload) {
        this.payload = payload;
        this.type = UserActionTypes.GET_USER_SUCCESS;
    }
    return GetUserSuccess;
}());
exports.GetUserSuccess = GetUserSuccess;
var GetOrderSuccess = /** @class */ (function () {
    function GetOrderSuccess(payload) {
        this.payload = payload;
        this.type = UserActionTypes.GET_ORDER_SUCCESS;
    }
    return GetOrderSuccess;
}());
exports.GetOrderSuccess = GetOrderSuccess;
var GetUserFailed = /** @class */ (function () {
    function GetUserFailed() {
        this.type = UserActionTypes.GET_USER_FAILED;
    }
    return GetUserFailed;
}());
exports.GetUserFailed = GetUserFailed;
var GetOrderFailed = /** @class */ (function () {
    function GetOrderFailed() {
        this.type = UserActionTypes.GET_ORDER_FAILED;
    }
    return GetOrderFailed;
}());
exports.GetOrderFailed = GetOrderFailed;
var GetSelectedSection = /** @class */ (function () {
    function GetSelectedSection(payload) {
        this.payload = payload;
        this.type = UserActionTypes.GET_SELECTED_SECTION;
    }
    return GetSelectedSection;
}());
exports.GetSelectedSection = GetSelectedSection;
var SetSelectedSection = /** @class */ (function () {
    function SetSelectedSection(payload) {
        this.payload = payload;
        this.type = UserActionTypes.SET_SELECTED_SECTION;
    }
    return SetSelectedSection;
}());
exports.SetSelectedSection = SetSelectedSection;
