"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var bag_action_1 = require("../../bag.action");
var bag_selectors_1 = require("../../bag-selectors");
var print_service_1 = require("../../../shared/services/print.service");
var rxjs_1 = require("rxjs");
var BagPackPdfComponent = /** @class */ (function () {
    function BagPackPdfComponent(_activatedRoute, _store, printService, router, cdr) {
        this._activatedRoute = _activatedRoute;
        this._store = _store;
        this.printService = printService;
        this.router = router;
        this.cdr = cdr;
        this.bagPDFSubscriptionList$ = new rxjs_1.Subscription();
        this.showloader = false;
    }
    BagPackPdfComponent.prototype.ngAfterViewInit = function () {
        this.bagID = this._activatedRoute.snapshot.params["bagID"];
        if (localStorage.getItem("route") === null) {
            localStorage.setItem("route", this.bagID);
            this.getBagDetails();
        }
        else {
            this.router.navigateByUrl("/setting/bags/list");
            localStorage.removeItem("route");
        }
    };
    /**
     * @description Get bag details
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    BagPackPdfComponent.prototype.getBagDetails = function () {
        var _this = this;
        this._store.dispatch(new bag_action_1.GetBagListForPDF({
            page: 1,
            perPage: 1,
            bagCode: this.bagID
        }));
        this.bagPDFSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagListForPDFSelector)).subscribe(function (data) {
            if (data && data["data"]) {
                setTimeout(function () {
                    _this.getBagItemDetails();
                }, 1000);
                _this.bagDetails = data["data"][0];
            }
        }));
        this.bagPDFSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagListErrorForPDFSelector)).subscribe());
    };
    /**
     * @description Get bag item details
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    BagPackPdfComponent.prototype.getBagItemDetails = function () {
        var _this = this;
        this._store.dispatch(new bag_action_1.GetBagItemDetails({
            //page: 1,
            //per_page: 1,
            bagID: this.bagID
        }));
        this.bagPDFSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagItemDetailsSelector)).subscribe(function (data) {
            if (data && data["data"] && data["data"].length > 0) {
                _this.bagItemDetails = data["data"][0]["items"];
                _this.printService.onDataReady();
                localStorage.removeItem('route');
                // this._store.dispatch(new GetBagList({
                //   page: 1,
                //   per_page: 25
                // }));
                _this.bagPDFSubscriptionList$.unsubscribe();
            }
            else {
            }
        }));
        this.bagPDFSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagItemDetailsErrorSelector)).subscribe(function (err) {
            if (err) {
                localStorage.removeItem('route');
                _this.router.navigateByUrl("/setting/bags/list");
            }
        }));
    };
    /**
     * @description Get inventory type label
     * @param inventoryType
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    BagPackPdfComponent.prototype.getInventoryType = function (inventoryType) {
        switch (inventoryType) {
            case "GOOD":
                return "Good";
                break;
            case "BAD":
                return "Bad";
                break;
            case "QC_REJECTED":
                return "QC Rejected";
                break;
            default:
                return "";
                break;
        }
    };
    BagPackPdfComponent.prototype.ngOnDestroy = function () {
        this.bagPDFSubscriptionList$.unsubscribe();
    };
    return BagPackPdfComponent;
}());
exports.BagPackPdfComponent = BagPackPdfComponent;
