"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ngrx_store_localstorage_1 = require("ngrx-store-localstorage");
var actions_1 = require("./actions");
var fromAuth = require("./auth/reducers/auth.reducer");
var fromUser = require("./user/reducers/user.reducer");
var fromWholesale = require("./wholesale/reducers/wholesale.reducer");
var fromConsignmentReceive = require("./consignment-receive/reducers/con-receive.reducer");
var fromDashboard = require("./dashboard/reducers/dashboard.reducer");
var fromShipmentModule = require("./shipment/reducers/shipment-module.state");
var fromRecallReducer = require("./consignment-recall/reducers/consignment-recall.reducer");
var fromBagReducer = require("./bags/bag.reducer");
var fromBrandReducer = require("./shared/stores/brand/brand.reducer");
// const reducers = {
//   auth: fromAuth.AuthReducer,
//   user: fromUser.UserReducer,
//   wholesale: fromWholesale.WholesaleReducer
// };
// export const developmentReducer: ActionReducer<AppState> = combineReducers(
//   reducers
// );
// export function AppReducer(state: any, action: any) {
//   return developmentReducer(state, action);
// }
function defaultReducer(state) {
    return state;
}
exports.defaultReducer = defaultReducer;
exports.initialReducerMap = {
    auth: fromAuth.AuthReducer,
    user: fromUser.UserReducer,
    wholesale: fromWholesale.WholesaleReducer,
    receiveState: fromConsignmentReceive.ConReceiveReducer,
    dashboard: fromDashboard.DashboardReducer,
    consignmentRecallState: fromRecallReducer.ConsignmentRecallReducer,
    bagState: fromBagReducer.reducer,
    brandState: fromBrandReducer.reducer
    //shipment_module: fromShipmentModule.reducers
};
function localStorageSyncReducer(reducer) {
    return ngrx_store_localstorage_1.localStorageSync({
        keys: ["wholesale"],
        rehydrate: true
    })(reducer);
}
exports.localStorageSyncReducer = localStorageSyncReducer;
function getInitialState() {
    return {
        user: fromUser.initialState,
        auth: fromAuth.initialState,
        wholesale: fromWholesale.initialState,
        dashboard: fromDashboard.initialState,
        shipment_module: fromShipmentModule.initialState,
        receiveState: fromConsignmentReceive.initialState,
        consignmentRecallState: fromRecallReducer.initialState,
        bagState: fromBagReducer.initialState,
        brandState: fromBrandReducer.initialState
    };
}
exports.getInitialState = getInitialState;
function clearState(reducer) {
    return function (state, action) {
        if (action.type === actions_1.ActionTypes.LOGOUT) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
exports.clearState = clearState;
exports.metaReducers = [
    localStorageSyncReducer,
    clearState
];
