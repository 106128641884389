import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../constants/constants';

@Component({
  selector: 'app-recall-pack-item-header',
  templateUrl: './recall-pack-item-header.component.html',
  styleUrls: ['./recall-pack-item-header.component.sass']
})
export class RecallPackItemHeaderComponent implements OnInit {

  ROUTES = ROUTES;

  constructor() { }

  ngOnInit() {
  }

}
