"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var AuthActions = require("../actions/auth.actions");
exports.initialState = {
    isLoggedIn: false,
    user: {},
    filter: null
};
function AuthReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case AuthActions.AuthActionTypes.LOGIN_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { isLoggedIn: true });
        case AuthActions.AuthActionTypes.LOGOUT:
            return exports.initialState; // the initial state has isLoggedIn set to false
        case AuthActions.AuthActionTypes.SET_SEARCH_FILTER: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { filter: action.payload });
        }
        // case AuthActions.AuthActionTypes.LOGIN: {
        //   return state;
        // }
        // case AuthActions.AuthActionTypes.LOGIN_SUCCESS: {
        //   return {
        //     ...state,
        //     isLoggedIn: true,
        //     user: action.payload
        //   };
        // }
        // case AuthActions.AuthActionTypes.LOGIN_FAILED: {
        //   return {
        //     ...state,
        //     ...action.payload
        //   };
        // }
        case AuthActions.AuthActionTypes.GET_CURRENT_USER: {
            return state.user;
        }
        // case AuthActions.AuthActionTypes.LOGOUT: {
        //   return {
        //     ...state,
        //     isLoggedIn: false,
        //     user: {}
        //   };
        // }
        default:
            return state;
    }
}
exports.AuthReducer = AuthReducer;
