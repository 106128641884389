import { Effect, ofType, Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import * as BagActions from './bag.action';
import { BagService } from './bag.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Injectable } from '@angular/core';


@Injectable()
export class BagEffects {
    constructor(
        private bagService: BagService,
        private action$: Actions,
        private store: Store<{ bag: any }>
    ) {

    }

    // Get bag list effect
    @Effect()
    getBagList$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.GetBagList>(BagActions.GET_BAG_LIST),
        mergeMap(action => {
            return this.bagService.getBagListService(action.payload).pipe(
                map(data => ({
                    type: BagActions.GET_BAG_LIST_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.GET_BAG_LIST_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );

        // Get bag list for PDF effect
        @Effect()
        getBagListForPDF$: Observable<Action> = this.action$.pipe(
            ofType<BagActions.GetBagListForPDF>(BagActions.GET_BAG_LIST_FOR_PDF),
            mergeMap(action => {
                return this.bagService.getBagListService(action.payload).pipe(
                    map(data => ({
                        type: BagActions.GET_BAG_LIST_FOR_PDF_SUCCESS,
                        payload: data
                    })),
                    catchError(error =>
                        of({
                            type: BagActions.GET_BAG_LIST_FOR_PDF_FAILURE,
                            payload: error['error']
                        })
                    )
                );
            })
        );

    // Create bag effect
    @Effect()
    createBag$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.CreateBag>(BagActions.CREATE_BAG),
        mergeMap(action => {
            return this.bagService.createBagService(action.payload).pipe(
                map(data => ({
                    type: BagActions.CREATE_BAG_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.CREATE_BAG_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );

    // Update bag effect
    @Effect()
    updateBag$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.UpdateBag>(BagActions.UPDATE_BAG),
        mergeMap(action => {
            return this.bagService.updateBagService(action.payload).pipe(
                map(data => ({
                    type: BagActions.UPDATE_BAG_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.UPDATE_BAG_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );

    // Get bag item details effect
    @Effect()
    getBagItemDetails$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.GetBagItemDetails>(BagActions.GET_BAG_ITEM_DETAILS),
        mergeMap(action => {
            return this.bagService.getBagItemDetailsService(action.payload).pipe(
                map(data => ({
                    type: BagActions.GET_BAG_ITEM_DETAILS_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.GET_BAG_ITEM_DETAILS_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );

    // Get single item details effect
    @Effect()
    getSingleItemDetails2$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.GetSingleItemDetails>(BagActions.GET_SINGLE_ITEM_DETAILS),
        mergeMap(action => {
            return this.bagService.getSingleItemDetailsService(action.payload).pipe(
                map(data => ({
                    type: BagActions.GET_SINGLE_ITEM_DETAILS_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.GET_SINGLE_ITEM_DETAILS_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );

    // Add item details effect
    @Effect()
    addItemIntoBag$: Observable<Action> = this.action$.pipe(
        ofType<BagActions.AddItem>(BagActions.ADD_ITEM),
        mergeMap(action => {
            return this.bagService.addItemIntoBagService(action.payload).pipe(
                map(data => ({
                    type: BagActions.ADD_ITEM_SUCCESS,
                    payload: data
                })),
                catchError(error =>
                    of({
                        type: BagActions.ADD_ITEM_FAILURE,
                        payload: error['error']
                    })
                )
            );
        })
    );
}