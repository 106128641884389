import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService, InboundService } from '../../services';
import moment = require('moment');


@Component({
  selector: 'app-consignment-detail',
  templateUrl: './consignment-detail.component.html',
  styleUrls: ['./consignment-detail.component.sass']
})
export class ConsignmentDetailComponent implements OnInit {
  consignmentNo: string;
  currentDate = moment().format('LLLL');
  consignmentDetail = [];
  moment = moment;
  totalCases = 0;
  totalLoose = 0;
  totalBundles = 0;
  cases = 0;
  loose = 0;
  bundles = 0;
  foodFilteredList = [];
  nonfoodFilteredList = [];
  constructor(private route: ActivatedRoute, private printService: PrintService, private service: InboundService) { }

  ngOnInit() {
    this.consignmentNo = this.route.snapshot.params["consignmentNo"];
    this.getConsignmentDetail();
  }
  getConsignmentDetail() {

    this.service.getConsignmentDetail(this.consignmentNo).subscribe((res) => {
      this.consignmentDetail = res['data'];
      if (this.consignmentDetail.length) {
        this.consignmentDetail.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        );

        this.foodFilteredList = this.consignmentDetail.filter(resp => {
          return resp.isFood === "1";
        });
        this.nonfoodFilteredList = this.consignmentDetail.filter(
          response => {
            return response.isFood !== "1";
          }
        );

        this.calculateTotalRows(this.consignmentDetail);
        this.printService.onDataReady();
      }

    });

  }

  calculateTotalRows(items) {
    items.map(item => {
      if (item.isFood === "1") {
        this.totalCases += item.totalCases;
        this.totalLoose += item.totalLoose;
        this.totalBundles += item.bundles;
      }
      if (item.isFood !== "1") {
        this.cases += item.totalCases;
        this.loose += item.totalLoose;
        this.bundles += item.bundles;
      }
    });


  }
  trackByFn(index) {
    return index;
  }
}
