import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { SetFilter } from "@app/auth/actions/auth.actions";
import { SetAccountId } from "../../../wholesale/actions/wholesale.actions";

import { AuthService, WholesaleService, SharedService } from "../../services";
import { ROUTES } from "@app/shared/constants/constants";
import { AppState } from "../../../app.state";
import { getAccountIdStatus } from "@app/wholesale/reducers/selectors";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    public auth: AuthService,
    private store: Store<AppState>,
    // private wareHouseStore : Store<{wholesale}>,
    private wholesaleService: WholesaleService,
    private sharedService: SharedService
  ) {}
  searchfield = [
    { value: "shipment", viewValue: "Shipment" },
    { value: "picklist", viewValue: "Picklist ID" },
    { value: "consignment-no", viewValue: "Consignment No." }
    // { value: "manifests", viewValue: "Manifests" },
    // { value: "awb-number", viewValue: "AWB Number" }
  ];
  selectedFilter = "shipment";
  warehouseList;
  selectedWarehouse: any;
  warehouseFromStore = null;
  ROUTES = ROUTES;
  showLoader: Boolean = false;
  searchText = null;
  filteredValue: string[];
  getAccountIdStatus$: Subscription;

  ngOnInit() {
    this.getWarehouseList();
    this.sharedService.loaderListner.subscribe(data => {
      this.showLoader = data.status;
    });
    this.getAccountIdStatus$ = this.store
      .pipe(select(getAccountIdStatus))
      .subscribe(result => {
        if (result) {
          this.warehouseFromStore = result;
        }
      });
  }
  getWareHouseId() {
    this.filteredValue = this._filter(this.selectedWarehouse);
  }
  getAllFilteredList() {
    this.filteredValue = this.warehouseList;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.warehouseList.filter(
      option =>
        option.externalWarehouseID.toLowerCase().indexOf(filterValue) === 0
    );
  }

  setValueOnStore() {
    this.store.dispatch(new SetAccountId(this.selectedWarehouse));
    window.location.reload();
  }

  getWarehouseList() {
    // this.store.dispatch(new GetWarehouseList({}));
    this.wholesaleService.getWarehouseList().subscribe(res => {
      this.warehouseList = res["data"];
      if (this.warehouseList && this.warehouseList.length) {
        this.selectedWarehouse = this.warehouseFromStore
          ? this.warehouseFromStore
          : this.warehouseList[0]["externalWarehouseID"];
        localStorage.setItem("selectedWarehouseID", this.selectedWarehouse);
        if (!this.warehouseFromStore) {
          this.store.dispatch(new SetAccountId(this.selectedWarehouse));
        }
        this.warehouseList.forEach(warehouse => {
          if (warehouse.externalWarehouseID === this.warehouseFromStore) {
            this.sharedService.selectedWarehouseId = warehouse.id;
          }
        });
      }
    });
  }

  onFilterChange(selectedValue) {
    this.selectedFilter = selectedValue;
  }

  trackByFn(index) {
    return index;
  }

  addSearchText($event) {
    const value = $event.target.value.trim();
    if (
      value.length > 0 &&
      this.router.url !== ROUTES.MARK_CONSIGNMENT_COMPLETE
    ) {
      switch (this.selectedFilter) {
        case "picklist":
          this.router.navigate([ROUTES.WHOLESALE_SHIPMENT], {
            queryParams: { id: value }
          });
          break;

        case "shipment":
          this.router.navigate([ROUTES.SHIPMENT], {
            queryParams: { id: value }
          });
          break;

        case "consignment-no":
          this.router.navigate([ROUTES.MARK_CONSIGNMENT_COMPLETE], {
            queryParams: { id: value }
          });
          break;

        default:
          break;
      }

      this.store.dispatch(new SetFilter(value));
      if (this.selectedFilter !== "consignment-no") {
        this.searchText = null;
      }
    } else {
      this.store.dispatch(new SetFilter(value));
      if (this.selectedFilter !== "consignment-no") {
        this.searchText = null;
      }
    }
  }
  clearFilter() {
    this.store.dispatch(new SetFilter(null));
    this.searchText = null;
  }
  ngOnDestroy() {
    if (this.getAccountIdStatus$) {
      this.getAccountIdStatus$.unsubscribe();
    }
  }
}
