import * as BagReducer from './bag.reducer';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const BagState = (state: AppState) => state.bagState;

// Get bag list selectors
export const bagListSelector = createSelector(
    BagState,
    BagReducer.bagList
);
export const loadingBagListSelector = createSelector(
    BagState,
    BagReducer.loadingBagList
);
export const loadedBagListSelector = createSelector(
    BagState,
    BagReducer.loadedBagList
);
export const bagListErrorSelector = createSelector(
    BagState,
    BagReducer.bagListError
);

// Get bag list for PDF selectors
export const bagListForPDFSelector = createSelector(
    BagState,
    BagReducer.bagListForPDF
);
export const loadingBagListForPDFSelector = createSelector(
    BagState,
    BagReducer.loadingBagListForPDF
);
export const loadedBagListForPDFSelector = createSelector(
    BagState,
    BagReducer.loadedBagListForPDF
);
export const bagListErrorForPDFSelector = createSelector(
    BagState,
    BagReducer.bagListErrorForPDF
);

// Create bag selectors
export const createBagSelector = createSelector(
    BagState,
    BagReducer.createBag
);
export const creatingBagSelector = createSelector(
    BagState,
    BagReducer.creatingBag
);
export const createdBagSelector = createSelector(
    BagState,
    BagReducer.createdBag
);
export const createBagErrorSelector = createSelector(
    BagState,
    BagReducer.createBagError
);

// Update bag selectors
export const updateBagSelector = createSelector(
    BagState,
    BagReducer.updateBag
);
export const updatingBagSelector = createSelector(
    BagState,
    BagReducer.updatingBag
);
export const updatedBagSelector = createSelector(
    BagState,
    BagReducer.updatedBag
);
export const updateBagErrorSelector = createSelector(
    BagState,
    BagReducer.updateBagError
);

// Get bag item details selector
export const bagItemDetailsSelector = createSelector(
    BagState,
    BagReducer.bagItemDetails
);
export const loadingBagItemDetailsSelector = createSelector(
    BagState,
    BagReducer.loadingBagItemDetails
);
export const loadedBagItemDetailsSelector = createSelector(
    BagState,
    BagReducer.loadedBagItemDetails
);
export const bagItemDetailsErrorSelector = createSelector(
    BagState,
    BagReducer.bagItemDetailsError
);

// Get single item details selector
export const singleItemDetailsSelector = createSelector(
    BagState,
    BagReducer.singleItemDetails
);
export const loadingSingleItemDetailsSelector = createSelector(
    BagState,
    BagReducer.loadingSingleItemDetails
);
export const loadedSingleItemDetailsSelector = createSelector(
    BagState,
    BagReducer.loadedSingleItemDetails
);
export const singleItemDetailsErrorSelector = createSelector(
    BagState,
    BagReducer.singleItemDetailsError
);

// Add item selectors
export const addItemSelector = createSelector(
    BagState,
    BagReducer.addItem
);
export const addingItemSelector = createSelector(
    BagState,
    BagReducer.addingItem
);
export const addedItemSelector = createSelector(
    BagState,
    BagReducer.addedItem
);
export const addItemErrorSelector = createSelector(
    BagState,
    BagReducer.addItemError
);