"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var DashboardActions = require("../actions/dashboard.actions");
exports.initialState = {
    entities: {},
    ids: [],
    dashboard_loading: false,
    label_loading: false,
    removalTODOList: null,
    removalTODOListError: null,
    removalTODOListLoading: false,
    removalTODOListLoaded: false
};
function DashboardReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case DashboardActions.DashboardActionTypes.LOAD_DASHBOARD: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { dashboard_loading: true });
        }
        case DashboardActions.DashboardActionTypes.LOAD_DASHBOARD_SUCCESS: {
            var orders = action.payload["data"];
            var expData_1 = {};
            var ids_1 = [];
            orders.map(function (order) {
                ids_1.push(order.customerOrderNumber);
                expData_1[order.customerOrderNumber] = order;
                return expData_1;
            });
            return tslib_1.__assign(tslib_1.__assign({}, state), { ids: ids_1, entities: Object.assign({}, state.entities, expData_1), label_loading: false, dashboard_loading: false });
        }
        /**
         * Removal
         */
        case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { removalTODOList: null, removalTODOListError: null, removalTODOListLoading: true, removalTODOListLoaded: false });
        }
        case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { removalTODOList: action.payload, removalTODOListError: null, removalTODOListLoading: false, removalTODOListLoaded: true });
        }
        case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST_FAILED: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { removalTODOList: null, removalTODOListError: action.payload, removalTODOListLoading: false, removalTODOListLoaded: true });
        }
        default: {
            return state;
        }
    }
}
exports.DashboardReducer = DashboardReducer;
