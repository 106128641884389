import { Action } from "@ngrx/store";
import { Order } from "@app/_models/order-models";

export enum OrderActionTypes {
  SEARCH = "[Order] Search Order",
  SEARCH_COMPLETE = "[Order] Search Order Complete",
  SEARCH_ERROR = "[Order] Search Order Error",
  SELECT = "[Order] Select"
}

export class Select implements Action {
  readonly type = OrderActionTypes.SELECT;
  constructor(public payload: string) {}
}
export class OrderSearch implements Action {
  readonly type = OrderActionTypes.SEARCH;
  constructor(public payload: string) {}
}

export class OrderSearchComplete implements Action {
  readonly type = OrderActionTypes.SEARCH_COMPLETE;
  constructor(public payload: Order) {}
}

export class OrderSearchError implements Action {
  readonly type = OrderActionTypes.SEARCH_ERROR;
  constructor(public payload: string) {}
}

export type OrderUnion =
  | Select
  | OrderSearch
  | OrderSearchComplete
  | OrderSearchError;
