"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AddGatepassComponent = /** @class */ (function () {
    function AddGatepassComponent() {
        this.baditemtable = false;
    }
    AddGatepassComponent.prototype.baditemclick = function () {
        this.baditemtable = !this.baditemtable;
    };
    AddGatepassComponent.prototype.ngOnInit = function () {
    };
    return AddGatepassComponent;
}());
exports.AddGatepassComponent = AddGatepassComponent;
