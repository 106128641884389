<section class="right-container">
  <div class="choose-action">
    <h3>What would you like to do ?</h3>
    <div class="inner-conent">
      <ul>
        <li [routerLink]="ROUTES.CONSIGNMENT_RECALL_LIST">
          <figure>
            <img src="assets/images/icon1.png" />
          </figure>
          <div class="right">
            <h4>Recall Consignment</h4>
            <p>View recall consignment requests</p>
          </div>
        </li>

        <li [routerLink]="ROUTES.CONSIGNMENT_RECALL_PACK_ITEM" [queryParams]="{'type': 'good'}">
          <figure>
            <img src="assets/images/plus-icon.png" />
          </figure>
          <div class="right">
            <h4>Pack Transfer Inventory</h4>
            <p>Pack good inventory items of recall consignment.</p>
          </div>
        </li>

        <li [routerLink]="ROUTES.CONSIGNMENT_RECALL_PACK_ITEM" [queryParams]="{'type': 'bad'}">
          <figure><img src="assets/images/plus-icon.png" /></figure>
          <div class="right">
            <h4>Pack Bad Inventory</h4>
            <p>Pack bad inventory items of recall consignment.</p>
          </div>
        </li>
        <li [routerLink]="ROUTES.CONSIGNMENT_RECALL_PACK_ITEM" [queryParams]="{'type': 'qc rejected'}">
          <figure><img src="assets/images/plus-icon.png" /></figure>
          <div class="right">
            <h4>Pack QC Rejected Inventory</h4>
            <p>Pack qc rejected inventory items of recall consignment.</p>
          </div>
        </li>
        <li>
          <figure><img src="assets/images/plus-icon.png" /></figure>
          <div class="right" (click)="openTransferConsignmentPopup()">
            <h4>Create Transfer Consignment</h4>
            <p>Create Consignment to Transfer Inventory across FC</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>


<ng-template #trasferDialog>
  <div class="popup-form">
    <form #transferForm="ngForm" class="form transferForm">
      <span class="close-popup" (click)="onCloseTransferConsignmentPopup()"></span>
      <div class="form-group">
        <label for="accounSelection">Select the account for which you want to move the inventory</label>
        <select class="form-style" name="accounSelection" #accounSelection
          (change)="onAccontSelection($event.target.value)">
          <option disabled>Select</option>
          <option *ngFor="let accountData of accountList" value="{{accountData.id}}">
            {{accountData.accountName}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="fcSelection">Select the FC to move inventory</label>
        <select class="form-style" name="fcSelection" #fcSelection (change)="onWarehouseSelection($event.target.value)"
          [disabled]="warehouseList.length == 0">
          <option disabled>Select</option>
          <option *ngFor="let warehouseData of warehouseList" value="{{warehouseData.warehouseId}}">
            {{warehouseData.externalWarehouseId}}</option>
        </select>
      </div>
      <button type="submit" class="btn-button" (click)="onSubmitConsignmentTransfer()"><span>S</span>Submit</button>
    </form>
  </div>
</ng-template>