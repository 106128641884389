"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./consign-receive-layout.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../shared/components/receive-header/receive-header.component.ngfactory");
var i3 = require("../../../shared/components/receive-header/receive-header.component");
var i4 = require("../../../shared/services/shared.service");
var i5 = require("../../../shared/services/inbound.service");
var i6 = require("../../../consignment-receive/services/consignment-receive.service");
var i7 = require("@angular/router");
var i8 = require("@ngrx/store");
var i9 = require("../../../shared/components/footer/footer.component.ngfactory");
var i10 = require("../../../shared/components/footer/footer.component");
var i11 = require("./consign-receive-layout.component");
var styles_ConsignReceiveLayoutComponent = [i0.styles];
var RenderType_ConsignReceiveLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsignReceiveLayoutComponent, data: {} });
exports.RenderType_ConsignReceiveLayoutComponent = RenderType_ConsignReceiveLayoutComponent;
function View_ConsignReceiveLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "consignment-rec-container full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-receive-header", [], null, null, null, i2.View_ReceiveHeaderComponent_0, i2.RenderType_ReceiveHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.ReceiveHeaderComponent, [i4.SharedService, i5.InboundService, i6.ConsignmentReceiveService, i7.Router, i8.Store], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i10.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
exports.View_ConsignReceiveLayoutComponent_0 = View_ConsignReceiveLayoutComponent_0;
function View_ConsignReceiveLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consign-receive-layout", [], null, null, null, View_ConsignReceiveLayoutComponent_0, RenderType_ConsignReceiveLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i11.ConsignReceiveLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ConsignReceiveLayoutComponent_Host_0 = View_ConsignReceiveLayoutComponent_Host_0;
var ConsignReceiveLayoutComponentNgFactory = i1.ɵccf("app-consign-receive-layout", i11.ConsignReceiveLayoutComponent, View_ConsignReceiveLayoutComponent_Host_0, {}, {}, []);
exports.ConsignReceiveLayoutComponentNgFactory = ConsignReceiveLayoutComponentNgFactory;
