import { Action } from "@ngrx/store";
import { Shipment } from "@app/_models/shipment-models";

export enum DashboardActionTypes {
  LOAD_DASHBOARD = "[Dashboard Page] Load Dashboard Shipment",
  LOAD_DASHBOARD_SUCCESS = "[Dashboard Page] Load Dashboard Shipment Complete",
  LOAD_DASHBOARD_FAILED = "[Dashboard Page] Load Dashboard Shipment Error",

  REMOVAL_TODO_LIST = '[Dashboard Removal] Get Removal TODO List',
  REMOVAL_TODO_LIST_SUCCESS = '[Dashboard Removal Success] Get Removal TODO List',
  REMOVAL_TODO_LIST_FAILED = '[Dashboard Removal Failure] Get Removal TODO List',
}

export class LoadDashboard implements Action {
  readonly type = DashboardActionTypes.LOAD_DASHBOARD;
  constructor(public payload: string) { }
}

export class LoadDashboardComplete implements Action {
  readonly type = DashboardActionTypes.LOAD_DASHBOARD_SUCCESS;
  constructor(public payload: Shipment[]) { }
}

export class LoadDashboardError implements Action {
  readonly type = DashboardActionTypes.LOAD_DASHBOARD_FAILED;
  constructor(public payload: string) { }
}

/**
 * Removal
 */
export class RemovalTODOListAction implements Action {
  readonly type = DashboardActionTypes.REMOVAL_TODO_LIST;
  constructor(public payload: any) { }
}

export class RemovalTODOListSuccessAction implements Action {
  readonly type = DashboardActionTypes.REMOVAL_TODO_LIST_SUCCESS;
  constructor(public payload: any) { }
}

export class RemovalTODOListFailureAction implements Action {
  readonly type = DashboardActionTypes.REMOVAL_TODO_LIST_FAILED;
  constructor(public payload: any) { }
}

export type DashboardUnion =
  | LoadDashboard
  | LoadDashboardComplete
  | LoadDashboardError
  | RemovalTODOListAction
  | RemovalTODOListSuccessAction
  | RemovalTODOListFailureAction;
