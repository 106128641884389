"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var add_da_component_1 = require("../add-da/add-da.component");
var forms_1 = require("@angular/forms");
var adjustment_service_1 = require("../../shared/services/adjustment.service");
var alert_service_1 = require("../../shared/services/alert.service");
var constants_1 = require("../../shared/constants/constants");
var AmazonWholesaleComponent = /** @class */ (function () {
    function AmazonWholesaleComponent(dialog, service, alertService) {
        this.dialog = dialog;
        this.service = service;
        this.alertService = alertService;
        this.submitButtonStatus = false;
        this.adjustTypes = [
            { value: 'STATUS-CHANGE' },
            { value: 'LOST' },
            { value: 'FOUND' }
        ];
    }
    AmazonWholesaleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.adjustmentForm = new forms_1.FormGroup({
            'sku': new forms_1.FormControl(null, [forms_1.Validators.required]),
            'adjustmentType': new forms_1.FormControl(null, [forms_1.Validators.required]),
            'sourceInventoryType': new forms_1.FormControl(''),
            'destinationInventoryType': new forms_1.FormControl(''),
            'quantity': new forms_1.FormControl(null, [forms_1.Validators.required, forms_1.Validators.pattern("[0-9]*")]),
        });
        this.adjustmentForm.statusChanges
            .subscribe(function (status) {
            if (status === "VALID") {
                _this.statusValue = status;
                _this.submitButtonStatus = true;
            }
        });
    };
    AmazonWholesaleComponent.prototype.openDialog = function (type) {
        var dialogRef = this.dialog.open(add_da_component_1.AddDAComponent, {
            width: "600px",
            data: { type: type }
        });
        dialogRef.afterClosed().subscribe(function (result) {
        });
    };
    AmazonWholesaleComponent.prototype.submitAdjustInventory = function () {
        var _this = this;
        var adjustInventoryObj = tslib_1.__assign({}, this.adjustmentForm.value);
        adjustInventoryObj['sourceInventoryType'] = adjustInventoryObj['adjustmentType'] === 'LOST' || adjustInventoryObj['adjustmentType'] === 'STATUS-CHANGE' ? adjustInventoryObj['sourceInventoryType'] : 'UNAVAILABLE',
            adjustInventoryObj['destinationInventoryType'] = adjustInventoryObj['adjustmentType'] === 'FOUND' || adjustInventoryObj['adjustmentType'] === 'STATUS-CHANGE' ? adjustInventoryObj['destinationInventoryType'] : 'UNAVAILABLE',
            this.service.saveAdjustmentForm(adjustInventoryObj).subscribe(function (res) {
                if (res) {
                    _this.alertService.showInfo(constants_1.CONSTANTS.MESSAGES.ADJUST_INVENTORY_SAVED);
                }
            }, function (error) {
                _this.alertService.showError(error);
            });
    };
    AmazonWholesaleComponent.prototype.onReset = function () {
        this.submitButtonStatus = false;
    };
    return AmazonWholesaleComponent;
}());
exports.AmazonWholesaleComponent = AmazonWholesaleComponent;
