"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var router_1 = require("@angular/router");
var consignment_receive_service_1 = require("../../../consignment-receive/services/consignment-receive.service");
var moment = require("moment");
var AcknowledgeSlipComponent = /** @class */ (function () {
    function AcknowledgeSlipComponent(printService, service, route) {
        this.printService = printService;
        this.service = service;
        this.route = route;
        this.boxesDamaged = [];
        this.weightMismatch = [];
        this.currentDate = moment(new Date()).format("DD/MM/YYYY");
    }
    AcknowledgeSlipComponent.prototype.ngOnInit = function () {
        this.consignmentNo = this.route.snapshot.params["consignmentNo"];
        this.getReceiveDetails();
    };
    AcknowledgeSlipComponent.prototype.getReceiveDetails = function () {
        var _this = this;
        this.getReceiveDetailsApi$ = this.service
            .getConsignmentReceiveDetails(this.consignmentNo)
            .subscribe(function (receiveDetails) {
            if (receiveDetails != null) {
                _this.receiveDetails = receiveDetails;
                _this.boxesDamaged = _this.receiveDetails['boxes'].filter(function (data) {
                    if (data.isBoxDamaged === '1') {
                        return data;
                    }
                });
                _this.weightMismatch = _this.receiveDetails['boxes'].filter(function (data) {
                    if (data.isWeightMentioned === '0') {
                        return data;
                    }
                });
                if (_this.receiveDetails) {
                    _this.printService.onDataReady();
                }
            }
        });
    };
    AcknowledgeSlipComponent.prototype.ngOnDestroy = function () {
        if (this.getReceiveDetailsApi$) {
            this.getReceiveDetailsApi$.unsubscribe();
        }
    };
    return AcknowledgeSlipComponent;
}());
exports.AcknowledgeSlipComponent = AcknowledgeSlipComponent;
