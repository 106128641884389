import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AddDAComponent } from "../add-da/add-da.component";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdjustmentService } from '../../shared/services/adjustment.service';
import { AlertService } from '../../shared/services/alert.service';
import { CONSTANTS } from '../../shared/constants/constants';
@Component({
  selector: "app-amazon-wholesale",
  templateUrl: "./amazon-wholesale.component.html",
  styleUrls: ["./amazon-wholesale.component.scss"]
})
export class AmazonWholesaleComponent implements OnInit {
  adjustmentForm: FormGroup;
  selected;
  statusValue;
  submitButtonStatus: boolean = false;
  constructor(private dialog: MatDialog, private service: AdjustmentService, private alertService: AlertService) { }

  adjustTypes = [
    { value: 'STATUS-CHANGE' },
    { value: 'LOST' },
    { value: 'FOUND' }
  ];

  ngOnInit() {
    this.adjustmentForm = new FormGroup({
      'sku': new FormControl(null, [Validators.required]),
      'adjustmentType': new FormControl(null, [Validators.required]),
      'sourceInventoryType': new FormControl(''),
      'destinationInventoryType': new FormControl(''),
      'quantity': new FormControl(null, [Validators.required, Validators.pattern("[0-9]*")]),

    });
    this.adjustmentForm.statusChanges
      .subscribe((status: any) => {
        if (status === "VALID") {
          this.statusValue = status;
          this.submitButtonStatus = true;
        }
      });
  }

  openDialog(type): void {
    const dialogRef = this.dialog.open(AddDAComponent, {
      width: "600px",
      data: { type }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



  submitAdjustInventory() {
    const adjustInventoryObj = { ...this.adjustmentForm.value }
    adjustInventoryObj['sourceInventoryType'] = adjustInventoryObj['adjustmentType'] === 'LOST' || adjustInventoryObj['adjustmentType'] === 'STATUS-CHANGE' ? adjustInventoryObj['sourceInventoryType'] : 'UNAVAILABLE',
      adjustInventoryObj['destinationInventoryType'] = adjustInventoryObj['adjustmentType'] === 'FOUND' || adjustInventoryObj['adjustmentType'] === 'STATUS-CHANGE' ? adjustInventoryObj['destinationInventoryType'] : 'UNAVAILABLE',

      this.service.saveAdjustmentForm(adjustInventoryObj).subscribe((res) => {
        if (res) {
          this.alertService.showInfo(CONSTANTS.MESSAGES.ADJUST_INVENTORY_SAVED);
        }
      }, (error) => {
        this.alertService.showError(error);
      });

  }



  onReset() {
    this.submitButtonStatus = false;
  }
}
