import { environment } from "@env/environment";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class Configuration {
  public SERVER_URL = environment.API_URL;
  public RECALL_URL = environment.RECALL_URL;
  public baseUrl = this.SERVER_URL;
  public API_URL = environment.DASHBOARD_API_URL;
  public PRODUCT_URL = environment.PRODUCT_API_URL;
  public ASIN_POST_URL = environment.ASIN_POST_URL;
  public inboundUrl = environment.INBOUND_URL;
  public UrlObj = {
    completeConsignmentListApi: this.inboundUrl + "consignment?",
    consignmentDetailApi: this.inboundUrl + "consignment/",
    editConsignmentApi: this.inboundUrl + "consignment/",
    updatePohAttachment: this.baseUrl + "picklist/",
    marConsignmentCompleteApi: this.inboundUrl + "consignment/completion ",
    warehouseList: this.inboundUrl + "warehouse?page=1&perPage=100",
    addAssociates: this.baseUrl + "picklistAssociates",
    deleteAssociate: this.baseUrl + "picklistAssociates/",
    deletePOH: this.baseUrl + "picklist/",
    getLabelsAndInvoiceUrl: this.baseUrl + "picklistShipments/",
    getShipment:
      this.API_URL + "order/shipment?page=1&perPage=100000&expectedShipDate=",
    getOrder: this.API_URL + "order/",
    searchOrders: this.API_URL + "order/shipment/",
    searchByTrackingId: this.API_URL + "order/shipment?trackingID=",
    updateShipment: this.API_URL + "order/shipment/",
    getPackageType: this.API_URL + "package-type?page=1&perPage=1000",
    printInvoice: this.API_URL + "pdf-print?path=",
    addConsignmentReceiveDetail: this.inboundUrl + "consignment/receiveDetails",
    addConsignmentBoxDetails: this.inboundUrl + "consignment/boxDetails",
    markConsignmentReceive: this.inboundUrl + "consignment/markReceived",
    getAccountNameList: this.inboundUrl + "account?",
    getConsignmentReceiveDetails: this.inboundUrl + "consignment/",
    getConsignmentByAccountId: this.inboundUrl + "consignment/ref?accountId=",
    updateBoxDetails: this.inboundUrl + "consignment/boxDetails",
    updateImageUrlInbound: this.inboundUrl + "consignment/boxDetails",
    cancelConsignment: this.inboundUrl + "consignmentCancelReceive",
    getBarcodeDetailsApi: this.inboundUrl + "barcode?qty=",
    searchProductByTitle: this.baseUrl + "fetchInfoByProductTitle",
    getTitles: this.baseUrl + "fetchTitles",
    saveAdjustmentInventoryForm: this.inboundUrl + "adjust-inventory",

    // Recall Consignment
    getConsignmentRecallList: this.inboundUrl + "recall-consignment",
    updateConsignmentRecall: this.inboundUrl + "recall-consignment/",
    getConsignmentRecallGRNItems: "gatepass/",
    getconsignmentRecallTODOList: this.inboundUrl + "recall-consignment/todo",

    vacationsList: this.inboundUrl + "holidays?page=1&perPage=50",
    markHoliday: this.inboundUrl + "markHolidays",
    getTodoList: this.inboundUrl + "getTodoList?date=",
    getDetailByConsignmentNo: this.inboundUrl + "consignment/",
    getTripSheetDetail: this.baseUrl + "tripSheet/",
    getRecallPickListDetails:
      this.RECALL_URL + "v1/wh/recall-consignment/picklist/",
    getRecallChallanDetails: this.RECALL_URL + "v1/wh/recall-consignment",
    scheduleWarehouseApi: this.inboundUrl + "warehouseSchedule",
    attributeApi: this.PRODUCT_URL + "getListing",
    postApi: this.ASIN_POST_URL + "updateListing",
    wareHouseList: this.RECALL_URL + "v1/wh/warehouses",
    createRecallConsignmentTrasnfer:
      this.RECALL_URL + "v1/recall-consignment/transfer",
    // Bags
    getBagList: this.inboundUrl + "bag",
    getBagListInward: this.inboundUrl + "consignment/",
    createBag: this.inboundUrl + "bag",
    updateBag: this.inboundUrl + "bag/",
    getBagItemDetails: this.inboundUrl + "bag-items/",
    getSingleItemDetails: this.inboundUrl + "item-details/",
    addItemIntoBag: this.inboundUrl + "add-item/",

    getBrandList: this.inboundUrl + "brand",
    // Export Job
    exportJob: "/export-job"
  };
}
