import { Action } from "@ngrx/store";
import { DeliveryAssociate } from "../models/delivery-associate.model";

export enum WholesaleActionTypes {
  GET_ROUTE_SUMMARY = "[Wholesale Page] Get Current Route Summary",
  GET_ROUTE_SUMMARY_SUCCESS = "[Route Summary API] Get Route Summary Success",
  GET_ROUTE_SUMMARY_FAILED = "[Route Summary API] Get Route Summary Failed",
  GET_WAREHOUSE_LIST = "[Wholesale Page] Get warehouse List",
  GET_WAREHOUSE_LIST_SUCCESS = "[Wholesale Page] Get warehouse List Success",
  GET_WAREHOUSE_LIST_FAILED = "[Wholesale Page] Get warehouse List Failed",
  GET_ASSOCIATES = "[Wholesale Page] Get Delivery Associates",
  GET_ASSOCIATES_SUCCESS = "[Delivery Associates API] Get Delivery Associates Success",
  GET_ASSOCIATES_FAILED = "[Delivery Associates API] Get Delivery Associates Failed",
  ADD_ASSOCIATE = "[Add Delivery Associate Page] Call Add Delivery Associate API",
  ADD_ASSOCIATE_SUCCESS = "[Add Delivery Associate API] Add Delivery Associate Success",
  ADD_ASSOCIATE_FAILED = "[Add Delivery Associate API] Add Delivery Associate Failure",
  DELETE_ASSOCIATE = "[Delete Delivery Associate Page] Call Delete Delivery Associate API",
  DELETE_ASSOCIATE_SUCCESS = "[Delete Delivery Associate API] Delete Delivery Associate Success",
  DELETE_ASSOCIATE_FAILED = "[Delete Delivery Associate API] Delete Delivery Associate Failure",
  GET_SIMULATED_SUMMARY = "[Wholesale Page] Get Simulated Data",
  GET_SIMULATED_SUCCESS = "[Simulated API] Get Simulated Data Success",
  GET_SIMULATED_FAILED = "[Simulated  API] Get Simulated Data Failed",
  GET_PICKLIST_SUMMARY = "[Wholesale Page] Get Picked Data",
  GET_PICKLIST_SUCCESS = "[Picklist API] Get Picked Data Success",
  GET_PICKLIST_FAILED = "[Picklist API] Get Picked Data Failed",
  SET_ACCOUNT_ID = "[Header Warehouse Selection] Set Account Id",
  SET_CONSOLIDATED_PICKLIST = "[Consolidated Picklist API] Set Consolidated Picklist",
  FETCH_TITLES = "[Search Product Page] Get Titles for auto suggest",
  FETCH_TITLES_SUCCESS = "[Search Product Page] Get Titles Success",
  FETCH_TITLES_FAILURE = "[Search Product Page] Get Titles Failure"
}

export class SetAccountId implements Action {
  readonly type = WholesaleActionTypes.SET_ACCOUNT_ID;

  constructor(public payload: Number) { }
}

export class SetConsolidatedPicklist implements Action {
  readonly type = WholesaleActionTypes.SET_CONSOLIDATED_PICKLIST;

  constructor(public payload: any) { }
}

export class GetRouteSummary implements Action {
  readonly type = WholesaleActionTypes.GET_ROUTE_SUMMARY;

  constructor(public payload: object) { }
}

export class GetRouteSummarySuccess implements Action {
  readonly type = WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS;

  constructor(public payload: { routeSummary: any }) { }
}

export class GetRouteSummaryFailed implements Action {
  readonly type = WholesaleActionTypes.GET_ROUTE_SUMMARY_FAILED;
}

export class GetWarehouseList implements Action {
  readonly type = WholesaleActionTypes.GET_WAREHOUSE_LIST;

  constructor(public payload: object) { }
}

export class GetWarehouseListSuccess implements Action {
  readonly type = WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS;

  constructor(public payload: { warehouseList: any }) { }
}

export class GetWarehouseListFailed implements Action {
  readonly type = WholesaleActionTypes.GET_WAREHOUSE_LIST_FAILED;
}

export class GetAssociates implements Action {
  readonly type = WholesaleActionTypes.GET_ASSOCIATES;
}

export class GetAssociatesSuccess implements Action {
  readonly type = WholesaleActionTypes.GET_ASSOCIATES_SUCCESS;

  constructor(public payload: { associates: any }) { }
}

export class AddAssociate implements Action {
  readonly type = WholesaleActionTypes.ADD_ASSOCIATE;
  constructor(public payload: object) { }
}

export class AddAssociateSuccess implements Action {
  readonly type = WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS;

  constructor(public payload: { associate: DeliveryAssociate }) { }
}

export class AddAssociateFailed implements Action {
  readonly type = WholesaleActionTypes.ADD_ASSOCIATE_FAILED;
}

export class DeleteAssociate implements Action {
  readonly type = WholesaleActionTypes.DELETE_ASSOCIATE;
  constructor(public payload: object) { }
}

export class DeleteAssociateSuccess implements Action {
  readonly type = WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS;

  constructor(public payload: { id: string }) { }
}

export class DeleteAssociateFailed implements Action {
  readonly type = WholesaleActionTypes.DELETE_ASSOCIATE_FAILED;
}

export class GetAssociatesFailed implements Action {
  readonly type = WholesaleActionTypes.GET_ASSOCIATES_FAILED;
}

export class GetSimulatedData implements Action {
  readonly type = WholesaleActionTypes.GET_SIMULATED_SUMMARY;
  constructor(public payload: object) { }
}

export class GetSimulatedSuccess implements Action {
  readonly type = WholesaleActionTypes.GET_SIMULATED_SUCCESS;

  constructor(public payload: { simulatedSummary: any }) { }
}

export class GetSimulatedFailed implements Action {
  readonly type = WholesaleActionTypes.GET_SIMULATED_FAILED;
}

export class GetPicklistData implements Action {
  readonly type = WholesaleActionTypes.GET_PICKLIST_SUMMARY;
  constructor(public payload: object) { }
}

export class GetPicklistSuccess implements Action {
  readonly type = WholesaleActionTypes.GET_PICKLIST_SUCCESS;

  constructor(public payload: { picklist: any }) { }
}

export class GetPicklistFailed implements Action {
  readonly type = WholesaleActionTypes.GET_PICKLIST_FAILED;
}

export class GetTitlesData implements Action {
  readonly type = WholesaleActionTypes.FETCH_TITLES;
  constructor(public payload: object) { }
}

export class GetTitlesSuccess implements Action {
  readonly type = WholesaleActionTypes.FETCH_TITLES_SUCCESS;

  constructor(public payload: { titles: any }) { }
}

export class GetTitlesFailure implements Action {
  readonly type = WholesaleActionTypes.FETCH_TITLES_FAILURE;
}

export type WholesaleUnion =
  | GetRouteSummary
  | GetRouteSummarySuccess
  | GetRouteSummaryFailed
  | GetAssociates
  | GetAssociatesSuccess
  | GetAssociatesFailed
  | DeleteAssociate
  | DeleteAssociateSuccess
  | DeleteAssociateFailed
  | AddAssociate
  | AddAssociateSuccess
  | AddAssociateFailed
  | GetSimulatedData
  | GetSimulatedSuccess
  | GetSimulatedFailed
  | GetPicklistData
  | GetPicklistSuccess
  | GetPicklistFailed
  | SetAccountId
  | SetConsolidatedPicklist
  | GetWarehouseList
  | GetWarehouseListSuccess
  | GetWarehouseListFailed
  | GetTitlesData
  | GetTitlesSuccess
  | GetTitlesFailure;
