<!-- <div class="invoice" *ngFor="let invoice of invoiceIds; let i = index">
  <h1>Invoice Num. {{ invoice }}</h1>
  Amount: {{ (invoiceDetails[i] | async)?.amount }} -->
<div class="picklist-pdf" xmlns="http://www.w3.org/1999/html">
  <!-- *ngIf="!WBLT" -->
  <!-- <div *ngIf="this.picklistId !== undefined; else consolidated" class="picklist-header">
    <div class="picklist-top-pane d-flex justify-content-between ">
      <span class="picklist-route "> Route: {{ invoiceDetails?.routeID }} </span>
      <span class="picklist-associate">
        Associate: {{ invoiceDetails?.associate }}
      </span>
      <span class="picklist-timing">
        Date/Time {{ invoiceDetails?.date }}
      </span>
    </div>

    <div class="picklist-barcode-wrapper d-flex justify-content-between">
      <span class="picklist-barcode">
        <img src="assets/images/barcode.jpg" alt="" /> 
        <ngx-barcode [bc-value]="picklistId" [bc-display-value]="true"></ngx-barcode>
      </span>

      <span class="invoice-qty">No. of Invoices: {{ invoiceDetails?.customers }}</span>
      <span class="picklist-comment">
          <span>textarea for handwritten comments/signature/seal/etc. </span>
        </span> 
    </div>
  </div> -->
  <div class="picklist-header">
    <div class="picklist-top-pane d-flex justify-content-between">
      <span class="picklist-route"> Route: {{ invoiceDetails?.routeID?.substr(0,7) }} </span>
      <span class="picklist-associate">
        Associate: {{ invoiceDetails?.associate }}
      </span>
      <span class="picklist-timing">
        Date/Time {{ invoiceDetails?.date }}
      </span>
    </div>

    <div class="picklist-barcode-wrapper d-flex justify-content-between">
      <span class="picklist-barcode">
        <!-- <img src="assets/images/barcode.jpg" alt="" /> -->
        <ngx-barcode [bc-value]="picklistId" [bc-display-value]="true"></ngx-barcode>
      </span>

      <span class="invoice-qty">No. of Orders: {{ this.uniqueRoutes }}</span>
      <!-- <span class="picklist-comment">
          <span>textarea for handwritten comments/signature/seal/etc. </span>
        </span>  -->
    </div>
  </div>
  
  <!-- end customize formate only for WBLT -->
  <div class="picklist-footer d-flex justify-content-between align-items-center">
    <span class="picklist-amount">
      <span>Total Loading Value: {{ invoiceDetails?.totalValue }}</span>
    </span>
  </div>

  <ng-template #consolidated>
    <div class="picklist-second">
      <h4 class="picklist-associate">
        {{ invoiceDetails?.pickingDate | date: "dd-MM-yyyy" }} Picklist Summary
        Consolidated
      </h4>
      <h5 class="invoice-qty">No. of Routes: {{ uniqueRoutes }}</h5>

      <h5 class="invoice-qty">
        No. of Invoices: {{ invoiceDetails?.customers }}
      </h5>
    </div>

    <h5>Summary</h5>
    <table>
      <tbody>
        <tr>
          <td>SKU's</td>
          <td>Cases</td>
          <td>Looses</td>
        </tr>

        <tr>
          <td>{{ invoiceDetails?.items?.length }}</td>
          <td>{{ totalCases + Cases }}</td>
          <td>{{ totalLoose + Loose }}</td>
        </tr>
      </tbody>
    </table>
  </ng-template>

  <!-- *ngIf="!WBLT" -->

  <div class="picklist-body">
    <div *ngIf="foodFilteredList?.length" class="left">
      <b> <u>Location: Food</u></b>
    </div>
    <br />
    <table *ngIf="foodFilteredList?.length">
      <!-- <div class="left"><b>FOOD</b></div> -->
      <thead>
        <th>Location</th>
        <th>Uts/Cs</th>
        <!-- <th>ASIN</th> -->
        <!-- <th>Barcode</th> -->
        <th>Title</th>
        <th>Case</th>

        <th>Bundles</th>
        <th>Loose</th>
        <!-- <th>Location</th> -->

        <th>MRP/pc</th>

        <!-- <th>Location</th> -->
        <th>Comments</th>
      </thead>
      <tbody>
        <tr *ngFor="
              let item of foodFilteredList;
              let i = index;
              trackBy: trackByFn
            ">
          <td>{{ i + 1 }}</td>
          <td>{{ item?.unitsPerContainer }}</td>
          <td>{{ item?.title }}</td>
          <td>{{ item?.cases }}</td>

          <td>{{ item?.bundles }}</td>
          <td>{{ item?.loose }}</td>

          <td>{{ item?.mrp }}</td>

          <td></td>
        </tr>
        <tr>
          <td></td>


          <td>---TOTALS ROW ---</td>
          <td></td>
          <td>{{ totalCases }}</td>
          <td>{{totalBundles}}</td>
          <td>{{ totalLoose }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <br />

    <div *ngIf="nonfoodFilteredList?.length" class="left">
      <b><u>Location: Non Food</u></b>
    </div>
    <br />
    <table *ngIf="nonfoodFilteredList?.length">
      <!-- <div class="left"><b>NON-FOOD</b></div> -->
      <thead>
        <th>Sl. No</th>
        <th>Uts/Cs</th>
        <th>Title</th>
        <th>Case</th>

        <th>Bundles</th>
        <th>Loose</th>


        <th>MRP/pc</th>


        <th>Comments</th>
      </thead>
      <tbody>
        <tr *ngFor="
              let item of nonfoodFilteredList;
              let i = index;
              trackBy: trackByFn
            ">
          <td>{{ i + 1 }}</td>
          <td>{{ item?.unitsPerContainer }}</td>
          <td>{{ item?.title }}</td>
          <td>{{ item?.cases }}</td>

          <td>{{ item?.bundles }}</td>
          <td>{{ item?.loose }}</td>

          <td>{{ item?.mrp }}</td>

          <td></td>
        </tr>
        <tr>
          <td></td>

          <td></td>
          <td>---TOTALS ROW ---</td>
          <td>{{ Cases }}</td>
          <td>{{bundles}}</td>
          <td>{{ Loose }}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- customize formate only for WBLT -->

  <!-- *ngIf="WBLT" -->
  <!-- table body starts -->

  <div class="picklist-body">
    <div *ngFor="let dataList of foodFilteredList |  keyvalue" style="min-height: 950px;">
      
      <div class="left">
        <b><u>Address : {{dataList.value[0].address}} </u> <br>  Order ID : {{dataList.value[0].orderId}}  &nbsp; &nbsp; &nbsp;  Shipment ID: {{dataList?.key}}  </b>
      </div>
      <br />
      <!-- Mukesh--------- please remove table-invoice-print class from the table in order get previous table format -->
      <table class="table-invoice-print">
        <!-- remove class from here -->
        <thead>
          <th>Location</th>
          <th>Uts/Cs</th>
          <th>Title</th>
          <th>Case</th>
          <th>Bundles</th>
          <th>Loose</th>
          <th>MRP/pc</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of foodFilteredList[dataList.key];">
            <td>{{ item?.location }}</td>
            <td>{{ item?.unitsPerContainer }}</td>
            <td>{{ item?.title }}</td>
            <td>{{ item?.cases }}</td>

            <td>{{ item?.bundles }}</td>
            <td>{{ item?.loose }}</td>

            <td>{{ item? priceRoundOff(item.mrp) : '' }}</td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div>



  
</div>