"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../../shared/services/api.service");
var config_1 = require("../../shared/configuration/config");
var store_1 = require("@ngrx/store");
var selectors_1 = require("../../wholesale/reducers/selectors");
var http_1 = require("@angular/common/http");
var auth_service_1 = require("../../shared/services/auth.service");
var i0 = require("@angular/core");
var i1 = require("../../shared/services/api.service");
var i2 = require("../../shared/configuration/config");
var i3 = require("@ngrx/store");
var i4 = require("../../shared/services/auth.service");
var ConsignmentRecallService = /** @class */ (function () {
    /**
     *
     * @param api
     * @param config
     * @param store
     * @param auth
     */
    function ConsignmentRecallService(api, config, store, auth) {
        this.api = api;
        this.config = config;
        this.store = store;
        this.auth = auth;
        // Set authorization token
        this.AuthorizationToken = this.auth.getAuthToken;
        this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;
        // Set headers
        this.Headers = new http_1.HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', this.AuthorizationToken)
            .set('warehouseId', "Warehouse");
        // Get warehouse id
        //    this.getWarehouseId();
    }
    /**
     * Get warehouse ID
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    ConsignmentRecallService.prototype.getWarehouseId = function () {
        var _this = this;
        this.store.pipe(store_1.select(selectors_1.getAccountIdStatus)).subscribe(function (result) {
            _this.wareHouseId = result;
        });
    };
    /**
     * Get consignment list service
     * @param requestBody
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    ConsignmentRecallService.prototype.getConsignmentListService = function (requestBody) {
        // return this.api.get(this.config.UrlObj.getConsignmentRecallList, {
        //   headers: this.Headers,
        //   params: requestBody
        // });
        return this.api.get(this.config.UrlObj.getConsignmentRecallList, { params: requestBody });
    };
    /**
    * Update consignment service
    * @param requestBody
    *
    * @author Chandrika Aggarwal <chandrika@eshopbox.com>
    */
    ConsignmentRecallService.prototype.updateConsignmentService = function (requestBody) {
        console.log("REQUEST BODY", requestBody);
        var apiUrl = this.config.UrlObj.updateConsignmentRecall + encodeURIComponent(encodeURIComponent(requestBody['consignmentNumber']));
        return this.api.put(apiUrl, requestBody, this.Headers);
    };
    /**
     * Get grn item list service
     * @param requestBody
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    ConsignmentRecallService.prototype.getGRNItemListService = function (requestBody) {
        var apiUrl = this.config.inboundUrl + 'recall-consignment/' + encodeURIComponent(requestBody['consignmentNumber']) + '/' + this.config.UrlObj.getConsignmentRecallGRNItems;
        return this.api.get(apiUrl, { params: requestBody });
    };
    /**
     * @description Get todo list servie
     * @param requestBody
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    ConsignmentRecallService.prototype.getTODOListService = function (requestBody) {
        var apiUrl = this.config.UrlObj.getconsignmentRecallTODOList;
        return this.api.get(apiUrl, { params: requestBody });
    };
    ConsignmentRecallService.prototype.getWarehouseDetails = function (accountId) {
        var queryParams = {
            'page': 1,
            'perPage': 50,
            'accountId': accountId
        };
        var apiUrl = this.config.UrlObj.wareHouseList;
        return this.api.get(apiUrl, { params: queryParams });
    };
    ConsignmentRecallService.prototype.createRecallConsignmentTransfer = function (transferData) {
        var apiUrl = this.config.UrlObj.createRecallConsignmentTrasnfer;
        return this.api.post(apiUrl, transferData);
    };
    ConsignmentRecallService.ngInjectableDef = i0.defineInjectable({ factory: function ConsignmentRecallService_Factory() { return new ConsignmentRecallService(i0.inject(i1.ApiService), i0.inject(i2.Configuration), i0.inject(i3.Store), i0.inject(i4.AuthService)); }, token: ConsignmentRecallService, providedIn: "root" });
    return ConsignmentRecallService;
}());
exports.ConsignmentRecallService = ConsignmentRecallService;
