<div class="picklist-pdf">
    <div class="picklist-header">
        <div class="picklist-top-pane d-flex justify-content-between">
            <span class="picklist-consignment"> Consignment Numner: {{picklistId}} </span>
            <span class="invoice-qty">No. of Asins: {{numberOfAsins}}</span>
            <span class="picklist-timing">
                Date/Time : {{pickingDate | date:'dd-MMMM-yyyy h:MM:ss a'}}
            </span>
        </div>
    </div>
    <br>
    <br>
    <div class="picklist-body">
        <table>
            <th>Location</th>
            <th>Uts/Cs</th>
            <th>Title</th>
            <th>Asin</th>
            <th>Case</th>
            <th>Bundles</th>
            <th>Loose</th>
            <th>MRP/pc</th>

            <tr class="recall-sheet-data" *ngFor="let recallData of recallSheetData">
                <td>{{recallData.location != null ? recallData.location : '-'}}</td>
                <td>{{recallData.unitsPerContainer != null ? recallData.unitsPerContainer : '-'}}</td>
                <td>{{recallData.productName != null ? recallData.productName : '-'}}</td>
                <td>{{recallData.asin != null ? recallData.asin : '-'}}</td>
                <td>{{recallData.cases != null ? recallData.cases : '-'}}</td>
                <td>{{recallData.bundles != null ? recallData.bundles : '-'}}</td>
                <td>{{recallData.loose != null ? recallData.loose : '-'}}</td>
                <td>{{recallData.mrp != null ? recallData.mrp : '-'}}</td>
            </tr>
        </table>
    </div>
</div>