<h4 class="text-center">Customer payment mode trip Sheet</h4>
<div class="picklist-pdf">
  <div class="picklist-body">
    <table>


      <th>Sl.No</th>
      <th>{{tripSheetDetail?.name}}{{tripSheetDetail?.date}}</th>
      <th>No.of Invoices</th>
      <th>Sum of Balance Due</th>
      <th>Phone no.</th>
      <th>Sign</th>


      <tr *ngIf="tripSheetDetail?.COD?.data?.length">
        <td></td>
        <td><b>COD/CASH </b></td>
        <td>{{tripSheetDetail?.COD?.totalInvoices}}</td>
        <td>{{tripSheetDetail?.totalBalance}}</td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngFor="let detail of tripSheetDetail?.COD?.data;let i=index">
        <td>{{i+1}}</td>
        <td>{{detail?.customerName}}</td>
        <td>{{detail?.invoiceCount}}</td>
        <td>{{detail?.balance}}</td>
        <td>{{detail?.contactNumber}}</td>
        <td></td>
      </tr>

      <tr *ngIf="tripSheetDetail?.INVOICE?.data?.length">
        <td></td>
        <td><b>INVOICE</b></td>
        <td>{{tripSheetDetail?.INVOICE?.totalInvoices}}</td>
        <td>{{tripSheetDetail?.INVOICE?.totalBalance}}</td>
        <td></td>
        <td></td>
      </tr>

      <tr *ngFor="let detail of tripSheetDetail?.INVOICE?.data;let i=index">
        <td>{{tripSheetDetail?.COD?.data.length + i}}</td>
        <td>{{detail?.customerName}}</td>
        <td>{{detail?.invoiceCount}}</td>
        <td>{{detail?.balance}}</td>
        <td>{{detail?.contactNumber}}</td>


        <td></td>
      </tr>
      <tr *ngIf="tripSheetDetail?.CREDIT_CARD?.data?.length">
        <td></td>
        <td><b>CREDIT CARD</b></td>
        <td>{{tripSheetDetail?.CREDIT_CARD?.totalInvoices}}</td>
        <td>{{tripSheetDetail?.CREDIT_CARD?.totalBalance}}</td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngFor="let detail of tripSheetDetail?.CREDIT_CARD?.data;let i=index">
        <td>{{tripSheetDetail?.COD?.data.length + tripSheetDetail?.INVOICE?.data.length + i}}</td>
        <td>{{detail?.customerName}}</td>
        <td>{{detail?.invoiceCount}}</td>
        <td>{{detail?.balance}}</td>
        <td>{{detail?.contactNumber}}</td>


        <td></td>
      </tr>
      <tr>
        <td></td>
        <td><b>GRAND TOTAL</b></td>
        <td>{{tripSheetDetail?.totalInvoices}}</td>
        <td>{{tripSheetDetail?.totalBalance}}</td>
        <td></td>
        <td></td>
      </tr>

    </table>
  </div>
</div>
