<div class="dialog-box">
  <button class="close" mat-dialog-close>
    <img src="assets/images/x.png" alt="" />
    <small>ESC</small>
  </button>
  <!-- <form> -->
  <div class="drag-area">
    <div class="db-header">
      <div class="left">
        <span *ngIf="data.type === 'addDA'; else deleteBlock"
          >Add Delivery Associate</span
        >
        <ng-template #deleteBlock>
          <span>Delete Delivery Associate</span>
        </ng-template>
      </div>
    </div>
    <div class="db-body">
      <mat-form-field *ngIf="data.type === 'addDA'; else deleteOptions">
        <input
          type="text"
          matInput
          placeholder="Enter Name"
          [formControl]="name"
        />
      </mat-form-field>
      <ng-template #deleteOptions>
        <mat-form-field>
          <mat-select placeholder="Delivery Associate" [formControl]="name">
            <mat-option
              *ngFor="let associate of deliveryAssociateList"
              [value]="associate"
            >
              {{ associate.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </div>
  </div>
  <div class="text-center">
    <button
      class="btn btn-transparent"
      type="submit"
      [disabled]="!name.valid"
      (click)="data.type === 'addDA' ? saveAssociate() : deleteAssociate()"
    >
      {{ data.type === "addDA" ? "Save" : "Delete" }}
    </button>
  </div>
  <!-- </form> -->
</div>
