import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { AlertComponent } from "./components/alert/alert.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UploadModalComponent } from "./components/upload-modal/upload-modal.component";
import { AddDAComponent } from "@app/components/add-da/add-da.component";
import { AcknowledgeSlipComponent } from './components/acknowledge-slip/acknowledge-slip.component';
import { ConsignmentDetailComponent } from './components/consignment-detail/consignment-detail.component';

import { AppMaterialModule } from "../app-material/app-material.module";
import { PrintService } from "./services/print.service";
import { NgxBarcodeModule } from "ngx-barcode";

import { PrintLayoutComponent } from "./components/print-layout/print-layout.component";
import { InvoiceComponent } from "./components/invoice/invoice.component";
import { FilterbarComponent } from "./components/filterbar/filterbar.component";
import { Configuration } from "../shared/configuration/config";
import { SharedRoutingModule } from "./shared-routing.module";
import { GetTruncatedName } from "../shared/pipes/string-operations.pipe";
import { GetWarehouseName } from '../shared/pipes/getwarehouse.pipe';
import { TripSheetComponent } from './components/trip-sheet/trip-sheet.component';
import { BagPackPdfComponent } from '../bags/components/bag-pack-pdf/bag-pack-pdf.component';
import { RecallSheetComponent } from './components/recall-sheet/recall-sheet.component';

const MODULE_SET_1 = [
  HeaderComponent,
  FooterComponent,
  SidebarComponent,
  AlertComponent,
  UploadModalComponent,
  AddDAComponent,
  PrintLayoutComponent,
  FilterbarComponent,
  InvoiceComponent,
  GetTruncatedName,
  GetWarehouseName,
  AcknowledgeSlipComponent,
  ConsignmentDetailComponent,
  TripSheetComponent,
  BagPackPdfComponent,
  RecallSheetComponent
];

const MODULE_SET_2 = [
  FontAwesomeModule,
  ReactiveFormsModule,
  FormsModule,
  NgxBarcodeModule
];

@NgModule({
  declarations: [
    MODULE_SET_1
  ],
  imports: [
    CommonModule,
    RouterModule,
    MODULE_SET_2, AppMaterialModule,
    NgMultiSelectDropDownModule.forRoot(),
    SharedRoutingModule
  ],
  exports: [
    NgMultiSelectDropDownModule,
    MODULE_SET_1,
    MODULE_SET_2
  ],
  providers: [Configuration, PrintService],
  entryComponents: [UploadModalComponent, AddDAComponent]
})
export class SharedModule { }
