import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Store, select } from "@ngrx/store";

import { ApiService } from "../shared/services";
import { getAccountIdStatus } from "../wholesale/reducers/selectors";
import { ConsoleLoggerService } from "../shared/services/console-logger.service";
import { Configuration } from "../shared/configuration/config";

@Injectable({
  providedIn: "root"
})
export class BagService {
  private API_URL = environment.API_URL;
  wareHouseId = null;

  constructor(
    private apiService: ApiService,
    private store: Store<any>,
    private loggerService: ConsoleLoggerService,
    private configurations: Configuration
  ) {
    this.getWarehouseId();
  }

  /**
   * @description Service to get warehouse id
   *
   * @author Chandrika Aggarwal
   */
  getWarehouseId() {
    this.store.pipe(select(getAccountIdStatus)).subscribe(result => {
      this.loggerService.consoleLog(result);
      if (result) {
        this.wareHouseId = result;
        return this.wareHouseId;
      }
    });
  }

  /**
   * @description Service to get bag list
   *
   * @author Chandrika Aggarwal
   */
  getBagListService(requestBody) {
    const apiUrl = this.configurations.UrlObj.getBagList;
    return this.apiService.get(apiUrl, { params: requestBody });
  }

  /**
   * @description Service to create bag
   *
   * @author Chandrika Aggarwal
   */
  createBagService(requestBody) {
    const apiUrl = this.configurations.UrlObj.createBag;
    return this.apiService.post(apiUrl, requestBody);
  }

  /**
   * @description Service to update bag
   *
   * @author Chandrika Aggarwal
   */
  updateBagService(requestBody) {
    const apiUrl = this.configurations.UrlObj.updateBag + requestBody["bagID"];
    return this.apiService.put(apiUrl, { status: requestBody["status"] });
  }

  /**
   * @description Service to get bag item details
   *
   * @author Chandrika Aggarwal
   */
  getBagItemDetailsService(requestBody) {
    const params = {
      ...requestBody,
      page: 1,
      perPage: 1000
    };
    const apiUrl =
      this.configurations.UrlObj.getBagItemDetails + requestBody["bagID"];
    return this.apiService.get(apiUrl, { params: params });
  }

  /**
   * @description Service to get single item details
   *
   * @author Chandrika Aggarwal
   */
  getSingleItemDetailsService(requestBody) {
    this.loggerService.consoleLog("get single item service");
    const apiUrl =
      this.configurations.UrlObj.getSingleItemDetails + requestBody["barcode"];
    return this.apiService.get(apiUrl, { params: requestBody });
  }

  /**
   * @description Service to add item into bag
   *
   * @author Chandrika Aggarwal
   */
  addItemIntoBagService(requestBody) {
    this.loggerService.consoleLog("add item into bag service");
    const apiUrl =
      this.configurations.UrlObj.addItemIntoBag + requestBody["bagId"];
    return this.apiService.post(apiUrl, { barcode: requestBody['barcode'] });
  }
}
