import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PrintService {
  isPrinting = false;
  constructor(private router: Router) { }
  printDocument(documentName: string, documentData?: string[]) {
    this.isPrinting = true;
    if (documentData) {
      this.router.navigate([
        "/",
        {
          outlets: {
            print: ["print", documentName, documentData.join()]
          }
        }
      ]);
    } else {
      this.router.navigate([
        "/",
        {
          outlets: {
            print: ["print", documentName]
          }
        }
      ]);
    }
  }

  printConsignment(documentName: string, documentData?: string[]) {
    this.isPrinting = true;
    if (documentData) {
      this.router.navigate([
        "/",
        {
          outlets: {
            print: ["print", documentName, documentData.join()]
          }
        }
      ]);
    }
  }

  onDataReady() {
    of([])
      .pipe(delay(100))
      .subscribe(() => {
        window.print();
        this.isPrinting = false;
        this.router.navigate([{ outlets: { print: null } }]);
      });
  }
}
