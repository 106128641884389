"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var store_1 = require("@ngrx/store");
var forms_1 = require("@angular/forms");
var user_service_1 = require("@app/shared/services/user.service");
var alert_service_1 = require("@app/shared/services/alert.service");
var selectors_1 = require("@app/wholesale/reducers/selectors");
var wholesale_actions_1 = require("@app/wholesale/actions/wholesale.actions");
var AddDAComponent = /** @class */ (function () {
    function AddDAComponent(data, userService, alertService, dialogRef, store) {
        this.data = data;
        this.userService = userService;
        this.alertService = alertService;
        this.dialogRef = dialogRef;
        this.store = store;
        this.deliveryAssociateList = [];
    }
    AddDAComponent.prototype.ngOnInit = function () {
        this.name = new forms_1.FormControl(null, forms_1.Validators.required);
        this.getDeliveryAssociatesList();
    };
    AddDAComponent.prototype.onSave = function () {
        if (this.data.type === "addDA") {
            this.saveAssociate();
            return;
        }
        this.deleteAssociate();
    };
    AddDAComponent.prototype.getDeliveryAssociatesList = function () {
        var _this = this;
        this.store.pipe(store_1.select(selectors_1.getCurrentDeliveryAssociates)).subscribe(function (result) {
            _this.deliveryAssociateList = result;
        });
    };
    AddDAComponent.prototype.saveAssociate = function () {
        if (this.name.valid) {
            this.store.dispatch(new wholesale_actions_1.AddAssociate({
                params: {
                    name: this.name.value,
                    status: "1"
                },
                ref: this.dialogRef
            }));
        }
    };
    AddDAComponent.prototype.deleteAssociate = function () {
        if (this.name.valid) {
            this.store.dispatch(new wholesale_actions_1.DeleteAssociate({ id: this.name.value.id, ref: this.dialogRef }));
        }
    };
    return AddDAComponent;
}());
exports.AddDAComponent = AddDAComponent;
