import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrintService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConsignmentReceiveService } from '../../../consignment-receive/services/consignment-receive.service';
import moment = require('moment');

@Component({
  selector: 'app-acknowledge-slip',
  templateUrl: './acknowledge-slip.component.html',
  styleUrls: ['./acknowledge-slip.component.sass']
})
export class AcknowledgeSlipComponent implements OnInit, OnDestroy {
  consignmentNo: any;
  getReceiveDetailsApi$: Subscription;
  receiveDetails: any;
  boxesDamaged = [];
  weightMentioned;
  weightMismatch = []
  currentDate = moment(new Date()).format("DD/MM/YYYY")
  constructor(private printService: PrintService, private service: ConsignmentReceiveService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.consignmentNo = this.route.snapshot.params["consignmentNo"];

    this.getReceiveDetails();
}

  getReceiveDetails() {

    this.getReceiveDetailsApi$ = this.service
      .getConsignmentReceiveDetails(this.consignmentNo)
      .subscribe(receiveDetails => {
        if (receiveDetails != null) {
          this.receiveDetails = receiveDetails;
          this.boxesDamaged = this.receiveDetails['boxes'].filter((data) => {
            if (data.isBoxDamaged === '1') {
              return data;
            }
          })
          this.weightMismatch = this.receiveDetails['boxes'].filter((data) => {
            if (data.isWeightMentioned === '0') {
              return data;
            }
          })
          if (this.receiveDetails) {
            this.printService.onDataReady();
          }
        }
      });
  }
  ngOnDestroy(): void {
    if (this.getReceiveDetailsApi$) {
      this.getReceiveDetailsApi$.unsubscribe();
    }

  }
}

