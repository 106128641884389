"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var bag_selectors_1 = require("../../../bags/bag-selectors");
var console_logger_service_1 = require("../../../shared/services/console-logger.service");
var bag_action_1 = require("../../../bags/bag.action");
var services_1 = require("../../../shared/services");
var constants_1 = require("../../../shared/constants/constants");
var PackItemComponent = /** @class */ (function () {
    function PackItemComponent(_activatedRoute, alertService, _store, loggerService, printService) {
        this._activatedRoute = _activatedRoute;
        this.alertService = alertService;
        this._store = _store;
        this.loggerService = loggerService;
        this.printService = printService;
        this.Routes = constants_1.ROUTES;
        this.Messages = constants_1.CONSTANTS;
        this.inventoryType = "good";
        this.scannedItemBagListCount = 0;
        this.isLoading = false;
        this.actionPopup = false;
        this.showLoader = false;
        this.packItemSubscriptionList$ = new rxjs_1.Subscription();
        this.isItemAdded = true;
        this.isItemAdding = false;
        this.bgItemQuantity = 0;
        this.BAG = {};
        this.bgData = [];
        this.showFlag = false;
        this.scannedBarcode = new forms_1.FormControl("");
        this.scannedBagID = new forms_1.FormControl("");
        this.createFormGroup = new forms_1.FormGroup({
            purpose: new forms_1.FormControl(""),
            inventoryType: new forms_1.FormControl("", forms_1.Validators.required),
            numberOfBag: new forms_1.FormControl("", forms_1.Validators.required),
            brandName: new forms_1.FormControl(""),
            consignmentNumber: new forms_1.FormControl("")
        });
        this.updateBagStatusAction = "";
        this.updateBagConfirmationPopup = false;
        this.inventoryType = this._activatedRoute.snapshot.queryParamMap.get("type");
    }
    /**
     * @description Scan item's barcode functionality
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.scanItem = function (scannedItemValue) {
        if (this.getItemDetails$) {
            this.getItemDetails$.unsubscribe();
            this.packItemSubscriptionList$.remove(this.getItemDetails$);
        }
        if (this.getItemDetailsError$) {
            this.getItemDetailsError$.unsubscribe();
            this.packItemSubscriptionList$.remove(this.getItemDetailsError$);
        }
        if (!scannedItemValue) {
            this.alertService.showError("Invalid request");
            return;
        }
        if (scannedItemValue.length < 4) {
            return;
        }
        this.scannedItemDetails = null;
        this.scannedItemBagList = null;
        this.scannedItemBagListCount = 0;
        this.scannedBagID.reset();
        this.getItemDetails(scannedItemValue);
    };
    /**
     * @description Get item details for scanned item's barcode
     * @param barcode
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.getItemDetails = function (barcode) {
        var _this = this;
        var requestBody = {
            barcode: barcode
        };
        this._store.dispatch(new bag_action_1.GetSingleItemDetails(requestBody));
        this.packItemSubscriptionList$.add(this._store
            .pipe(store_1.select(bag_selectors_1.loadingSingleItemDetailsSelector))
            .subscribe(function (isLoading) {
            if (isLoading) {
                _this.isLoading = true;
                _this.showLoader = true;
            }
        }));
        this.packItemSubscriptionList$.add(this._store
            .pipe(store_1.select(bag_selectors_1.loadedSingleItemDetailsSelector))
            .subscribe(function (isLoaded) {
            if (isLoaded) {
                _this.isLoading = false;
                _this.showLoader = false;
            }
        }));
        this.getItemDetails$ = this._store
            .pipe(store_1.select(bag_selectors_1.singleItemDetailsSelector))
            .subscribe(function (data) {
            _this.loggerService.consoleLog(data);
            if ((!data || !data["code"]) && !_this.isLoading) {
                _this.alertService.showError(_this.Messages.MESSAGES.BARCODE_DETAILS_NOT_FOUND + " " + barcode);
                return true;
                //this.getItemDetails$.unsubscribe();
            }
            if (_this.validateScannedItemResponse(data)) {
                _this.loggerService.consoleLog("invalid status");
                return true;
            }
            _this.scannedItemDetails = data;
            // Get bag list for respective recall consignment number
            if (data["recallConsignmentNumber"]) {
                _this.getBagList(data["recallConsignmentNumber"]);
            }
        });
        this.packItemSubscriptionList$.add(this.getItemDetails$);
        this.getItemDetailsError$ = this._store
            .pipe(store_1.select(bag_selectors_1.singleItemDetailsErrorSelector))
            .subscribe(function (error) {
            if (error) {
                _this.loggerService.consoleLog(error, "error");
            }
        });
        this.packItemSubscriptionList$.add(this.getItemDetailsError$);
    };
    /**
     * @description Validate scanned item response data
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.validateScannedItemResponse = function (data) {
        if (!data || !data["status"]) {
            return true;
        }
        // Validate status
        switch (this.inventoryType.toLowerCase()) {
            case "good":
                if (data["status"] != "ALLOCATED") {
                    this.alertService.showError(constants_1.CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]);
                    return true;
                }
                break;
            case "bad":
                if (data["status"] != "BAD_INVENTORY") {
                    this.alertService.showError(constants_1.CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]);
                    return true;
                }
                break;
            case "qc rejected":
                if (data["status"] != "QC_REJECTED") {
                    this.alertService.showError(constants_1.CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]);
                    return true;
                }
                break;
        }
        return false;
    };
    /**
     * @description Get bag list for requested recall consignment number
     * @param recallConsignmentNumber
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.getBagList = function (recallConsignmentNumber) {
        var _this = this;
        this._store.dispatch(new bag_action_1.GetBagList({
            page: 1,
            perPage: 10,
            recallConsignmentNumber: recallConsignmentNumber,
            status: "CREATED,PROCESSING"
        }));
        // Response handling
        this.packItemSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagListSelector)).subscribe(function (data) {
            if (!data || !data["data"]) {
                return;
            }
            // this.scannedItemBagList = data['data'].filter(bag => {
            //   return !['DISCARDED', 'PACKED'].includes(bag['status']);
            // });
            _this.scannedItemBagList = data["data"];
            _this.scannedItemBagListCount = data["total"];
            setTimeout(function () {
                _this.scanBagEleRef.nativeElement.focus();
            }, 0);
            _this.loggerService.consoleLog(_this.scannedItemBagList);
        }));
        this.packItemSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.loadingBagListSelector)).subscribe(function (isLoading) {
            if (isLoading) {
                _this.isLoading = true;
            }
        }));
        this.packItemSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.loadedBagListSelector)).subscribe(function (isLoaded) {
            if (isLoaded) {
                _this.isLoading = false;
            }
        }));
        // Error handling
        this.packItemSubscriptionList$.add(this._store.pipe(store_1.select(bag_selectors_1.bagListErrorSelector)).subscribe(function (error) {
            if (error) {
                _this.loggerService.consoleLog(error, "error");
            }
        }));
    };
    /**
     * @description Scan bag and add item into it
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.scanBag = function (bagId) {
        var _this = this;
        this.bagID = bagId;
        this._store.dispatch(new bag_action_1.GetBagList({
            bagCode: bagId
        }));
        this.scannedBagged$ = this._store.pipe(store_1.select(bag_selectors_1.bagListSelector)).pipe(operators_1.map(function (bagData) {
            console.log("BAGD", bagData);
            if (bagData != null) {
                _this.bgData = bagData["data"];
                if (_this.bgData.length !== 0) {
                    setTimeout(function () {
                        _this.bgItemQuantity = _this.bgData[0]["quantity"];
                        _this.BAG = bagData["data"][0]; // {}
                    });
                }
                else {
                    _this.showFlag = true;
                }
                // console.log("BAG", this.BAG)
                return bagData["data"];
            }
        }));
        this._store.pipe(store_1.select(bag_selectors_1.loadingBagListSelector)).subscribe(function (loading) {
            _this.isloadingBagList = loading;
            // console.log("BAG LOADING ", this.isloadingBagList);
        });
        this._store.pipe(store_1.select(bag_selectors_1.loadedBagListSelector)).subscribe(function (loaded) {
            _this.isloadedBagList = loaded;
            // console.log("BAG LOADED ", this.isloadedBagList);
            setTimeout(function () {
                if (_this.isloadedBagList) {
                    _this.scanBarcodeEleRef.nativeElement.focus();
                }
            }, 0);
        });
    };
    PackItemComponent.prototype.scanBagItem = function (item) {
        var _this = this;
        if (!this.isItemAdding && this.isItemAdded) {
            this._store.dispatch(new bag_action_1.AddItem({ barcode: item, bagId: this.bagID }));
        }
        var temp = 0;
        temp = this.bgItemQuantity;
        this.subscriptions = this._store.pipe(store_1.select(bag_selectors_1.addItemSelector)).pipe(operators_1.tap()).subscribe(function (addItem) {
            if (addItem !== null) {
                if (temp == _this.bgItemQuantity) {
                    _this.bgItemQuantity += 1;
                    _this.resetItem();
                }
            }
        }, function (error) {
            if (error) {
                _this.subscriptions.unsubscribe();
            }
        });
        this._store.pipe(store_1.select(bag_selectors_1.addingItemSelector)).subscribe(function (adding) {
            _this.isItemAdding = adding;
        });
        this._store.pipe(store_1.select(bag_selectors_1.addedItemSelector)).subscribe(function (added) {
            _this.isItemAdded = added;
        });
    };
    PackItemComponent.prototype.resetBag = function () {
        this.scannedBagID.reset();
    };
    PackItemComponent.prototype.resetItem = function () {
        this.scannedBarcode.reset();
    };
    //  printPackedBagPDF(bagID){
    //   this.printService.printDocument("packed-bag", [bagID]);
    //  }
    // scanBag() {
    //   //Unsubscribe observables
    //   if (this.addItem$) {
    //     this.addItem$.unsubscribe();
    //     this.packItemSubscriptionList$.remove(this.addItem$);
    //   }
    //   if (this.addItemError$) {
    //     this.addItemError$.unsubscribe();
    //     this.packItemSubscriptionList$.remove(this.addItemError$);
    //   }
    //   if (!this.scannedBagID.value) {
    //     this.alertService.showError('Invalid bag request');
    //     return;
    //   }
    //   if (this.scannedBagID.value.length < 16) {
    //     return;
    //   }
    //   // Add item into bag functionality
    //   let requestBody = this.scannedItemDetails;
    //   requestBody['bagNumber'] = this.scannedBagID.value;
    //   this._store.dispatch(new AddItem(requestBody));
    //   // Response handling
    //   this.addItem$ = this._store.pipe(select(addItemSelector)).subscribe(response => {
    //     this.loggerService.consoleLog(response);
    //     if (response && response['bagNumber']) {
    //       setTimeout(() => {
    //         this.scanBarcodeEleRef.nativeElement.focus();
    //       }, 0);
    //       this.alertService.showSuccess('Item added into bag ' + response['bagNumber'] + ' successfully');
    //       this.reset();
    //     }
    //   });
    //   this.packItemSubscriptionList$.add(this.addItem$);
    //   // Loader handling
    //   this.packItemSubscriptionList$.add(this._store.pipe(select(addingItemSelector)).subscribe(isAdding => {
    //     if (isAdding) {
    //       this.isLoading = true;
    //       this.showLoader = true;
    //     }
    //   }));
    //   this.packItemSubscriptionList$.add(this._store.pipe(select(addedItemSelector)).subscribe(isAdded => {
    //     if (isAdded) {
    //       this.isLoading = false;
    //       this.showLoader = false;
    //     }
    //   }));
    //   // Error Handling
    //   this.addItemError$ = this._store.pipe(select(addItemErrorSelector)).subscribe(error => {
    //     this.loggerService.consoleLog(error);
    //   });
    //   this.packItemSubscriptionList$.add(this.addItemError$);
    // }
    /**
     * @description Reset page after adding item
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.reset = function () {
        this.scannedBarcode.reset();
        this.scannedBagID.reset();
        this.scannedItemDetails = "";
        this.scannedItemBagList = "";
        this.scannedItemBagListCount = 0;
    };
    /**
     * @description
     * 1. Set default create foem values
     * 2. Open create bag form
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.openCreateBagForm = function () {
        this.createFormGroup.patchValue({
            inventoryType: this.inventoryType
                .toUpperCase()
                .split(" ")
                .join("_"),
            brand: this.scannedItemDetails["brand"]
        });
        this.actionPopup = true;
    };
    /**
     * @description Create bag functionality
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.createFormSubmit = function () {
        var _this = this;
        this._store.dispatch(new bag_action_1.CreateBag(this.createFormGroup.value));
        this._store.pipe(store_1.select(bag_selectors_1.createBagSelector)).subscribe(function (data) {
            _this.loggerService.consoleLog(data);
            if (!data) {
                _this.loggerService.consoleLog("No response found in create bag");
                return;
            }
            _this.actionPopup = false;
            _this.createFormGroup.reset();
            _this.getBagList(_this.scannedItemDetails["recallConsignmentNumber"]);
            _this.alertService.showSuccess(_this.Messages.MESSAGES.BAG_CREATION_SUCCESS);
        });
        this._store.pipe(store_1.select(bag_selectors_1.creatingBagSelector)).subscribe(function (isCreating) {
            _this.loggerService.consoleLog(isCreating);
            if (isCreating) {
                _this.isLoading = true;
                _this.showLoader = true;
            }
        });
        this._store.pipe(store_1.select(bag_selectors_1.createdBagSelector)).subscribe(function (isCreated) {
            _this.loggerService.consoleLog(isCreated);
            if (isCreated) {
                _this.isLoading = false;
                _this.showLoader = false;
            }
        });
        this._store.pipe(store_1.select(bag_selectors_1.createBagErrorSelector)).subscribe(function (error) {
            _this.loggerService.consoleLog(error, "error");
        });
    };
    PackItemComponent.prototype.updateBagStatus = function (bagID, actionName) {
        if ((actionName != "DISCARDED" && actionName != "COMPLETE") || !bagID) {
            this.alertService.showError("Please provide valid action and bag id");
        }
        this.updateBagStatusAction = actionName;
        this.updateBagStatusBagID = bagID;
        this.updateBagConfirmationPopup = true;
    };
    PackItemComponent.prototype.updateBagStatusSubmit = function () {
        var _this = this;
        if (this.updateBag$) {
            this.updateBag$.unsubscribe();
        }
        if (this.updateBagError$) {
            this.updateBagError$.unsubscribe();
        }
        var requestBody = {
            bagID: this.updateBagStatusBagID,
            status: this.updateBagStatusAction
        };
        this._store.dispatch(new bag_action_1.UpdateBag(requestBody));
        this.updateBag$ = this._store
            .pipe(store_1.select(bag_selectors_1.updateBagSelector))
            .subscribe(function (data) {
            if (!data) {
                return;
            }
            _this.showLoader = false;
            if (data.status == _this.updateBagStatusAction) {
                _this.updateBagConfirmationPopup = false;
                _this.updateBagStatusAction = "";
                _this.updateBagStatusBagID = "";
                if (data.status == "COMPLETE" && data.refNumber) {
                    _this.alertService.showSuccess("Bag " + data.bagNumber + " packed successfully");
                    _this.printPackedBagPDF(data["bagNumber"]);
                    _this._store.pipe(store_1.select(bag_selectors_1.bagItemDetailsSelector)).subscribe(function (data) {
                        if (data && data["data"]) {
                            _this.getBagList(data["data"]["recallConsignmentNumber"]);
                        }
                    });
                }
                else if (data.status == "DISCARDED") {
                    _this.alertService.showSuccess("Bag " + data.bagNumber + " discard successfully");
                    _this.getBagList(data["data"]["recallConsignmentNumber"]);
                }
                //this.getBagList();
            }
        });
        this._store.pipe(store_1.select(bag_selectors_1.updatingBagSelector)).subscribe(function (isUpdating) {
            if (isUpdating) {
                _this.showLoader = true;
            }
        });
        this._store.pipe(store_1.select(bag_selectors_1.updatedBagSelector)).subscribe(function (isUpdated) {
            if (isUpdated) {
                _this.showLoader = false;
            }
        });
        this.updateBagError$ = this._store
            .pipe(store_1.select(bag_selectors_1.updateBagErrorSelector))
            .subscribe(function (error) {
            if (!error) {
                return;
            }
        });
    };
    /**
     * @description Print Pack List PDF functionality
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.printPackedBagPDF = function (bagID) {
        this.printService.printDocument("packed-bag", [bagID]);
    };
    PackItemComponent.prototype.ngOnInit = function () {
        // this._store.pipe(select(loadingBagListSelector)).subscribe(loading => {
        //       this.isloadingBagList = loading;
        //       console.log("BAG LOADING ", this.isloadingBagList);
        // });
        // this._store.pipe(select(loadedBagListSelector)).subscribe(loaded => {
        //     this.isloadedBagList = loaded;
        //     console.log("BAG LOADED ", this.isloadedBagList);
        //   // setTimeout(() => {
        //   //     if(this.isloadedBagList){
        //   //       this.scanBarcodeEle.nativeElement.focus();
        //   //     }
        //   //   }, 0);
        // });
    };
    PackItemComponent.prototype.ngAfterViewInit = function () {
        // console.log("element called.")
        //this.scanBarcodeEle.nativeElement.focus();
    };
    /**
     * @description
     * 1. Destroy active subscriptions
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    PackItemComponent.prototype.ngOnDestroy = function () {
        this.packItemSubscriptionList$.unsubscribe();
    };
    return PackItemComponent;
}());
exports.PackItemComponent = PackItemComponent;
