"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReceiveActionTypes;
(function (ReceiveActionTypes) {
    ReceiveActionTypes["SET_TRANSPORTER_DETAILS"] = "[Transporter Details Page] Get Transporter Details";
    ReceiveActionTypes["SET_CONSIGNMENT_NO"] = "[Documents Details Page] Get Consignment No.";
    ReceiveActionTypes["SET_ACCOUNT_DETAILS"] = "[Documents Details Page] Set Account Details.";
    ReceiveActionTypes["SET_BOX_DETAILS"] = "[Documents Details Page] Set Box Details.";
})(ReceiveActionTypes = exports.ReceiveActionTypes || (exports.ReceiveActionTypes = {}));
var SetTransporterDetails = /** @class */ (function () {
    function SetTransporterDetails(payload) {
        this.payload = payload;
        this.type = ReceiveActionTypes.SET_TRANSPORTER_DETAILS;
    }
    return SetTransporterDetails;
}());
exports.SetTransporterDetails = SetTransporterDetails;
var SetConsignmentNo = /** @class */ (function () {
    function SetConsignmentNo(payload) {
        this.payload = payload;
        this.type = ReceiveActionTypes.SET_CONSIGNMENT_NO;
    }
    return SetConsignmentNo;
}());
exports.SetConsignmentNo = SetConsignmentNo;
var SetAccountDetails = /** @class */ (function () {
    function SetAccountDetails(payload) {
        this.payload = payload;
        this.type = ReceiveActionTypes.SET_ACCOUNT_DETAILS;
    }
    return SetAccountDetails;
}());
exports.SetAccountDetails = SetAccountDetails;
var SetBoxDetails = /** @class */ (function () {
    function SetBoxDetails(payload) {
        this.payload = payload;
        this.type = ReceiveActionTypes.SET_BOX_DETAILS;
    }
    return SetBoxDetails;
}());
exports.SetBoxDetails = SetBoxDetails;
