<aside class="left-sidebar">
  <nav class="sidebar-nav">

    <div class="innersidebar">
      <div class="sidebarnav">
        <!-- <h2 class="heading">menuHead </h2> -->
        <mat-accordion>
          <a class="head-link dashboard" [routerLink]="ROUTES.DASHBOARD" routerLinkActive="active">Dashboard</a>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="order head-link">Orders</a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a class="nav-link" [routerLink]="ROUTES.WHOLESALE" routerLinkActive="active">New Order</a>
            <a class="nav-link" [routerLink]="ROUTES.PICKLIST" routerLinkActive="active">Picklist</a>
            <a class="nav-link" [routerLink]="ROUTES.SHIPMENT" routerLinkActive="active">Shipments</a>
            <a class="nav-link" [routerLink]="ROUTES.SEARCH_PRODUCT" routerLinkActive="active">Search Product</a>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="head-link inventory">Inventory</a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a class="nav-link" [routerLink]="ROUTES.INBOUND"
              routerLinkActive="active">Inbound</a>
            <a class="nav-link" [routerLink]="ROUTES.CONSIGNMENT_RECALL" routerLinkActive="active">Recall</a>
            <a class="nav-link" [routerLink]="ROUTES.ADJUST_INVENTORY" routerLinkActive="active">Adjustment</a>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <a class="head-link settings">Settings</a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a class="nav-link" [routerLink]="ROUTES.SETTING_WAREHOUSE" routerLinkActive="active">Warehouse</a>
            <a class="nav-link" [routerLink]="ROUTES.SETTING_BAGS" routerLinkActive="active">Bags</a>
            <a class="nav-link" [routerLink]="ROUTES.SETTING_EXPORT" routerLinkActive="active">Exports</a>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>


    <!--
    <ul>
      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.DASHBOARD" routerLinkActive="active">
          <img src="assets/images/nav-icon-dashboard.png" alt="" />
          Dashboard
        </a>
      </li>
      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.SHIPMENT" routerLinkActive="active"><img src="assets/images/nav-icon-shipments.png"
            alt="" />
          Shipments</a>
      </li>
      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.WHOLESALE" routerLinkActive="active">
          <img src="assets/images/nav-icon-wholesale.png" alt="" />
          New Orders
        </a>
      </li>
      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.PICKLIST" routerLinkActive="active">
          <img src="assets/images/nav-icon-manifests.png" alt="" />
          Picklist
        </a>
      </li>
      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.SEARCH_PRODUCT" routerLinkActive="active">
          <img src="assets/images/nav-icon-shipments.png" alt="" />
          Search Product
        </a>
      </li>

      <li>
        <a [routerLink]="ROUTES.INBOUND" routerLinkActive="active"><img src="assets/images/nav-icon-gatepass.png"
            alt="" /> Inbound</a>
      </li>

      <li>
        <a [routerLink]="ROUTES.CONSIGNMENT_RECALL" routerLinkActive="active"><img
            src="assets/images/nav-icon-inbound.png" alt="" /> Recall</a>
      </li>

      <li>
        <a [routerLink]="ROUTES.AMZWHOLESALE" routerLinkActive="active"><img
            src="assets/images/nav-icon-Inv-Adjustment.png" alt="" /> Inv. Adjustment</a>
      </li>

      <li [ngClass]="hostname === warehouseHostName ? 'disable-input' : ''">
        <a [routerLink]="ROUTES.SETTINGS" routerLinkActive="active"><img src="assets/images/nav-icon-setting.png"
            alt="" /> Settings</a>
      </li>


    </ul> -->
  </nav>
</aside>
