"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ShipmentActionTypes;
(function (ShipmentActionTypes) {
    ShipmentActionTypes["SEARCH"] = "[Shipment] Search Shipment";
    ShipmentActionTypes["SEARCH_COMPLETE"] = "[Shipment] Search Shipment Complete";
    ShipmentActionTypes["SEARCH_ERROR"] = "[Shipment] Search Shipment Error";
    ShipmentActionTypes["TRACKING_ID_SEARCH"] = "[Shipment] Tracking Id Search Shipment";
    ShipmentActionTypes["TRACKING_ID_SEARCH_COMPLETE"] = "[Shipment] Tracking Id Search Shipment Complete";
    ShipmentActionTypes["TRACKING_ID_SEARCH_ERROR"] = "[Shipment] Tracking Id Search Shipment Error";
    ShipmentActionTypes["CLEAR_SEARCH"] = "[Shipment] ClearSearch Shipment";
    ShipmentActionTypes["LOAD_PACKAGE_TYPE"] = "[PackageType] Load";
    ShipmentActionTypes["LOAD_PACKAGE_TYPE_SUCCESS"] = "[PackageType] Load Success";
    ShipmentActionTypes["LOAD_PACKAGE_TYPE_ERROR"] = "[PackageType] Load Error";
    ShipmentActionTypes["LOAD"] = "[Shipment] Load";
    ShipmentActionTypes["LOAD_SUCCESS"] = "[Shipment] Load Success";
    ShipmentActionTypes["LOAD_ERROR"] = "[Shipment] Load Error";
    ShipmentActionTypes["UPDATE_SHIPMENT"] = "[Shipment] Update Shipment";
    ShipmentActionTypes["UPDATE_SHIPMENT_COMPLETE"] = "[Shipment] Update Shipment Complete";
    ShipmentActionTypes["UPDATE_SHIPMENT_ERROR"] = "[Shipment] Update Shipment Error";
    ShipmentActionTypes["LABEL_LOADING"] = "[Shipment] Label Loading";
    ShipmentActionTypes["CLEAR_ORDER_SEARCH"] = "[Shipment] Clear Order Search";
    ShipmentActionTypes["PRINT_INVOICE"] = "[Shipment] Print Invoice";
    ShipmentActionTypes["PRINT_INVOICE_COMPLETE"] = "[Shipment] Print Invoice Complete";
    ShipmentActionTypes["PRINT_INVOICE_ERROR"] = "[Shipment] Print Invoice Error";
})(ShipmentActionTypes = exports.ShipmentActionTypes || (exports.ShipmentActionTypes = {}));
var LoadPackageType = /** @class */ (function () {
    function LoadPackageType() {
        this.type = ShipmentActionTypes.LOAD_PACKAGE_TYPE;
    }
    return LoadPackageType;
}());
exports.LoadPackageType = LoadPackageType;
var LoadPackageTypeSuccess = /** @class */ (function () {
    function LoadPackageTypeSuccess(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LOAD_PACKAGE_TYPE_SUCCESS;
    }
    return LoadPackageTypeSuccess;
}());
exports.LoadPackageTypeSuccess = LoadPackageTypeSuccess;
var LoadPackageTypeFailure = /** @class */ (function () {
    function LoadPackageTypeFailure(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LOAD_PACKAGE_TYPE_ERROR;
    }
    return LoadPackageTypeFailure;
}());
exports.LoadPackageTypeFailure = LoadPackageTypeFailure;
var Load = /** @class */ (function () {
    function Load(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LOAD;
    }
    return Load;
}());
exports.Load = Load;
var LoadSuccess = /** @class */ (function () {
    function LoadSuccess(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LOAD_SUCCESS;
    }
    return LoadSuccess;
}());
exports.LoadSuccess = LoadSuccess;
var LoadFailure = /** @class */ (function () {
    function LoadFailure(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LOAD_ERROR;
    }
    return LoadFailure;
}());
exports.LoadFailure = LoadFailure;
var OrderSearch = /** @class */ (function () {
    function OrderSearch(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.SEARCH;
    }
    return OrderSearch;
}());
exports.OrderSearch = OrderSearch;
var OrderSearchComplete = /** @class */ (function () {
    function OrderSearchComplete(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.SEARCH_COMPLETE;
    }
    return OrderSearchComplete;
}());
exports.OrderSearchComplete = OrderSearchComplete;
var OrderSearchError = /** @class */ (function () {
    function OrderSearchError(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.SEARCH_ERROR;
    }
    return OrderSearchError;
}());
exports.OrderSearchError = OrderSearchError;
var UpdateShipment = /** @class */ (function () {
    function UpdateShipment(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.UPDATE_SHIPMENT;
    }
    return UpdateShipment;
}());
exports.UpdateShipment = UpdateShipment;
var UpdateShipmentSuccess = /** @class */ (function () {
    function UpdateShipmentSuccess(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.UPDATE_SHIPMENT_COMPLETE;
    }
    return UpdateShipmentSuccess;
}());
exports.UpdateShipmentSuccess = UpdateShipmentSuccess;
var UpdateShipmentFailure = /** @class */ (function () {
    function UpdateShipmentFailure(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.UPDATE_SHIPMENT_ERROR;
    }
    return UpdateShipmentFailure;
}());
exports.UpdateShipmentFailure = UpdateShipmentFailure;
var ClearSearch = /** @class */ (function () {
    function ClearSearch() {
        this.type = ShipmentActionTypes.CLEAR_SEARCH;
    }
    return ClearSearch;
}());
exports.ClearSearch = ClearSearch;
var LabelLoading = /** @class */ (function () {
    function LabelLoading(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.LABEL_LOADING;
    }
    return LabelLoading;
}());
exports.LabelLoading = LabelLoading;
var TrackingIdSearch = /** @class */ (function () {
    function TrackingIdSearch(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.TRACKING_ID_SEARCH;
    }
    return TrackingIdSearch;
}());
exports.TrackingIdSearch = TrackingIdSearch;
var TrackingIdSearchComplete = /** @class */ (function () {
    function TrackingIdSearchComplete(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.TRACKING_ID_SEARCH_COMPLETE;
    }
    return TrackingIdSearchComplete;
}());
exports.TrackingIdSearchComplete = TrackingIdSearchComplete;
var TrackingIdSearchError = /** @class */ (function () {
    function TrackingIdSearchError(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.TRACKING_ID_SEARCH_ERROR;
    }
    return TrackingIdSearchError;
}());
exports.TrackingIdSearchError = TrackingIdSearchError;
var PrintInvoice = /** @class */ (function () {
    function PrintInvoice(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.PRINT_INVOICE;
    }
    return PrintInvoice;
}());
exports.PrintInvoice = PrintInvoice;
var PrintInvoiceComplete = /** @class */ (function () {
    function PrintInvoiceComplete(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.PRINT_INVOICE_COMPLETE;
    }
    return PrintInvoiceComplete;
}());
exports.PrintInvoiceComplete = PrintInvoiceComplete;
var PrintInvoiceError = /** @class */ (function () {
    function PrintInvoiceError(payload) {
        this.payload = payload;
        this.type = ShipmentActionTypes.PRINT_INVOICE_ERROR;
    }
    return PrintInvoiceError;
}());
exports.PrintInvoiceError = PrintInvoiceError;
var ClearOrderSearch = /** @class */ (function () {
    function ClearOrderSearch() {
        this.type = ShipmentActionTypes.CLEAR_ORDER_SEARCH;
    }
    return ClearOrderSearch;
}());
exports.ClearOrderSearch = ClearOrderSearch;
