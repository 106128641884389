import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  PrintService,
  AlertService,
  WholesaleService
} from "@app/shared/services";
import { Store, select } from "@ngrx/store";
import * as moment from "moment";
import { Subscription } from "rxjs";

import {
  GetRouteSummary,
  SetConsolidatedPicklist
} from "@app/wholesale/actions/wholesale.actions";
import {
  getCurrentRouteSummary,
  getCurrentDeliveryAssociates
} from "@app/wholesale/reducers/selectors";
import { AppState } from "@app/app.state";
import { CONSTANTS } from "@app/shared/constants/constants";
import { ROUTES } from "@app/shared/constants/constants";
import { Router } from "@angular/router";
@Component({
  selector: "app-picklists",
  templateUrl: "./picklists.component.html",
  styleUrls: ["./picklists.component.sass"]
})
export class PicklistsComponent implements OnInit, OnDestroy {
  endDate = moment(new Date())
    .add(7, "d")
    .format("YYYY-MM-DD");
  startDate = moment(new Date())
    .subtract(7, "d")
    .format("YYYY-MM-DD");

  getConsolidatedData$: Subscription;
  summaryList: any[] = [];
  picklistAssociates: any[] = [];
  uniquePicklist = [];
  showPicklist = false;
  selectedPicklistIndex;

  constructor(
    private printService: PrintService,
    private store: Store<AppState>,
    private router: Router,
    private alertService: AlertService,
    private wholesaleService: WholesaleService
  ) { }

  ngOnInit() {
    this.getConsolidatedPicklistSummary();
  }

  getConsolidatedPicklistSummary() {
    const params = {
      expectedShipDateFrom: moment(this.startDate).format("YYYY-MM-DD"),
      expectedShipDateTo: moment(this.endDate).format("YYYY-MM-DD")
    };
    if (params.expectedShipDateFrom > params.expectedShipDateTo) {
      this.alertService.showError(CONSTANTS.ALERT_MESSAGES.DATE_RANGE_ERROR);
    } else {
      this.getConsolidatedData$ = this.wholesaleService
        .getConsolidatedPicklist(params)
        .subscribe((res: any[]) => {
          console.log(res);
          if (res) {
            this.uniquePicklist = res;
            this.sortPicklistByPickingDate();
          }
        });
    }
  }

  onPrintInvoice(index, picklistId?) {
    const picklistIds = [picklistId];
    if (picklistId) {
      this.printService.printDocument("invoice", picklistIds);
    } else {
      this.store.dispatch(
        new SetConsolidatedPicklist(this.uniquePicklist[index])
      );
      this.printService.printDocument("invoice");
    }
  }

  onPrintSheet(picklistId) {
    const picklistIds = [picklistId];
    if (picklistId) {
      this.printService.printDocument("trip-sheet", picklistIds);
    }
  }

  getAssociateNameById(id) {
    const associateList = this.wholesaleService.getAssociateName(
      this.picklistAssociates,
      id
    );
    if (associateList.length > 0) {
      return associateList[0].name;
    }
    return null;
  }

  sortPicklistByPickingDate() {
    this.uniquePicklist.sort((a, b) =>
      moment(a["pickingDate"]) < moment(b["pickingDate"])
        ? 1
        : moment(b["pickingDate"]) < moment(a["pickingDate"])
          ? -1
          : 0
    );
  }

  trackByFn(index) {
    return index;
  }

  togglePicklist(index) {
    this.showPicklist =
      this.selectedPicklistIndex === index ? !this.showPicklist : true;
    this.selectedPicklistIndex = index;
  }
  hopToShipment(pickListId) {
    this.router.navigate([ROUTES.WHOLESALE_SHIPMENT], {
      queryParams: { id: pickListId }
    });
  }
  ngOnDestroy() {
    if (this.getConsolidatedData$) {
      this.getConsolidatedData$.unsubscribe();
    }
  }
}
