import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "getWarehouseName" })
export class GetWarehouseName implements PipeTransform {
  transform(id: any, warehouseList: any): string {

    if (warehouseList.length) {
      const warehouse = warehouseList.filter((data) => {
        return data.id == id
      })
      return warehouse[0].externalWarehouseID
    }
  }
}
