import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import {
  GetConsignmentListAction,
  UpdateConsignmentAction
} from "../../actions/consignment-recall.action";
import {
  getConsignmentListSelector,
  getConsignmentListErrorSelector,
  getConsignmentListLoadingSelector,
  getConsignmentListLoadedSelector,
  updateConsignmentSelector,
  updateConsignmentErrorSelector,
  updatingConsignmentSelector,
  updatedConsignmentSelector
} from "../../reducers/selector";
import { AlertService } from "../../../shared/services/alert.service";
import { SharedService } from "../../../shared/services/shared.service";
import { ROUTES } from "../../../shared/constants/constants";
import { ConsoleLoggerService } from "../../../shared/services/console-logger.service";
import { GetBagList, UpdateBag } from "../../../bags/bag.action";
import {
  bagListSelector,
  updateBagSelector,
  bagItemDetailsSelector
} from "../../../bags/bag-selectors";
import { PrintService, WholesaleService } from "../../../shared/services";
import { Subscription } from "rxjs";
import * as filestack from "filestack-js";
import { PickerOptions } from "filestack-js/build/main/lib/picker";
import { environment } from "@env/environment";

const client = filestack.init(environment.FILESTACK_APIKEY);

@Component({
  selector: "app-recall-list",
  templateUrl: "./recall-list.component.html",
  styleUrls: ["./recall-list.component.scss"],
  providers: [DatePipe]
})
export class RecallListComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  activeTab: string = "CREATED";
  activeTabIndex: number = 0;
  consignmentList: any = [];
  consignmentCount: number = 0;
  getListRequestBody: any = {
    page: 1,
    perPage: 100,
    status: this.activeTab
  };
  markpacked;

  ROUTES = ROUTES;

  actionPopup: boolean = false;
  actionPackBagsPopup: boolean = false;
  actionDispatchBagsPopup: boolean = false;
  actionConsignmentNumber: any;
  actionName: any;
  actionFormGroup = new FormGroup({
    containerQty: new FormControl(""),
    handoverURL: new FormControl("")
  });
  uploadedFilename;

  activeFilter: any;
  filterOrderNo = new FormControl("");
  filterVendorCode = new FormControl("");
  filterInventoryType = new FormControl("");
  filterExpProcessingFrom = new FormControl("");
  filterExpProcessingTo = new FormControl("");
  filterExpCompletionFrom = new FormControl("");
  filterExpCompletionTo = new FormControl("");
  filterProcessingFrom = new FormControl("");
  filterProcessingTo = new FormControl("");
  filterCompletionFrom = new FormControl("");
  filterCompletionTo = new FormControl("");

  masterInventoryTypeList = [
    { label: "Good", value: "GOOD" },
    { label: "Bad", value: "BAD" },
    { label: "QC Rejected", value: "QC_REJECTED" },
    { label: "Transfer", value: "TRANSFER" }
  ];

  orderNo: any;
  fileroverlay: boolean;
  VendorCode: boolean;
  Inventory: boolean;
  Completedon: boolean;
  ProcessedFrom: boolean;
  ExpProcessing: boolean;
  dateFilterType = [
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Last 90 Days",
    "This month",
    "Last month"
  ];
  Expcompletionby: boolean;

  bagListToBePacked: any;
  bagListToBeDispatched: any = [];
  scannedBagBarcode = new FormControl("");
  dispatchedBagsCount: number = 0;

  bagList$: Subscription;
  updateBag$: Subscription;
  updateConsignment$: Subscription;
  accountId: number; //For Print Picklist Data In Recall Consignment For Amazon Portal
  navigateToListDetails: boolean = false; //For Preserving Tab Selection If User Navigates Back From Details Page
  isFilterTypeTransfer: boolean = false;
  warehouseObj: any;
  warehouseIds = [646, 647, 648, 650, 651, 652];

  constructor(
    private store: Store<any>,
    public alertService: AlertService,
    private sharedService: SharedService,
    public router: Router,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private loggerService: ConsoleLoggerService,
    private printService: PrintService,
    private wholesaleService: WholesaleService
  ) {
  }

  ngOnInit() {
    this.getWarehouseDetails();
    if (this.sharedService.activeTabIndex != null) {
      this.activeTabIndex = this.sharedService.activeTabIndex;
      this.changeActiveTab(this.activeTabIndex);
    } else {
      this.getConsignmentList();
    }
  }

  getWarehouseDetails() {
    this.isLoading = true;
    this.wholesaleService.getWarehouseList().subscribe(
      (response) => {
        let selWarehouse = localStorage.getItem('selectedWarehouseID');
        this.isLoading = false;
        if(response && response['data'] && selWarehouse) {
          this.warehouseObj = response['data'].find(obj => obj.externalWarehouseID === selWarehouse)
        }
      }
    )
  }

  // Open filter popup
  showFilter(formcontrolName) {
    this.activeFilter = formcontrolName;
  }

  /**
   * Reset filters
   */
  resetFilter(formcontrolName) {
    // Reset formcontrols
    switch (formcontrolName) {
      case "filterOrderNo":
      case "filterVendorCode":
      case "filterInventoryType":
        this[formcontrolName].reset();
        break;
      case "filterExpProcessingFromTo":
        this.filterExpProcessingFrom.reset();
        this.filterExpProcessingTo.reset();
        break;
      case "filterExpCompletion":
        this.filterExpCompletionFrom.reset();
        this.filterExpCompletionTo.reset();
        break;
      case "filterProcessedFromTo":
        this.filterProcessingFrom.reset();
        this.filterProcessingTo.reset();
        break;
      case "filterCompletedFromTo":
        this.filterCompletionFrom.reset();
        this.filterCompletionTo.reset();
        break;
    }

    // Close all filters popup
    this.activeFilter = "";

    // Submit filters
    this.applyFilters();
  }

  /**
   * Set and submit filters
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  applyFilters() {
    this.activeFilter = "";

    // Set order number filter request
    if (this.filterOrderNo.value) {
      this.getListRequestBody["orderNumber"] = this.filterOrderNo.value;
    } else {
      delete this.getListRequestBody["orderNumber"];
    }

    // Set vendor code filter request
    if (this.filterVendorCode.value) {
      this.getListRequestBody["vendorCode"] = this.filterVendorCode.value;
    } else {
      delete this.getListRequestBody["vendorCode"];
    }

    // Set inventory type filter request
    if (this.filterInventoryType.value) {
      if (this.filterInventoryType.value == "TRANSFER") {
        this.isFilterTypeTransfer = true;
      } else {
        this.isFilterTypeTransfer = false;
        this.getListRequestBody[
          "inventoryType"
        ] = this.filterInventoryType.value;
      }
    } else {
      this.isFilterTypeTransfer = false;
      delete this.getListRequestBody["inventoryType"];
    }

    // Set expected processing date filter request
    if (
      this.filterExpProcessingFrom.value &&
      this.filterExpProcessingTo.value
    ) {
      this.getListRequestBody["expProcessingFrom"] = this.datePipe.transform(
        this.filterExpProcessingFrom.value,
        "y-MM-dd"
      );
      this.getListRequestBody["expProcessingTo"] = this.datePipe.transform(
        this.filterExpProcessingTo.value,
        "y-MM-dd"
      );
    } else {
      delete this.getListRequestBody["expProcessingFrom"];
      delete this.getListRequestBody["expProcessingTo"];
    }

    // Set expected completion date filter request
    if (
      this.filterExpCompletionFrom.value &&
      this.filterExpCompletionTo.value
    ) {
      this.getListRequestBody["expCompletionFrom"] = this.datePipe.transform(
        this.filterExpCompletionFrom.value,
        "y-MM-dd"
      );
      this.getListRequestBody["expCompletionTo"] = this.datePipe.transform(
        this.filterExpCompletionTo.value,
        "y-MM-dd"
      );
    } else {
      delete this.getListRequestBody["expCompletionFrom"];
      delete this.getListRequestBody["expCompletionTo"];
    }

    // Set processed date filter request
    if (this.filterProcessingFrom.value && this.filterProcessingTo.value) {
      this.getListRequestBody["processingFrom"] = this.datePipe.transform(
        this.filterProcessingFrom.value,
        "y-MM-dd"
      );
      this.getListRequestBody["processingTo"] = this.datePipe.transform(
        this.filterProcessingTo.value,
        "y-MM-dd"
      );
    } else {
      delete this.getListRequestBody["processingFrom"];
      delete this.getListRequestBody["processingTo"];
    }

    // Set completion date filter request
    if (this.filterCompletionFrom.value && this.filterCompletionTo.value) {
      this.getListRequestBody["completionFrom"] = this.datePipe.transform(
        this.filterCompletionFrom.value,
        "y-MM-dd"
      );
      this.getListRequestBody["completionTo"] = this.datePipe.transform(
        this.filterCompletionTo.value,
        "y-MM-dd"
      );
    } else {
      delete this.getListRequestBody["completionFrom"];
      delete this.getListRequestBody["completionTo"];
    }

    // Set order number filter request
    // if(this.filterExpProcessingFrom.value && this.filterExpProcessingTo.value){
    //   this.getListRequestBody['expProcessingFrom'] = this.filterExpProcessingFrom.value;
    //   this.getListRequestBody['expProcessingTo'] = this.filterExpProcessingTo.value;
    // }else{
    //   delete this.getListRequestBody['expProcessingFrom'];
    //   delete this.getListRequestBody['expProcessingTo'];
    // }

    this.getConsignmentList();
  }

  /**
   * Paginate functionality
   * @param pageEvent
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  onPaginate(pageEvent: any) {
    this.getListRequestBody.page = pageEvent.pageIndex + 1;
    this.getListRequestBody.perPage = pageEvent.pageSize;
    this.getConsignmentList();
  }

  // Method to Switch Consignment Color
  switchConsignmentColor(inventoryType) {
    switch (inventoryType) {
      case "GOOD":
        return "#BBFDD1";
      case "BAD":
        return "#FE9EB7";
      case "CUSTOMER-RETURN":
      case "QC_REJECTED":
        return "#FEFB98";
      default:
        return "#FDFBFB;";
    }
  }

  /**
   * @description Navigate to recall consignment detail page if status is not created
   * @param consignmentNumber
   * @param consignmentStatus
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  navigateToDetailPage(consignmentNumber, consignmentStatus) {
    if (consignmentStatus != "CREATED") {
      this.sharedService.activeTabIndex = this.activeTabIndex;
      this.navigateToListDetails = true;
      this.router.navigate([
        this.ROUTES.CONSIGNMENT_RECALL_DETAILS +
          this.accountId +
          "/" +
          encodeURIComponent(consignmentNumber)
      ]);
    }
  }

  /**
   * Change active tab and call list
   * @param selectedTab
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  changeActiveTab(matTabEvent) {
    // Change active tab
    // if (matTabEvent.index || matTabEvent.index === 0) {
    // Set selected tab value
    switch (matTabEvent) {
      case 0:
        this.activeTab = "CREATED";
        break;
      case 1:
        this.activeTab = "PROCESSING";
        break;
      case 2:
        this.activeTab = "PACKED";
        break;
      case 3:
        this.activeTab = "DISPATCHED";
        break;
      case 4:
        this.activeTab = "";
        break;
    }

    this.getListRequestBody["status"] = this.activeTab;

    // Set selected tab index
    this.activeTabIndex = matTabEvent;
    // }

    // Call list
    this.getConsignmentList();
  }

  /**
   * Get consignment list
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getConsignmentList() {
    this.consignmentList = [];
    if (this.getListRequestBody["status"] == "") {
      delete this.getListRequestBody["status"];
    }
    this.store.dispatch(new GetConsignmentListAction(this.getListRequestBody));
    this.store.pipe(select(getConsignmentListSelector)).subscribe(result => {
      if (!result) {
        return false;
      }
      this.isLoading = false;
      if (this.isFilterTypeTransfer) {
        this.consignmentList = this.getFilteredConsignment(result["data"]);
        if (result["data"] && result["data"].length != 0) {
          this.accountId = result["data"][0]["accountId"];
        }
        this.consignmentCount = this.consignmentList.length;
      } else {
        this.filterInventoryType.value == "GOOD"
          ? (this.consignmentList = this.getFilteredConsignment(result["data"]))
          : (this.consignmentList = result["data"]);
        if (result["data"] && result["data"].length != 0) {
          this.accountId = result["data"][0]["accountId"];
        }
        this.filterInventoryType.value == "GOOD"
          ? (this.consignmentCount = this.consignmentList.length)
          : (this.consignmentCount = result["total"]);
      }
    });
    this.store
      .pipe(select(getConsignmentListErrorSelector))
      .subscribe(error => {
        if (error) {
          this.isLoading = false;
        }
      });
    this.store
      .pipe(select(getConsignmentListLoadingSelector))
      .subscribe(isLoading => {
        if (isLoading) {
          this.isLoading = true;
        }
      });
    this.store
      .pipe(select(getConsignmentListLoadedSelector))
      .subscribe(isLoaded => {
        if (isLoaded) {
          this.isLoading = false;
        }
      });
  }

  getFilteredConsignment(consigmentData) {
    let consignmentList = [];
    if (this.filterInventoryType.value == "TRANSFER") {
      consignmentList = consigmentData.filter(consignmentData => {
        return consignmentData.type == this.filterInventoryType.value;
      });
    } else if (this.filterInventoryType.value == "GOOD") {
      consignmentList = consigmentData.filter(consignmentData => {
        return (
          consignmentData.inventoryType == this.filterInventoryType.value &&
          consignmentData.type != "TRANSFER"
        );
      });
    }
    return consignmentList;
  }

  /**
   * Start Processing
   * Mark as Packed
   * Mark as Complete
   *
   * @param requiredAction
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  markAction(requiredAction, consignmentNumber, consignmentType) {
    this.uploadedFilename = "";
    this.actionFormGroup.reset();

    let updateRequestBody = {
      consignmentNumber: encodeURIComponent(consignmentNumber)
    };

    if (this.updateConsignment$) {
      this.updateConsignment$.unsubscribe();
    }

    switch (requiredAction) {
      case "START_PROCESSING":
        updateRequestBody["status"] = "PROCESSING";
        if (consignmentType) {
          updateRequestBody["type"] = "transfer";
        }
        this.store.dispatch(new UpdateConsignmentAction(updateRequestBody));
        this.updateConsignmentHandling();
        break;

      case "MARK_PACKED":
        updateRequestBody["status"] = "PACKED";
        if (consignmentType) {
          updateRequestBody["type"] = "transfer";
        }
        this.store.dispatch(new UpdateConsignmentAction(updateRequestBody));
        this.updateConsignmentHandling();
        break;

      case "MARK_COMPLETED":
        this.getBagList(consignmentNumber, "TO_BE_DISPATCHED");
        if (consignmentType) {
          updateRequestBody["type"] = "transfer";
        }
        this.actionConsignmentNumber = consignmentNumber;
        this.actionName = "MARK_COMPLETED";
        break;
    }
  }

  /**
   * Action form submit
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  actionFormSubmit() {
    // Validations
    if (
      !this.actionFormGroup.value.containerQty &&
      !this.actionFormGroup.value.handoverURL &&
      this.bagListToBeDispatched.length > 0
    ) {
      this.alertService.showError("Please enter required values");
      return false;
    }

    let updateRequestBody = {
      consignmentNumber: encodeURIComponent(this.actionConsignmentNumber)
    };

    // Mark as dispatched/completed
    if (this.actionName == "MARK_COMPLETED") {
      // Validation
      if (!this.actionFormGroup.value.handoverURL) {
        this.alertService.showError("Please upload handover document");
        return false;
      }

      updateRequestBody["status"] = "DISPATCHED";
      updateRequestBody[
        "pohAttachmentUrl"
      ] = this.actionFormGroup.value.handoverURL;
    }

    // Update request
    this.store.dispatch(new UpdateConsignmentAction(updateRequestBody));
    this.updateConsignmentHandling();
  }

  /**
   * Update consignment handling
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.cm>
   */
  updateConsignmentHandling() {
    this.updateConsignment$ = this.store
      .pipe(select(updateConsignmentSelector))
      .subscribe(result => {
        this.loggerService.consoleLog(result);

        if (result) {
          if (
            result["unpackedBagDetails"] &&
            result["unpackedBagDetails"].length > 0
          ) {
            this.getBagList(
              result["unpackedBagDetails"][0]["refNumber"],
              "TO_BE_PACKED"
            );
          } else if (result["status"]) {
            // Success
            // Mark processing
            if (result["status"] == "PROCESSING") {
              this.alertService.showSuccess(
                "Consignment successfully marked processing, now please process it."
              );
              this.getConsignmentList();
              this.actionPopup = false;
            }
            // Mark packed
            else if (result["status"] == "PACKED") {
              this.alertService.showSuccess(
                "Order Number " +
                  result["consignmentNumber"] +
                  " packed successfully."
              );
              this.getConsignmentList();
              this.actionPopup = false;
            }
            // Mark dispatched/completed
            else if (result["status"] == "DISPATCHED") {
              this.actionDispatchBagsPopup = false;
              this.alertService.showSuccess(
                "Order Number " +
                  result["consignmentNumber"] +
                  " dispatched successfully."
              );
              this.getConsignmentList();
              this.actionPopup = false;
            } else {
              // Failure
              this.alertService.showError(
                "Something went wrong please try again later."
              );
            }
          } else {
            // Error
            console.log(result);
          }
        }
      });

    this.store.pipe(select(updateConsignmentErrorSelector)).subscribe(error => {
      this.isLoading = false;
    });

    this.store
      .pipe(select(updatingConsignmentSelector))
      .subscribe(isUpdating => {
        if (isUpdating) {
          this.isLoading = true;
        }
      });

    this.store.pipe(select(updatedConsignmentSelector)).subscribe(isUpdated => {
      if (isUpdated) {
        this.isLoading = false;
      }
    });
  }

  getBagList(recallConsignmentNumber, statusCase) {
    if (this.bagList$) {
      this.bagList$.unsubscribe();
    }

    let requestBody = {
      page: 1,
      perPage: 1000,
      recallConsignmentNumber: recallConsignmentNumber
    };

    if (statusCase == "TO_BE_DISPATCHED") {
      requestBody["status"] = "COMPLETE" + "," + "DISPATCHED";
    } else if (statusCase == "TO_BE_PACKED") {
      requestBody["status"] = "CREATED" + "," + "PROCESSING";
    }

    this.store.dispatch(new GetBagList(requestBody));

    this.bagList$ = this.store.pipe(select(bagListSelector)).subscribe(data => {
      if (data) {
        if (statusCase == "TO_BE_DISPATCHED") {
          this.actionDispatchBagsPopup = true;
          this.bagListToBeDispatched = data["data"];
          this.dispatchedBagsCount = data["data"].filter(function(element) {
            return element.status == "DISPATCHED";
          }).length;
        } else if (statusCase == "TO_BE_PACKED") {
          this.actionPackBagsPopup = true;
          this.bagListToBePacked = data["data"];

          // If no records found for created and processing bags
          // if(data['data'] && data['data'].length == 0){
          //   this.actionPackBagsPopup = false;
          //   this.bagListToBePacked = null;
          //   this.markAction('MARK_PACKED', recallConsignmentNumber);
          // }else{
          //   this.actionPackBagsPopup = true;
          //   this.bagListToBePacked = data['data'];
          // }
        }
      }
    });
  }

  /**
   * Upload file on stack
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  onFileStackUpload() {
    const fileStackOption: PickerOptions = {
      maxFiles: 10,
      accept: [".pdf", ".jpg", ".xls", ".csv", ".xlsx", ".png", ".jpeg"],
      uploadInBackground: false,
      startUploadingWhenMaxFilesReached: true,
      fromSources: [
        "local_file_system",
        "imagesearch",
        "googledrive",
        "dropbox"
      ],
      onOpen: () => {},
      dropPane: {},
      storeTo: {
        location: "gcs",
        path: "/wms-frontend/"
      },
      onUploadDone: (response: any) => {
        console.log("response from filestack", response);
        if (response.filesUploaded && response.filesUploaded.length > 0) {
          this.actionFormGroup
            .get("handoverURL")
            .patchValue(response.filesUploaded[0].url);
          this.uploadedFilename = response.filesUploaded[0].filename;
        }
      }
    };
    client.picker(fileStackOption).open();

    // Can be removed after testing the upgraded version of filestack
    // this.sharedService
    //   .uploadDocument()
    //   .then(response => {
    //     if (response.filesUploaded && response.filesUploaded.length > 0) {
    //       this.actionFormGroup.get('handoverURL').patchValue(response.filesUploaded[0].url);
    //       this.uploadedFilename = response.filesUploaded[0].filename;
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  updateBagStatus(bagID, status) {
    if (this.updateBag$) {
      this.updateBag$.unsubscribe();
    }

    // Mark bag as dispatch
    if (status == "DISPATCH") {
      let findPackedBag = this.bagListToBeDispatched.filter(element => {
        return element["bagNumber"] == bagID && element["status"] == "COMPLETE";
      });

      if (findPackedBag) {
        this.store.dispatch(
          new UpdateBag({
            bagID: bagID,
            status: "DISPATCHED"
          })
        );

        this.updateBag$ = this.store
          .pipe(select(updateBagSelector))
          .subscribe(data => {
            if (!data) {
              return;
            }
            if (data.status == "DISPATCHED") {
              this.alertService.showSuccess(
                "Bag " + data["bagNumber"] + " marked as scanned successfully"
              );
              this.actionFormGroup.reset();
              this.scannedBagBarcode.reset();
            }

            this.getBagList(data["refNumber"], "TO_BE_DISPATCHED");
          });
      }
    } else {
      // Mark bag as packed/complete
      this.store.dispatch(
        new UpdateBag({
          bagID: bagID,
          status: status
        })
      );

      this.updateBag$ = this.store
        .pipe(select(updateBagSelector))
        .subscribe(data => {
          if (!data) {
            return;
          }

          if (data.status == status) {
            if (data.status == "COMPLETE" && data.refNumber) {
              this.alertService.showSuccess(
                "Bag " + data.bagNumber + " packed successfully"
              );
              this.printService.printDocument("packed-bag", [
                data["bagNumber"]
              ]);

              this.store
                .pipe(select(bagItemDetailsSelector))
                .subscribe(data => {
                  if (data && data["data"]) {
                    this.getBagList(
                      data["data"]["recallConsignmentNumber"],
                      "TO_BE_PACKED"
                    );
                  }
                });
            } else if (data.status == "DISCARDED") {
              this.alertService.showSuccess(
                "Bag " + data.bagNumber + " discarded successfully"
              );
              this.getBagList(data["refNumber"], "TO_BE_PACKED");
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.bagList$) {
      this.bagList$.unsubscribe();
    }

    if (this.updateBag$) {
      this.updateBag$.unsubscribe();
    }

    if (this.updateConsignment$) {
      this.updateConsignment$.unsubscribe();
    }
    this.accountId = null;
    if (!this.navigateToListDetails) {
      this.sharedService.activeTabIndex = null;
    }
  }

  orderclick() {
    this.orderNo = !this.orderNo;
    this.fileroverlay = !this.fileroverlay;
  }
  vendorclick() {
    this.VendorCode = !this.VendorCode;
    this.fileroverlay = !this.fileroverlay;
  }
  Inventoryclick() {
    this.Inventory = true;
    this.fileroverlay = !this.fileroverlay;
  }
  Completedonclick() {
    this.Completedon = true;
    this.fileroverlay = !this.fileroverlay;
  }
  ProcessedFromclick() {
    this.ProcessedFrom = true;
    this.fileroverlay = !this.fileroverlay;
  }
  ExpProcessingclick() {
    this.ExpProcessing = true;
    this.fileroverlay = !this.fileroverlay;
  }
  Expcompletionbyclick() {
    this.Expcompletionby = true;
    this.fileroverlay = !this.fileroverlay;
  }
  overlayclick() {
    this.orderNo = false;
    this.VendorCode = false;
    this.Inventory = false;
    this.Inventory = false;
    this.Completedon = false;
    this.ProcessedFrom = false;
    this.ExpProcessing = false;
    this.fileroverlay = false;
    this.Expcompletionby = false;
  }
}
