import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Configuration } from "../../shared/configuration/config";

@Injectable({
  providedIn: "root"
})
export class AdjustmentService {
  constructor(private api: ApiService, private config: Configuration) { }

  /**
* @author Nidhi Raturi
* @params Object - params
* @description Api call to save manual adjust inventory form
* @return Observable<any> -

*/
  saveAdjustmentForm(params = {}) {
    console.log(params);
    console.log(this.config.UrlObj);
    return this.api.post(this.config.UrlObj.saveAdjustmentInventoryForm, params);
  }
}
