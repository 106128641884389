import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as RecallAction from '../actions/consignment-recall.action';
import { ConsignmentRecallService } from '../services/consignment-recall.service';
import { catchError, map, mergeMap, tap, exhaustMap } from 'rxjs/operators';

@Injectable()
export class ConsignmentEffects {
  constructor(
    private actions$: Actions,
    private consignmentService: ConsignmentRecallService,
    private store: Store<{ consignment: any }>
  ) { }

  /**
   * Effect to handle get consignment details api
   */
  @Effect()
  getConsignmentList$: Observable<Action> = this.actions$.pipe(
    ofType<RecallAction.GetConsignmentListAction>(RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST),
    mergeMap(action => {
      return this.consignmentService.getConsignmentListService(action.payload).pipe(
        map(data => ({
          type: RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  /**
   * Effect to handle update consignment api
   */
  @Effect()
  updateConsignmentList$: Observable<Action> = this.actions$.pipe(
    ofType<RecallAction.UpdateConsignmentAction>(RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT),
    mergeMap(action => {
      return this.consignmentService.updateConsignmentService(action.payload).pipe(
        map(data => ({
          type: RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  /**
 * Effect to handle get grn items api
 */
  @Effect()
  getGRNItemList$: Observable<Action> = this.actions$.pipe(
    ofType<RecallAction.GetGRNItemsAction>(RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS),
    mergeMap(action => {
      return this.consignmentService.getGRNItemListService(action.payload).pipe(
        map(data => ({
          type: RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
}