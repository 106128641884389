import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ROUTES } from "../../../shared/constants/constants";
import { MatDialog } from '@angular/material';
import { ConsignmentReceiveService } from '../../../consignment-receive/services/consignment-receive.service';
import { ConsignmentRecallService } from '../../services/consignment-recall.service';
import { SharedService } from '../../../shared/services/shared.service';
import { AlertService } from '../../../shared/services/alert.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-recall-home',
  templateUrl: './recall-home.component.html',
  styleUrls: ['./recall-home.component.scss']
})
export class RecallHomeComponent implements OnInit {

  ROUTES = ROUTES;
  accountList: any = []
  warehouseList: any = []
  selectedWarehouse: any;
  selectedAccountId: any;
  isSubmitForm: boolean = false
  @ViewChild('trasferDialog') trasferDialog: TemplateRef<any>;
  @ViewChild("transferForm") transferForm: NgForm

  constructor(public dialog: MatDialog, private consignmentReceiveService: ConsignmentReceiveService,
    private consignmentRecallService: ConsignmentRecallService,
    private sharedService: SharedService, private alertService: AlertService) { }

  ngOnInit() {
    this.consignmentReceiveService.getAccountNameList().subscribe((response) => {
      if (response['data']) {
        this.accountList = response['data']
      }
    })
  }

  onAccontSelection(selectedAccount) {
    if (selectedAccount) {
      this.selectedAccountId = selectedAccount
      this.consignmentRecallService.getWarehouseDetails(selectedAccount).subscribe(
        (response) => {
          if (response['data']) {
            let updatedWarehouseList = []
            response['data'].forEach((warehouse) => {
              if (warehouse.warehouseId != this.sharedService.selectedWarehouseId) {
                updatedWarehouseList.push(warehouse)
              }
            })
            this.warehouseList = updatedWarehouseList
          }
        }
      )
    }
  }

  onWarehouseSelection(selectedWarehouse) {
    this.selectedWarehouse = selectedWarehouse
  }

  openTransferConsignmentPopup() {
    this.dialog.open(this.trasferDialog);
  }

  onSubmitConsignmentTransfer() {
    this.isSubmitForm = true
    let transferData = {
      "fromWarehouseId": this.sharedService.selectedWarehouseId,
      "toPartyId": +this.selectedWarehouse,
      "accountId": +this.selectedAccountId,
    }
    this.consignmentRecallService.createRecallConsignmentTransfer(transferData).subscribe((response) => {
      if (response) {
        this.isSubmitForm = false
        this.alertService.showSuccess(`${"Recall consignment "}${response['consignmentNumber']}${"is created. Now create bag and add items to bag."}`)
        this.onCloseTransferConsignmentPopup()
      }
    },
      (error) => {
        this.isSubmitForm = false
      })
  }

  onCloseTransferConsignmentPopup() {
    this.dialog.closeAll();
    this.selectedAccountId = null
    this.selectedWarehouse = null
  }

}
