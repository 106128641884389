"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fromShipment = require("./shipment.reducer");
var fromOrder = require("./order.reducer");
exports.initialState = {
    shipment: fromShipment.initialState,
    order: fromOrder.initialState
};
exports.reducers = {
    shipment: fromShipment.ShipmentReducer,
    order: fromOrder.OrderReducer
};
