"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var config_1 = require("../configuration/config");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../configuration/config");
var ShipmentService = /** @class */ (function () {
    function ShipmentService(http, config) {
        this.http = http;
        this.config = config;
    }
    ShipmentService.prototype.getAllShipmentsByKey = function (date) {
        return this.http
            .get("" + this.config.UrlObj.getShipment + date)
            .pipe(operators_1.map(function (res) { return res; }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.handleError = function (error) {
        if (error.status && error.status != null && error.status > 0) {
            return rxjs_1.throwError(error || { message: "Server error" });
        }
        return rxjs_1.throwError(error || "Server error");
    };
    ShipmentService.prototype.getOrderDetails = function (volumeId) {
        return this.http.get("" + this.config.UrlObj.getOrder + volumeId);
    };
    ShipmentService.prototype.searchOrders = function (queryTitle) {
        return this.http
            .get("" + this.config.UrlObj.searchOrders + queryTitle)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.searchTrackingId = function (queryTitle) {
        return this.http
            .get("" + this.config.UrlObj.searchByTrackingId + queryTitle)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.getPackageType = function () {
        return this.http
            .get("" + this.config.UrlObj.getPackageType)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.getTodoList = function (params) {
        console.log(params);
        return this.http
            .get("" + this.config.UrlObj.getTodoList + params)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.updateShipment = function (data, shipmentId) {
        var bodyText = JSON.stringify(data);
        return this.http
            .put("" + this.config.UrlObj.updateShipment + shipmentId, bodyText)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.prototype.PrintInvoice = function (path) {
        return this.http
            .get("" + this.config.UrlObj.printInvoice + path)
            .pipe(operators_1.map(function (res) {
            return res;
        }), operators_1.catchError(this.handleError));
    };
    ShipmentService.ngInjectableDef = i0.defineInjectable({ factory: function ShipmentService_Factory() { return new ShipmentService(i0.inject(i1.HttpClient), i0.inject(i2.Configuration)); }, token: ShipmentService, providedIn: "root" });
    return ShipmentService;
}());
exports.ShipmentService = ShipmentService;
