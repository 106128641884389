<main class="right-container">
    <!-- <div class="search-product"> -->
    <!-- <div class="search-bar"> -->
    <!-- <div class="input-search" [ngClass]="
      submitted &&
      searchInput.invalid
        ? 'input-field error'
        : 'input-field'
    ">

                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Search by ASIN*" matInput [formControl]="searchInput" (keydown.enter)="getAttributeList()">


                </mat-form-field>
                <p *ngIf="currentProduct">Details of customers who ordered product <b>{{currentProduct}}</b></p>
            </div> -->


    <div class="search-product">
        <div class="search-bar">
            <div class="input-search" [ngClass]="
            submitted &&
            searchInput.invalid
              ? 'input-field error'
              : 'input-field'
          ">

                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Search by Title or ASIN*" (keyup.enter)="getAttributeList()" matInput [formControl]="searchInput" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="getAttributeList(option)">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>


                </mat-form-field>
                <p *ngIf="currentProduct">Details of customers who ordered product <b>{{currentProduct}}</b></p>
            </div>


        </div>



        <div class="search-table attribute-table" *ngIf="searchByAsin">
            <div class="grey-header-bar">
                <span>
          Attributes
        </span>
                <span>
          {{currentProduct}}
        </span>
                <div class="buttons-wrp">
                    <button class="edit" (click)="edit(searchByAsin)" *ngIf="isEditRequest"> <img title="Edit"
              src="../../assets/images/edi-filter-edit.png" /> </button>

                    <button class="save" (click)="save(searchByAsin)" *ngIf="isActionBtnShow"> <img title="Save"
              src="../../assets/images/green-tick.png" /> </button>
                    <button class="close" (click)="close()" *ngIf="isActionBtnShow"> <img title="Close"
              src="../../assets/images/edi-close.png" /></button>
                </div>
            </div>
            <div class="attribute-table-body">

                <ul>
                    <li>
                        <span class="attribute-name">
              ASIN
            </span>
                        <span>
              {{searchByAsin.asin}}
            </span>

                    </li>
                    <li>
                        <span class="attribute-name">
              IPQ
            </span>
                        <span *ngIf="isEditRequest">
              {{searchByAsin.ipq}}
            </span>
                        <input type="text" name="ipq" [(ngModel)]="searchByAsin.ipq" *ngIf="!isEditRequest">
                    </li>
                    <li>
                        <span class="attribute-name">
              Case Pack Size
            </span>
                        <span *ngIf="isEditRequest">
              {{searchByAsin.unitsPerContainer}}
            </span>
                        <input type="text" name="unitsPerContainer" [(ngModel)]="searchByAsin.unitsPerContainer" *ngIf="!isEditRequest">
                    </li>

                </ul>

                <ul>
                    <li>
                        <span class="attribute-name">
              Location
            </span>
                        <span *ngIf="isEditRequest">
              {{searchByAsin.location}}
            </span>
                        <input type="text" [(ngModel)]="searchByAsin.location" name="location" *ngIf="!isEditRequest">
                    </li>
                    <li>
                        <span class="attribute-name">
              Dimension
            </span>
                        <span *ngIf="isEditRequest">
              {{searchByAsin.length }} x {{searchByAsin.width}} x {{searchByAsin.height}}
            </span>
                        <div class="dimension-inpt">
                            <input type="text" name="length" [(ngModel)]="searchByAsin.length" *ngIf="!isEditRequest">
                            <input type="text" name="width" [(ngModel)]="searchByAsin.width" *ngIf="!isEditRequest">
                            <input type="text" name="height" [(ngModel)]="searchByAsin.height" *ngIf="!isEditRequest">
                        </div>

                    </li>
                    <li>
                        <span class="attribute-name">
              Weight
            </span>
                        <span *ngIf="isEditRequest">
              {{searchByAsin.weight}}
            </span>

                        <input type="text" name="isFood" [(ngModel)]="searchByAsin.weight" *ngIf="!isEditRequest">
                    </li>
                </ul>
                <ul>
                    <li>
                        <span class="attribute-name">
              Category
            </span>
                        <span *ngIf="isEditRequest">
              {{change(searchByAsin.isFood)}}
            </span>

                        <input type="text" name="isFood" [(ngModel)]="searchByAsin.isFood" *ngIf="!isEditRequest">
                    </li>
                    <li>
                        <span class="attribute-name">
              IBQ
            </span>
                        <span *ngIf="isEditRequest">
              {{ searchByAsin.ibq}}
            </span>
                        <input type="text" name="ibq" [(ngModel)]="searchByAsin.ibq" *ngIf="!isEditRequest">
                    </li>
                    <li>
                        <span class="attribute-name">
              Inventory
            </span>
                        <span>
              {{ inventory(searchByAsin.inventory, searchByAsin.ibq , searchByAsin.unitsPerContainer)}}
            </span>
                    </li>

                </ul>

            </div>

            <div class="no-data" *ngIf="productsLoaded && products.length===0">No Data Found</div>
        </div>

        <div class="search-table" *ngIf="searchByAsin">
            <div class="grey-header-bar">

                <span>
          Picked Orders
        </span>
                <span>
          <div>
            <span class="calender-wrapper">
              <span class="filter-title date-picker-title">Picking Date</span>
                <input matInput [matDatepicker]="pickingDatepicker" placeholder="Choose Picking Date" class="btn btn-filter" (click)="pickingDatepicker.open()" (dateChange)="getProductByTitle(searchInput.value)" readonly [formControl]="pickingDate" />
                <mat-datepicker-toggle matPrefix [for]="pickingDatepicker" class="calender-toggle">
                </mat-datepicker-toggle>
                <mat-datepicker #pickingDatepicker [startAt]="pickingDate"></mat-datepicker>
                </span>
                <p class="error-msg" *ngIf="submitted && pickingDate.invalid">This Field is required</p>

            </div>
            </span>


        </div>
        <ul *ngIf="products.length > 0">
            <li class="thead">
                <span>Customer Name</span>
                <span>Route</span>
                <span>Picklist No.</span>
                <span>Associate</span>
                <span>Case</span>
                <span>Bundles</span>
                <span>Loose</span>
            </li>
            <li class="tbody" *ngFor="let product of products; trackBy: trackByFn">
                <span>{{product?.customerName}}</span>
                <span>{{product?.routeID}}</span>
                <span>{{product?.picklistCode}}</span>
                <span>{{product?.associate}}</span>
                <span>{{product?.case}}</span>
                <span>{{product?.bundles}}</span>
                <span>{{product?.loose}}</span>
            </li>
        </ul>
        <div class="no-data" *ngIf="productsLoaded && products.length===0">No Data Found</div>
    </div>
    </div>
</main>


<!--
<main class="right-container">
    <div class="search-product">
        <div class="search-bar">
            <div class="input-search" [ngClass]="
      submitted &&
      searchInput.invalid
        ? 'input-field error'
        : 'input-field'
    ">

                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Search by Title or ASIN*" matInput [formControl]="searchInput" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="getProductByTitle(option)">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>


                </mat-form-field>
                <p *ngIf="currentProduct">Details of customers who ordered product <b>{{currentProduct}}</b></p>
            </div>


            <div>
                <span class="calender-wrapper">
            <span class="filter-title date-picker-title">Picking Date</span>
                <input matInput [matDatepicker]="pickingDatepicker" placeholder="Choose Picking Date" class="btn btn-filter" (click)="pickingDatepicker.open()" (dateChange)="getProductByTitle(searchInput.value)" readonly [formControl]="pickingDate" />
                <mat-datepicker-toggle matPrefix [for]="pickingDatepicker" class="calender-toggle"></mat-datepicker-toggle>
                <mat-datepicker #pickingDatepicker [startAt]="pickingDate"></mat-datepicker>
                </span>
                <p class="error-msg" *ngIf="submitted && pickingDate.invalid">This Field is required</p>

            </div>


        </div>
        <div class="search-table">
            <ul *ngFor="let product of products; trackBy: trackByFn">
                <li class="thead">
                    <span>Customer Name</span>
                    <span>Route</span>
                    <span>Picklist No.</span>
                    <span>Associate</span>
                    <span>Case</span>
                    <span>Bundles</span>
                    <span>Loose</span>
                </li>
                <li class="tbody">
                    <span>{{product?.customerName}}</span>
                    <span>{{product?.routeID}}</span>
                    <span>{{product?.picklistCode}}</span>
                    <span>{{product?.associate}}</span>
                    <span>{{product?.case}}</span>
                    <span>{{product?.bundles}}</span>
                    <span>{{product?.loose}}</span>
                </li>
            </ul>
            <div class="no-data" *ngIf="productsLoaded && products.length===0">No Data Found</div>
        </div>
    </div> -->