import { Injectable, Inject } from "@angular/core";
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { ApiService } from "./api.service";
import { apiUrl } from "@app/app.constants";
import { Store } from "@ngrx/store";
import Auth0Lock from "auth0-lock";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { ROUTES } from "@app/shared/constants/constants";
import { Logout } from "../../actions";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  returnUrl: string;

  // private _Auth0 = new auth0.WebAuth({
  //   clientID: environment.auth.clientID,
  //   domain: environment.auth.domain,
  //   responseType: "token",
  //   redirectUri: environment.auth.redirect,
  //   scope: environment.auth.scope
  // });
  private auth0 = new Auth0Lock(
    environment.auth.clientID,
    environment.auth.domain,
    {
      oidcConformant: true,
      autoclose: true,
      rememberLastLogin: false,
      allowSignUp: false,
      allowedConnections: ["Username-Password-Authentication"],
      auth: {
        autoParseHash: false,
        // redirectUrl: environment.production
        //   ? "https://" + window.location.hostname + "/callback"
        //   : "http://" + window.location.hostname + "4200/callback",
        redirectUrl: environment.auth.redirect,
        responseType: "token id_token",
        audience: environment.auth.audience,
        params: {
          scope: "openid profile email app_metadata"
        }
      },
      theme: {
        logo: "assets/images/logo2.png",
        primaryColor: "#31324F",
        displayName: "Sign in"
      },
      languageDictionary: {
        emailInputPlaceholder: "Email",
        title: "WMS",
        passwordInputPlaceholder: "Password"
      }
    }
  );

  // Track whether or not to renew token
  private _authFlag = "isLoggedIn";
  // Authentication navigation
  private _onAuthSuccessUrl;
  private _onAuthFailureUrl = "";
  private _logoutUrl = "http://localhost:4200";
  private _expiresAt: number;

  /**
   * Creates an instance of AuthService
   * @param platformId - platformId
   * @param api - HTTP service to call the APIS
   * @param store - Store
   * */
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private api: ApiService,
    private store: Store<{ auth }>,
    private router: Router
  ) {
    this._onAuthSuccessUrl =
      (window.location.hostname !== "warehouse.auperator.co" &&
        window.location.hostname !== "warehouse.eshopbox.com")
        ? ROUTES.WHOLESALE
        : ROUTES.INBOUND;
  }

  public handleAuthenticationWithHash(): void {
    this.auth0.resumeAuth(window.location.hash, (err, authResult) => {
      //alert('hi')
      console.log('hey1',authResult);
      if (authResult && authResult.accessToken && authResult.idToken) {
        // alert('nohi')
        this.setAuth(authResult);
        this.router.navigate([this.authSuccessUrl]);
      } else if (err) {
        // alert(`Error: ${err.error}. Check the console for further details.`);
        this.router.navigate([this.authFailureUrl]);
      }
    });
  }

  getAuthToken() {
    if (isPlatformBrowser(this.platformId)) {
      // alert();
      return localStorage.getItem("token");
    }
  }

  isLoggedIn() {
    if (this.getAuthToken() == null) {
      return false;
    }
    return true;
  }

  /**
   * Call the Login API and store the user in localStorage.
   * @param email - email of the user;
   * @param password - password of the user;
   * @returns user - User from the response of the API;
   */
  // login({ username, password }) {
  //   const params = { data: { username: username, password: password } };
  //   return this.api.post(apiUrl.login, params).pipe(
  //     map(user => {
  //       this.setAuthToken(JSON.stringify(user), "token");
  //       return user;
  //     })
  //   );
  // }

  get authSuccessUrl(): string {
    return this._onAuthSuccessUrl;
  }

  get authFailureUrl(): string {
    return this._onAuthFailureUrl;
  }

  get authenticated(): boolean {
    return JSON.parse(localStorage.getItem(this._authFlag));
  }

  resetAuthFlag() {
    localStorage.removeItem(this._authFlag);
  }

  login() {
    this.auth0.show();
  }

  setAuth(authResult) {
    console.log(authResult);
    this._expiresAt = authResult.expiresIn * 1000 + Date.now();
    // Set flag in local storage stating this app is logged in
    localStorage.setItem(this._authFlag, JSON.stringify(true));
    localStorage.setItem("token", authResult.accessToken);
  }

  logout() {
    // Set authentication status flag in local storage to false
    localStorage.clear();
    this.store.dispatch(new Logout());
    // This does a refresh and redirects back to homepage
    // Make sure you have the logout URL in your Auth0
    // Dashboard Application settings in Allowed Logout URLs
    this.router.navigate([ROUTES.LOGIN]);
  }
}
