import { Shipment } from "@app/_models/shipment-models";
import * as ShipmentActions from "../actions/shipment.actions";
import { ShipmentState } from "./shipment.state";

export const initialState: ShipmentState = {
  loaded: false,
  loading: false,
  ids: [],
  entities: {},
  selectedId: null,
  search_text: null,
  package_type: []
};

export function ShipmentReducer(
  state = initialState,
  action: ShipmentActions.ShipmentUnion
): ShipmentState {
  switch (action.type) {
    case ShipmentActions.ShipmentActionTypes.LOAD: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case ShipmentActions.ShipmentActionTypes.SEARCH: {
      return {
        ...state,
        loaded: false,
        loading: true,
        search_text: action.payload
      };
    }
    case ShipmentActions.ShipmentActionTypes.SEARCH_COMPLETE: {
      const order = action.payload;
      if (order != null) {
        return {
          ...state,
          loaded: true,
          loading: false,
          selectedId: order.externalShipmentID.toString(),
          entities: Object.assign({}, state.entities, {
            [order.externalShipmentID.toString()]: order
          })
        };
      } else {
        return {
          ...state,
          loaded: true,
          loading: false,
          entities: {}
        };
      }
    }
    case ShipmentActions.ShipmentActionTypes.TRACKING_ID_SEARCH: {
      return {
        ...state,
        loaded: false,
        loading: true,
        search_text: ""
      };
    }
    case ShipmentActions.ShipmentActionTypes.TRACKING_ID_SEARCH_COMPLETE: {
      const order: Shipment = action.payload["data"][0];
      if (order != null) {
        return {
          ...state,
          loaded: true,
          loading: false,
          selectedId: order.externalShipmentID.toString(),
          search_text: order.externalShipmentID.toString(),
          entities: Object.assign({}, state.entities, {
            [order.externalShipmentID.toString()]: order
          })
        };
      } else {
        return {
          ...state,
          loaded: true,
          loading: false,
          entities: {}
        };
      }
    }
    case ShipmentActions.ShipmentActionTypes.CLEAR_SEARCH: {
      return {
        ...state,
        loaded: false,
        loading: false,
        search_text: "",
        selectedId: "",
        entities: {}
      };
    }
    case ShipmentActions.ShipmentActionTypes.SEARCH_ERROR: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case ShipmentActions.ShipmentActionTypes.LOAD_PACKAGE_TYPE_SUCCESS: {
      return {
        ...state,
        package_type: Object.assign(action.payload["data"])
      };
    }

    case ShipmentActions.ShipmentActionTypes.UPDATE_SHIPMENT: {
      const order = action.payload;
      const shipmentID = order.shipmentId;
      const data = state.entities[shipmentID];
      data.boxType = order.data.boxType;
      data.dimension_height = order.data.dimension_height;
      data.dimension_length = order.data.dimension_length;
      data.dimension_width = order.data.dimension_width;
      data.weight = order.data.weight;

      return {
        ...state,
        entities: Object.assign({}, state.entities, { [shipmentID]: data })
      };
    }
    case ShipmentActions.ShipmentActionTypes.UPDATE_SHIPMENT_COMPLETE: {
      const order = action.payload;
      return {
        ...state,
        entities: Object.assign({}, state.entities, {
          [order.externalShipmentID]: order
        })
      };
    }

    case ShipmentActions.ShipmentActionTypes.CLEAR_ORDER_SEARCH: {
      return {
        ...state,
        loaded: false,
        loading: false,
        entities: {}
      };
    }

    default:
      return state;
  }
}
