<main class="right-container">
  <div class="main-container">
    <div class="example-container  completeConsignment recall-Consignment ">
      <div class="table-top">
        <div
          class="heading"
          [ngStyle]="{ width: '210px' }"
          [routerLink]="ROUTES.CONSIGNMENT_RECALL"
        >
          <a><img src="assets/images/ic-back.png"/></a>
          <h3>Recall Consignment</h3>
        </div>

        <!------------------ Tab list --------------------->
        <!-- <mat-tab-group [(selectedIndex)]="activeTabIndex" (selectedTabChange)="changeActiveTab($event)">
          <mat-tab label="All"> ALL </mat-tab>
          <mat-tab label="Created">Created </mat-tab>
          <mat-tab label="Processing"> Processing </mat-tab>
          <mat-tab label="Packed"> Packed </mat-tab>
          <mat-tab label="Dispatched"> Dispatched </mat-tab>
        </mat-tab-group> -->

        <!------------------- Pagination ------------------->
        <mat-paginator
          *ngIf="consignmentCount > 0"
          [length]="consignmentCount"
          [pageSize]="getListRequestBody.perPage"
          [pageSizeOptions]="[10, 25, 50, 100]"
          (page)="onPaginate($event)"
        >
        </mat-paginator>

        <!----------------- Add gatepass ------------------->
        <div class="add" *ngIf="!1">
          <a routerLink="/recall/add-gatepass"
            ><img src="assets/images/ic-plus.png"
          /></a>
        </div>
      </div>

      <div class="table">
        <ul class="all-table">
          <!------------------ Tab list --------------------->
          <li>
            <span
              [ngStyle]="{
                width: '100%',
                'padding-left': '0px',
                'padding-bottom': '0px',
                'background-color': 'antiquewhite',
                'padding-top': '0px'
              }"
            >
              <mat-tab-group
                [(selectedIndex)]="activeTabIndex"
                (selectedTabChange)="changeActiveTab($event.index)"
              >
                <mat-tab label="Created">Created </mat-tab>
                <mat-tab label="Processing"> Processing </mat-tab>
                <mat-tab label="Packed"> Packed </mat-tab>
                <mat-tab label="Dispatched"> Dispatched </mat-tab>
                <mat-tab label="All"> ALL </mat-tab>
              </mat-tab-group>
            </span>
          </li>

          <!--------------------- Filters ------------------>
          <li class="thead">
            <span
              >Order no.
              <img
                [src]="
                  activeFilter === 'filterOrderNo'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterOrderNo')"
              />
              <div class="filter-drop" *ngIf="activeFilter == 'filterOrderNo'">
                <div class="input-field">
                  <p>Order No.</p>
                  <input type="text" [formControl]="filterOrderNo" />
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterOrderNo')">RESET</button>
                </div>
              </div>
            </span>
            <span
              >Vendor Code
              <img
                [src]="
                  activeFilter === 'filterVendorCode'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterVendorCode')"
              />
              <div
                class="filter-drop"
                *ngIf="activeFilter == 'filterVendorCode'"
              >
                <div class="input-field">
                  <p>Vendor Code</p>
                  <input type="text" [formControl]="filterVendorCode" />
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterVendorCode')">
                    RESET
                  </button>
                </div>
              </div>
            </span>
            <span>Vendor Name</span>
            <span
              >Inventory<br />Type
              <img
                [src]="
                  activeFilter === 'filterInventoryType'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterInventoryType')"
              />
              <div
                class="filter-drop"
                *ngIf="activeFilter == 'filterInventoryType'"
              >
                <div class="input-field">
                  <p>Inventory Type</p>
                  <select multiple [formControl]="filterInventoryType">
                    <option
                      *ngFor="let type of masterInventoryTypeList"
                      value="{{ type.value }}"
                      >{{ type.label }}</option
                    >
                  </select>
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterInventoryType')">
                    RESET
                  </button>
                </div>
              </div>
            </span>
            <span>Qty</span>
            <span>Processed<br />Qty</span>
            <span>Pending<br />Qty</span>
            <span
              >Exp. Processing<br />from
              <img
                [src]="
                  activeFilter === 'filterExpProcessingFromTo'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterExpProcessingFromTo')"
              />
              <div
                class="filter-drop datepicker"
                *ngIf="activeFilter == 'filterExpProcessingFromTo'"
              >
                <div class="calendar-left">
                  <p>Exp. Processing From</p>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker4"
                      placeholder="From date"
                      readonly
                      [formControl]="filterExpProcessingFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker5"
                      placeholder="To date"
                      readonly
                      [formControl]="filterExpProcessingTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="calendar-right">
                  <ul>
                    <li *ngFor="let type of dateFilterType; let i = index">
                      <a href="javascript:void(0)" (click)="selectDates(i)">{{
                        type
                      }}</a>
                    </li>
                  </ul>
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterExpProcessingFromTo')">
                    RESET
                  </button>
                </div>
              </div>
            </span>
            <span
              >Exp. completion <br />by<img
                [src]="
                  activeFilter === 'filterExpCompletion'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterExpCompletion')"
              />
              <div
                class="filter-drop datepicker"
                *ngIf="activeFilter == 'filterExpCompletion'"
              >
                <div class="calendar-left">
                  <p>Exp. Completion by</p>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker4"
                      placeholder="From date"
                      readonly
                      [formControl]="filterExpCompletionFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker5"
                      placeholder="To date"
                      readonly
                      [formControl]="filterExpCompletionTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="calendar-right">
                  <ul>
                    <li *ngFor="let type of dateFilterType; let i = index">
                      <a href="javascript:void(0)" (click)="selectDates(i)">{{
                        type
                      }}</a>
                    </li>
                  </ul>
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterExpCompletion')">
                    RESET
                  </button>
                </div>
              </div>
            </span>

            <span
              >Processed<br />from
              <img
                [src]="
                  activeFilter === 'filterProcessedFromTo'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterProcessedFromTo')"
              />
              <div
                class="filter-drop datepicker"
                *ngIf="activeFilter == 'filterProcessedFromTo'"
              >
                <div class="calendar-left">
                  <p>Processed From</p>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker4"
                      placeholder="From date"
                      readonly
                      [formControl]="filterProcessingFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker5"
                      placeholder="To date"
                      readonly
                      [formControl]="filterProcessingTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="calendar-right">
                  <ul>
                    <li *ngFor="let type of dateFilterType; let i = index">
                      <a href="javascript:void(0)" (click)="selectDates(i)">{{
                        type
                      }}</a>
                    </li>
                  </ul>
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterProcessedFromTo')">
                    RESET
                  </button>
                </div>
              </div>
            </span>
            <span
              >Packed<br />on
              <img
                [src]="
                  activeFilter === 'filterCompletedFromTo'
                    ? 'assets/images/filter-icon-active.png'
                    : 'assets/images/filter-icon.png'
                "
                (click)="showFilter('filterCompletedFromTo')"
              />
              <div
                class="filter-drop datepicker"
                *ngIf="activeFilter == 'filterCompletedFromTo'"
              >
                <div class="calendar-left">
                  <p>Complete on</p>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker4"
                      placeholder="From date"
                      readonly
                      [formControl]="filterCompletionFrom"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker5"
                      placeholder="To date"
                      readonly
                      [formControl]="filterCompletionTo"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="calendar-right">
                  <ul>
                    <li *ngFor="let type of dateFilterType; let i = index">
                      <a href="javascript:void(0)" (click)="selectDates(i)">{{
                        type
                      }}</a>
                    </li>
                  </ul>
                </div>
                <div class="calendar-right">
                  <button (click)="applyFilters()">APPLY</button>
                  <button (click)="resetFilter('filterCompletedFromTo')">
                    RESET
                  </button>
                </div>
              </div>
            </span>
            <span>Scheduled On</span>
            <span>SlA status</span>
            <span>&nbsp;</span>
          </li>

          <li
            class="tbody not-found"
            *ngIf="consignmentList.length == 0 && !isLoading"
          >
            <img src="../../../assets/images/no-found.png" width="100" />
            <p>No consignment found</p>
          </li>
          <!-- switchConsignmentColor(consignment?.inventoryType) -->

          <!------------------------ Datatable ----------------------->
          <li class="tbody" *ngFor="let consignment of consignmentList">
            <span
              [ngStyle]="{ padding: '5px 10px' }"
              [title]="consignment?.consignmentNumber"
              (click)="
                navigateToDetailPage(
                  consignment.consignmentNumber,
                  consignment?.status
                )
              "
              ><a>{{ consignment?.consignmentNumber || "-" }}</a
              ><br />{{
                consignment?.refConsignmentNumber != null
                  ? consignment?.refConsignmentNumber
                  : ""
              }}</span
            >
            <span>{{ consignment?.partyID || "-" }}</span>
            <span [title]="consignment?.partyName">{{
              consignment?.partyName || "-"
            }}</span>
            <span
              [ngStyle]="{
                'background-color': switchConsignmentColor(
                  consignment?.inventoryType
                )
              }"
              >{{ consignment?.inventoryType || "-"
              }}{{ consignment?.type ? "/" + consignment?.type : "" }}</span
            >
            <span>{{ consignment?.qty || "0" }}</span>
            <span>{{ consignment?.processedQty || "0" }}</span>
            <span>{{ consignment?.pending || "0" }}</span>
            <span>{{
              consignment?.estimatedStartProcessingDate | date: "dd-MM-y" || "-"
            }}</span>
            <span>{{
              consignment?.estimatedCompletionDate | date: "dd-MM-y" || "-"
            }}</span>
            <span>{{
              consignment?.processedFrom | date: "dd-MM-y" || "-"
            }}</span>
            <span>{{ consignment?.packedOn | date: "dd-MM-y" || "-" }}</span>
            <span
              >{{ consignment?.scheduledTimeSlotFrom | date: "dd-MM-y" || "-"
              }}<br />
              {{
                consignment?.scheduledTimeSlotFrom | date: "hh:mm a" || "00:00"
              }}
              -
              {{
                consignment?.scheduledTimeSlotTo | date: "hh:mm a" || "00:00"
              }}
            </span>
            <span
              ><img
                title="SLA BREACHED"
                *ngIf="consignment.slaStatus"
                src="assets/images/ic-sla-status.png"
              /><b>SLA BREACHED</b></span
            >
            <!-- <span class="action-button"> -->
            <span class="action-btn">
              <div class="btn-wrp">
                <a
                  *ngIf="consignment?.status == 'CREATED'"
                  (click)="
                    markAction(
                      'START_PROCESSING',
                      consignment?.consignmentNumber,
                      consignment?.type
                    )
                  "
                  class="btn"
                  [ngClass]="
                  warehouseObj && 
                  warehouseIds.includes(warehouseObj.id) && 
                  consignment?.inventoryType.toLowerCase() =='good' ? 'disabled' : ''"
                  >START PROCESSING</a
                >
                <a
                  *ngIf="consignment?.status == 'PROCESSING'"
                  (click)="
                    markAction(
                      'MARK_PACKED',
                      consignment?.consignmentNumber,
                      consignment?.type
                    )
                  "
                  class="btn"
                  >Mark Packed</a
                >
                <a
                  *ngIf="consignment?.status == 'PACKED'"
                  (click)="
                    markAction(
                      'MARK_COMPLETED',
                      consignment?.consignmentNumber,
                      consignment?.type
                    )
                  "
                  class="btn"
                  >Mark Completed</a
                >
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</main>

<!-- <div class="filteroverlay" *ngIf="fileroverlay" (click)="overlayclick()"></div>

<div class="popup" [ngClass]="markpacked ? 'visible': ''"> -->

<!------------ Mark Packed/Complete Action -------------->
<div class="popup" [ngClass]="actionPopup ? 'visible' : ''">
  <div class="innerpopup">
    <a class="close" (click)="actionPopup = false"
      ><img src="assets/images/edi-close.png"
    /></a>
    <h2>Pack {{ actionConsignmentNumber }}</h2>
    <form [formGroup]="actionFormGroup" (ngSubmit)="actionFormSubmit()">
      <!------------ Handover proof ---------->
      <div *ngIf="actionName == 'MARK_COMPLETED'" class="fileupload">
        <mat-form-field class="inputfile" (click)="onFileStackUpload()">
          <label>Upload Proof of handover</label>
          <input matInput formControlName="handoverURL" />
          <span>{{ uploadedFilename }}</span>
          <img class="linkimg" src="assets/images/ic-link.png" />
        </mat-form-field>
      </div>
      <!---------- Number of boxes ----------->
      <mat-form-field *ngIf="actionName == 'MARK_PACKED'">
        <input
          matInput
          placeholder="Number Of boxes"
          maxlength="3"
          formControlName="containerQty"
        />
      </mat-form-field>
      <div class="btn-right">
        <button type="submit" class="btn"><span>S</span>SUBMIT</button>
      </div>
    </form>
  </div>
</div>
<!------------ Mark Packed/Complete Action End -------------->

<div
  class="popup list-modal-style"
  [ngClass]="actionPackBagsPopup ? 'visible' : ''"
>
  <div class="innerpopup">
    <a class="close" (click)="actionPackBagsPopup = false"
      ><img src="assets/images/edi-close.png"
    /></a>
    <div>
      <div class="table-top">
        <div class="heading">
          <h3>In Process Bags List</h3>
        </div>
      </div>
      <div class="table">
        <ul class="all-table">
          <li class="thead">
            <span>Bag ID </span>
            <span>Type </span>
            <span>Consignment No.</span>
            <span>Purpose</span>
            <span>Qty</span>
            <span>Status </span>
            <span></span>
          </li>
          <ng-container
            *ngIf="
              bagListToBePacked && bagListToBePacked.length > 0;
              else noProcessBags
            "
          >
            <li class="tbody" *ngFor="let bag of bagListToBePacked">
              <span> {{ bag["bagNumber"] }} </span>
              <span>{{ bag["refType"] }}</span>
              <span>{{ bag["refNumber"] }}</span>
              <span>{{ bag["purpose"] }}</span>
              <span>{{ bag["quantity"] }}</span>
              <span>{{ bag["status"] }}</span>
              <span>
                <img
                  *ngIf="
                    ['CREATED', 'PROCESSING'].indexOf(bag['status']) != '-1'
                  "
                  src="../../../assets/images/pack-icon/check-icon.png"
                  (click)="updateBagStatus(bag['bagNumber'], 'COMPLETE')"
                  title="COMPLETE"
                />&nbsp;
                <img
                  *ngIf="bag['status'] == 'CREATED'"
                  src="../../../assets/images/pack-icon/cross-icon.png"
                  (click)="updateBagStatus(bag['bagNumber'], 'DISCARDED')"
                  title="DISCARDED"
                />
              </span>
            </li>
          </ng-container>
          <ng-template #noProcessBags>
            <li class="no-process-bags">
              <img src="../../../assets/images/no-found.png" width="100" /> No
              bags in-process
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>

<!------------ Mark Dispatch Bags list Popup  -------------->
<div
  class="popup dispatch-modal-style"
  [ngClass]="actionDispatchBagsPopup ? 'visible' : ''"
>
  <div class="innerpopup">
    <a class="close" (click)="actionDispatchBagsPopup = false"
      ><img src="assets/images/edi-close.png"
    /></a>
    <div>
      <div class="table-top">
        <div class="heading">
          <h3>Dispatch Bags(Challan Number)</h3>
        </div>
        <div class="clear"></div>
      </div>
      <div class="search-filed">
        <!---------- Scan bag id --------->
        <div class="search-inpt">
          <mat-form-field class="example-full-width">
            <input
              matInput
              type="text"
              [formControl]="scannedBagBarcode"
              maxlength="50"
              placeholder="Scan Bag(Challan Number) Number"
              (keyup.enter)="
                updateBagStatus(scannedBagBarcode.value, 'DISPATCH')
              "
            />
            <!-- <app-scan-input [scannedBarcodeInput]="scannedBagBarcode.value"
              (scannedBarcodeOutput)="updateBagStatus($event, 'DISPATCH')">
            </app-scan-input> -->
            <a class="search-arrow" title="Clear Search"></a>
          </mat-form-field>
        </div>
        <!------------ Dispatched count -------->
        <div class="no-of-dispatch" *ngIf="bagListToBeDispatched.length > 0">
          {{ dispatchedBagsCount }} Out of
          {{ bagListToBeDispatched.length }} Bags(Challan Number) Scanned
        </div>
        <div class="clear"></div>
      </div>
      <!------ Data table -------->
      <div class="table">
        <ul class="all-table">
          <li class="thead">
            <span>Bag ID(Challan Number)</span>
            <span>Type </span>
            <span>Consignment No.</span>
            <span>Purpose</span>
            <span>Qty</span>
            <span>Status</span>
          </li>
          <li class="tbody" *ngFor="let bag of bagListToBeDispatched">
            <span> {{ bag["bagNumber"] }} </span>
            <span>{{ bag["refType"] }}</span>
            <span>{{ bag["refNumber"] }}</span>
            <span>{{ bag["purpose"] }}</span>
            <span>{{ bag["quantity"] }}</span>
            <span>{{ bag["status"] }} </span>
          </li>
        </ul>
      </div>

      <div class="pop-foot">
        <!----- Upload POD ---->
        <!--  -->
        <form
          [formGroup]="actionFormGroup"
          (ngSubmit)="
            (bagListToBeDispatched.length > 0 &&
              bagListToBeDispatched.length - dispatchedBagsCount == 0) ||
            bagListToBeDispatched.length == 0
              ? actionFormSubmit()
              : ''
          "
        >
          <div class="fileupload">
            <div
              class="uploadfile"
              (click)="
                (bagListToBeDispatched.length > 0 &&
                  bagListToBeDispatched.length - dispatchedBagsCount == 0) ||
                bagListToBeDispatched.length == 0
                  ? onFileStackUpload()
                  : ''
              "
            >
              <span *ngIf="!actionFormGroup?.value?.handoverURL"
                >Upload POD</span
              >
              <i
                ><img
                  alt=""
                  src="../../../../assets/images/file-upload-icon.png"
              /></i>

              <input matInput formControlName="handoverURL" readonly />
              <span>{{ uploadedFilename }}</span>
            </div>
            <button type="submit">Dispatch</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- *ngIf="bagListToBeDispatched.length > 0 && (bagListToBeDispatched.length - dispatchedBagsCount) == 0" -->
