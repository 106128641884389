import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';


// Get bag list actions
export const GET_BAG_LIST = '[BAG] Get Bag List';
export const GET_BAG_LIST_SUCCESS = '[BAG] Get Bag List Success';
export const GET_BAG_LIST_FAILURE = '[BAG] Get Bag List Failure';

export class GetBagList implements Action {
    readonly type = GET_BAG_LIST
    constructor(public payload: any) { }
}
export class GetBagListSuccess implements Action {
    readonly type = GET_BAG_LIST_SUCCESS
    constructor(public payload: any) { }
}
export class GetBagListFailure implements Action {
    readonly type = GET_BAG_LIST_FAILURE
    constructor(public payload: any) { }
}

// Get bag list for PDF actions
export const GET_BAG_LIST_FOR_PDF = '[BAG] Get Bag List For PDF';
export const GET_BAG_LIST_FOR_PDF_SUCCESS = '[BAG] Get Bag List For PDF Success';
export const GET_BAG_LIST_FOR_PDF_FAILURE = '[BAG] Get Bag List For PDF Failure';

export class GetBagListForPDF implements Action {
    readonly type = GET_BAG_LIST_FOR_PDF
    constructor(public payload: any) { }
}
export class GetBagListForPDFSuccess implements Action {
    readonly type = GET_BAG_LIST_FOR_PDF_SUCCESS
    constructor(public payload: any) { }
}
export class GetBagListForPDFFailure implements Action {
    readonly type = GET_BAG_LIST_FOR_PDF_FAILURE
    constructor(public payload: any) { }
}

// Create bag actions
export const CREATE_BAG = '[BAG] Create Bag';
export const CREATE_BAG_SUCCESS = '[BAG] Create Bag Success';
export const CREATE_BAG_FAILURE = '[BAG] Create Bag Failure';

export class CreateBag implements Action {
    readonly type = CREATE_BAG
    constructor(public payload: any) { }
}
export class CreateBagSuccess implements Action {
    readonly type = CREATE_BAG_SUCCESS
    constructor(public payload: any) { }
}
export class CreateBagFailure implements Action {
    readonly type = CREATE_BAG_FAILURE
    constructor(public payload: any) { }
}

// Update bag actions
export const UPDATE_BAG = '[BAG] Update Bag';
export const UPDATE_BAG_SUCCESS = '[BAG] Update Bag Success';
export const UPDATE_BAG_FAILURE = '[BAG] Update Bag Failure';

export class UpdateBag implements Action {
    readonly type = UPDATE_BAG
    constructor(public payload: any) { }
}
export class UpdateBagSuccess implements Action {
    readonly type = UPDATE_BAG_SUCCESS
    constructor(public payload: any) { }
}
export class UpdateBagFailure implements Action {
    readonly type = UPDATE_BAG_FAILURE
    constructor(public payload: any) { }
}

// Get bag item details actions
export const GET_BAG_ITEM_DETAILS = '[BAG] Get Bag Item Details';
export const GET_BAG_ITEM_DETAILS_SUCCESS = '[BAG] Get Bag Item Details Success';
export const GET_BAG_ITEM_DETAILS_FAILURE = '[BAG] Get Bag Item Details Failure';

export class GetBagItemDetails implements Action {
    readonly type = GET_BAG_ITEM_DETAILS
    constructor(public payload: any) { }
}
export class GetBagItemDetailsSuccess implements Action {
    readonly type = GET_BAG_ITEM_DETAILS_SUCCESS
    constructor(public payload: any) { }
}
export class GetBagItemDetailsFailure implements Action {
    readonly type = GET_BAG_ITEM_DETAILS_FAILURE
    constructor(public payload: any) { }
}

// Get single item details actions
export const GET_SINGLE_ITEM_DETAILS = '[BAG] Get Single Item Details';
export const GET_SINGLE_ITEM_DETAILS_SUCCESS = '[BAG] Get Single Item Details Success';
export const GET_SINGLE_ITEM_DETAILS_FAILURE = '[BAG] Get Single Item Details Failure';

export class GetSingleItemDetails implements Action {
    readonly type = GET_SINGLE_ITEM_DETAILS
    constructor(public payload: any) { }
}
export class GetSingleItemDetailsSuccess implements Action {
    readonly type = GET_SINGLE_ITEM_DETAILS_SUCCESS
    constructor(public payload: any) { }
}
export class GetSingleItemDetailsFailure implements Action {
    readonly type = GET_SINGLE_ITEM_DETAILS_FAILURE
    constructor(public payload: any) { }
}

// Add item in bag actions
export const ADD_ITEM = '[BAG] Add Item';
export const ADD_ITEM_SUCCESS = '[BAG] Add Item Success';
export const ADD_ITEM_FAILURE = '[BAG] Update Item Failure';

export class AddItem implements Action {
    readonly type = ADD_ITEM
    constructor(public payload: any) { }
}
export class AddItemSuccess implements Action {
    readonly type = ADD_ITEM_SUCCESS
    constructor(public payload: any) { }
}
export class AddItemFailure implements Action {
    readonly type = ADD_ITEM_FAILURE
    constructor(public payload: any) { }
}

// Export action types
export type Actions =
    GetBagList
    | GetBagListSuccess
    | GetBagListFailure
    | GetBagListForPDF
    | GetBagListForPDFFailure
    | GetBagListForPDFSuccess
    | CreateBag
    | CreateBagSuccess
    | CreateBagFailure
    | UpdateBag
    | UpdateBagSuccess
    | UpdateBagFailure
    | GetBagItemDetails
    | GetBagItemDetailsSuccess
    | GetBagItemDetailsFailure
    | GetSingleItemDetails
    | GetSingleItemDetailsSuccess
    | GetSingleItemDetailsFailure
    | AddItem
    | AddItemSuccess
    | AddItemFailure;