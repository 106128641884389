import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CONSTANTS } from "@app/shared/constants/constants";
import {
  GetRouteSummary,
  GetAssociates,
  WholesaleActionTypes,
  GetSimulatedData,
  GetPicklistData,
  AddAssociate,
  DeleteAssociate,
  GetTitlesData
} from "../actions/wholesale.actions";
import {
  WholesaleService,
  SharedService,
  AlertService
} from "@shared/services";

@Injectable()
export class WholesaleEffects {
  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for route Summary API
   * @return Observable Success or failure Action
   */
  @Effect()
  getRouteSummary$: Observable<Action> = this.actions$.pipe(
    ofType<GetRouteSummary>(WholesaleActionTypes.GET_ROUTE_SUMMARY),
    mergeMap(action => {
      return this.wholesaleService.getRouteSummary(action.payload).pipe(
        map(data => {
          return {
            type: WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS,
            payload: data["listOfRouteSummary"]
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.GET_ROUTE_SUMMARY_FAILED });
        })
      );
    })
  );

   /**
   * @author Nidhi Raturi
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for warehouse Listing API
   * @return Observable Success or failure Action
   */
  @Effect()
  getWarehouseListing$: Observable<Action> = this.actions$.pipe(
    ofType<GetRouteSummary>(WholesaleActionTypes.GET_WAREHOUSE_LIST),
    mergeMap(action => {
      return this.wholesaleService.getWarehouseList().pipe(
        map(data => {

          return {
            type: WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS,
            payload: data['data']
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.GET_WAREHOUSE_LIST_FAILED });
        })
      );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Get Delivery Associate API
   * @return Observable Success or failure Action
   */
  @Effect()
  getDeliveryAssociates$: Observable<Action> = this.actions$.pipe(
    ofType<GetAssociates>(WholesaleActionTypes.GET_ASSOCIATES),
    mergeMap(action => {
      return this.wholesaleService.getDeliveryAssociates().pipe(
        map(data => {
          return {
            type: WholesaleActionTypes.GET_ASSOCIATES_SUCCESS,
            payload: data
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.GET_ASSOCIATES_FAILED });
        })
      );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Add Delivery Associate API
   * @return Observable Success or failure Action
   */
  @Effect()
  addDeliveryAssociate$: Observable<Action> = this.actions$.pipe(
    ofType<AddAssociate>(WholesaleActionTypes.ADD_ASSOCIATE),
    mergeMap(action => {
      return this.wholesaleService
        .addDeliveryAssociate(action.payload["params"])
        .pipe(
          map(data => {
            if (data["id"]) {
              this.alertService.showSuccess(
                CONSTANTS.ALERT_MESSAGES.ADDPICKLIST_ASSOCIATE_SUCCESS
              );
              action.payload["ref"].close();
              return {
                type: WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS,
                payload: data
              };
            } else {
              this.alertService.showError(
                CONSTANTS.ALERT_MESSAGES.ADDPICKLIST_ASSOCIATE_FAILURE
              );
              return { type: WholesaleActionTypes.ADD_ASSOCIATE_FAILED };
            }
          }),
          catchError(() => {
            return of({ type: WholesaleActionTypes.ADD_ASSOCIATE_FAILED });
          })
        );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Delete Delivery Associate API
   * @return Observable Success or failure Action
   */
  @Effect()
  deleteDeliveryAssociate$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteAssociate>(WholesaleActionTypes.DELETE_ASSOCIATE),
    mergeMap(action => {
      return this.wholesaleService
        .deleteDeliveryAssociate(action.payload["id"])
        .pipe(
          map(data => {
            this.alertService.showSuccess(
              CONSTANTS.ALERT_MESSAGES.DELETEPICKLIST_ASSOCIATE_SUCCESS
            );
            action.payload["ref"].close();
            return {
              type: WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS,
              payload: action.payload
            };
          }),
          catchError(() => {
            return of({ type: WholesaleActionTypes.DELETE_ASSOCIATE_FAILED });
          })
        );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Get Simulated Summary API
   * @return Observable Success or failure Action
   */
  @Effect()
  getSimulatedSummary$: Observable<Action> = this.actions$.pipe(
    ofType<GetSimulatedData>(WholesaleActionTypes.GET_SIMULATED_SUMMARY),
    mergeMap(action => {
      return this.wholesaleService.getSimulatedSummary(action.payload).pipe(
        map(data => {
          return {
            type: WholesaleActionTypes.GET_SIMULATED_SUCCESS,
            payload: data["routeSummaryList"]
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.GET_SIMULATED_FAILED });
        })
      );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Get Summary After Picking API
   * @return Observable Success or failure Action
   */
  @Effect()
  getPicklistSummary$: Observable<Action> = this.actions$.pipe(
    ofType<GetPicklistData>(WholesaleActionTypes.GET_PICKLIST_SUMMARY),
    mergeMap(action => {
      return this.wholesaleService.getPicklistSummary(action.payload).pipe(
        map(data => {
          return {
            type: WholesaleActionTypes.GET_PICKLIST_SUCCESS,
            payload: data["routeSummaryList"]
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.GET_PICKLIST_FAILED });
        })
      );
    })
  );

  /**
   * @author Saher Shaukat
   * @param Observable dispatched action
   * @description dispatch sucess and failure actions for Get Ttiles API
   * @return Observable Success or failure Action
   */
  @Effect()
  getTitles$: Observable<Action> = this.actions$.pipe(
    ofType<GetTitlesData>(WholesaleActionTypes.FETCH_TITLES),
    mergeMap(action => {
      return this.wholesaleService.getTitles().pipe(
        map(data => {
          return {
            type: WholesaleActionTypes.FETCH_TITLES_SUCCESS,
            payload: data
          };
        }),
        catchError(() => {
          return of({ type: WholesaleActionTypes.FETCH_TITLES_FAILURE });
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private wholesaleService: WholesaleService,
    private sharedService: SharedService,
    private alertService: AlertService
  ) {}
}
