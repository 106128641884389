"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GetWarehouseName = /** @class */ (function () {
    function GetWarehouseName() {
    }
    GetWarehouseName.prototype.transform = function (id, warehouseList) {
        if (warehouseList.length) {
            var warehouse = warehouseList.filter(function (data) {
                return data.id == id;
            });
            return warehouse[0].externalWarehouseID;
        }
    };
    return GetWarehouseName;
}());
exports.GetWarehouseName = GetWarehouseName;
