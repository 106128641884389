"use strict";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
Object.defineProperty(exports, "__esModule", { value: true });
// export const environment = {
//   production: false,
//   API_URL:
//     "https://eshopbox-wholesale-service-dot-eshopbox-2018.appspot.com/api/",
//   INBOUND_URL:
//     "https://backend-dot-eshopbox-portal-dev.appspot.com/_ah/api/esb/v1/wh/",
//   DASHBOARD_API_URL: "https://amazon.eshopbox.com/api/",
//   CDN_URL: "https://cdn.eshopbox.com/",
//   auth: {
//     cookie_domain: "localhost",
//     audience: "https://wms.myeshopbox.com",
//     clientID: "IINJ6kn3EBFKd2eTFzMoYgKfhl65402L",
//     domain: "eshopbox.auth0.com",
//     redirect: "http://localhost:4200/callback",
//     scope: "openid profile email"
//   },
//   FILESTACK_APIKEY: "AQ2ZyOPLSRSmn5ETQx29dz"
// };
exports.environment = {
    production: false,
    API_URL: "https://warehouse.auperator.co/api/",
    RECALL_URL: "https://warehouse.auperator.co/api/",
    DASHBOARD_API_URL: "https://wms.eshopbox.com/api/",
    PLATFORM_API_URL: "https://warehouse.auperator.co/platform/api/v1/wh",
    PRODUCT_API_URL: " https://amzb2b.eshopbox.com/api/",
    INBOUND_URL: "https://warehouse.auperator.co/api/v1/wh/",
    ASIN_POST_URL: "https://eshopbox-wholesale-service-dot-eshopbox-2018.appspot.com/api/",
    CDN_URL: "https://cdn.eshopbox.com/",
    auth: {
        cookie_domain: "localhost",
        clientID: "TSH2TXx7Wvfx6hppIFfzl5cb1MGqv9Vp",
        domain: "login.auperator.co",
        redirect: "https://" + window.location.hostname + "/callback",
        scope: "openid profile email",
        audience: "https://eshopbox-portal-dev.appspot.com"
    },
    FILESTACK_APIKEY: "AQ2ZyOPLSRSmn5ETQx29dz"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
