<header>
  <div class="header-logo">
    <a>
      <img src="assets/images/logo2.png" alt="" />
    </a>
  </div>
  <div class="header-container d-flex align-items-center">
    <div class="header-location-dropdown">
      <mat-form-field>
        <input
          type="text"
          placeholder="Select warehouse"
          aria-label="Number"
          [(ngModel)]="selectedWarehouse"
          (input)="getWareHouseId()"
          (keydown.enter)="getAllFilteredList();getWareHouseId();"
          matInput
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="setValueOnStore()"
        >
          <mat-option
            *ngFor="let warehouse of filteredValue; trackBy: trackByFn"
            [value]="warehouse['externalWarehouseID']"
          >
            {{ warehouse["externalWarehouseID"] }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <!-- <div class="header-location-dropdown">
      <ul class="ul-dropdown">
        <li>
          <span class="ul-dropdown-toggle">AMZ_Banglore</span>
          <ul>
            <li>
              <a href="javascript:void(0)">AMZ_Banglore</a>
            </li>
          </ul>
        </li>
      </ul>
    </div> -->
    <div class="header-search d-flex">
      <mat-form-field>
        <mat-select
          [value]="selectedFilter"
          (selectionChange)="onFilterChange($event.value)"
        >
          <mat-option
            *ngFor="let searchId of searchfield; trackBy: trackByFn"
            [value]="searchId.value"
          >
            {{ searchId?.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <ul class="ul-dropdown">
        <li>
          <span
            *ngIf="router.url != ROUTES.MARK_CONSIGNMENT_COMPLETE"
            class="ul-dropdown-toggle"
            >Picklist ID</span
          >
          <span
            *ngIf="router.url == ROUTES.MARK_CONSIGNMENT_COMPLETE"
            class="ul-dropdown-toggle"
            >Consignment No.</span
          >

          <ul>
            <li>
              <a href="javascript:void(0)">Picklist ID</a>
            </li>
            <li>
              <a href="javascript:void(0)">Consignment No.</a>
            </li>
          </ul>
        </li>
      </ul> -->
      <div class="header-search-form">
        <input
          type="text"
          class="header-search-bar"
          (keydown.enter)="addSearchText($event)"
          placeholder="Search"
          [(ngModel)]="searchText"
        />
        <button class="header-search-btn"></button>
        <a class="close-search" (click)="clearFilter()" *ngIf="searchText">X</a>
      </div>
    </div>
    <div class="header-language">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="auth.logout()">Logout</button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <img src="assets/images/ic-user.png" alt="" width="15" />
      </button>
      <!-- <ul class="ul-dropdown">
        <li>
          <span class="ul-dropdown-toggle">
            <img src="assets/images/lang1.png" alt="" />
          </span>
          <ul>
            <li>
              <a href="javascript:void(0)" (click)="auth.logout()">Logout</a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/lang1.png" alt="" />
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/lang1.png" alt="" />
              </a>
            </li>
          </ul>
        </li>
      </ul> -->
    </div>
  </div>
</header>
<!-- <div class="page-loader" *ngIf="showLoader">
  <div class="loader-icon"></div>
</div> -->


<div class="matloader" *ngIf="showLoader">
  <mat-spinner [diameter]="25" [strokeWidth]="2.2"></mat-spinner>
  <span>Loading...</span>
</div>
