"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("@app/shared/constants/constants");
var services_1 = require("../../services");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var selector_1 = require("../../../../app/consignment-receive/reducers/selector");
var consignment_receive_service_1 = require("../../../../app/consignment-receive/services/consignment-receive.service");
var ReceiveHeaderComponent = /** @class */ (function () {
    function ReceiveHeaderComponent(sharedService, inboundService, receiveService, router, store) {
        this.sharedService = sharedService;
        this.inboundService = inboundService;
        this.receiveService = receiveService;
        this.router = router;
        this.store = store;
        this.ROUTES = constants_1.ROUTES;
        this.showLoader = false;
        this.cancelpopup = false;
        this.RESUME_URL = "/consignment-receive/resume-receiving";
    }
    ReceiveHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.router.url);
        // if (this.router.url != this.RESUME_URL) {
        console.log('cffffffffff');
        this.getStep$ = this.inboundService.currentMessage.subscribe(function (message) {
            return _this.stepNo = message;
        });
        //  }
        console.log(this.stepNo);
        this.sharedService.loaderListner.subscribe(function (data) {
            _this.showLoader = data.status;
        });
        this.getConsignmentNo();
    };
    ReceiveHeaderComponent.prototype.getConsignmentNo = function () {
    };
    ReceiveHeaderComponent.prototype.showcancel = function () {
        if (this.router.url != this.RESUME_URL) {
            this.cancelpopup = true;
        }
        else {
            this.router.navigate([constants_1.ROUTES.INBOUND]);
        }
    };
    ReceiveHeaderComponent.prototype.close = function () {
        this.cancelpopup = false;
    };
    ReceiveHeaderComponent.prototype.hopToHome = function () {
        this.router.navigate([constants_1.ROUTES.INBOUND]);
    };
    ReceiveHeaderComponent.prototype.cancelConsignment = function () {
        var _this = this;
        this.getConsignmentNo$ = this.store
            .pipe(store_1.select(selector_1.getConsigmentNoStatus))
            .subscribe(function (res) {
            _this.consignmentNo = res;
            console.log(_this.consignmentNo);
            if (_this.consignmentNo) {
                var obj = { consignmentNumber: _this.consignmentNo };
                _this.receiveService.cancelConsignment(obj).subscribe(function (res) {
                    console.log(res);
                    _this.hopToHome();
                }, function (error) {
                    console.log(error);
                });
            }
            else {
                _this.hopToHome();
            }
        });
    };
    ReceiveHeaderComponent.prototype.ngOnDestroy = function () {
        if (this.getStep$) {
            this.getStep$.unsubscribe();
        }
    };
    return ReceiveHeaderComponent;
}());
exports.ReceiveHeaderComponent = ReceiveHeaderComponent;
