import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { getGRNItemListSelector, getGRNItemListErrorSelector, getGRNItemListLoadingSelector, getGRNItemListLoadedSelector } from '../../reducers/selector';
import { GetGRNItemsAction } from '../../actions/consignment-recall.action';
import { AlertService } from '../../../shared/services/alert.service';
import { ROUTES } from '../../../shared/constants/constants';
import { PrintService } from '../../../shared/services/print.service';
import { WholesaleService } from '../../../shared/services/wholesale.service';

@Component({
  selector: 'app-recall-details',
  templateUrl: './recall-details.component.html',
  styleUrls: ['./recall-details.component.scss']
})
export class RecallDetailsComponent implements OnInit {
  accountId: number; //For Print Picklist Data In Recall Consignment For Amazon Portal
  getGRNItemsRequestBody = { page: 1, perPage: 25 }
  grnItemList: any = []
  grnItemsCount: number = 0;
  isLoading: boolean = false;
  consignmentNumber = '';
  routes = ROUTES;
  recallConsignmentStatus: any;
  challanPDFPath: any;

  constructor(
    private store: Store<any>,
    public activatedRoute: ActivatedRoute,
    public alertServices: AlertService,
    private printService: PrintService,
    private wholesaleService: WholesaleService
  ) {
    this.requestGRNItems();
  }

  ngOnInit() {
    this.accountId = this.activatedRoute.snapshot.params['accountId']
    this.wholesaleService.getRecallChallanDetails(this.consignmentNumber).subscribe(
      (response) => {
        if (response) {
          if (response['data']) {
            this.recallConsignmentStatus = response['data'][0]['status']
            this.challanPDFPath = response['data'][0]['invoiceUrl']
          }
        }
      }
    )
  }

  /**
   * Pagination
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  onPaginate(pageEvent) {
    this.getGRNItemsRequestBody.page = (pageEvent.pageIndex + 1);
    this.getGRNItemsRequestBody.perPage = pageEvent.pageSize;
    this.getGRNItemsHandling();
  }

  /**
   * Get GRN items handling
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getGRNItemsHandling() {
    this.store.dispatch(new GetGRNItemsAction(this.getGRNItemsRequestBody));
    this.store.pipe(select(getGRNItemListSelector)).subscribe(data => {
      if (data) {
        if (data['data']) {
          this.isLoading = false;
          this.grnItemList = data['data'];
          this.grnItemsCount = data['total'];
        }
      }
    });
    this.store.pipe(select(getGRNItemListErrorSelector)).subscribe(error => {
    });
    this.store.pipe(select(getGRNItemListLoadingSelector)).subscribe(isLoading => {
      if (isLoading) {
        this.isLoading = true;
      }
    });
    this.store.pipe(select(getGRNItemListLoadedSelector)).subscribe(isLoaded => {
      if (isLoaded) {
        this.isLoading = false;
      }
    });
  }

  /**
   * Request GRN Item list for requested consignment number
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  requestGRNItems() {
    this.activatedRoute.params.subscribe(paramList => {
      // If consignment number exists
      if (paramList['id']) {
        this.getGRNItemsRequestBody['consignmentNumber'] = paramList['id'];   // encoded consignment number
        this.consignmentNumber = decodeURIComponent(paramList['id']);
        this.getGRNItemsHandling();
      } else {
        // If not exists
        this.alertServices.showError('Unable to fetch gatepass items. Invalid consignment number');
      }
    });
  }

  onPrintRecallList(consignmentNumber) {
    const consignmentNumbersDetails = [consignmentNumber]
    this.printService.printDocument("recall-sheet", consignmentNumbersDetails)
  }

  openChallan() {
    window.open(this.challanPDFPath)
  }
}
