<div class="dialog-box">
  <button class="close" mat-dialog-close>
    <img src="assets/images/x.png" alt="" />
    <small>ESC</small>
  </button>
  <div class="drag-area">
    <div class="db-header">
      <div class="left">
        <img src="assets/images/export.png" alt="" />
        <span>Add File</span>
      </div>
      <div class="right">
        <span>Upload POH Copy </span>
      </div>
    </div>
    <div class="db-body">
      <span>or drag and drop file here</span>
      <span class="path-name"
        >scance.jpge<a class="remove">
          <img src="assets/images/x.png" alt=""/></a
      ></span>
    </div>
  </div>
  <div class="text-center">
    <button class="btn btn-transparent">Save</button>
  </div>
</div>
