"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WholesaleActionTypes;
(function (WholesaleActionTypes) {
    WholesaleActionTypes["GET_ROUTE_SUMMARY"] = "[Wholesale Page] Get Current Route Summary";
    WholesaleActionTypes["GET_ROUTE_SUMMARY_SUCCESS"] = "[Route Summary API] Get Route Summary Success";
    WholesaleActionTypes["GET_ROUTE_SUMMARY_FAILED"] = "[Route Summary API] Get Route Summary Failed";
    WholesaleActionTypes["GET_WAREHOUSE_LIST"] = "[Wholesale Page] Get warehouse List";
    WholesaleActionTypes["GET_WAREHOUSE_LIST_SUCCESS"] = "[Wholesale Page] Get warehouse List Success";
    WholesaleActionTypes["GET_WAREHOUSE_LIST_FAILED"] = "[Wholesale Page] Get warehouse List Failed";
    WholesaleActionTypes["GET_ASSOCIATES"] = "[Wholesale Page] Get Delivery Associates";
    WholesaleActionTypes["GET_ASSOCIATES_SUCCESS"] = "[Delivery Associates API] Get Delivery Associates Success";
    WholesaleActionTypes["GET_ASSOCIATES_FAILED"] = "[Delivery Associates API] Get Delivery Associates Failed";
    WholesaleActionTypes["ADD_ASSOCIATE"] = "[Add Delivery Associate Page] Call Add Delivery Associate API";
    WholesaleActionTypes["ADD_ASSOCIATE_SUCCESS"] = "[Add Delivery Associate API] Add Delivery Associate Success";
    WholesaleActionTypes["ADD_ASSOCIATE_FAILED"] = "[Add Delivery Associate API] Add Delivery Associate Failure";
    WholesaleActionTypes["DELETE_ASSOCIATE"] = "[Delete Delivery Associate Page] Call Delete Delivery Associate API";
    WholesaleActionTypes["DELETE_ASSOCIATE_SUCCESS"] = "[Delete Delivery Associate API] Delete Delivery Associate Success";
    WholesaleActionTypes["DELETE_ASSOCIATE_FAILED"] = "[Delete Delivery Associate API] Delete Delivery Associate Failure";
    WholesaleActionTypes["GET_SIMULATED_SUMMARY"] = "[Wholesale Page] Get Simulated Data";
    WholesaleActionTypes["GET_SIMULATED_SUCCESS"] = "[Simulated API] Get Simulated Data Success";
    WholesaleActionTypes["GET_SIMULATED_FAILED"] = "[Simulated  API] Get Simulated Data Failed";
    WholesaleActionTypes["GET_PICKLIST_SUMMARY"] = "[Wholesale Page] Get Picked Data";
    WholesaleActionTypes["GET_PICKLIST_SUCCESS"] = "[Picklist API] Get Picked Data Success";
    WholesaleActionTypes["GET_PICKLIST_FAILED"] = "[Picklist API] Get Picked Data Failed";
    WholesaleActionTypes["SET_ACCOUNT_ID"] = "[Header Warehouse Selection] Set Account Id";
    WholesaleActionTypes["SET_CONSOLIDATED_PICKLIST"] = "[Consolidated Picklist API] Set Consolidated Picklist";
    WholesaleActionTypes["FETCH_TITLES"] = "[Search Product Page] Get Titles for auto suggest";
    WholesaleActionTypes["FETCH_TITLES_SUCCESS"] = "[Search Product Page] Get Titles Success";
    WholesaleActionTypes["FETCH_TITLES_FAILURE"] = "[Search Product Page] Get Titles Failure";
})(WholesaleActionTypes = exports.WholesaleActionTypes || (exports.WholesaleActionTypes = {}));
var SetAccountId = /** @class */ (function () {
    function SetAccountId(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.SET_ACCOUNT_ID;
    }
    return SetAccountId;
}());
exports.SetAccountId = SetAccountId;
var SetConsolidatedPicklist = /** @class */ (function () {
    function SetConsolidatedPicklist(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.SET_CONSOLIDATED_PICKLIST;
    }
    return SetConsolidatedPicklist;
}());
exports.SetConsolidatedPicklist = SetConsolidatedPicklist;
var GetRouteSummary = /** @class */ (function () {
    function GetRouteSummary(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_ROUTE_SUMMARY;
    }
    return GetRouteSummary;
}());
exports.GetRouteSummary = GetRouteSummary;
var GetRouteSummarySuccess = /** @class */ (function () {
    function GetRouteSummarySuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS;
    }
    return GetRouteSummarySuccess;
}());
exports.GetRouteSummarySuccess = GetRouteSummarySuccess;
var GetRouteSummaryFailed = /** @class */ (function () {
    function GetRouteSummaryFailed() {
        this.type = WholesaleActionTypes.GET_ROUTE_SUMMARY_FAILED;
    }
    return GetRouteSummaryFailed;
}());
exports.GetRouteSummaryFailed = GetRouteSummaryFailed;
var GetWarehouseList = /** @class */ (function () {
    function GetWarehouseList(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_WAREHOUSE_LIST;
    }
    return GetWarehouseList;
}());
exports.GetWarehouseList = GetWarehouseList;
var GetWarehouseListSuccess = /** @class */ (function () {
    function GetWarehouseListSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS;
    }
    return GetWarehouseListSuccess;
}());
exports.GetWarehouseListSuccess = GetWarehouseListSuccess;
var GetWarehouseListFailed = /** @class */ (function () {
    function GetWarehouseListFailed() {
        this.type = WholesaleActionTypes.GET_WAREHOUSE_LIST_FAILED;
    }
    return GetWarehouseListFailed;
}());
exports.GetWarehouseListFailed = GetWarehouseListFailed;
var GetAssociates = /** @class */ (function () {
    function GetAssociates() {
        this.type = WholesaleActionTypes.GET_ASSOCIATES;
    }
    return GetAssociates;
}());
exports.GetAssociates = GetAssociates;
var GetAssociatesSuccess = /** @class */ (function () {
    function GetAssociatesSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_ASSOCIATES_SUCCESS;
    }
    return GetAssociatesSuccess;
}());
exports.GetAssociatesSuccess = GetAssociatesSuccess;
var AddAssociate = /** @class */ (function () {
    function AddAssociate(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.ADD_ASSOCIATE;
    }
    return AddAssociate;
}());
exports.AddAssociate = AddAssociate;
var AddAssociateSuccess = /** @class */ (function () {
    function AddAssociateSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS;
    }
    return AddAssociateSuccess;
}());
exports.AddAssociateSuccess = AddAssociateSuccess;
var AddAssociateFailed = /** @class */ (function () {
    function AddAssociateFailed() {
        this.type = WholesaleActionTypes.ADD_ASSOCIATE_FAILED;
    }
    return AddAssociateFailed;
}());
exports.AddAssociateFailed = AddAssociateFailed;
var DeleteAssociate = /** @class */ (function () {
    function DeleteAssociate(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.DELETE_ASSOCIATE;
    }
    return DeleteAssociate;
}());
exports.DeleteAssociate = DeleteAssociate;
var DeleteAssociateSuccess = /** @class */ (function () {
    function DeleteAssociateSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS;
    }
    return DeleteAssociateSuccess;
}());
exports.DeleteAssociateSuccess = DeleteAssociateSuccess;
var DeleteAssociateFailed = /** @class */ (function () {
    function DeleteAssociateFailed() {
        this.type = WholesaleActionTypes.DELETE_ASSOCIATE_FAILED;
    }
    return DeleteAssociateFailed;
}());
exports.DeleteAssociateFailed = DeleteAssociateFailed;
var GetAssociatesFailed = /** @class */ (function () {
    function GetAssociatesFailed() {
        this.type = WholesaleActionTypes.GET_ASSOCIATES_FAILED;
    }
    return GetAssociatesFailed;
}());
exports.GetAssociatesFailed = GetAssociatesFailed;
var GetSimulatedData = /** @class */ (function () {
    function GetSimulatedData(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_SIMULATED_SUMMARY;
    }
    return GetSimulatedData;
}());
exports.GetSimulatedData = GetSimulatedData;
var GetSimulatedSuccess = /** @class */ (function () {
    function GetSimulatedSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_SIMULATED_SUCCESS;
    }
    return GetSimulatedSuccess;
}());
exports.GetSimulatedSuccess = GetSimulatedSuccess;
var GetSimulatedFailed = /** @class */ (function () {
    function GetSimulatedFailed() {
        this.type = WholesaleActionTypes.GET_SIMULATED_FAILED;
    }
    return GetSimulatedFailed;
}());
exports.GetSimulatedFailed = GetSimulatedFailed;
var GetPicklistData = /** @class */ (function () {
    function GetPicklistData(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_PICKLIST_SUMMARY;
    }
    return GetPicklistData;
}());
exports.GetPicklistData = GetPicklistData;
var GetPicklistSuccess = /** @class */ (function () {
    function GetPicklistSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.GET_PICKLIST_SUCCESS;
    }
    return GetPicklistSuccess;
}());
exports.GetPicklistSuccess = GetPicklistSuccess;
var GetPicklistFailed = /** @class */ (function () {
    function GetPicklistFailed() {
        this.type = WholesaleActionTypes.GET_PICKLIST_FAILED;
    }
    return GetPicklistFailed;
}());
exports.GetPicklistFailed = GetPicklistFailed;
var GetTitlesData = /** @class */ (function () {
    function GetTitlesData(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.FETCH_TITLES;
    }
    return GetTitlesData;
}());
exports.GetTitlesData = GetTitlesData;
var GetTitlesSuccess = /** @class */ (function () {
    function GetTitlesSuccess(payload) {
        this.payload = payload;
        this.type = WholesaleActionTypes.FETCH_TITLES_SUCCESS;
    }
    return GetTitlesSuccess;
}());
exports.GetTitlesSuccess = GetTitlesSuccess;
var GetTitlesFailure = /** @class */ (function () {
    function GetTitlesFailure() {
        this.type = WholesaleActionTypes.FETCH_TITLES_FAILURE;
    }
    return GetTitlesFailure;
}());
exports.GetTitlesFailure = GetTitlesFailure;
