"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service");
// import { environment } from "@env/environment";
var config_1 = require("../configuration/config");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var InboundService = /** @class */ (function () {
    function InboundService(api, config) {
        this.api = api;
        this.config = config;
        this.messageSource = new rxjs_1.BehaviorSubject('step1');
        this.currentMessage = this.messageSource.asObservable();
    }
    /**
      * @author Nidhi Raturi
      * @params Object - params
      * @description Api call to  get Complete Consignment list
      * @return Observable<any> -
  
      */
    InboundService.prototype.getCompleteConsignmentList = function (pageObj) {
        return this.api
            .get(this.config.UrlObj.completeConsignmentListApi, { params: pageObj })
            .pipe(operators_1.map(function (response) {
            return response;
        }));
    };
    InboundService.prototype.changeStep = function (message) {
        this.messageSource.next(message);
    };
    /**
    * @author Nidhi Raturi
    * @params Object - params
    * @description Api call to mark Consignment Receive
    * @return Observable<any> -
  
    */
    InboundService.prototype.markConsignmentComplete = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.config.UrlObj.marConsignmentCompleteApi, params);
    };
    /**
   * @author Nidhi Raturi
   * @params Object - params
   * @description Api call to get SKU Details
   * @return Observable<any> -
  
   */
    InboundService.prototype.getConsignmentDetail = function (consignmentNo) {
        var consignmentNumber = (encodeURIComponent(encodeURIComponent(consignmentNo)));
        return this.api.get("" + this.config.UrlObj.getDetailByConsignmentNo + consignmentNumber + "/items?page=1&perPage=1000")
            .pipe(operators_1.map(function (response) {
            return response;
        }));
    };
    InboundService.prototype.editConsignmentDetail = function (params, consignmentNo) {
        var consignmentNumber = consignmentNo;
        return this.api.put(this.config.UrlObj.editConsignmentApi + "items-adjustment?consignmentNumber=" + consignmentNumber, params);
    };
    InboundService.prototype.editMultipleConsignmentDetail = function (params) {
        return this.api.put(this.config.UrlObj.editConsignmentApi + "multiple-items-adjustment", params);
    };
    InboundService.ngInjectableDef = i0.defineInjectable({ factory: function InboundService_Factory() { return new InboundService(i0.inject(i1.ApiService), i0.inject(i2.Configuration)); }, token: InboundService, providedIn: "root" });
    return InboundService;
}());
exports.InboundService = InboundService;
