"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Action types
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
var ActionTypes;
(function (ActionTypes) {
    // Get List
    ActionTypes["ACTION_GET_CONSIGNMENT_LIST"] = "[Consignment Recall] Get Consignment Recall List";
    ActionTypes["ACTION_GET_CONSIGNMENT_LIST_SUCCESS"] = "[Consignment Recall] Get Consignment Recall List Success";
    ActionTypes["ACTION_GET_CONSIGNMENT_LIST_FAILURE"] = "[Consignment Recall] Get Consignment Recall List Failure";
    // Update Consignment
    ActionTypes["ACTION_UPDATE_CONSIGNMENT"] = "[Consignment Recall] Update Consignment Recall";
    ActionTypes["ACTION_UPDATE_CONSIGNMENT_SUCCESS"] = "[Consignment Recall] Update Consignment Recall Success";
    ActionTypes["ACTION_UPDATE_CONSIGNMENT_FAILURE"] = "[Consignment Recall] Update Consignment Recall Failure";
    // Get GRN Items
    ActionTypes["ACTION_GET_GRN_ITEMS"] = "[Consignment Recall] Get GRN Items";
    ActionTypes["ACTION_GET_GRN_ITEMS_SUCCESS"] = "[Consignment Recall] Get GRN Items Success";
    ActionTypes["ACTION_GET_GRN_ITEMS_FAILURE"] = "[Consignment Recall] Get GRN Items Failure";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));
/**
 * Get consignment list action types definition
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
var GetConsignmentListAction = /** @class */ (function () {
    function GetConsignmentListAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST;
    }
    return GetConsignmentListAction;
}());
exports.GetConsignmentListAction = GetConsignmentListAction;
var GetConsignmentListFailureAction = /** @class */ (function () {
    function GetConsignmentListFailureAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE;
    }
    return GetConsignmentListFailureAction;
}());
exports.GetConsignmentListFailureAction = GetConsignmentListFailureAction;
var GetConsignmentListSuccessAction = /** @class */ (function () {
    function GetConsignmentListSuccessAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS;
    }
    return GetConsignmentListSuccessAction;
}());
exports.GetConsignmentListSuccessAction = GetConsignmentListSuccessAction;
/**
 * Update consignment action types definition
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
var UpdateConsignmentAction = /** @class */ (function () {
    function UpdateConsignmentAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_UPDATE_CONSIGNMENT;
    }
    return UpdateConsignmentAction;
}());
exports.UpdateConsignmentAction = UpdateConsignmentAction;
var UpdateConsignmentFailureAction = /** @class */ (function () {
    function UpdateConsignmentFailureAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE;
    }
    return UpdateConsignmentFailureAction;
}());
exports.UpdateConsignmentFailureAction = UpdateConsignmentFailureAction;
var UpdateConsignmentSuccessAction = /** @class */ (function () {
    function UpdateConsignmentSuccessAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS;
    }
    return UpdateConsignmentSuccessAction;
}());
exports.UpdateConsignmentSuccessAction = UpdateConsignmentSuccessAction;
/**
 * Get GRN items action types definition
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
var GetGRNItemsAction = /** @class */ (function () {
    function GetGRNItemsAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_GRN_ITEMS;
    }
    return GetGRNItemsAction;
}());
exports.GetGRNItemsAction = GetGRNItemsAction;
var GetGRNItemsFailureAction = /** @class */ (function () {
    function GetGRNItemsFailureAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE;
    }
    return GetGRNItemsFailureAction;
}());
exports.GetGRNItemsFailureAction = GetGRNItemsFailureAction;
var GetGRNItemsSuccessAction = /** @class */ (function () {
    function GetGRNItemsSuccessAction(payload) {
        this.payload = payload;
        this.type = ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS;
    }
    return GetGRNItemsSuccessAction;
}());
exports.GetGRNItemsSuccessAction = GetGRNItemsSuccessAction;
