"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ShipmentActions = require("../actions/shipment.actions");
exports.initialState = {
    loaded: false,
    loading: false,
    ids: [],
    entities: {},
    selectedId: null,
    search_text: null,
    package_type: []
};
function ShipmentReducer(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case ShipmentActions.ShipmentActionTypes.LOAD: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: true });
        }
        case ShipmentActions.ShipmentActionTypes.SEARCH: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: true, search_text: action.payload });
        }
        case ShipmentActions.ShipmentActionTypes.SEARCH_COMPLETE: {
            var order = action.payload;
            if (order != null) {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: true, loading: false, selectedId: order.externalShipmentID.toString(), entities: Object.assign({}, state.entities, (_a = {},
                        _a[order.externalShipmentID.toString()] = order,
                        _a)) });
            }
            else {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: true, loading: false, entities: {} });
            }
        }
        case ShipmentActions.ShipmentActionTypes.TRACKING_ID_SEARCH: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: true, search_text: "" });
        }
        case ShipmentActions.ShipmentActionTypes.TRACKING_ID_SEARCH_COMPLETE: {
            var order = action.payload["data"][0];
            if (order != null) {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: true, loading: false, selectedId: order.externalShipmentID.toString(), search_text: order.externalShipmentID.toString(), entities: Object.assign({}, state.entities, (_b = {},
                        _b[order.externalShipmentID.toString()] = order,
                        _b)) });
            }
            else {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: true, loading: false, entities: {} });
            }
        }
        case ShipmentActions.ShipmentActionTypes.CLEAR_SEARCH: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: false, search_text: "", selectedId: "", entities: {} });
        }
        case ShipmentActions.ShipmentActionTypes.SEARCH_ERROR: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: false });
        }
        case ShipmentActions.ShipmentActionTypes.LOAD_PACKAGE_TYPE_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { package_type: Object.assign(action.payload["data"]) });
        }
        case ShipmentActions.ShipmentActionTypes.UPDATE_SHIPMENT: {
            var order = action.payload;
            var shipmentID = order.shipmentId;
            var data = state.entities[shipmentID];
            data.boxType = order.data.boxType;
            data.dimension_height = order.data.dimension_height;
            data.dimension_length = order.data.dimension_length;
            data.dimension_width = order.data.dimension_width;
            data.weight = order.data.weight;
            return tslib_1.__assign(tslib_1.__assign({}, state), { entities: Object.assign({}, state.entities, (_c = {}, _c[shipmentID] = data, _c)) });
        }
        case ShipmentActions.ShipmentActionTypes.UPDATE_SHIPMENT_COMPLETE: {
            var order = action.payload;
            return tslib_1.__assign(tslib_1.__assign({}, state), { entities: Object.assign({}, state.entities, (_d = {},
                    _d[order.externalShipmentID] = order,
                    _d)) });
        }
        case ShipmentActions.ShipmentActionTypes.CLEAR_ORDER_SEARCH: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: false, entities: {} });
        }
        default:
            return state;
    }
}
exports.ShipmentReducer = ShipmentReducer;
