"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var constants_1 = require("@app/shared/constants/constants");
var wholesale_actions_1 = require("../actions/wholesale.actions");
var services_1 = require("@shared/services");
var WholesaleEffects = /** @class */ (function () {
    function WholesaleEffects(actions$, wholesaleService, sharedService, alertService) {
        var _this = this;
        this.actions$ = actions$;
        this.wholesaleService = wholesaleService;
        this.sharedService = sharedService;
        this.alertService = alertService;
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for route Summary API
         * @return Observable Success or failure Action
         */
        this.getRouteSummary$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.GET_ROUTE_SUMMARY), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getRouteSummary(action.payload).pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS,
                    payload: data["listOfRouteSummary"]
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.GET_ROUTE_SUMMARY_FAILED });
            }));
        }));
        /**
        * @author Nidhi Raturi
        * @param Observable dispatched action
        * @description dispatch sucess and failure actions for warehouse Listing API
        * @return Observable Success or failure Action
        */
        this.getWarehouseListing$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.GET_WAREHOUSE_LIST), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getWarehouseList().pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS,
                    payload: data['data']
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.GET_WAREHOUSE_LIST_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Get Delivery Associate API
         * @return Observable Success or failure Action
         */
        this.getDeliveryAssociates$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.GET_ASSOCIATES), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getDeliveryAssociates().pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.GET_ASSOCIATES_SUCCESS,
                    payload: data
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.GET_ASSOCIATES_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Add Delivery Associate API
         * @return Observable Success or failure Action
         */
        this.addDeliveryAssociate$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.ADD_ASSOCIATE), operators_1.mergeMap(function (action) {
            return _this.wholesaleService
                .addDeliveryAssociate(action.payload["params"])
                .pipe(operators_1.map(function (data) {
                if (data["id"]) {
                    _this.alertService.showSuccess(constants_1.CONSTANTS.ALERT_MESSAGES.ADDPICKLIST_ASSOCIATE_SUCCESS);
                    action.payload["ref"].close();
                    return {
                        type: wholesale_actions_1.WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS,
                        payload: data
                    };
                }
                else {
                    _this.alertService.showError(constants_1.CONSTANTS.ALERT_MESSAGES.ADDPICKLIST_ASSOCIATE_FAILURE);
                    return { type: wholesale_actions_1.WholesaleActionTypes.ADD_ASSOCIATE_FAILED };
                }
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.ADD_ASSOCIATE_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Delete Delivery Associate API
         * @return Observable Success or failure Action
         */
        this.deleteDeliveryAssociate$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.DELETE_ASSOCIATE), operators_1.mergeMap(function (action) {
            return _this.wholesaleService
                .deleteDeliveryAssociate(action.payload["id"])
                .pipe(operators_1.map(function (data) {
                _this.alertService.showSuccess(constants_1.CONSTANTS.ALERT_MESSAGES.DELETEPICKLIST_ASSOCIATE_SUCCESS);
                action.payload["ref"].close();
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS,
                    payload: action.payload
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.DELETE_ASSOCIATE_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Get Simulated Summary API
         * @return Observable Success or failure Action
         */
        this.getSimulatedSummary$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.GET_SIMULATED_SUMMARY), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getSimulatedSummary(action.payload).pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.GET_SIMULATED_SUCCESS,
                    payload: data["routeSummaryList"]
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.GET_SIMULATED_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Get Summary After Picking API
         * @return Observable Success or failure Action
         */
        this.getPicklistSummary$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.GET_PICKLIST_SUMMARY), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getPicklistSummary(action.payload).pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.GET_PICKLIST_SUCCESS,
                    payload: data["routeSummaryList"]
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.GET_PICKLIST_FAILED });
            }));
        }));
        /**
         * @author Saher Shaukat
         * @param Observable dispatched action
         * @description dispatch sucess and failure actions for Get Ttiles API
         * @return Observable Success or failure Action
         */
        this.getTitles$ = this.actions$.pipe(effects_1.ofType(wholesale_actions_1.WholesaleActionTypes.FETCH_TITLES), operators_1.mergeMap(function (action) {
            return _this.wholesaleService.getTitles().pipe(operators_1.map(function (data) {
                return {
                    type: wholesale_actions_1.WholesaleActionTypes.FETCH_TITLES_SUCCESS,
                    payload: data
                };
            }), operators_1.catchError(function () {
                return rxjs_1.of({ type: wholesale_actions_1.WholesaleActionTypes.FETCH_TITLES_FAILURE });
            }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getRouteSummary$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getWarehouseListing$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getDeliveryAssociates$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "addDeliveryAssociate$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "deleteDeliveryAssociate$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getSimulatedSummary$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getPicklistSummary$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], WholesaleEffects.prototype, "getTitles$", void 0);
    return WholesaleEffects;
}());
exports.WholesaleEffects = WholesaleEffects;
