"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["a[_ngcontent-%COMP%]{cursor:pointer}.cancelpopup[_ngcontent-%COMP%]{position:fixed;top:0;left:0;background:#00000061;width:100%;bottom:0;z-index:99;opacity:0;visibility:hidden;transition:all .3s linear}.cancelpopup.visible[_ngcontent-%COMP%]{visibility:visible;opacity:1}.cancelpopup[_ngcontent-%COMP%]   .innerpopup[_ngcontent-%COMP%]{width:400px;background:#fff;position:absolute;text-align:center;top:30%;padding:40px;margin:auto;left:0;right:0;font-size:14px;color:#333;font-family:latomedium;letter-spacing:.5px}.cancelpopup[_ngcontent-%COMP%]   .innerpopup[_ngcontent-%COMP%]   .close[_ngcontent-%COMP%]{position:absolute;right:15px;top:15px;cursor:pointer}.cancelpopup[_ngcontent-%COMP%]   .innerpopup[_ngcontent-%COMP%]   .button[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center}.cancelpopup[_ngcontent-%COMP%]   .innerpopup[_ngcontent-%COMP%]   .button[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{display:inline-block;border:2px solid #000;color:#000;padding:3px 0;width:70px;margin:0 10px;font-size:12px;cursor:pointer}.cancelpopup[_ngcontent-%COMP%]   .innerpopup[_ngcontent-%COMP%]   .button[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#fff;background:#000}.loaderOverlay[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100%;bottom:0;z-index:9}.matloader[_ngcontent-%COMP%]{left:0}"];
exports.styles = styles;
