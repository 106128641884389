import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { RecallListComponent } from './components/recall-list/recall-list.component';
import { ConsignmentRecallRoutingModule } from './consignment-recall-routing.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { EffectsModule } from '@ngrx/effects';
import { ConsignmentEffects } from './effects/consignment.effects';
import { AddGatepassComponent } from './components/add-gatepass/add-gatepass.component';
import { RecallHomeComponent } from './components/recall-home/recall-home.component';
import { RecallDetailsComponent } from './components/recall-details/recall-details.component';
import { PackItemComponent } from './components/pack-item/pack-item.component';
import { BagEffects } from './../bags/bag.effects';
import { ScanInputComponent } from './components/container/scan-input/scan-input.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    RecallListComponent,
    AddGatepassComponent,
    RecallHomeComponent,
    RecallDetailsComponent,
    ScanInputComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    ConsignmentRecallRoutingModule,
    MatTooltipModule,
    EffectsModule.forFeature([ConsignmentEffects, BagEffects]),
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule
  ],
  exports: [
    ScanInputComponent
  ]

})
export class ConsignmentRecallModule { }
