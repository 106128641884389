"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var RecallAction = require("../actions/consignment-recall.action");
exports.initialState = {
    consignmentList: null,
    consignmentListError: null,
    loadingConsignmentList: false,
    loadedConsignmentList: false,
    updateConsignment: null,
    updateConsignmentError: null,
    updatingConsignment: false,
    updatedConsignment: false,
    getGRNItemList: null,
    getGRNItemListError: null,
    loadingGRNItemList: false,
    loadedGRNItemList: false
};
function ConsignmentRecallReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST:
            return tslib_1.__assign(tslib_1.__assign({}, state), { consignmentList: null, consignmentListError: null, loadingConsignmentList: true, loadedConsignmentList: false });
        case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { consignmentList: null, consignmentListError: action.payload, loadingConsignmentList: false, loadedConsignmentList: true });
        case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { consignmentList: action.payload, consignmentListError: null, loadingConsignmentList: false, loadedConsignmentList: true });
        case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateConsignment: null, updateConsignmentError: null, updatingConsignment: true, updatedConsignment: false });
        case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateConsignment: null, updateConsignmentError: action.payload, updatingConsignment: false, updatedConsignment: true });
        case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateConsignment: action.payload, updateConsignmentError: null, updatingConsignment: false, updatedConsignment: true });
        case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { getGRNItemList: null, getGRNItemListError: null, loadingGRNItemList: true, loadedGRNItemList: false });
        case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { getGRNItemList: null, getGRNItemListError: action.payload, loadingGRNItemList: false, loadedGRNItemList: true });
        case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { getGRNItemList: action.payload, getGRNItemListError: null, loadingGRNItemList: false, loadedGRNItemList: true });
        default:
            return state;
    }
}
exports.ConsignmentRecallReducer = ConsignmentRecallReducer;
// Get consignment list
exports.getConsignmentList = function (recallState) { return recallState.consignmentList; };
exports.getConsignmentListError = function (recallState) { return recallState.consignmentListError; };
exports.getConsignmentListLoading = function (recallState) { return recallState.loadingConsignmentList; };
exports.getConsignmentListLoaded = function (recallState) { return recallState.loadedConsignmentList; };
// Update consignment
exports.updateConsignment = function (recallState) { return recallState.updateConsignment; };
exports.updateConsignmentError = function (recallState) { return recallState.updateConsignmentError; };
exports.updatingConsignment = function (recallState) { return recallState.updatingConsignment; };
exports.updatedConsignment = function (recallState) { return recallState.updatedConsignment; };
// Get grn item list
exports.getGRNItemList = function (recallState) { return recallState.getGRNItemList; };
exports.getGRNItemListError = function (recallState) { return recallState.getGRNItemListError; };
exports.loadingGRNItemList = function (recallState) { return recallState.loadingGRNItemList; };
exports.loadedGRNItemList = function (recallState) { return recallState.loadedGRNItemList; };
