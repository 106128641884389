"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var UserActions = require("../actions/user.actions");
exports.initialState = {
    order: null,
    selectedSection: null
};
function UserReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case UserActions.UserActionTypes.GET_CURRENT_USER: {
            return state;
        }
        case UserActions.UserActionTypes.GET_ORDER: {
            return state;
        }
        case UserActions.UserActionTypes.GET_USER_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { isLoggedIn: true, user: action.payload });
        }
        case UserActions.UserActionTypes.GET_ORDER_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { order: action.payload });
        }
        case UserActions.UserActionTypes.GET_USER_FAILED: {
            return state;
        }
        case UserActions.UserActionTypes.GET_SELECTED_SECTION: {
            return state;
        }
        case UserActions.UserActionTypes.SET_SELECTED_SECTION: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { selectedSection: action.payload });
        }
        default:
            return state;
    }
}
exports.UserReducer = UserReducer;
