import { Component, OnInit } from "@angular/core";
import { ROUTES } from "@app/shared/constants/constants";
import { SharedService, InboundService } from "../../services";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store, select } from "@ngrx/store";
import {

  getConsigmentNoStatus,

} from "../../../../app/consignment-receive/reducers/selector";
import { ConsignmentReceiveService } from '../../../../app/consignment-receive/services/consignment-receive.service';

@Component({
  selector: "app-receive-header",
  templateUrl: "./receive-header.component.html",
  styleUrls: ["./receive-header.component.scss"]
})
export class ReceiveHeaderComponent implements OnInit {
  ROUTES = ROUTES;
  showLoader: Boolean = false;
  cancelpopup: boolean = false;
  stepNo;
  RESUME_URL = "/consignment-receive/resume-receiving"
  getConsignmentNo$: Subscription;
  consignmentNo;
  getStep$: Subscription;

  constructor(private sharedService: SharedService, private inboundService: InboundService, private receiveService: ConsignmentReceiveService
    , private router: Router, private store: Store<{ receiveState }>) { }

  ngOnInit() {
    console.log(this.router.url);
   // if (this.router.url != this.RESUME_URL) {
      console.log('cffffffffff');
      this.getStep$ = this.inboundService.currentMessage.subscribe(message =>
        this.stepNo = message)
  //  }
    console.log(this.stepNo);
    this.sharedService.loaderListner.subscribe(data => {
      this.showLoader = data.status;
    });
    this.getConsignmentNo();
  }

  getConsignmentNo() {

  }
  showcancel() {
    if (this.router.url != this.RESUME_URL) {
      this.cancelpopup = true;
    }
    else {
      this.router.navigate([ROUTES.INBOUND])
    }
  }
  close() {
    this.cancelpopup = false;
  }
  hopToHome() {
    this.router.navigate([ROUTES.INBOUND])
  }

  cancelConsignment() {
    this.getConsignmentNo$ = this.store
      .pipe(select(getConsigmentNoStatus))
      .subscribe(res => {
        this.consignmentNo = res
        console.log(this.consignmentNo);
        if (this.consignmentNo) {
          const obj = { consignmentNumber: this.consignmentNo }
          this.receiveService.cancelConsignment(obj).subscribe((res) => {
            console.log(res);
            this.hopToHome()
          }, (error) => {
            console.log(error);
          })
        }
        else {
          this.hopToHome();
        }
      });

  }

  ngOnDestroy(): void {
    if (this.getStep$) {
      this.getStep$.unsubscribe();
    }

  }
}

