"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Get bag list actions
exports.GET_BAG_LIST = '[BAG] Get Bag List';
exports.GET_BAG_LIST_SUCCESS = '[BAG] Get Bag List Success';
exports.GET_BAG_LIST_FAILURE = '[BAG] Get Bag List Failure';
var GetBagList = /** @class */ (function () {
    function GetBagList(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST;
    }
    return GetBagList;
}());
exports.GetBagList = GetBagList;
var GetBagListSuccess = /** @class */ (function () {
    function GetBagListSuccess(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST_SUCCESS;
    }
    return GetBagListSuccess;
}());
exports.GetBagListSuccess = GetBagListSuccess;
var GetBagListFailure = /** @class */ (function () {
    function GetBagListFailure(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST_FAILURE;
    }
    return GetBagListFailure;
}());
exports.GetBagListFailure = GetBagListFailure;
// Get bag list for PDF actions
exports.GET_BAG_LIST_FOR_PDF = '[BAG] Get Bag List For PDF';
exports.GET_BAG_LIST_FOR_PDF_SUCCESS = '[BAG] Get Bag List For PDF Success';
exports.GET_BAG_LIST_FOR_PDF_FAILURE = '[BAG] Get Bag List For PDF Failure';
var GetBagListForPDF = /** @class */ (function () {
    function GetBagListForPDF(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST_FOR_PDF;
    }
    return GetBagListForPDF;
}());
exports.GetBagListForPDF = GetBagListForPDF;
var GetBagListForPDFSuccess = /** @class */ (function () {
    function GetBagListForPDFSuccess(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST_FOR_PDF_SUCCESS;
    }
    return GetBagListForPDFSuccess;
}());
exports.GetBagListForPDFSuccess = GetBagListForPDFSuccess;
var GetBagListForPDFFailure = /** @class */ (function () {
    function GetBagListForPDFFailure(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_LIST_FOR_PDF_FAILURE;
    }
    return GetBagListForPDFFailure;
}());
exports.GetBagListForPDFFailure = GetBagListForPDFFailure;
// Create bag actions
exports.CREATE_BAG = '[BAG] Create Bag';
exports.CREATE_BAG_SUCCESS = '[BAG] Create Bag Success';
exports.CREATE_BAG_FAILURE = '[BAG] Create Bag Failure';
var CreateBag = /** @class */ (function () {
    function CreateBag(payload) {
        this.payload = payload;
        this.type = exports.CREATE_BAG;
    }
    return CreateBag;
}());
exports.CreateBag = CreateBag;
var CreateBagSuccess = /** @class */ (function () {
    function CreateBagSuccess(payload) {
        this.payload = payload;
        this.type = exports.CREATE_BAG_SUCCESS;
    }
    return CreateBagSuccess;
}());
exports.CreateBagSuccess = CreateBagSuccess;
var CreateBagFailure = /** @class */ (function () {
    function CreateBagFailure(payload) {
        this.payload = payload;
        this.type = exports.CREATE_BAG_FAILURE;
    }
    return CreateBagFailure;
}());
exports.CreateBagFailure = CreateBagFailure;
// Update bag actions
exports.UPDATE_BAG = '[BAG] Update Bag';
exports.UPDATE_BAG_SUCCESS = '[BAG] Update Bag Success';
exports.UPDATE_BAG_FAILURE = '[BAG] Update Bag Failure';
var UpdateBag = /** @class */ (function () {
    function UpdateBag(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_BAG;
    }
    return UpdateBag;
}());
exports.UpdateBag = UpdateBag;
var UpdateBagSuccess = /** @class */ (function () {
    function UpdateBagSuccess(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_BAG_SUCCESS;
    }
    return UpdateBagSuccess;
}());
exports.UpdateBagSuccess = UpdateBagSuccess;
var UpdateBagFailure = /** @class */ (function () {
    function UpdateBagFailure(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_BAG_FAILURE;
    }
    return UpdateBagFailure;
}());
exports.UpdateBagFailure = UpdateBagFailure;
// Get bag item details actions
exports.GET_BAG_ITEM_DETAILS = '[BAG] Get Bag Item Details';
exports.GET_BAG_ITEM_DETAILS_SUCCESS = '[BAG] Get Bag Item Details Success';
exports.GET_BAG_ITEM_DETAILS_FAILURE = '[BAG] Get Bag Item Details Failure';
var GetBagItemDetails = /** @class */ (function () {
    function GetBagItemDetails(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_ITEM_DETAILS;
    }
    return GetBagItemDetails;
}());
exports.GetBagItemDetails = GetBagItemDetails;
var GetBagItemDetailsSuccess = /** @class */ (function () {
    function GetBagItemDetailsSuccess(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_ITEM_DETAILS_SUCCESS;
    }
    return GetBagItemDetailsSuccess;
}());
exports.GetBagItemDetailsSuccess = GetBagItemDetailsSuccess;
var GetBagItemDetailsFailure = /** @class */ (function () {
    function GetBagItemDetailsFailure(payload) {
        this.payload = payload;
        this.type = exports.GET_BAG_ITEM_DETAILS_FAILURE;
    }
    return GetBagItemDetailsFailure;
}());
exports.GetBagItemDetailsFailure = GetBagItemDetailsFailure;
// Get single item details actions
exports.GET_SINGLE_ITEM_DETAILS = '[BAG] Get Single Item Details';
exports.GET_SINGLE_ITEM_DETAILS_SUCCESS = '[BAG] Get Single Item Details Success';
exports.GET_SINGLE_ITEM_DETAILS_FAILURE = '[BAG] Get Single Item Details Failure';
var GetSingleItemDetails = /** @class */ (function () {
    function GetSingleItemDetails(payload) {
        this.payload = payload;
        this.type = exports.GET_SINGLE_ITEM_DETAILS;
    }
    return GetSingleItemDetails;
}());
exports.GetSingleItemDetails = GetSingleItemDetails;
var GetSingleItemDetailsSuccess = /** @class */ (function () {
    function GetSingleItemDetailsSuccess(payload) {
        this.payload = payload;
        this.type = exports.GET_SINGLE_ITEM_DETAILS_SUCCESS;
    }
    return GetSingleItemDetailsSuccess;
}());
exports.GetSingleItemDetailsSuccess = GetSingleItemDetailsSuccess;
var GetSingleItemDetailsFailure = /** @class */ (function () {
    function GetSingleItemDetailsFailure(payload) {
        this.payload = payload;
        this.type = exports.GET_SINGLE_ITEM_DETAILS_FAILURE;
    }
    return GetSingleItemDetailsFailure;
}());
exports.GetSingleItemDetailsFailure = GetSingleItemDetailsFailure;
// Add item in bag actions
exports.ADD_ITEM = '[BAG] Add Item';
exports.ADD_ITEM_SUCCESS = '[BAG] Add Item Success';
exports.ADD_ITEM_FAILURE = '[BAG] Update Item Failure';
var AddItem = /** @class */ (function () {
    function AddItem(payload) {
        this.payload = payload;
        this.type = exports.ADD_ITEM;
    }
    return AddItem;
}());
exports.AddItem = AddItem;
var AddItemSuccess = /** @class */ (function () {
    function AddItemSuccess(payload) {
        this.payload = payload;
        this.type = exports.ADD_ITEM_SUCCESS;
    }
    return AddItemSuccess;
}());
exports.AddItemSuccess = AddItemSuccess;
var AddItemFailure = /** @class */ (function () {
    function AddItemFailure(payload) {
        this.payload = payload;
        this.type = exports.ADD_ITEM_FAILURE;
    }
    return AddItemFailure;
}());
exports.AddItemFailure = AddItemFailure;
