import { createSelector } from "@ngrx/store";
import { ConReceiveState } from "../reducers/con-receive.state";
import { AppState } from "../../../app/app.state";

const getConReceiveState = (state: AppState) => state.receiveState;
const getTransportDetails = (state: ConReceiveState) => state.transportDetails;
const getConsignmentNo = (state: ConReceiveState) => state.consignmentNo;
const getAccountDetails = (state: ConReceiveState) => state.accountDetails;
const getBoxDetails = (state: ConReceiveState) => state.boxDetails;

export const getTransportDetailsStatus = createSelector(
  getConReceiveState,
  getTransportDetails
);

export const getConsigmentNoStatus = createSelector(
  getConReceiveState,
  getConsignmentNo
);
export const getAccountStatus = createSelector(
  getConReceiveState,
  getAccountDetails
);

export const getBoxDetailsStatus = createSelector(
  getConReceiveState,
  getBoxDetails
);
