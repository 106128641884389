import * as WholesaleActions from "../actions/wholesale.actions";
import { WholesaleState } from "./wholesale.state";

export const initialState: WholesaleState = {
  routeSummary: [],
  warehouseList: [],
  associates: [],
  simulatedSummary: [],
  picklistSummary: [],
  accountId: null,
  consolidatedPicklist: null,
  titles: null
};

export function WholesaleReducer(
  state = initialState,
  action: WholesaleActions.WholesaleUnion
) {
  switch (action.type) {
    case WholesaleActions.WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS: {
      return { ...state, routeSummary: action.payload };
    }

    case WholesaleActions.WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS: {
      return { ...state, warehouseList: action.payload };
    }

    case WholesaleActions.WholesaleActionTypes.GET_ASSOCIATES_SUCCESS: {
      return Object.assign({}, state, { associates: action.payload });
    }

    case WholesaleActions.WholesaleActionTypes.GET_SIMULATED_SUCCESS: {
      return Object.assign({}, state, { simulatedSummary: action.payload });
    }

    case WholesaleActions.WholesaleActionTypes.GET_PICKLIST_SUCCESS: {
      return Object.assign({}, state, { picklistSummary: action.payload });
    }

    case WholesaleActions.WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS: {
      const newAssociates = [...state.associates, action.payload];
      return Object.assign({}, state, { associates: newAssociates });
    }

    case WholesaleActions.WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS: {
      const newAssociates = state.associates.filter(
        item => item.id !== action.payload.id
      );
      return Object.assign({}, state, { associates: newAssociates });
    }
    case WholesaleActions.WholesaleActionTypes.SET_ACCOUNT_ID: {
      return { ...state, accountId: action.payload };
    }
    case WholesaleActions.WholesaleActionTypes.SET_CONSOLIDATED_PICKLIST: {
      return { ...state, consolidatedPicklist: action.payload };
    }
    case WholesaleActions.WholesaleActionTypes.FETCH_TITLES_SUCCESS: {
      return { ...state,titles: action.payload };
    }
    default:
      return state;
  }
}
