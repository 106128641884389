import { InvoiceComponent } from "./components/invoice/invoice.component";
import { PrintLayoutComponent } from "./components/print-layout/print-layout.component";

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AcknowledgeSlipComponent } from './components/acknowledge-slip/acknowledge-slip.component';
import { ConsignmentDetailComponent } from './components/consignment-detail/consignment-detail.component';
import { TripSheetComponent } from './components/trip-sheet/trip-sheet.component';
import { BagPackPdfComponent } from '../bags/components/bag-pack-pdf/bag-pack-pdf.component';
import { RecallSheetComponent } from './components/recall-sheet/recall-sheet.component';


const UserRoutes: Routes = [
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "invoice/:picklistId", component: InvoiceComponent }]
  },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "invoice", component: InvoiceComponent }]
  },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "consignment/:consignmentNo", component: ConsignmentDetailComponent }]
  }
  ,
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "acknowlege-slip/:consignmentNo", component: AcknowledgeSlipComponent }]
  },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "trip-sheet/:picklistId", component: TripSheetComponent }]
  },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "packed-bag/:bagID", component: BagPackPdfComponent }]
  },
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "recall-sheet/:picklistId", component: RecallSheetComponent }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(UserRoutes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
