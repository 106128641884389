import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

export const GET_BRAND_LIST = '[BRAND] Get Brand List';
export const GET_BRAND_LIST_SUCCESS = '[BRAND] Get Brand List Success';
export const GET_BRAND_LIST_FAILURE = '[BRAND] Get Brand List Failure';

export class GetBrandList implements Action {
    readonly type = GET_BRAND_LIST
    constructor(public payload: any) { }
}
export class GetBrandListSuccess implements Action {
    readonly type = GET_BRAND_LIST_SUCCESS
    constructor(public payload: any) { }
}
export class GetBrandListFailure implements Action {
    readonly type = GET_BRAND_LIST_FAILURE
    constructor(public payload: any) { }
}

// Export action types
export type Actions =
    GetBrandList
    | GetBrandListSuccess
    | GetBrandListFailure;