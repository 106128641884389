import { Component, OnInit, OnDestroy } from "@angular/core";
import { PrintService } from "../../services/print.service";
import { select, Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { WholesaleService } from "../../services";
import * as moment from "moment";
import { Subscription } from "rxjs";
import {
  getCurrentRouteSummary,
  getCurrentDeliveryAssociates,
  getConsolidatedStatus,
  getCurrentSimulatedSummary,
  getCurrentPicklistSummary,
  getAccountIdStatus
} from "../../../wholesale/reducers/selectors";
@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"]
})
export class InvoiceComponent implements OnInit, OnDestroy {
  Object = Object;
  picklistId: string;
  invoiceDetails: any;
  totalCases = 0;
  totalLoose = 0;
  totalBundles = 0;
  Cases = 0;
  Loose = 0;
  bundles = 0;
  foodFilteredList: any;
  nonfoodFilteredList = [];
  associateName: string;
  pickListAssociatesList$: Subscription;
  getConsolidatedpickList$: Subscription;
  getPicklistById$: Subscription;
  associatePicklist: any;
  uniqueRoutes: any;
  getAccountIdStatus$: Subscription;
  warehouseFromStore = null;

  wbltCustomersCount: any;
  WBLT = false;
  constructor(
    private route: ActivatedRoute,
    private printService: PrintService,
    private service: WholesaleService,
    private router: Router,
    private store: Store<{ wholesale }>
  ) {}

  ngOnInit() {
    this.picklistId = this.route.snapshot.params["picklistId"];

    this.getPickListAssociate();
    if (this.picklistId !== undefined) {
      this.getPicklistDetails(this.picklistId);
    } else {
      this.consolidatedPicklist();
    }

    this.getAccountIdStatus$ = this.store
      .pipe(select(getAccountIdStatus))
      .subscribe(result => {
        if (result) {
          this.warehouseFromStore = result;
          if (this.warehouseFromStore.toUpperCase() == "WBLT") {
            this.WBLT = true;
          }
        }
      });
  }

  consolidatedPicklist() {
    this.getConsolidatedpickList$ = this.store
      .pipe(select(getConsolidatedStatus))
      .subscribe(result => {
        this.invoiceDetails = result;
        if (
          this.warehouseFromStore &&
          this.warehouseFromStore.toUpperCase() == "WBLT"
        ) {
          this.foodFilteredList = this.invoiceDetails.customerRecords;
          this.uniqueRoutes = Object.keys(
            this.invoiceDetails.customerRecords
          ).length;
        } else {
          this.invoiceDetails["items"].sort((a, b) =>
            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
          );
          this.foodFilteredList = this.invoiceDetails.items.filter(resp => {
            return resp.isFood === "1";
          });
          this.nonfoodFilteredList = this.invoiceDetails.items.filter(
            response => {
              return response.isFood !== "1";
            }
          );
          this.calculateTotalRows(this.invoiceDetails.items);
        }
        if (this.invoiceDetails) {
          this.printService.onDataReady();
        }
      });
  }
  getPicklistDetails(id) {
    this.getPicklistById$ = this.service.getPicklistById(id).subscribe(res => {
      if (res) {
        this.invoiceDetails = res;
        if (this.invoiceDetails) {
          // if(this.warehouseFromStore) {
          this.invoiceDetails.date = moment(res["pickingDate"]).format(
            "DD-MMM-YYYY, h:mm:ss A (dddd)"
          );
          const associates = this.service.getAssociateName(
            this.associatePicklist,
            this.invoiceDetails.picklistAssociate
          );
          if (associates.length > 0) {
            this.invoiceDetails.associate = associates[0]["name"];
          }
          this.foodFilteredList = this.invoiceDetails.customerRecords;
          this.uniqueRoutes = Object.keys(
            this.invoiceDetails.customerRecords
          ).length;
          // }
          // else{
          if (this.invoiceDetails["items"]) {
            this.invoiceDetails["items"].sort((a, b) =>
              a.title > b.title ? 1 : b.title > a.title ? -1 : 0
            );
          }
          this.invoiceDetails.date = moment(res["pickingDate"]).format(
            "DD-MMM-YYYY, h:mm:ss A (dddd)"
          );
          // const associates = this.service.getAssociateName(
          //   this.associatePicklist,
          //   this.invoiceDetails.picklistAssociate
          // );
          if (associates.length > 0) {
            this.invoiceDetails.associate = associates[0]["name"];
          }
          if (this.invoiceDetails.items) {
            this.foodFilteredList = this.invoiceDetails.items.filter(resp => {
              return resp.isFood === "1";
            });
            this.nonfoodFilteredList = this.invoiceDetails.items.filter(
              response => {
                return response.isFood !== "1";
              }
            );
            this.calculateTotalRows(this.invoiceDetails.items);
          }

          // }
          this.printService.onDataReady();
        }
      } else {
        this.router.navigate([{ outlets: { print: null } }]);
      }

      // return new Promise(resolve => setTimeout(() => resolve({ res }), 1000));
    });
  }

  getPickListAssociate() {
    this.pickListAssociatesList$ = this.store
      .pipe(select(getCurrentDeliveryAssociates))
      .subscribe(result => {
        this.associatePicklist = result;
      });
  }

  calculateTotalRows(items) {
    items.map(item => {
      if (item.isFood === "1") {
        this.totalCases += item.cases;
        this.totalLoose += item.loose;
        this.totalBundles += item.bundles;
      }
      if (item.isFood !== "1") {
        this.Cases += item.cases;
        this.Loose += item.loose;
        this.bundles += item.bundles;
      }
    });

    if (this.picklistId === undefined) {
      const array = this.invoiceDetails.picklists;

      this.uniqueRoutes = Array.from(new Set(array.map(x => x.routeID))).length;
    }
  }

  trackByFn(index, item) {
    return index;
  }
  ngOnDestroy() {
    if (this.pickListAssociatesList$) {
      this.pickListAssociatesList$.unsubscribe();
    }
    if (this.getConsolidatedpickList$) {
      this.getConsolidatedpickList$.unsubscribe();
    }
    if (this.getPicklistById$) {
      this.getPicklistById$.unsubscribe();
    }
  }

  priceRoundOff(price) {
    return Number(price.toFixed(1));
  }
}
