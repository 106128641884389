import { Action } from "@ngrx/store";

export enum ReceiveActionTypes {
  SET_TRANSPORTER_DETAILS = "[Transporter Details Page] Get Transporter Details",
  SET_CONSIGNMENT_NO = "[Documents Details Page] Get Consignment No.",
  SET_ACCOUNT_DETAILS = "[Documents Details Page] Set Account Details.",
  SET_BOX_DETAILS = "[Documents Details Page] Set Box Details."
}

export class SetTransporterDetails implements Action {
  readonly type = ReceiveActionTypes.SET_TRANSPORTER_DETAILS;

  constructor(public payload: object) {}
}
export class SetConsignmentNo implements Action {
  readonly type = ReceiveActionTypes.SET_CONSIGNMENT_NO;

  constructor(public payload: string) {}
}

export class SetAccountDetails implements Action {
  readonly type = ReceiveActionTypes.SET_ACCOUNT_DETAILS;

  constructor(public payload: object) {}
}

export class SetBoxDetails implements Action {
  readonly type = ReceiveActionTypes.SET_BOX_DETAILS;

  constructor(public payload: object) {}
}


export type ReceiveUnion =
  | SetTransporterDetails
  | SetConsignmentNo
  | SetAccountDetails
  | SetBoxDetails
