<!-- Header Start -->
<header>
  <div class="brand">
    <a href="#">
      <img src="assets/images/brand-img.png" alt="" />
    </a>
  </div>
  <!-- <a [routerLink]="ROUTES.INBOUND">X</a> -->
  <a (click)="showcancel()"><img src="assets/images/edi-close.png" /></a>
</header>
<!-- <div class="page-loader" *ngIf="showLoader">
  <div class="loader-icon"></div>
</div> -->

<!-- <div class="cancelpopup" [ngClass]="cancelpopup? 'visible':''">
  <div class="innerpopup">
    <a class="close" (click)="close()"><img src="assets/images/edi-close.png" /></a>
    <p>Are you sure want to cancel.</p>
    <div class="button">
      <a [routerLink]="ROUTES.INBOUND">Yes</a>
      <a (click)="close()">No</a>
    </div>
  </div>
</div> -->


<div class="popup-form" *ngIf="cancelpopup">
  <form class="form">
    <span class="close-popup" (click)="close()"></span>
    <div class="form-title form-title-2">
      <h4 *ngIf="stepNo==='step3' || stepNo==='step4'" >Cancel Consignment</h4>
      <h4 *ngIf="stepNo==='step1' || stepNo==='step2'">Confirmation</h4>
    </div>


    <div class="form-body">
      <p class="form-text" *ngIf="stepNo==='step3' || stepNo==='step4'">
        Are you sure you want to mark this receiving cancel?
      </p>
      <p class="form-text" *ngIf="stepNo==='step1' || stepNo==='step2'">
        Are you sure you want to leave this page?
      </p>

    </div>
    <div class="form-body note-text" *ngIf="stepNo==='step3' || stepNo==='step4'">
      <p class="form-text">NOTE:In case you want to close receiving now <br>
        and resume later please click on RESUME LATER</p>
    </div>

    <div class="btn-block">
      <div class="btn-wrapper" *ngIf="stepNo==='step3' || stepNo==='step4'">
        <button type="submit" class="submit-button n-btn f-left" (click)="cancelConsignment()">
          C Cancel
        </button>
        <button type="submit" class="submit-button n-btn f-right" [routerLink]="ROUTES.INBOUND">
          R Resume Later
        </button>
      </div>
      <div class="btn-wrapper" *ngIf="stepNo==='step1' || stepNo==='step2'">
        <button type="submit" class="submit-button n-btn f-left" (click)="hopToHome()">
          Y Yes
        </button>
        <button type="submit" class="submit-button n-btn f-right" (click)="close()">
          N No
        </button>
      </div>
    </div>

  </form>
</div>


<div class="matloader" *ngIf="showLoader">
  <mat-spinner [diameter]="25" [strokeWidth]="2.2"></mat-spinner>
  <span>Loading...</span>
</div>
<div class="loaderOverlay" *ngIf="showLoader"></div>
