"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var router_1 = require("@angular/router");
var selector_1 = require("../../reducers/selector");
var consignment_recall_action_1 = require("../../actions/consignment-recall.action");
var alert_service_1 = require("../../../shared/services/alert.service");
var constants_1 = require("../../../shared/constants/constants");
var print_service_1 = require("../../../shared/services/print.service");
var wholesale_service_1 = require("../../../shared/services/wholesale.service");
var RecallDetailsComponent = /** @class */ (function () {
    function RecallDetailsComponent(store, activatedRoute, alertServices, printService, wholesaleService) {
        this.store = store;
        this.activatedRoute = activatedRoute;
        this.alertServices = alertServices;
        this.printService = printService;
        this.wholesaleService = wholesaleService;
        this.getGRNItemsRequestBody = { page: 1, perPage: 25 };
        this.grnItemList = [];
        this.grnItemsCount = 0;
        this.isLoading = false;
        this.consignmentNumber = '';
        this.routes = constants_1.ROUTES;
        this.requestGRNItems();
    }
    RecallDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountId = this.activatedRoute.snapshot.params['accountId'];
        this.wholesaleService.getRecallChallanDetails(this.consignmentNumber).subscribe(function (response) {
            if (response) {
                if (response['data']) {
                    _this.recallConsignmentStatus = response['data'][0]['status'];
                    _this.challanPDFPath = response['data'][0]['invoiceUrl'];
                }
            }
        });
    };
    /**
     * Pagination
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallDetailsComponent.prototype.onPaginate = function (pageEvent) {
        this.getGRNItemsRequestBody.page = (pageEvent.pageIndex + 1);
        this.getGRNItemsRequestBody.perPage = pageEvent.pageSize;
        this.getGRNItemsHandling();
    };
    /**
     * Get GRN items handling
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallDetailsComponent.prototype.getGRNItemsHandling = function () {
        var _this = this;
        this.store.dispatch(new consignment_recall_action_1.GetGRNItemsAction(this.getGRNItemsRequestBody));
        this.store.pipe(store_1.select(selector_1.getGRNItemListSelector)).subscribe(function (data) {
            if (data) {
                if (data['data']) {
                    _this.isLoading = false;
                    _this.grnItemList = data['data'];
                    _this.grnItemsCount = data['total'];
                }
            }
        });
        this.store.pipe(store_1.select(selector_1.getGRNItemListErrorSelector)).subscribe(function (error) {
        });
        this.store.pipe(store_1.select(selector_1.getGRNItemListLoadingSelector)).subscribe(function (isLoading) {
            if (isLoading) {
                _this.isLoading = true;
            }
        });
        this.store.pipe(store_1.select(selector_1.getGRNItemListLoadedSelector)).subscribe(function (isLoaded) {
            if (isLoaded) {
                _this.isLoading = false;
            }
        });
    };
    /**
     * Request GRN Item list for requested consignment number
     *
     * @author Chandrika Aggarwal <chandrika@eshopbox.com>
     */
    RecallDetailsComponent.prototype.requestGRNItems = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (paramList) {
            // If consignment number exists
            if (paramList['id']) {
                _this.getGRNItemsRequestBody['consignmentNumber'] = paramList['id']; // encoded consignment number
                _this.consignmentNumber = decodeURIComponent(paramList['id']);
                _this.getGRNItemsHandling();
            }
            else {
                // If not exists
                _this.alertServices.showError('Unable to fetch gatepass items. Invalid consignment number');
            }
        });
    };
    RecallDetailsComponent.prototype.onPrintRecallList = function (consignmentNumber) {
        var consignmentNumbersDetails = [consignmentNumber];
        this.printService.printDocument("recall-sheet", consignmentNumbersDetails);
    };
    RecallDetailsComponent.prototype.openChallan = function () {
        window.open(this.challanPDFPath);
    };
    return RecallDetailsComponent;
}());
exports.RecallDetailsComponent = RecallDetailsComponent;
