import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Store, select } from "@ngrx/store";
import { FormControl, Validators } from "@angular/forms";
import { UserService } from "@app/shared/services/user.service";
import { CONSTANTS } from "@app/shared/constants/constants";
import { AlertService } from "@app/shared/services/alert.service";
import { getCurrentDeliveryAssociates } from "@app/wholesale/reducers/selectors";
import {
  AddAssociate,
  DeleteAssociate
} from "@app/wholesale/actions/wholesale.actions";

@Component({
  selector: "app-add-da",
  templateUrl: "./add-da.component.html",
  styleUrls: ["./add-da.component.sass"]
})
export class AddDAComponent implements OnInit {
  name: FormControl;
  deliveryAssociateList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<AddDAComponent>,
    private store: Store<{ wholesale }>
  ) {}

  ngOnInit() {
    this.name = new FormControl(null, Validators.required);
    this.getDeliveryAssociatesList();
  }

  onSave() {
    if (this.data.type === "addDA") {
      this.saveAssociate();
      return;
    }
    this.deleteAssociate();
  }

  getDeliveryAssociatesList() {
    this.store.pipe(select(getCurrentDeliveryAssociates)).subscribe(result => {
      this.deliveryAssociateList = result;
    });
  }

  saveAssociate() {
    if (this.name.valid) {
      this.store.dispatch(
        new AddAssociate({
          params: {
            name: this.name.value,
            status: "1"
          },
          ref: this.dialogRef
        })
      );
    }
  }

  deleteAssociate() {
    if (this.name.valid) {
      this.store.dispatch(
        new DeleteAssociate({ id: this.name.value.id, ref: this.dialogRef })
      );
    }
  }
}
