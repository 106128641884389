"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var router_1 = require("@angular/router");
var TripSheetComponent = /** @class */ (function () {
    function TripSheetComponent(printService, route, service) {
        this.printService = printService;
        this.route = route;
        this.service = service;
    }
    TripSheetComponent.prototype.ngOnInit = function () {
        this.getTripSheetDetail();
    };
    TripSheetComponent.prototype.getTripSheetDetail = function () {
        var _this = this;
        this.picklistCode = this.route.snapshot.params["picklistId"];
        console.log(this.picklistCode);
        this.service.getTripSheetDetail(this.picklistCode).subscribe(function (res) {
            _this.tripSheetDetail = res;
            if (_this.tripSheetDetail) {
                _this.printService.onDataReady();
            }
        });
    };
    return TripSheetComponent;
}());
exports.TripSheetComponent = TripSheetComponent;
