"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../environments/environment");
var i0 = require("@angular/core");
var ConsoleLoggerService = /** @class */ (function () {
    function ConsoleLoggerService() {
        this.consoleTypeList = ['error', 'warn', 'debug', 'log', 'info'];
    }
    /**
     * @description Log request if production is not enabled
     * @param request
     *
     * @author Chandrika Aggarwal
     */
    ConsoleLoggerService.prototype.consoleLog = function (request, type) {
        if (type === void 0) { type = 'log'; }
        if (!environment_1.environment.production) {
            console[type](request);
        }
    };
    ConsoleLoggerService.ngInjectableDef = i0.defineInjectable({ factory: function ConsoleLoggerService_Factory() { return new ConsoleLoggerService(); }, token: ConsoleLoggerService, providedIn: "root" });
    return ConsoleLoggerService;
}());
exports.ConsoleLoggerService = ConsoleLoggerService;
