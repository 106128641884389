import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
// import filestack from "filestack-js";
// import { environment } from "@env/environment";
// const client = filestack.init(environment.FILESTACK_APIKEY);
@Injectable({
  providedIn: "root"
})
export class SharedService {
  activeTabIndex: any;
  selectedWarehouseId: any;
  constructor() {}

  loaderSource = new Subject<{ status: boolean }>();
  loaderListner = this.loaderSource.asObservable();
  showHideLoader(status: boolean) {
    this.loaderSource.next({ status: status });
  }

  // Can be removed after testing the upgraded version of filestack
  // uploadDocument() {
  //   // return client.pick({
  //   //   maxFiles: 10,
  //   //   accept: [".pdf", ".jpg", ".xls", ".csv", ".xlsx", ".png", ".jpeg"],
  //   //   uploadInBackground: false,
  //   //   startUploadingWhenMaxFilesReached: true,
  //   //   fromSources: [
  //   //     "local_file_system",
  //   //     "imagesearch",
  //   //     "googledrive",
  //   //     "dropbox"
  //   //   ],
  //   //   onOpen: () => {},
  //   //   rejectOnCancel: true,
  //   //   storeTo: {
  //   //     location: "gcs",
  //   //     path: "/wms-frontend/"
  //   //   }
  //   // });
  // }
}
