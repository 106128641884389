"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSTANTS = {
    MESSAGES: {
        START_PICKING_POPUP: "Pick-list will be generated and no more changes will be allowed. Please click OK to confirm or cancel to go back and revise the route plan. Once you click on OK, it is not possible to change the plan.",
        CANCEL_POH: "Do you want to continue ? If yes, shipments will be released from this picklist and become ready to be picked again from new orders tab.",
        UPLOAD_SUCCESS: "Image Uploaded Successfully.",
        ACCOUNT_NO_ERROR: "Please select the Account Number",
        REF_NUMBER_ERROR: "Please enter Ref Consignment Number",
        DUPLICATE_DA_ON_SIMULATE: "You have assigned two or more routes to the same Associate. This will combine the routes. Please click OK if this is what you wanted or CANCEL to reassign.",
        MARK_RECEIVE_SUCCESS: "Consignment Received Successfully",
        ADJUST_INVENTORY_SAVED: "Adjust Inventory Saved Successfully.",
        BAG_CREATION_SUCCESS: "Bag created successfully",
        BARCODE_DETAILS_NOT_FOUND: "Details not found for requested barcode",
        INVALID_SCANNED_ITEM_STATUS: "Invalid Item status of the scanned bar code",
        INVALID_SCANNED_BARCODE: "Invalid bar code scanned, please scan the correct bar code.",
        START_SUBMIT_ONCLICK: ' Please make sure all containers are scanned before submitting changes Do you want to continue ? . Please click OK to confirm or cancel to go back.'
    },
    HEADERS: {
        START_PICKING_POPUP: "Confirmation",
        START_SIMULATE_POPUP: "Confirmation",
        CONFIRMATION: "Confirmation"
    },
    ALERT_MESSAGES: {
        ADDPICKLIST_ASSOCIATE_SUCCESS: "Updated Successfully.",
        CONSIGNMENT_UPDATED_SUCCESS: "Consignment Updated Successfully",
        ADDPICKLIST_ASSOCIATE_FAILURE: "Update Unsuccessful, Please try again.",
        DELETEPICKLIST_ASSOCIATE_SUCCESS: "Delivery Associate deleted Successfully.",
        DELETEPICKLIST_ASSOCIATE_FAILURE: "Delivery Associate deletion unsuccessful, Please try again.",
        POH_UPLOAD_SUCCESS: "Document uploaded successfully.",
        POH_UPLOAD_FAILURE: "Document upload unsuccessfully, Please try again.",
        DATE_RANGE_ERROR: "Please select end date greater than start date",
        NO_INTERNET_CONNECTION: "Please Check Internet Connectivity. ",
        SOMETHING_WENT_WRONG: "Something went wrong. Please try later",
        UNKNOWN_REGEXP: "Invalid Container",
        CONSIGNMENT_NOT_FOUND: "This appears to be an extra container that does not match the consignment list. Please sideline this container on a separate pallet and continue scanning remaining containers. Someone from Amazon will reach out to resolve the issue later"
    }
};
exports.ROUTES = {
    LOGIN: "/login",
    MARK_CONSIGNMENT_COMPLETE: "/inbound/consignment-complete/list",
    INBOUND_DETAIL_PAGE: "/inbound/consignment-complete/inbound-detail",
    MARK_CONSIGNMENT_RECEIVE: "/consignment-receive",
    RECEIVE_TRANSPORT_DETAILS: "/consignment-receive/transporter-details",
    RECEIVE_DOCUMENT_DETAILS: "/consignment-receive/document-details",
    RECEIVE_MASTER_BOXES_DETAILS: "/consignment-receive/master-boxes-QC",
    RECEIVE_ATTACHMENTS: "/consignment-receive/attachments",
    RESUME_RECEIVING: "/consignment-receive/resume-receiving",
    WHOLESALE_SHIPMENT: "/wholesale/shipment",
    WHOLESALE: "/wholesale",
    INBOUND: "/inbound",
    SETTING_ASSOCIATES: "/setting/associate",
    VACATION_SETTING: "/setting/vacations",
    SETTING_TODO: "/setting",
    SETTING_WAREHOUSE: "/setting/warehouse",
    SETTING_WAREHOUSE_DETAILS: "/setting/warehouse-details",
    SETTING_OPERATING_SCHEDULE: "/setting/operating-schedule",
    SETTING_BAGS: "/setting/bags",
    SETTING_BAG_LIST: "/setting/bags/list",
    SETTING_EXPORT: "/setting/export",
    SETTING_EXPORT_CONFIG: "/setting/export/configuration",
    ADJUST_INVENTORY: "/manage-inventory",
    PICKLIST: "/picklist",
    DASHBOARD: "/dashboard",
    SHIPMENT: "/shipment",
    SEARCH_PRODUCT: "/search-product",
    COMPLETE_CONSIGNMENT_DEFAULT_PARAMS: {
        page: 1,
        perPage: 10
    },
    // Recall/Removal
    CONSIGNMENT_RECALL: "/consignment-recall",
    CONSIGNMENT_RECALL_LIST: "/consignment-recall/list",
    CONSIGNMENT_RECALL_PACK_ITEM: "/consignment-recall/pack-item",
    CONSIGNMENT_RECALL_DETAILS: "/consignment-recall/details/",
};
