"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { EdiCreateManifestsDialog } from "./create-manifests-dialog";
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var $ = require("jquery");
var services_1 = require("../../services");
var FilterbarComponent = /** @class */ (function () {
    function FilterbarComponent(dialog, router, route, cdr, alertService, service) {
        var _this = this;
        this.dialog = dialog;
        this.router = router;
        this.route = route;
        this.cdr = cdr;
        this.alertService = alertService;
        this.service = service;
        this.forEditEmit = new core_1.EventEmitter();
        this.createManifest = new core_1.EventEmitter();
        this.manifestClosed = new core_1.EventEmitter();
        this.saveShipmentClick = new core_1.EventEmitter();
        this.printManifest = new core_1.EventEmitter();
        this.printHTML = new core_1.EventEmitter();
        this.title = "";
        this.route.queryParams.subscribe(function (params) {
            _this.title = "";
            if (params["id"]) {
                _this.title = params["id"];
            }
        });
    }
    FilterbarComponent.prototype.ngOnChanges = function () {
        if (this.shipment && this.shipment.invoice_url) {
            //alert(this.shipment.invoice_url);
            $("#id-v").attr("src", this.shipment.invoice_url);
        }
    };
    FilterbarComponent.prototype.ngOnInit = function () {
        // this.openPopup();
    };
    /*---- to get updated value on the bases of @Input value Update----*/
    /**
     * click to change edit shipment
     */
    FilterbarComponent.prototype.goForEdit = function () {
        this.forEditEmit.emit(true);
    };
    /**
     * click to change from update page to previous Page
     */
    FilterbarComponent.prototype.goForClose = function () {
        this.forEditEmit.emit(false);
    };
    /**
     * Open Dialog for create new manifest
     */
    FilterbarComponent.prototype.OpenCreateManifestsDialog = function () {
        // let dialogRef = this.dialog.open(EdiCreateManifestsDialog, {
        //   width: "390px",
        //   height: "250px"
        // });
    };
    Object.defineProperty(FilterbarComponent.prototype, "getSelected", {
        //selected value for page type like shipment/manifest/Reports etc
        get: function () {
            var value = this.pageType;
            var words = true;
            if (value) {
                if (words) {
                    return value.replace(/\b\w/g, function (first) { return first.toLocaleUpperCase(); });
                }
                else {
                    return value.charAt(0).toUpperCase() + value.slice(1);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * close manifest
     * @param manifestId id
     */
    FilterbarComponent.prototype.closeManifest = function (manifestId) {
        var person = prompt("Please enter LoadID:", "MH25AV9999");
        if (person != null && person !== "" && person !== "MH25AV9999") {
            this.manifestClosed.emit({ loadId: person, manifestId: manifestId });
            //alert("Hello Yes"+person);
        }
    };
    Object.defineProperty(FilterbarComponent.prototype, "getLabelCode", {
        get: function () {
            var result = "";
            if (this.shipment &&
                this.shipment["labels"] &&
                this.shipment["labels"][0] &&
                this.shipment["labels"][0].labelName) {
                result = atob(this.shipment["labels"][0].labelName);
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    FilterbarComponent.prototype.printLabel = function (url, type) {
        if (url !== "") {
            this.service
                .printLabels("https://amzb2b.eshopbox.com" + url)
                .subscribe(function (response) {
                var blob = new Blob([response], { type: "application/pdf" });
                var blobUrl = URL.createObjectURL(blob);
                var iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.src = blobUrl;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
            });
        }
        else {
            this.alertService.showError(type + " URL is Blank!");
        }
        // this.printHTML.emit({ label: this.getLabelCode, status: status });
    };
    FilterbarComponent.prototype.printInvoice = function (url) {
        if (url !== "") {
            this.iframe.nativeElement.focus();
            this.iframe.nativeElement.contentWindow.print();
        }
        else {
            this.alertService.showError("Invoice URL is Blank!");
        }
    };
    return FilterbarComponent;
}());
exports.FilterbarComponent = FilterbarComponent;
