"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".account_setting[_ngcontent-%COMP%]{padding:0;width:100%;margin:0}.account_setting[_ngcontent-%COMP%] > h2[_ngcontent-%COMP%]{border-bottom:none;font-size:14px;font-family:lato;color:#333;padding:20px;background:#f0f0f0}.amz-form[_ngcontent-%COMP%]{max-width:780px;padding:10px 25px}.amz-form[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]{display:flex;align-items:center;margin:0 0 5px;float:none}.amz-form[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .col-left[_ngcontent-%COMP%]{width:25%;text-align:left;font-size:13px;font-family:latomedium;color:#333;letter-spacing:.5px;padding-left:15px}.amz-form[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .col-right[_ngcontent-%COMP%]{width:75%}.btnblock[_ngcontent-%COMP%]{display:flex;justify-content:flex-end;margin:20px 0}.btnblock[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{border:2px solid #000;font-family:gotham;color:#000;font-size:11px;width:100px;height:35px;line-height:30px;display:block;background:0 0;margin-left:20px;cursor:pointer;transition:all .35s ease 0s}.btnblock[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:hover{color:#fff;background:#000}.btnblock[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:disabled{opacity:.4;background:0 0;color:#000;cursor:no-drop}"];
exports.styles = styles;
