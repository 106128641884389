import { Component, OnInit, OnDestroy } from "@angular/core";
import { WholesaleService } from "../../shared/services/wholesale.service";
import { Store } from "@ngrx/store";
import { WholesaleActionTypes } from "../../wholesale/actions/wholesale.actions";
import { getProductTitles } from "../../wholesale/reducers/selectors";
import { Subscription, Observable } from "rxjs";
import { FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { SerachProductModel } from './search-product-model';
@Component({
  selector: "app-search-product",
  templateUrl: "./search-product.component.html",
  styleUrls: ["./search-product.component.scss"]
})
export class SearchProductComponent implements OnInit, OnDestroy {
  constructor(
    private wholesaleService: WholesaleService,
    private store: Store<{ wholesale }>
  ) { }

  titles = [];
  currentProduct = '';
  searchInput = new FormControl('', Validators.required);
  pickingDate = new FormControl(moment(new Date()).format('YYYY-MM-DD'), Validators.required);
  titlesSubscription$: Subscription;
  filteredOptions: Observable<string[]>;
  products = [];
  productsLoaded = false;
  submitted: boolean = false;
  searchByAsin: SerachProductModel;
  isEditRequest: boolean;
  isActionBtnShow: boolean;
  oldData: SerachProductModel;
  ngOnInit() {
    this.isEditRequest = true;
    this.isActionBtnShow = false;
    this.getTitles();
    this.filteredOptions = this.searchInput.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          if (value.length > 2) {
            return this._filter(value)
          }
        })
      );
    //  this.getAsinByUPCBarCode();
  }

  edit(searchByAsin) {
    this.oldData = Object.assign({}, searchByAsin);
    this.isEditRequest = !this.isEditRequest;
    this.isActionBtnShow = !this.isActionBtnShow;
  }
  close() {
    this.isActionBtnShow = false;
    this.isEditRequest = !this.isEditRequest;
    this.searchByAsin = this.oldData;
  }
  change(val) {
    const data = (val === 1 || val === '1') ? 'Food' : 'Non-Food';
    return data;
  }
  save(searchByAsin) {
    this.isActionBtnShow = false;
    this.isEditRequest = !this.isEditRequest;
    this.wholesaleService.changeData(searchByAsin).subscribe((res: any) => {
      if (res) {
        this.searchByAsin = res;
        this.getProductByTitle(res.productName);
      }
    });
  }


  //  getAsinByUPCBarCode(){
  //    this.wholesaleService.getAsinData(' https://www.amazondistribution.in/search?keywords=8901088066532').subscribe((res: any ) =>{
  // console.log(res);
  //    });
  //  }

  inventory(count: number, ibq: number, unitpercontainer: number) {
    let str = '';
    if (count) {
      count = Number(count)
      if (ibq === 0 || ibq === undefined || ibq === null)
        ibq = 1;
      if (unitpercontainer === 0 || unitpercontainer === undefined || unitpercontainer === null)
        unitpercontainer = 1;
      const caspack = Math.trunc((count * ibq) / unitpercontainer);
      if (caspack !== 0) str = 'CasePack :' + caspack;
      const bundle = Math.trunc((count % unitpercontainer) / ibq);
      if (bundle !== 0) str = str + ' Bundle :' + bundle;
      const loose = (count % unitpercontainer) % ibq;
      if (loose !== 0) str = str + ' Loose :' + loose;
      return str;
    }
  }

  getAttributeList(option?) {
    let serachInputValue;
    if (option && option !== null && option !== undefined) {
      serachInputValue = option;
    } else {
      serachInputValue = this.searchInput.value;
    }
    this.wholesaleService.getAttributesListing(serachInputValue).subscribe((res: any) => {
      if (res) {
        this.searchByAsin = res;
        this.searchInput.setValue('');
        this.getProductByTitle(res.productName);
      }
    });
  }

  getProductByTitle(option) {
    this.submitted = true;
    this.currentProduct = option
    if (this.pickingDate.valid && this.pickingDate.valid) {
      const params = {
        title: this.currentProduct,
        date: moment(this.pickingDate.value).format('YYYY-MM-DD')
      };
      this.wholesaleService.getProductsByTitle(params).subscribe((res: any) => {
        this.productsLoaded = true;
        if (res) {
          this.products = res;
        }
      });
    }
  }

  getTitles() {
    this.titlesSubscription$ = this.store
      .select(getProductTitles)
      .subscribe(result => {
        if (result) {
          if (result.length > 0) {
            this.titles = result;
            return;
          }
        }
        this.store.dispatch({ type: WholesaleActionTypes.FETCH_TITLES });
      });
  }

  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();
    const titles = this.titles.filter((option) => {
      if (option) {
        return option.toLowerCase().indexOf(filterValue) === 0
      }
    });
    return titles;
  }

  trackByFn(index) {
    return index;
  }

  ngOnDestroy() {
    if (this.titlesSubscription$) {
      this.titlesSubscription$.unsubscribe();
    }
  }
}

