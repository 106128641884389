"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var picklists_component_1 = require("./components/picklists/picklists.component");
var login_layout_component_1 = require("./components/_layouts/login-layout/login-layout.component");
var home_layout_component_1 = require("./components/_layouts/home-layout/home-layout.component");
var consign_receive_layout_component_1 = require("./components/_layouts/consign-receive-layout/consign-receive-layout.component");
var amazon_wholesale_component_1 = require("./components/amazon-wholesale/amazon-wholesale.component");
var search_product_component_1 = require("./components/search-product/search-product.component");
var pack_item_component_1 = require("./consignment-recall/components/pack-item/pack-item.component");
var recall_pack_item_layout_component_1 = require("./components/_layouts/recall-pack-item-layout/recall-pack-item-layout.component");
var ɵ0 = { title: 'Settings  | WMS' }, ɵ1 = { title: 'Search Product  | WMS' }, ɵ2 = { title: 'Recall  | WMS' };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var routes = [
    {
        path: "",
        component: login_layout_component_1.LoginLayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./auth/auth.module#AuthModule"
            }
        ]
    },
    {
        path: "",
        component: home_layout_component_1.HomeLayoutComponent,
        children: [
            {
                path: "dashboard",
                loadChildren: "./dashboard/index#DashboardModule"
            },
            {
                path: "",
                redirectTo: "wholesale",
                pathMatch: "full"
            },
            {
                path: "wholesale",
                loadChildren: "./wholesale/index#WholesaleModule"
            },
            {
                path: "inbound",
                loadChildren: "./inbound/index#InboundModule"
            },
            {
                path: "manage-inventory",
                component: amazon_wholesale_component_1.AmazonWholesaleComponent
            },
            {
                path: "picklist",
                component: picklists_component_1.PicklistsComponent
            },
            {
                path: "shipment",
                loadChildren: "./shipment/index#ShipmentModule"
            },
            {
                path: "user",
                loadChildren: "./user/index#UserModule"
            },
            {
                path: 'setting',
                loadChildren: './setting/setting.module#SettingModule',
                data: ɵ0
            },
            {
                path: "search-product",
                component: search_product_component_1.SearchProductComponent,
                data: ɵ1
            },
            {
                path: "consignment-recall",
                // component: RecallListComponent,
                loadChildren: './consignment-recall/consignment-recall.module#ConsignmentRecallModule',
                data: ɵ2
            },
        ]
    },
    {
        path: "",
        component: consign_receive_layout_component_1.ConsignReceiveLayoutComponent,
        children: [
            {
                path: "consignment-receive",
                loadChildren: "./consignment-receive/index#ConsignmentReceiveModule"
            }
        ]
    },
    {
        path: "",
        component: recall_pack_item_layout_component_1.RecallPackItemLayoutComponent,
        children: [
            {
                path: "consignment-recall/pack-item",
                component: pack_item_component_1.PackItemComponent
            }
        ]
    },
    { path: "**", redirectTo: "" }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
