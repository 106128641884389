"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./print-layout.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("./print-layout.component");
var styles_PrintLayoutComponent = [i0.styles];
var RenderType_PrintLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintLayoutComponent, data: {} });
exports.RenderType_PrintLayoutComponent = RenderType_PrintLayoutComponent;
function View_PrintLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "header-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "tfoot", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "footer-space"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 10, 0); }, null); }
exports.View_PrintLayoutComponent_0 = View_PrintLayoutComponent_0;
function View_PrintLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-layout", [], null, null, null, View_PrintLayoutComponent_0, RenderType_PrintLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.PrintLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PrintLayoutComponent_Host_0 = View_PrintLayoutComponent_Host_0;
var PrintLayoutComponentNgFactory = i1.ɵccf("app-print-layout", i3.PrintLayoutComponent, View_PrintLayoutComponent_Host_0, {}, {}, []);
exports.PrintLayoutComponentNgFactory = PrintLayoutComponentNgFactory;
