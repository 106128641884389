<main class="right-container">
  <div class="main-container">
    <div class="account_setting">
      <h2>Adjust Inventory</h2>
      <form #inventoryForm="ngForm" class="amz-form" [formGroup]="adjustmentForm">
        <div class="row">
          <div class="col-left">
            <span>ASIN</span>
          </div>
          <div class="col-right">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="ASIN" formControlName="sku">
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-left">
            <span>Adjustment Type</span>
          </div>
          <div class="col-right">
            <mat-form-field>
              <mat-label>Adjustment Type</mat-label>
              <mat-select formControlName="adjustmentType" [(value)]="selected">
                <mat-option *ngFor="let adjustType of adjustTypes" [value]="adjustType.value">{{adjustType.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="row" *ngIf="selected == 'LOST'">
          <div class="col-left">
            <span>Source Inventory Type</span>
          </div>
          <div class="col-right">
            <mat-form-field>
              <mat-label>Source Inventory Type</mat-label>
              <mat-select formControlName="sourceInventoryType">
                <mat-option value="PRIME">PRIME</mat-option>
                <mat-option value="VENDOR-DAMAGE">VENDOR-DAMAGE</mat-option>
                <mat-option value="CARRIER-DAMAGE">CARRIER-DAMAGE</mat-option>
                <mat-option value="WAREHOUSE-DAMAGE">WAREHOUSE-DAMAGE</mat-option>
                <mat-option value="DEFECTIVE">DEFECTIVE</mat-option>
                <mat-option value="EXPIRED">EXPIRED</mat-option>
                <mat-option value="CUSTOMER-RETURN">CUSTOMER-RETURN</mat-option>
                <mat-option value="CUSTOMER-RETURN-DAMAGE">CUSTOMER-RETURN-DAMAGE</mat-option>


              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selected == 'STATUS-CHANGE' ">
          <div class="col-left">
            <span>Source Inventory Type</span>
          </div>
          <div class="col-right">
            <mat-form-field>
              <mat-label>Source Inventory Type</mat-label>
              <mat-select formControlName="sourceInventoryType">
                <mat-option value="PRIME">PRIME</mat-option>
                <mat-option value="VENDOR-DAMAGE">VENDOR-DAMAGE</mat-option>
                <mat-option value="CARRIER-DAMAGE">CARRIER-DAMAGE</mat-option>
                <mat-option value="WAREHOUSE-DAMAGE">WAREHOUSE-DAMAGE</mat-option>
                <mat-option value="DEFECTIVE">DEFECTIVE</mat-option>
                <mat-option value="EXPIRED">EXPIRED</mat-option>
                <mat-option value="CUSTOMER-RETURN">CUSTOMER-RETURN</mat-option>
                <mat-option value="CUSTOMER-RETURN-DAMAGE">CUSTOMER-RETURN-DAMAGE</mat-option>


              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selected == 'STATUS-CHANGE' ">
          <div class="col-left">
            <span>Destination Inventory Type</span>
          </div>
          <div class="col-right">
            <mat-form-field>
              <mat-label>Destination Inventory Type</mat-label>
              <mat-select formControlName="destinationInventoryType">
                <mat-option value="PRIME">PRIME</mat-option>
                <mat-option value="VENDOR-DAMAGE">VENDOR-DAMAGE</mat-option>
                <mat-option value="CARRIER-DAMAGE">CARRIER-DAMAGE</mat-option>
                <mat-option value="WAREHOUSE-DAMAGE">WAREHOUSE-DAMAGE</mat-option>
                <mat-option value="DEFECTIVE">DEFECTIVE</mat-option>
                <mat-option value="EXPIRED">EXPIRED</mat-option>
                <mat-option value="CUSTOMER-RETURN">CUSTOMER-RETURN</mat-option>
                <mat-option value="CUSTOMER-RETURN-DAMAGE">CUSTOMER-RETURN-DAMAGE</mat-option>


              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selected == 'FOUND'">
          <div class="col-left">
            <span>Destination Inventory Type</span>
          </div>
          <div class="col-right">
            <mat-form-field>
              <mat-label>Destination Inventory Type</mat-label>
              <mat-select formControlName="destinationInventoryType">
                <mat-option value="PRIME">PRIME</mat-option>
                <mat-option value="VENDOR-DAMAGE">VENDOR-DAMAGE</mat-option>
                <mat-option value="CARRIER-DAMAGE">CARRIER-DAMAGE</mat-option>
                <mat-option value="WAREHOUSE-DAMAGE">WAREHOUSE-DAMAGE</mat-option>
                <mat-option value="DEFECTIVE">DEFECTIVE</mat-option>
                <mat-option value="EXPIRED">EXPIRED</mat-option>
                <mat-option value="CUSTOMER-RETURN">CUSTOMER-RETURN</mat-option>
                <mat-option value="CUSTOMER-RETURN-DAMAGE">CUSTOMER-RETURN-DAMAGE</mat-option>


              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-left">
            <span>Quantity</span>
          </div>
          <div class="col-right">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Quantity" value="" formControlName="quantity">
            </mat-form-field>
          </div>
        </div>

        <div class="btnblock">
          <button type="button" value="RESET" class="resetbtn" (click)="inventoryForm.reset();">RESET</button>
          <button type="button" value="SUBMIT" class="submit" [disabled]="!submitButtonStatus"
            (click)="submitAdjustInventory()">SUBMIT</button>
        </div>
      </form>

    </div>
  </div>
</main>
