"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".picklist-top-pane[_ngcontent-%COMP%]{padding-top:1px}.picklist-consignment[_ngcontent-%COMP%]{border-right:1px solid #000;text-align:center}.invoice-qty[_ngcontent-%COMP%]{padding-left:5px;border-right:1px solid #000;text-align:center}.picklist-timing[_ngcontent-%COMP%], .recall-sheet-data[_ngcontent-%COMP%]{text-align:center}"];
exports.styles = styles;
