"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["LOGIN"] = "[Login Page] Login";
    AuthActionTypes["LOGIN_COMPLETE"] = "[Callback Page] Login Complete";
    AuthActionTypes["LOGIN_SUCCESS"] = "[Auth API] Login Success";
    AuthActionTypes["LOGIN_FAILED"] = "[Auth API] Login Failure";
    AuthActionTypes["CHECK_LOGIN"] = "[Auth] Check Login";
    AuthActionTypes["LOGOUT"] = "[Auth] Confirm Logout";
    AuthActionTypes["SET_SEARCH_FILTER"] = "[Header Search] Sets filter value";
    AuthActionTypes["GET_CURRENT_USER"] = "[Create User] GET_CURRENT_USER";
    // LogoutCancelled = '[Auth] Logout Cancelled',
    // LogoutConfirmed = '[Auth] Logout Confirmed'
})(AuthActionTypes = exports.AuthActionTypes || (exports.AuthActionTypes = {}));
var Login = /** @class */ (function () {
    function Login() {
        this.type = AuthActionTypes.LOGIN;
    }
    return Login;
}());
exports.Login = Login;
var LoginComplete = /** @class */ (function () {
    function LoginComplete() {
        this.type = AuthActionTypes.LOGIN_COMPLETE;
    }
    return LoginComplete;
}());
exports.LoginComplete = LoginComplete;
var LoginSuccess = /** @class */ (function () {
    function LoginSuccess() {
        this.type = AuthActionTypes.LOGIN_SUCCESS;
    }
    return LoginSuccess;
}());
exports.LoginSuccess = LoginSuccess;
var LoginFailure = /** @class */ (function () {
    function LoginFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LOGIN_FAILED;
    }
    return LoginFailure;
}());
exports.LoginFailure = LoginFailure;
var CheckLogin = /** @class */ (function () {
    function CheckLogin() {
        this.type = AuthActionTypes.CHECK_LOGIN;
    }
    return CheckLogin;
}());
exports.CheckLogin = CheckLogin;
var Logout = /** @class */ (function () {
    function Logout() {
        this.type = AuthActionTypes.LOGOUT;
    }
    return Logout;
}());
exports.Logout = Logout;
var GetCurrentUser = /** @class */ (function () {
    function GetCurrentUser() {
        this.type = AuthActionTypes.GET_CURRENT_USER;
    }
    return GetCurrentUser;
}());
exports.GetCurrentUser = GetCurrentUser;
var SetFilter = /** @class */ (function () {
    function SetFilter(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.SET_SEARCH_FILTER;
    }
    return SetFilter;
}());
exports.SetFilter = SetFilter;
// | LogoutCancelled
// | LogoutConfirmed;
