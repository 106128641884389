"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("../../environments/environment");
var store_1 = require("@ngrx/store");
var services_1 = require("../shared/services");
var selectors_1 = require("../wholesale/reducers/selectors");
var console_logger_service_1 = require("../shared/services/console-logger.service");
var config_1 = require("../shared/configuration/config");
var i0 = require("@angular/core");
var i1 = require("../shared/services/api.service");
var i2 = require("@ngrx/store");
var i3 = require("../shared/services/console-logger.service");
var i4 = require("../shared/configuration/config");
var BagService = /** @class */ (function () {
    function BagService(apiService, store, loggerService, configurations) {
        this.apiService = apiService;
        this.store = store;
        this.loggerService = loggerService;
        this.configurations = configurations;
        this.API_URL = environment_1.environment.API_URL;
        this.wareHouseId = null;
        this.getWarehouseId();
    }
    /**
     * @description Service to get warehouse id
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.getWarehouseId = function () {
        var _this = this;
        this.store.pipe(store_1.select(selectors_1.getAccountIdStatus)).subscribe(function (result) {
            _this.loggerService.consoleLog(result);
            if (result) {
                _this.wareHouseId = result;
                return _this.wareHouseId;
            }
        });
    };
    /**
     * @description Service to get bag list
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.getBagListService = function (requestBody) {
        var apiUrl = this.configurations.UrlObj.getBagList;
        return this.apiService.get(apiUrl, { params: requestBody });
    };
    /**
     * @description Service to create bag
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.createBagService = function (requestBody) {
        var apiUrl = this.configurations.UrlObj.createBag;
        return this.apiService.post(apiUrl, requestBody);
    };
    /**
     * @description Service to update bag
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.updateBagService = function (requestBody) {
        var apiUrl = this.configurations.UrlObj.updateBag + requestBody["bagID"];
        return this.apiService.put(apiUrl, { status: requestBody["status"] });
    };
    /**
     * @description Service to get bag item details
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.getBagItemDetailsService = function (requestBody) {
        var params = tslib_1.__assign(tslib_1.__assign({}, requestBody), { page: 1, perPage: 1000 });
        var apiUrl = this.configurations.UrlObj.getBagItemDetails + requestBody["bagID"];
        return this.apiService.get(apiUrl, { params: params });
    };
    /**
     * @description Service to get single item details
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.getSingleItemDetailsService = function (requestBody) {
        this.loggerService.consoleLog("get single item service");
        var apiUrl = this.configurations.UrlObj.getSingleItemDetails + requestBody["barcode"];
        return this.apiService.get(apiUrl, { params: requestBody });
    };
    /**
     * @description Service to add item into bag
     *
     * @author Chandrika Aggarwal
     */
    BagService.prototype.addItemIntoBagService = function (requestBody) {
        this.loggerService.consoleLog("add item into bag service");
        var apiUrl = this.configurations.UrlObj.addItemIntoBag + requestBody["bagId"];
        return this.apiService.post(apiUrl, { barcode: requestBody['barcode'] });
    };
    BagService.ngInjectableDef = i0.defineInjectable({ factory: function BagService_Factory() { return new BagService(i0.inject(i1.ApiService), i0.inject(i2.Store), i0.inject(i3.ConsoleLoggerService), i0.inject(i4.Configuration)); }, token: BagService, providedIn: "root" });
    return BagService;
}());
exports.BagService = BagService;
