import { DashboardState } from "./dashboard.state";
import * as DashboardActions from "../actions/dashboard.actions";

export const initialState: DashboardState = {
  entities: {},
  ids: [],
  dashboard_loading: false,
  label_loading: false,

  removalTODOList: null,
  removalTODOListError: null,
  removalTODOListLoading: false,
  removalTODOListLoaded: false
};

export function DashboardReducer(
  state = initialState,
  action: DashboardActions.DashboardUnion
): DashboardState {
  switch (action.type) {
    case DashboardActions.DashboardActionTypes.LOAD_DASHBOARD: {
      return {
        ...state,
        dashboard_loading: true
      };
    }
    case DashboardActions.DashboardActionTypes.LOAD_DASHBOARD_SUCCESS: {
      const orders = action.payload["data"];
      const expData = {};
      const ids = [];
      orders.map(order => {
        ids.push(order.customerOrderNumber);
        expData[order.customerOrderNumber] = order;
        return expData;
      });
      return {
        ...state,
        ids: ids,
        entities: Object.assign({}, state.entities, expData),
        label_loading: false,
        dashboard_loading: false
      };
    }

    /**
     * Removal
     */
    case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST: {
      return {
        ...state,
        removalTODOList: null,
        removalTODOListError: null,
        removalTODOListLoading: true,
        removalTODOListLoaded: false
      }
    }
    case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST_SUCCESS: {
      return {
        ...state,
        removalTODOList: action.payload,
        removalTODOListError: null,
        removalTODOListLoading: false,
        removalTODOListLoaded: true
      }
    }
    case DashboardActions.DashboardActionTypes.REMOVAL_TODO_LIST_FAILED: {
      return {
        ...state,
        removalTODOList: null,
        removalTODOListError: action.payload,
        removalTODOListLoading: false,
        removalTODOListLoaded: true
      }
    }

    default: {
      return state;
    }
  }
}
