import * as BagAction from './bag.action';
import { BagState } from './bag.state';

export const initialState: BagState = {
    bagList: null,
    loadingBagList: false,
    loadedBagList: false,
    bagListError: null,

    bagListForPDF: null,
    loadingBagListForPDF: false,
    loadedBagListForPDF: false,
    bagListErrorForPDF: null,

    createBag: null,
    creatingBag: false,
    createdBag: false,
    createBagError: null,

    updateBag: null,
    updatingBag: false,
    updatedBag: false,
    updateBagError: null,

    bagItemDetails: null,
    loadingBagItemDetails: false,
    loadedBagItemDetails: false,
    bagItemDetailsError: null,

    singleItemDetails: null,
    loadingSingleItemDetails: false,
    loadedSingleItemDetails: false,
    singleItemDetailsError: null,

    addItem: null,
    addingItem: false,
    addedItem: false,
    addItemError: null
}

export function reducer(state: BagState = initialState, action: any) {
    switch (action.type) {

        // --------------------------------------------------------
        case BagAction.GET_BAG_LIST:
            return {
                ...state,
                bagList: null,
                loadingBagList: true,
                loadedBagList: false,
                bagListError: null
            };
            break;
        case BagAction.GET_BAG_LIST_SUCCESS:
            return {
                ...state,
                bagList: action.payload,
                loadingBagList: false,
                loadedBagList: true,
                bagListError: null
            };
            break;
        case BagAction.GET_BAG_LIST_FAILURE:
            return {
                ...state,
                bagList: null,
                loadingBagList: false,
                loadedBagList: true,
                bagListError: action.payload
            };
            break;

            // --------------------------------------------------------
        case BagAction.GET_BAG_LIST_FOR_PDF:
            return {
                ...state,
                bagListForPDF: null,
                loadingBagListForPDF: true,
                loadedBagListForPDF: false,
                bagListErrorForPDF: null
            };
            break;
        case BagAction.GET_BAG_LIST_FOR_PDF_SUCCESS:
            return {
                ...state,
                bagListForPDF: action.payload,
                loadingBagListForPDF: false,
                loadedBagListForPDF: true,
                bagListErrorForPDF: null
            };
            break;
        case BagAction.GET_BAG_LIST_FOR_PDF_FAILURE:
            return {
                ...state,
                bagListForPDF: null,
                loadingBagListForPDF: false,
                loadedBagListForPDF: true,
                bagListErrorForPDF: action.payload
            };
            break;

        // --------------------------------------------------------
        case BagAction.CREATE_BAG:
            return {
                ...state,
                createBag: null,
                creatingBag: true,
                createdBag: false,
                createBagError: null
            };
            break;
        case BagAction.CREATE_BAG_SUCCESS:
            return {
                ...state,
                createBag: action.payload,
                creatingBag: false,
                createdBag: true,
                createBagError: null
            };
            break;
        case BagAction.CREATE_BAG_FAILURE:
            return {
                ...state,
                createBag: null,
                creatingBag: false,
                createdBag: true,
                createBagError: action.payload
            };
            break;

        // --------------------------------------------------------
        case BagAction.UPDATE_BAG:
            return {
                ...state,
                updateBag: null,
                updatingBag: true,
                updatedBag: false,
                updateBagError: null
            };
            break;
        case BagAction.UPDATE_BAG_SUCCESS:
            return {
                ...state,
                updateBag: action.payload,
                updatingBag: false,
                updatedBag: true,
                updateBagError: null
            };
            break;
        case BagAction.UPDATE_BAG_FAILURE:
            return {
                ...state,
                updateBag: null,
                updatingBag: false,
                updatedBag: true,
                updateBagError: action.payload
            };
            break;

        // --------------------------------------------------------
        case BagAction.GET_BAG_ITEM_DETAILS:
            return {
                ...state,
                bagItemDetails: null,
                loadingBagItemDetails: true,
                loadedBagItemDetails: false,
                bagItemDetailsError: null,
            };
            break;
        case BagAction.GET_BAG_ITEM_DETAILS_SUCCESS:
            return {
                ...state,
                bagItemDetails: action.payload,
                loadingBagItemDetails: false,
                loadedBagItemDetails: true,
                bagItemDetailsError: null,
            };
            break;
        case BagAction.GET_BAG_ITEM_DETAILS_FAILURE:
            return {
                ...state,
                bagItemDetails: null,
                loadingBagItemDetails: false,
                loadedBagItemDetails: true,
                bagItemDetailsError: action.payload,
            };
            break;

        // --------------------------------------------------------
        case BagAction.GET_SINGLE_ITEM_DETAILS:
            return {
                ...state,
                singleItemDetails: null,
                loadingSingleItemDetails: true,
                loadedSingleItemDetails: false,
                singleItemDetailsError: null,
            };
            break;
        case BagAction.GET_SINGLE_ITEM_DETAILS_SUCCESS:
            return {
                ...state,
                singleItemDetails: action.payload,
                loadingSingleItemDetails: false,
                loadedSingleItemDetails: true,
                singleItemDetailsError: null,
            };
            break;
        case BagAction.GET_SINGLE_ITEM_DETAILS_FAILURE:
            return {
                ...state,
                singleItemDetails: null,
                loadingSingleItemDetails: false,
                loadedSingleItemDetails: true,
                singleItemDetailsError: action.payload,
            };
            break;

        // --------------------------------------------------------
        case BagAction.ADD_ITEM:
            return {
                ...state,
                addItem: null,
                addingItem: true,
                addedItem: false,
                addItemError: null
            };
            break;
        case BagAction.ADD_ITEM_SUCCESS:
            return {
                ...state,
                addItem: action.payload,
                addingItem: false,
                addedItem: true,
                addItemError: null
            };
            break;
        case BagAction.ADD_ITEM_FAILURE:
            return {
                ...state,
                addItem: null,
                addingItem: false,
                addedItem: true,
                addItemError: action.payload
            };
            break;

        default:
            return state;
    }
}

export const bagList = (state: BagState = initialState) => state.bagList;
export const loadingBagList = (state: BagState = initialState) => state.loadingBagList;
export const loadedBagList = (state: BagState = initialState) => state.loadedBagList;
export const bagListError = (state: BagState = initialState) => state.bagListError;

export const bagListForPDF = (state: BagState = initialState) => state.bagListForPDF;
export const loadingBagListForPDF = (state: BagState = initialState) => state.loadingBagListForPDF;
export const loadedBagListForPDF = (state: BagState = initialState) => state.loadedBagListForPDF;
export const bagListErrorForPDF = (state: BagState = initialState) => state.bagListErrorForPDF;

export const createBag = (state: BagState = initialState) => state.createBag;
export const creatingBag = (state: BagState = initialState) => state.creatingBag;
export const createdBag = (state: BagState = initialState) => state.createdBag;
export const createBagError = (state: BagState = initialState) => state.createBagError;

export const updateBag = (state: BagState = initialState) => state.updateBag;
export const updatingBag = (state: BagState = initialState) => state.updatingBag;
export const updatedBag = (state: BagState = initialState) => state.updatedBag;
export const updateBagError = (state: BagState = initialState) => state.updateBagError;

export const bagItemDetails = (state: BagState = initialState) => state.bagItemDetails;
export const loadingBagItemDetails = (state: BagState = initialState) => state.loadingBagItemDetails;
export const loadedBagItemDetails = (state: BagState = initialState) => state.loadedBagItemDetails;
export const bagItemDetailsError = (state: BagState = initialState) => state.bagItemDetailsError;

export const singleItemDetails = (state: BagState = initialState) => state.singleItemDetails;
export const loadingSingleItemDetails = (state: BagState = initialState) => state.loadingSingleItemDetails;
export const loadedSingleItemDetails = (state: BagState = initialState) => state.loadedSingleItemDetails;
export const singleItemDetailsError = (state: BagState = initialState) => state.singleItemDetailsError;

export const addItem = (state: BagState = initialState) => state.addItem;
export const addingItem = (state: BagState = initialState) => state.addingItem;
export const addedItem = (state: BagState = initialState) => state.addedItem;
export const addItemError = (state: BagState = initialState) => state.addItemError;
