import { Component, OnInit } from '@angular/core';
import { PrintService, WholesaleService } from '../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trip-sheet',
  templateUrl: './trip-sheet.component.html',
  styleUrls: ['./trip-sheet.component.sass']
})
export class TripSheetComponent implements OnInit {
  picklistCode;
  tripSheetDetail: any;
  constructor(private printService: PrintService, private route: ActivatedRoute, private service: WholesaleService) { }

  ngOnInit() {
    this.getTripSheetDetail();
  }
  getTripSheetDetail() {
    this.picklistCode = this.route.snapshot.params["picklistId"];
    console.log(this.picklistCode);
    this.service.getTripSheetDetail(this.picklistCode).subscribe((res) => {
      this.tripSheetDetail = res;

      if (this.tripSheetDetail) {
        this.printService.onDataReady();
      }
    })

  }
}
