import { ShipmentState } from "./shipment.state";
import { OrderState } from "./order.state";
import * as fromShipment from "./shipment.reducer";
import * as fromOrder from "./order.reducer";
import { AppState } from "@app/app.state";

export interface ShipmentModuleState extends AppState {
  shipment: ShipmentState;
  order: OrderState;
}

export const initialState = {
  shipment: fromShipment.initialState,
  order: fromOrder.initialState
};

export const reducers = {
  shipment: fromShipment.ShipmentReducer,
  order: fromOrder.OrderReducer
};
