"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".footer[_ngcontent-%COMP%], .footer-space[_ngcontent-%COMP%], .header[_ngcontent-%COMP%], .header-space[_ngcontent-%COMP%]{height:100px}.header[_ngcontent-%COMP%]{position:fixed;top:0}.footer[_ngcontent-%COMP%]{position:fixed;bottom:0}@media screen{[_nghost-%COMP%]{display:none}}\n/*# sourceMappingURL=print-layout.component.css.map*/"];
exports.styles = styles;
