"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BRAND_LIST = '[BRAND] Get Brand List';
exports.GET_BRAND_LIST_SUCCESS = '[BRAND] Get Brand List Success';
exports.GET_BRAND_LIST_FAILURE = '[BRAND] Get Brand List Failure';
var GetBrandList = /** @class */ (function () {
    function GetBrandList(payload) {
        this.payload = payload;
        this.type = exports.GET_BRAND_LIST;
    }
    return GetBrandList;
}());
exports.GetBrandList = GetBrandList;
var GetBrandListSuccess = /** @class */ (function () {
    function GetBrandListSuccess(payload) {
        this.payload = payload;
        this.type = exports.GET_BRAND_LIST_SUCCESS;
    }
    return GetBrandListSuccess;
}());
exports.GetBrandListSuccess = GetBrandListSuccess;
var GetBrandListFailure = /** @class */ (function () {
    function GetBrandListFailure(payload) {
        this.payload = payload;
        this.type = exports.GET_BRAND_LIST_FAILURE;
    }
    return GetBrandListFailure;
}());
exports.GetBrandListFailure = GetBrandListFailure;
