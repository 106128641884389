"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var Reducer = require("./consignment-recall.reducer");
var getConsignmentRecallState = function (state) { return state.consignmentRecallState; };
var ɵ0 = getConsignmentRecallState;
exports.ɵ0 = ɵ0;
// Get consignment list
exports.getConsignmentListSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getConsignmentList);
exports.getConsignmentListErrorSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getConsignmentListError);
exports.getConsignmentListLoadingSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getConsignmentListLoading);
exports.getConsignmentListLoadedSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getConsignmentListLoaded);
// Update consignment
exports.updateConsignmentSelector = store_1.createSelector(getConsignmentRecallState, Reducer.updateConsignment);
exports.updateConsignmentErrorSelector = store_1.createSelector(getConsignmentRecallState, Reducer.updateConsignmentError);
exports.updatingConsignmentSelector = store_1.createSelector(getConsignmentRecallState, Reducer.updatingConsignment);
exports.updatedConsignmentSelector = store_1.createSelector(getConsignmentRecallState, Reducer.updatedConsignment);
// Get GRN item list
exports.getGRNItemListSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getGRNItemList);
exports.getGRNItemListErrorSelector = store_1.createSelector(getConsignmentRecallState, Reducer.getGRNItemListError);
exports.getGRNItemListLoadingSelector = store_1.createSelector(getConsignmentRecallState, Reducer.loadingGRNItemList);
exports.getGRNItemListLoadedSelector = store_1.createSelector(getConsignmentRecallState, Reducer.loadedGRNItemList);
