"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var print_service_1 = require("../../services/print.service");
var store_1 = require("@ngrx/store");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var moment = require("moment");
var selectors_1 = require("../../../wholesale/reducers/selectors");
var InvoiceComponent = /** @class */ (function () {
    function InvoiceComponent(route, printService, service, router, store) {
        this.route = route;
        this.printService = printService;
        this.service = service;
        this.router = router;
        this.store = store;
        this.Object = Object;
        this.totalCases = 0;
        this.totalLoose = 0;
        this.totalBundles = 0;
        this.Cases = 0;
        this.Loose = 0;
        this.bundles = 0;
        this.nonfoodFilteredList = [];
        this.warehouseFromStore = null;
        this.WBLT = false;
    }
    InvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.picklistId = this.route.snapshot.params["picklistId"];
        this.getPickListAssociate();
        if (this.picklistId !== undefined) {
            this.getPicklistDetails(this.picklistId);
        }
        else {
            this.consolidatedPicklist();
        }
        this.getAccountIdStatus$ = this.store
            .pipe(store_1.select(selectors_1.getAccountIdStatus))
            .subscribe(function (result) {
            if (result) {
                _this.warehouseFromStore = result;
                if (_this.warehouseFromStore.toUpperCase() == "WBLT") {
                    _this.WBLT = true;
                }
            }
        });
    };
    InvoiceComponent.prototype.consolidatedPicklist = function () {
        var _this = this;
        this.getConsolidatedpickList$ = this.store
            .pipe(store_1.select(selectors_1.getConsolidatedStatus))
            .subscribe(function (result) {
            _this.invoiceDetails = result;
            if (_this.warehouseFromStore &&
                _this.warehouseFromStore.toUpperCase() == "WBLT") {
                _this.foodFilteredList = _this.invoiceDetails.customerRecords;
                _this.uniqueRoutes = Object.keys(_this.invoiceDetails.customerRecords).length;
            }
            else {
                _this.invoiceDetails["items"].sort(function (a, b) {
                    return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
                });
                _this.foodFilteredList = _this.invoiceDetails.items.filter(function (resp) {
                    return resp.isFood === "1";
                });
                _this.nonfoodFilteredList = _this.invoiceDetails.items.filter(function (response) {
                    return response.isFood !== "1";
                });
                _this.calculateTotalRows(_this.invoiceDetails.items);
            }
            if (_this.invoiceDetails) {
                _this.printService.onDataReady();
            }
        });
    };
    InvoiceComponent.prototype.getPicklistDetails = function (id) {
        var _this = this;
        this.getPicklistById$ = this.service.getPicklistById(id).subscribe(function (res) {
            if (res) {
                _this.invoiceDetails = res;
                if (_this.invoiceDetails) {
                    // if(this.warehouseFromStore) {
                    _this.invoiceDetails.date = moment(res["pickingDate"]).format("DD-MMM-YYYY, h:mm:ss A (dddd)");
                    var associates = _this.service.getAssociateName(_this.associatePicklist, _this.invoiceDetails.picklistAssociate);
                    if (associates.length > 0) {
                        _this.invoiceDetails.associate = associates[0]["name"];
                    }
                    _this.foodFilteredList = _this.invoiceDetails.customerRecords;
                    _this.uniqueRoutes = Object.keys(_this.invoiceDetails.customerRecords).length;
                    // }
                    // else{
                    if (_this.invoiceDetails["items"]) {
                        _this.invoiceDetails["items"].sort(function (a, b) {
                            return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
                        });
                    }
                    _this.invoiceDetails.date = moment(res["pickingDate"]).format("DD-MMM-YYYY, h:mm:ss A (dddd)");
                    // const associates = this.service.getAssociateName(
                    //   this.associatePicklist,
                    //   this.invoiceDetails.picklistAssociate
                    // );
                    if (associates.length > 0) {
                        _this.invoiceDetails.associate = associates[0]["name"];
                    }
                    if (_this.invoiceDetails.items) {
                        _this.foodFilteredList = _this.invoiceDetails.items.filter(function (resp) {
                            return resp.isFood === "1";
                        });
                        _this.nonfoodFilteredList = _this.invoiceDetails.items.filter(function (response) {
                            return response.isFood !== "1";
                        });
                        _this.calculateTotalRows(_this.invoiceDetails.items);
                    }
                    // }
                    _this.printService.onDataReady();
                }
            }
            else {
                _this.router.navigate([{ outlets: { print: null } }]);
            }
            // return new Promise(resolve => setTimeout(() => resolve({ res }), 1000));
        });
    };
    InvoiceComponent.prototype.getPickListAssociate = function () {
        var _this = this;
        this.pickListAssociatesList$ = this.store
            .pipe(store_1.select(selectors_1.getCurrentDeliveryAssociates))
            .subscribe(function (result) {
            _this.associatePicklist = result;
        });
    };
    InvoiceComponent.prototype.calculateTotalRows = function (items) {
        var _this = this;
        items.map(function (item) {
            if (item.isFood === "1") {
                _this.totalCases += item.cases;
                _this.totalLoose += item.loose;
                _this.totalBundles += item.bundles;
            }
            if (item.isFood !== "1") {
                _this.Cases += item.cases;
                _this.Loose += item.loose;
                _this.bundles += item.bundles;
            }
        });
        if (this.picklistId === undefined) {
            var array = this.invoiceDetails.picklists;
            this.uniqueRoutes = Array.from(new Set(array.map(function (x) { return x.routeID; }))).length;
        }
    };
    InvoiceComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    InvoiceComponent.prototype.ngOnDestroy = function () {
        if (this.pickListAssociatesList$) {
            this.pickListAssociatesList$.unsubscribe();
        }
        if (this.getConsolidatedpickList$) {
            this.getConsolidatedpickList$.unsubscribe();
        }
        if (this.getPicklistById$) {
            this.getPicklistById$.unsubscribe();
        }
    };
    InvoiceComponent.prototype.priceRoundOff = function (price) {
        return Number(price.toFixed(1));
    };
    return InvoiceComponent;
}());
exports.InvoiceComponent = InvoiceComponent;
