"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var api_service_1 = require("./api.service");
var environment_1 = require("@env/environment");
var config_1 = require("../configuration/config");
var selectors_1 = require("@app/wholesale/reducers/selectors");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var i3 = require("@ngrx/store");
var WholesaleService = /** @class */ (function () {
    function WholesaleService(api, config, store) {
        this.api = api;
        this.config = config;
        this.store = store;
        this.API_URL = environment_1.environment.API_URL;
        this.wareHouseId = null;
        this.getWarehouseId();
    }
    WholesaleService.prototype.getRouteSummary = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.API_URL + "routeSummary", params);
    };
    WholesaleService.prototype.getCompleteConsignmentList = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.API_URL + "routeSummary", params);
    };
    WholesaleService.prototype.getDeliveryAssociates = function () {
        return this.api.get(this.API_URL + "picklistAssociates");
        // return of([])
    };
    WholesaleService.prototype.getSimulatedSummary = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.API_URL + "simulateRoute", params);
    };
    WholesaleService.prototype.getPicklistSummary = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.API_URL + "startPicking", params);
    };
    WholesaleService.prototype.getPicklistById = function (id) {
        // var ApiUrl = 'picklist/';
        var ApiUrl = 'picklistByCustomer/';
        this.getWarehouseId();
        // if( this.wareHouseId.toUpperCase() == "WBLT" ){
        //   ApiUrl = 'picklistByCustomer/'
        // }
        return this.api.get("" + this.API_URL + ApiUrl + id);
    };
    WholesaleService.prototype.getConsolidatedPicklist = function (params) {
        // return of([])
        return this.api.get(this.API_URL +
            "fetchConsolidatedPicklists?dateFrom=" +
            params["expectedShipDateFrom"] +
            "&dateTo=" +
            params["expectedShipDateTo"]);
    };
    WholesaleService.prototype.updatePohAttachmentUrl = function (params, id) {
        if (params === void 0) { params = {}; }
        return this.api.put("" + this.config.UrlObj.updatePohAttachment + id, params);
    };
    WholesaleService.prototype.getWarehouseList = function () {
        return this.api.get(this.config.UrlObj.warehouseList);
    };
    WholesaleService.prototype.getAssociateName = function (list, id) {
        return list.filter(function (items) {
            return items.id === id;
        });
    };
    WholesaleService.prototype.getId = function (list) {
        return list.map(function (item) { return item.id; });
    };
    WholesaleService.prototype.getAssociatesListFromId = function (associateList, id) {
        return associateList.filter(function (associates) { return associates.id === id; });
    };
    WholesaleService.prototype.addDeliveryAssociate = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.config.UrlObj.addAssociates, params);
    };
    WholesaleService.prototype.deleteDeliveryAssociate = function (id) {
        return this.api.delete("" + this.config.UrlObj.deleteAssociate + id);
    };
    WholesaleService.prototype.printLabels = function (url) {
        return this.api.getFile(url);
    };
    WholesaleService.prototype.deletePOH = function (id) {
        return this.api.delete("" + this.config.UrlObj.deletePOH + id);
    };
    WholesaleService.prototype.getWarehouseId = function () {
        var _this = this;
        this.store.pipe(store_1.select(selectors_1.getAccountIdStatus)).subscribe(function (result) {
            console.log(result);
            if (result) {
                _this.wareHouseId = result;
                return _this.wareHouseId;
            }
            // window.location.reload();
        });
    };
    WholesaleService.prototype.getProductsByTitle = function (params) {
        if (params === void 0) { params = {}; }
        return this.api.post(this.config.UrlObj.searchProductByTitle, params);
    };
    WholesaleService.prototype.getTitles = function () {
        return this.api.get(this.config.UrlObj.getTitles);
    };
    WholesaleService.prototype.getTripSheetDetail = function (picklistCode) {
        return this.api.get("" + this.config.UrlObj.getTripSheetDetail + picklistCode);
    };
    WholesaleService.prototype.getAttributesListing = function (asin) {
        return this.api.get(this.config.UrlObj.attributeApi + "/" + this.wareHouseId + "/" + asin);
    };
    WholesaleService.prototype.changeData = function (postData) {
        return this.api.post(this.config.UrlObj.postApi + "/" + this.wareHouseId, postData);
    };
    WholesaleService.prototype.getRecallPickListDetails = function (pickListId) {
        var APIURL = "" + this.config.UrlObj.getRecallPickListDetails + pickListId;
        return this.api.get(APIURL);
    };
    WholesaleService.prototype.getRecallChallanDetails = function (picklistId) {
        var requestParams = {
            "page": 1,
            "perPage": 25,
            "orderNumber": picklistId
        };
        var APIURL = "" + this.config.UrlObj.getRecallChallanDetails;
        return this.api.get(APIURL, { params: requestParams });
    };
    WholesaleService.ngInjectableDef = i0.defineInjectable({ factory: function WholesaleService_Factory() { return new WholesaleService(i0.inject(i1.ApiService), i0.inject(i2.Configuration), i0.inject(i3.Store)); }, token: WholesaleService, providedIn: "root" });
    return WholesaleService;
}());
exports.WholesaleService = WholesaleService;
