import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, Router } from '@angular/router';

import { RecallListComponent } from './components/recall-list/recall-list.component';
import { RecallHomeComponent } from './components/recall-home/recall-home.component';
import { RecallDetailsComponent } from './components/recall-details/recall-details.component';
import { AddGatepassComponent } from './components/add-gatepass/add-gatepass.component';
import { PackItemComponent } from './components/pack-item/pack-item.component';

/**
 * Define routes
 * 
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
const routes: Routes = [
  { path: '', component: RecallHomeComponent },
  { path: 'list', component: RecallListComponent },
  { path: 'add-gatepass', component: AddGatepassComponent },
  { path: 'details/:accountId/:id', component: RecallDetailsComponent },
  //{ path: 'pack-item', component: ConsignReceiveLayoutComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ConsignmentRecallRoutingModule { }
