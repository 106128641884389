<section class="acknowledgment">
  <div class="acknowledgment-block">
    <h2>Acknowledgment Slip</h2>
    <ul class="details-block">
      <li>
        <span>Consignment: <b>{{receiveDetails?.consignmentNumber}}</b></span>
        <span>Received on: <b>{{currentDate }}</b></span>
      </li>

      <li>
        <span>Document: <b>{{receiveDetails?.refConsignmentNumber}}</b></span>
        <span> <b></b></span>
      </li>

      <li>
        <span>
          Delivered by: <br />
          Courier Name/Driver Name: <b>{{receiveDetails?.rec_courierName || receiveDetails?.rec_driverName }}</b><br />
          Tracking Number/Vehicle Number:
          <b>{{receiveDetails?.rec_trackingNumber || receiveDetails?.rec_vehiclePlateNumber}}</b>
        </span>
        <span>
          Quantity (as per the document): <b>{{receiveDetails?.rec_refConsignmentQty}}</b><br />
          Document amount: <b>INR. {{receiveDetails?.rec_refConsignmentAmount}}</b><br />
          Boxes received: <b>{{receiveDetails?.rec_containersQty}}</b>
        </span>
      </li>
    </ul>
    <div class="description">
      This is to confirm that ({{receiveDetails?.rec_containersQty}}) boxes of {{receiveDetails?.rec_accountName}} has
      been recevied at the warehouse on (received on
      date).<br />

      <h3>Box wise details:</h3>
      Number of Boxes Damaged: <b>{{boxesDamaged.length}}</b> <br />
      Was weight mentioned on boxes:<b> {{receiveDetails?.boxes[0]?.isWeightMentioned == '1' ? 'Yes' : 'No'}} </b><br />
      Number of Boxes having weight mismatch
      (applicable only if weight was mentioned on the boxes):<b> {{weightMismatch?.length}}</b> <br />
      Remarks: <b>{{receiveDetails?.rec_remarks}}</b>
    </div>
    <div class="signature">
      <p>
        Signature: <br />
        Name:
      </p>
    </div>
    <div class="note">
      <b>NOTE:</b> This acknowledgment is only for number of boxes received and not for the number of units.
    </div>
  </div>
</section>
