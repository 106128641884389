"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var wholesale_service_1 = require("../../shared/services/wholesale.service");
var store_1 = require("@ngrx/store");
var wholesale_actions_1 = require("../../wholesale/actions/wholesale.actions");
var selectors_1 = require("../../wholesale/reducers/selectors");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var moment = require("moment");
var SearchProductComponent = /** @class */ (function () {
    function SearchProductComponent(wholesaleService, store) {
        this.wholesaleService = wholesaleService;
        this.store = store;
        this.titles = [];
        this.currentProduct = '';
        this.searchInput = new forms_1.FormControl('', forms_1.Validators.required);
        this.pickingDate = new forms_1.FormControl(moment(new Date()).format('YYYY-MM-DD'), forms_1.Validators.required);
        this.products = [];
        this.productsLoaded = false;
        this.submitted = false;
    }
    SearchProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isEditRequest = true;
        this.isActionBtnShow = false;
        this.getTitles();
        this.filteredOptions = this.searchInput.valueChanges
            .pipe(operators_1.startWith(''), operators_1.map(function (value) {
            if (value.length > 2) {
                return _this._filter(value);
            }
        }));
        //  this.getAsinByUPCBarCode();
    };
    SearchProductComponent.prototype.edit = function (searchByAsin) {
        this.oldData = Object.assign({}, searchByAsin);
        this.isEditRequest = !this.isEditRequest;
        this.isActionBtnShow = !this.isActionBtnShow;
    };
    SearchProductComponent.prototype.close = function () {
        this.isActionBtnShow = false;
        this.isEditRequest = !this.isEditRequest;
        this.searchByAsin = this.oldData;
    };
    SearchProductComponent.prototype.change = function (val) {
        var data = (val === 1 || val === '1') ? 'Food' : 'Non-Food';
        return data;
    };
    SearchProductComponent.prototype.save = function (searchByAsin) {
        var _this = this;
        this.isActionBtnShow = false;
        this.isEditRequest = !this.isEditRequest;
        this.wholesaleService.changeData(searchByAsin).subscribe(function (res) {
            if (res) {
                _this.searchByAsin = res;
                _this.getProductByTitle(res.productName);
            }
        });
    };
    //  getAsinByUPCBarCode(){
    //    this.wholesaleService.getAsinData(' https://www.amazondistribution.in/search?keywords=8901088066532').subscribe((res: any ) =>{
    // console.log(res);
    //    });
    //  }
    SearchProductComponent.prototype.inventory = function (count, ibq, unitpercontainer) {
        var str = '';
        if (count) {
            count = Number(count);
            if (ibq === 0 || ibq === undefined || ibq === null)
                ibq = 1;
            if (unitpercontainer === 0 || unitpercontainer === undefined || unitpercontainer === null)
                unitpercontainer = 1;
            var caspack = Math.trunc((count * ibq) / unitpercontainer);
            if (caspack !== 0)
                str = 'CasePack :' + caspack;
            var bundle = Math.trunc((count % unitpercontainer) / ibq);
            if (bundle !== 0)
                str = str + ' Bundle :' + bundle;
            var loose = (count % unitpercontainer) % ibq;
            if (loose !== 0)
                str = str + ' Loose :' + loose;
            return str;
        }
    };
    SearchProductComponent.prototype.getAttributeList = function (option) {
        var _this = this;
        var serachInputValue;
        if (option && option !== null && option !== undefined) {
            serachInputValue = option;
        }
        else {
            serachInputValue = this.searchInput.value;
        }
        this.wholesaleService.getAttributesListing(serachInputValue).subscribe(function (res) {
            if (res) {
                _this.searchByAsin = res;
                _this.searchInput.setValue('');
                _this.getProductByTitle(res.productName);
            }
        });
    };
    SearchProductComponent.prototype.getProductByTitle = function (option) {
        var _this = this;
        this.submitted = true;
        this.currentProduct = option;
        if (this.pickingDate.valid && this.pickingDate.valid) {
            var params = {
                title: this.currentProduct,
                date: moment(this.pickingDate.value).format('YYYY-MM-DD')
            };
            this.wholesaleService.getProductsByTitle(params).subscribe(function (res) {
                _this.productsLoaded = true;
                if (res) {
                    _this.products = res;
                }
            });
        }
    };
    SearchProductComponent.prototype.getTitles = function () {
        var _this = this;
        this.titlesSubscription$ = this.store
            .select(selectors_1.getProductTitles)
            .subscribe(function (result) {
            if (result) {
                if (result.length > 0) {
                    _this.titles = result;
                    return;
                }
            }
            _this.store.dispatch({ type: wholesale_actions_1.WholesaleActionTypes.FETCH_TITLES });
        });
    };
    SearchProductComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        var titles = this.titles.filter(function (option) {
            if (option) {
                return option.toLowerCase().indexOf(filterValue) === 0;
            }
        });
        return titles;
    };
    SearchProductComponent.prototype.trackByFn = function (index) {
        return index;
    };
    SearchProductComponent.prototype.ngOnDestroy = function () {
        if (this.titlesSubscription$) {
            this.titlesSubscription$.unsubscribe();
        }
    };
    return SearchProductComponent;
}());
exports.SearchProductComponent = SearchProductComponent;
