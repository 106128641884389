"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./home-layout.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../shared/components/header/header.component.ngfactory");
var i3 = require("../../../shared/components/header/header.component");
var i4 = require("@angular/router");
var i5 = require("../../../shared/services/auth.service");
var i6 = require("@ngrx/store");
var i7 = require("../../../shared/services/wholesale.service");
var i8 = require("../../../shared/services/shared.service");
var i9 = require("../../../shared/components/sidebar/sidebar.component.ngfactory");
var i10 = require("../../../shared/components/sidebar/sidebar.component");
var i11 = require("../../../shared/components/footer/footer.component.ngfactory");
var i12 = require("../../../shared/components/footer/footer.component");
var i13 = require("./home-layout.component");
var styles_HomeLayoutComponent = [i0.styles];
var RenderType_HomeLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeLayoutComponent, data: {} });
exports.RenderType_HomeLayoutComponent = RenderType_HomeLayoutComponent;
function View_HomeLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.Router, i5.AuthService, i6.Store, i7.WholesaleService, i8.SharedService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "body-container-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-sidebar", [], null, null, null, i9.View_SidebarComponent_0, i9.RenderType_SidebarComponent)), i1.ɵdid(4, 114688, null, 0, i10.SidebarComponent, [], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(8, 114688, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
exports.View_HomeLayoutComponent_0 = View_HomeLayoutComponent_0;
function View_HomeLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-layout", [], null, null, null, View_HomeLayoutComponent_0, RenderType_HomeLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i13.HomeLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HomeLayoutComponent_Host_0 = View_HomeLayoutComponent_Host_0;
var HomeLayoutComponentNgFactory = i1.ɵccf("app-home-layout", i13.HomeLayoutComponent, View_HomeLayoutComponent_Host_0, {}, {}, []);
exports.HomeLayoutComponentNgFactory = HomeLayoutComponentNgFactory;
