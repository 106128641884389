"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_fontawesome_1 = require("@fortawesome/angular-fontawesome");
var forms_1 = require("@angular/forms");
var header_component_1 = require("./components/header/header.component");
var footer_component_1 = require("./components/footer/footer.component");
var sidebar_component_1 = require("./components/sidebar/sidebar.component");
var alert_component_1 = require("./components/alert/alert.component");
var upload_modal_component_1 = require("./components/upload-modal/upload-modal.component");
var add_da_component_1 = require("@app/components/add-da/add-da.component");
var acknowledge_slip_component_1 = require("./components/acknowledge-slip/acknowledge-slip.component");
var consignment_detail_component_1 = require("./components/consignment-detail/consignment-detail.component");
var ngx_barcode_1 = require("ngx-barcode");
var print_layout_component_1 = require("./components/print-layout/print-layout.component");
var invoice_component_1 = require("./components/invoice/invoice.component");
var filterbar_component_1 = require("./components/filterbar/filterbar.component");
var string_operations_pipe_1 = require("../shared/pipes/string-operations.pipe");
var getwarehouse_pipe_1 = require("../shared/pipes/getwarehouse.pipe");
var trip_sheet_component_1 = require("./components/trip-sheet/trip-sheet.component");
var bag_pack_pdf_component_1 = require("../bags/components/bag-pack-pdf/bag-pack-pdf.component");
var recall_sheet_component_1 = require("./components/recall-sheet/recall-sheet.component");
var MODULE_SET_1 = [
    header_component_1.HeaderComponent,
    footer_component_1.FooterComponent,
    sidebar_component_1.SidebarComponent,
    alert_component_1.AlertComponent,
    upload_modal_component_1.UploadModalComponent,
    add_da_component_1.AddDAComponent,
    print_layout_component_1.PrintLayoutComponent,
    filterbar_component_1.FilterbarComponent,
    invoice_component_1.InvoiceComponent,
    string_operations_pipe_1.GetTruncatedName,
    getwarehouse_pipe_1.GetWarehouseName,
    acknowledge_slip_component_1.AcknowledgeSlipComponent,
    consignment_detail_component_1.ConsignmentDetailComponent,
    trip_sheet_component_1.TripSheetComponent,
    bag_pack_pdf_component_1.BagPackPdfComponent,
    recall_sheet_component_1.RecallSheetComponent
];
var MODULE_SET_2 = [
    angular_fontawesome_1.FontAwesomeModule,
    forms_1.ReactiveFormsModule,
    forms_1.FormsModule,
    ngx_barcode_1.NgxBarcodeModule
];
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
exports.SharedModule = SharedModule;
