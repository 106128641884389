"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PrintLayoutComponent = /** @class */ (function () {
    function PrintLayoutComponent() {
    }
    PrintLayoutComponent.prototype.ngOnInit = function () { };
    return PrintLayoutComponent;
}());
exports.PrintLayoutComponent = PrintLayoutComponent;
