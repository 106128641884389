"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var invoice_component_1 = require("./components/invoice/invoice.component");
var print_layout_component_1 = require("./components/print-layout/print-layout.component");
var router_1 = require("@angular/router");
var acknowledge_slip_component_1 = require("./components/acknowledge-slip/acknowledge-slip.component");
var consignment_detail_component_1 = require("./components/consignment-detail/consignment-detail.component");
var trip_sheet_component_1 = require("./components/trip-sheet/trip-sheet.component");
var bag_pack_pdf_component_1 = require("../bags/components/bag-pack-pdf/bag-pack-pdf.component");
var recall_sheet_component_1 = require("./components/recall-sheet/recall-sheet.component");
var UserRoutes = [
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "invoice/:picklistId", component: invoice_component_1.InvoiceComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "invoice", component: invoice_component_1.InvoiceComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "consignment/:consignmentNo", component: consignment_detail_component_1.ConsignmentDetailComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "acknowlege-slip/:consignmentNo", component: acknowledge_slip_component_1.AcknowledgeSlipComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "trip-sheet/:picklistId", component: trip_sheet_component_1.TripSheetComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "packed-bag/:bagID", component: bag_pack_pdf_component_1.BagPackPdfComponent }]
    },
    {
        path: "print",
        outlet: "print",
        component: print_layout_component_1.PrintLayoutComponent,
        children: [{ path: "recall-sheet/:picklistId", component: recall_sheet_component_1.RecallSheetComponent }]
    }
];
var SharedRoutingModule = /** @class */ (function () {
    function SharedRoutingModule() {
    }
    return SharedRoutingModule;
}());
exports.SharedRoutingModule = SharedRoutingModule;
