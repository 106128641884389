import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Observable } from "rxjs";
import { SharedService, AlertService, AuthService, WholesaleService } from "../services";
import { CONSTANTS, ROUTES } from "@app/shared/constants/constants";
import { Configuration } from "../configuration/config";

@Injectable({
  providedIn: "root"
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, private alertService: AlertService, private sharedService: SharedService,
    private wholesaleService: WholesaleService,
    private config: Configuration) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      this.alertService.showError(CONSTANTS.ALERT_MESSAGES.NO_INTERNET_CONNECTION);
    }
    else {
      // todo hack to be removed
      // console.log("INSIDE INTERCEPTOR", request)
      // var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
      // console.log("1", request.url.split('/'))
      // console.log("API DETECTOR", apidetector)
      if (request.responseType !== "blob") {
        request = request.clone({
          setHeaders: {
            ProxyHost: "amazon",
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            "Access-Control-Allow-Origin": "*",
            warehouseId: this.wholesaleService.wareHouseId || ""
            // warehouseId: 'WBLS' || ""
          }
        });
      }


      if (!request.url.includes(this.config.API_URL)) {
        this.sharedService.showHideLoader(true);
      }
      return next.handle(request);
    }
  }
}

export let tokenProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};
