"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BagReducer = require("./bag.reducer");
var store_1 = require("@ngrx/store");
exports.BagState = function (state) { return state.bagState; };
// Get bag list selectors
exports.bagListSelector = store_1.createSelector(exports.BagState, BagReducer.bagList);
exports.loadingBagListSelector = store_1.createSelector(exports.BagState, BagReducer.loadingBagList);
exports.loadedBagListSelector = store_1.createSelector(exports.BagState, BagReducer.loadedBagList);
exports.bagListErrorSelector = store_1.createSelector(exports.BagState, BagReducer.bagListError);
// Get bag list for PDF selectors
exports.bagListForPDFSelector = store_1.createSelector(exports.BagState, BagReducer.bagListForPDF);
exports.loadingBagListForPDFSelector = store_1.createSelector(exports.BagState, BagReducer.loadingBagListForPDF);
exports.loadedBagListForPDFSelector = store_1.createSelector(exports.BagState, BagReducer.loadedBagListForPDF);
exports.bagListErrorForPDFSelector = store_1.createSelector(exports.BagState, BagReducer.bagListErrorForPDF);
// Create bag selectors
exports.createBagSelector = store_1.createSelector(exports.BagState, BagReducer.createBag);
exports.creatingBagSelector = store_1.createSelector(exports.BagState, BagReducer.creatingBag);
exports.createdBagSelector = store_1.createSelector(exports.BagState, BagReducer.createdBag);
exports.createBagErrorSelector = store_1.createSelector(exports.BagState, BagReducer.createBagError);
// Update bag selectors
exports.updateBagSelector = store_1.createSelector(exports.BagState, BagReducer.updateBag);
exports.updatingBagSelector = store_1.createSelector(exports.BagState, BagReducer.updatingBag);
exports.updatedBagSelector = store_1.createSelector(exports.BagState, BagReducer.updatedBag);
exports.updateBagErrorSelector = store_1.createSelector(exports.BagState, BagReducer.updateBagError);
// Get bag item details selector
exports.bagItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.bagItemDetails);
exports.loadingBagItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.loadingBagItemDetails);
exports.loadedBagItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.loadedBagItemDetails);
exports.bagItemDetailsErrorSelector = store_1.createSelector(exports.BagState, BagReducer.bagItemDetailsError);
// Get single item details selector
exports.singleItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.singleItemDetails);
exports.loadingSingleItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.loadingSingleItemDetails);
exports.loadedSingleItemDetailsSelector = store_1.createSelector(exports.BagState, BagReducer.loadedSingleItemDetails);
exports.singleItemDetailsErrorSelector = store_1.createSelector(exports.BagState, BagReducer.singleItemDetailsError);
// Add item selectors
exports.addItemSelector = store_1.createSelector(exports.BagState, BagReducer.addItem);
exports.addingItemSelector = store_1.createSelector(exports.BagState, BagReducer.addingItem);
exports.addedItemSelector = store_1.createSelector(exports.BagState, BagReducer.addedItem);
exports.addItemErrorSelector = store_1.createSelector(exports.BagState, BagReducer.addItemError);
