"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service");
var environment_1 = require("@env/environment");
var operators_1 = require("rxjs/operators");
var config_1 = require("../configuration/config");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var UserService = /** @class */ (function () {
    function UserService(api, config) {
        this.api = api;
        this.config = config;
        this.API_URL = environment_1.environment.API_URL;
    }
    UserService.prototype.getOrders = function (params) {
        if (params === void 0) { params = {}; }
        return this.api
            .get(this.API_URL + "orders", params)
            .pipe(operators_1.map(function (response) { return response["data"]; }));
    };
    UserService.prototype.getOrder = function (id) {
        return this.api
            .get(this.API_URL + "orders/" + id)
            .pipe(operators_1.map(function (response) { return response["data"]; }));
    };
    UserService.prototype.getOrdersCount = function () {
        return this.api
            .get(this.API_URL + "orders/count")
            .pipe(operators_1.map(function (response) { return response["data"]; }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ApiService), i0.inject(i2.Configuration)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
exports.UserService = UserService;
