"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./recall-pack-item-layout.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../shared/components/recall-pack-item-header/recall-pack-item-header.component.ngfactory");
var i3 = require("../../../shared/components/recall-pack-item-header/recall-pack-item-header.component");
var i4 = require("@angular/router");
var i5 = require("../../../shared/components/footer/footer.component.ngfactory");
var i6 = require("../../../shared/components/footer/footer.component");
var i7 = require("./recall-pack-item-layout.component");
var styles_RecallPackItemLayoutComponent = [i0.styles];
var RenderType_RecallPackItemLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecallPackItemLayoutComponent, data: {} });
exports.RenderType_RecallPackItemLayoutComponent = RenderType_RecallPackItemLayoutComponent;
function View_RecallPackItemLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "consignment-rec-container full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-recall-pack-item-header", [], null, null, null, i2.View_RecallPackItemHeaderComponent_0, i2.RenderType_RecallPackItemHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.RecallPackItemHeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i5.View_FooterComponent_0, i5.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i6.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
exports.View_RecallPackItemLayoutComponent_0 = View_RecallPackItemLayoutComponent_0;
function View_RecallPackItemLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recall-pack-item-layout", [], null, null, null, View_RecallPackItemLayoutComponent_0, RenderType_RecallPackItemLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i7.RecallPackItemLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RecallPackItemLayoutComponent_Host_0 = View_RecallPackItemLayoutComponent_Host_0;
var RecallPackItemLayoutComponentNgFactory = i1.ɵccf("app-recall-pack-item-layout", i7.RecallPackItemLayoutComponent, View_RecallPackItemLayoutComponent_Host_0, {}, {}, []);
exports.RecallPackItemLayoutComponentNgFactory = RecallPackItemLayoutComponentNgFactory;
