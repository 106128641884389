"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ConsignReceiveLayoutComponent = /** @class */ (function () {
    function ConsignReceiveLayoutComponent() {
    }
    ConsignReceiveLayoutComponent.prototype.ngOnInit = function () { };
    return ConsignReceiveLayoutComponent;
}());
exports.ConsignReceiveLayoutComponent = ConsignReceiveLayoutComponent;
