import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "getTruncatedName" })
export class GetTruncatedName implements PipeTransform {
  transform(value: string): string {
    let stringToSplit: string = value;
    if (stringToSplit.length > 10) {
      stringToSplit = stringToSplit.substr(0, 10) + "...";
    }
    return stringToSplit;
  }
}
