"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../services");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var RecallSheetComponent = /** @class */ (function () {
    function RecallSheetComponent(printService, route, wholesaleService, datePipe) {
        this.printService = printService;
        this.route = route;
        this.wholesaleService = wholesaleService;
        this.datePipe = datePipe;
        this.pickingDate = new Date();
    }
    RecallSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.picklistId = this.route.snapshot.params["picklistId"];
        var encodedPicklistId = encodeURIComponent(this.picklistId);
        this.wholesaleService.getRecallPickListDetails(encodeURIComponent(encodedPicklistId)).subscribe(function (response) {
            if (response) {
                _this.numberOfAsins = response['total'];
                if (response['data']) {
                    _this.recallSheetData = response['data'];
                    _this.printService.onDataReady();
                }
            }
        });
    };
    return RecallSheetComponent;
}());
exports.RecallSheetComponent = RecallSheetComponent;
