import { Component, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { GetBagItemDetails, GetBagListForPDF } from "../../bag.action";
import {
  bagItemDetailsSelector,
  bagItemDetailsErrorSelector,
  bagListForPDFSelector,
  bagListErrorForPDFSelector
} from "../../bag-selectors";
import { PrintService } from "../../../shared/services/print.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-bag-pack-pdf",
  templateUrl: "./bag-pack-pdf.component.html",
  styleUrls: ["./bag-pack-pdf.component.scss"]
})
export class BagPackPdfComponent implements AfterViewInit, OnDestroy {
  bagID: string;
  bagDetails: any;
  bagItemDetails: any;
  bagPDFSubscriptionList$: Subscription = new Subscription();
  showloader: boolean = false;
  constructor(
    protected _activatedRoute: ActivatedRoute,
    protected _store: Store<any>,
    protected printService: PrintService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {

    this.bagID = this._activatedRoute.snapshot.params["bagID"];
    if (localStorage.getItem("route") === null) {
      localStorage.setItem("route", this.bagID);
      this.getBagDetails();
    } else {
      this.router.navigateByUrl("/setting/bags/list");
      localStorage.removeItem("route");
    }

  }

  /**
   * @description Get bag details
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getBagDetails() {
    this._store.dispatch(
      new GetBagListForPDF({
        page: 1,
        perPage: 1,
        bagCode: this.bagID
      })
    );
    this.bagPDFSubscriptionList$.add(
      this._store.pipe(select(bagListForPDFSelector)).subscribe(data => {
        if (data && data["data"]) {
          setTimeout(() => {
            this.getBagItemDetails();
          }, 1000);
          this.bagDetails = data["data"][0];
        }
      })
    );
    this.bagPDFSubscriptionList$.add(
      this._store.pipe(select(bagListErrorForPDFSelector)).subscribe()
    );
  }

  /**
   * @description Get bag item details
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getBagItemDetails() {
    this._store.dispatch(
      new GetBagItemDetails({
        //page: 1,
        //per_page: 1,
        bagID: this.bagID
      })
    );
    this.bagPDFSubscriptionList$.add(
      this._store.pipe(select(bagItemDetailsSelector)).subscribe(data => {
        if (data && data["data"] && data["data"].length > 0) {
          this.bagItemDetails = data["data"][0]["items"];
          this.printService.onDataReady();
          localStorage.removeItem('route')
          // this._store.dispatch(new GetBagList({
          //   page: 1,
          //   per_page: 25
          // }));
          this.bagPDFSubscriptionList$.unsubscribe();
        } else {
        }
      })
    );

    this.bagPDFSubscriptionList$.add(
      this._store.pipe(select(bagItemDetailsErrorSelector)).subscribe(err => {
        if (err) {
          localStorage.removeItem('route')
          this.router.navigateByUrl("/setting/bags/list");
        }
      })
    );
  }

  /**
   * @description Get inventory type label
   * @param inventoryType
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getInventoryType(inventoryType) {
    switch (inventoryType) {
      case "GOOD":
        return "Good";
        break;
      case "BAD":
        return "Bad";
        break;
      case "QC_REJECTED":
        return "QC Rejected";
        break;
      default:
        return "";
        break;
    }
  }

  ngOnDestroy() {
    this.bagPDFSubscriptionList$.unsubscribe();
  }
}
