"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../shared/constants/constants");
var material_1 = require("@angular/material");
var consignment_receive_service_1 = require("../../../consignment-receive/services/consignment-receive.service");
var consignment_recall_service_1 = require("../../services/consignment-recall.service");
var shared_service_1 = require("../../../shared/services/shared.service");
var alert_service_1 = require("../../../shared/services/alert.service");
var forms_1 = require("@angular/forms");
var RecallHomeComponent = /** @class */ (function () {
    function RecallHomeComponent(dialog, consignmentReceiveService, consignmentRecallService, sharedService, alertService) {
        this.dialog = dialog;
        this.consignmentReceiveService = consignmentReceiveService;
        this.consignmentRecallService = consignmentRecallService;
        this.sharedService = sharedService;
        this.alertService = alertService;
        this.ROUTES = constants_1.ROUTES;
        this.accountList = [];
        this.warehouseList = [];
        this.isSubmitForm = false;
    }
    RecallHomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.consignmentReceiveService.getAccountNameList().subscribe(function (response) {
            if (response['data']) {
                _this.accountList = response['data'];
            }
        });
    };
    RecallHomeComponent.prototype.onAccontSelection = function (selectedAccount) {
        var _this = this;
        if (selectedAccount) {
            this.selectedAccountId = selectedAccount;
            this.consignmentRecallService.getWarehouseDetails(selectedAccount).subscribe(function (response) {
                if (response['data']) {
                    var updatedWarehouseList_1 = [];
                    response['data'].forEach(function (warehouse) {
                        if (warehouse.warehouseId != _this.sharedService.selectedWarehouseId) {
                            updatedWarehouseList_1.push(warehouse);
                        }
                    });
                    _this.warehouseList = updatedWarehouseList_1;
                }
            });
        }
    };
    RecallHomeComponent.prototype.onWarehouseSelection = function (selectedWarehouse) {
        this.selectedWarehouse = selectedWarehouse;
    };
    RecallHomeComponent.prototype.openTransferConsignmentPopup = function () {
        this.dialog.open(this.trasferDialog);
    };
    RecallHomeComponent.prototype.onSubmitConsignmentTransfer = function () {
        var _this = this;
        this.isSubmitForm = true;
        var transferData = {
            "fromWarehouseId": this.sharedService.selectedWarehouseId,
            "toPartyId": +this.selectedWarehouse,
            "accountId": +this.selectedAccountId,
        };
        this.consignmentRecallService.createRecallConsignmentTransfer(transferData).subscribe(function (response) {
            if (response) {
                _this.isSubmitForm = false;
                _this.alertService.showSuccess("" + "Recall consignment " + response['consignmentNumber'] + "is created. Now create bag and add items to bag.");
                _this.onCloseTransferConsignmentPopup();
            }
        }, function (error) {
            _this.isSubmitForm = false;
        });
    };
    RecallHomeComponent.prototype.onCloseTransferConsignmentPopup = function () {
        this.dialog.closeAll();
        this.selectedAccountId = null;
        this.selectedWarehouse = null;
    };
    return RecallHomeComponent;
}());
exports.RecallHomeComponent = RecallHomeComponent;
