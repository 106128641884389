import * as ConReceiveActions from "../actions/con-receive.action";
import { ConReceiveState } from "./con-receive.state";

export const initialState: ConReceiveState = {
  transportDetails: null,
  consignmentNo: "",
  accountDetails: null,
  boxDetails: []
};

export function ConReceiveReducer(
  state = initialState,
  action: ConReceiveActions.ReceiveUnion
) {
  switch (action.type) {
    case ConReceiveActions.ReceiveActionTypes.SET_TRANSPORTER_DETAILS:
      return { ...state, transportDetails: action.payload };

    case ConReceiveActions.ReceiveActionTypes.SET_CONSIGNMENT_NO:
      return { ...state, consignmentNo: action.payload };

    case ConReceiveActions.ReceiveActionTypes.SET_ACCOUNT_DETAILS:
      return { ...state, accountDetails: action.payload };
    case ConReceiveActions.ReceiveActionTypes.SET_BOX_DETAILS:
      return { ...state, boxDetails: action.payload };
    default:
      return state;
  }
}
