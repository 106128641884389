<main class="right-container">
  <div class="main-container">
    <div class="example-container  completeConsignment recall-Consignment ">
      <div class="table-top">
        <div class="heading" [ngStyle]="{'width':'500px'}">
          <a [routerLink]="routes.CONSIGNMENT_RECALL_LIST"><img src="assets/images/ic-back.png" /></a>
          <h3>Recall Consignment Packed Item List - {{consignmentNumber}}</h3>
        </div>
        <div class="print-btn">
          <div *ngIf="accountId == 1" matTooltipClass="primary-tooltip" matTooltip="Picklist">
            <img src="assets/images/print.png" alt="" (click)="onPrintRecallList(consignmentNumber)" />
          </div>
          <div matTooltipClass="primary-tooltip"
            *ngIf="accountId == 1 && (recallConsignmentStatus == 'PACKED' || recallConsignmentStatus == 'DISPATCHED')"
            matTooltip="Challan">
            <a (click)="openChallan()">
              <img src="assets/images/print.png" />
            </a>
          </div>
        </div>
        <!------------------- Pagination ------------------->
        <mat-paginator *ngIf="grnItemsCount > 0" [length]="grnItemsCount" [pageSize]="getGRNItemsRequestBody.perPage"
          [pageSizeOptions]="[10, 25, 50, 100]" (page)="onPaginate($event)"> </mat-paginator>
      </div>
      <div class="table">
        <ul class="all-table">
          <!--------------------- Filters ------------------>
          <li class="thead">
            <span *ngIf="accountId != 1">Item Barcode
              <!-- <img src="assets/images/filter-icon.png" (click)="orderclick()" />
              <div class="filter-drop" *ngIf="orderNo">
                <div class="input-field">
                  <p>Order No.</p>
                  <input type="text">
                </div>
                <div class="calendar-right">
                  <button>APPLY</button>
                  <button>CANCEL</button>
                </div>
              </div> -->
            </span>
            <span>Item SKU </span>
            <span>Product Name</span>
            <span>Total QTY</span>
            <span>Packed QTY</span>
            <span>Out Of Stock QTY</span>
          </li>
          <!------------------ No data found ----------------->
          <li class="tbody not-found p-50" *ngIf="grnItemList.length == 0 && !isLoading">
            <img src="../../../assets/images/no-found.png" width="100" />
            <p>No items packed till now</p>
          </li>
          <!------------------------ Data table ----------------------->
          <li style="text-align: center;" class="tbody flex-start" *ngFor="let item of grnItemList">
            <span *ngIf="accountId != 1">{{item?.itemCode || '-'}}</span>
            <span> {{item?.itemSkuCode || '-'}} </span>
            <span class="manage-wid"> <i > {{item?.productName || '-'}} </i></span>
            <span>{{item?.totalQuantity || '0'}}</span>
            <span>{{item?.packedQuantity || '0'}}</span>
            <span>{{item?.outOfStockQuantity || '0'}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</main>