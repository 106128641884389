"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var WholesaleActions = require("../actions/wholesale.actions");
exports.initialState = {
    routeSummary: [],
    warehouseList: [],
    associates: [],
    simulatedSummary: [],
    picklistSummary: [],
    accountId: null,
    consolidatedPicklist: null,
    titles: null
};
function WholesaleReducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case WholesaleActions.WholesaleActionTypes.GET_ROUTE_SUMMARY_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { routeSummary: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.GET_WAREHOUSE_LIST_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { warehouseList: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.GET_ASSOCIATES_SUCCESS: {
            return Object.assign({}, state, { associates: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.GET_SIMULATED_SUCCESS: {
            return Object.assign({}, state, { simulatedSummary: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.GET_PICKLIST_SUCCESS: {
            return Object.assign({}, state, { picklistSummary: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.ADD_ASSOCIATE_SUCCESS: {
            var newAssociates = tslib_1.__spreadArrays(state.associates, [action.payload]);
            return Object.assign({}, state, { associates: newAssociates });
        }
        case WholesaleActions.WholesaleActionTypes.DELETE_ASSOCIATE_SUCCESS: {
            var newAssociates = state.associates.filter(function (item) { return item.id !== action.payload.id; });
            return Object.assign({}, state, { associates: newAssociates });
        }
        case WholesaleActions.WholesaleActionTypes.SET_ACCOUNT_ID: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { accountId: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.SET_CONSOLIDATED_PICKLIST: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { consolidatedPicklist: action.payload });
        }
        case WholesaleActions.WholesaleActionTypes.FETCH_TITLES_SUCCESS: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { titles: action.payload });
        }
        default:
            return state;
    }
}
exports.WholesaleReducer = WholesaleReducer;
