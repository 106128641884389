"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var BrandAction = require("./brand.action");
exports.initialState = {
    brandList: null,
    loadedBrandList: false,
    loadingBrandList: false,
    brandListError: null
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case BrandAction.GET_BRAND_LIST:
            return tslib_1.__assign(tslib_1.__assign({}, state), { brandList: null, loadingBrandList: true, loadedBrandList: false, brandListError: null });
            break;
        case BrandAction.GET_BRAND_LIST_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { brandList: action.payload, loadingBrandList: false, loadedBrandList: true, brandListError: null });
            break;
        case BrandAction.GET_BRAND_LIST_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { brandList: null, loadingBrandList: false, loadedBrandList: true, brandListError: action.payload });
            break;
    }
}
exports.reducer = reducer;
exports.brandList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.brandList;
};
exports.loadingBrandList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadingBrandList;
};
exports.loadedBrandList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadedBrandList;
};
exports.brandListError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.brandListError;
};
