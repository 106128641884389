"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrderActionTypes;
(function (OrderActionTypes) {
    OrderActionTypes["SEARCH"] = "[Order] Search Order";
    OrderActionTypes["SEARCH_COMPLETE"] = "[Order] Search Order Complete";
    OrderActionTypes["SEARCH_ERROR"] = "[Order] Search Order Error";
    OrderActionTypes["SELECT"] = "[Order] Select";
})(OrderActionTypes = exports.OrderActionTypes || (exports.OrderActionTypes = {}));
var Select = /** @class */ (function () {
    function Select(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SELECT;
    }
    return Select;
}());
exports.Select = Select;
var OrderSearch = /** @class */ (function () {
    function OrderSearch(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SEARCH;
    }
    return OrderSearch;
}());
exports.OrderSearch = OrderSearch;
var OrderSearchComplete = /** @class */ (function () {
    function OrderSearchComplete(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SEARCH_COMPLETE;
    }
    return OrderSearchComplete;
}());
exports.OrderSearchComplete = OrderSearchComplete;
var OrderSearchError = /** @class */ (function () {
    function OrderSearchError(payload) {
        this.payload = payload;
        this.type = OrderActionTypes.SEARCH_ERROR;
    }
    return OrderSearchError;
}());
exports.OrderSearchError = OrderSearchError;
