"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./recall-pack-item-header.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./recall-pack-item-header.component");
var styles_RecallPackItemHeaderComponent = [i0.styles];
var RenderType_RecallPackItemHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecallPackItemHeaderComponent, data: {} });
exports.RenderType_RecallPackItemHeaderComponent = RenderType_RecallPackItemHeaderComponent;
function View_RecallPackItemHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/brand-img.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "assets/images/edi-close.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ROUTES.CONSIGNMENT_RECALL; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
exports.View_RecallPackItemHeaderComponent_0 = View_RecallPackItemHeaderComponent_0;
function View_RecallPackItemHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recall-pack-item-header", [], null, null, null, View_RecallPackItemHeaderComponent_0, RenderType_RecallPackItemHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.RecallPackItemHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RecallPackItemHeaderComponent_Host_0 = View_RecallPackItemHeaderComponent_Host_0;
var RecallPackItemHeaderComponentNgFactory = i1.ɵccf("app-recall-pack-item-header", i4.RecallPackItemHeaderComponent, View_RecallPackItemHeaderComponent_Host_0, {}, {}, []);
exports.RecallPackItemHeaderComponentNgFactory = RecallPackItemHeaderComponentNgFactory;
