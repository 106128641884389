import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService {

  consoleTypeList: string[] = ['error', 'warn', 'debug', 'log', 'info'];

  /**
   * @description Log request if production is not enabled
   * @param request 
   * 
   * @author Chandrika Aggarwal
   */
  consoleLog(request: any, type: string = 'log') {
    if (!environment.production) {
      console[type](request);
    }
  }

  constructor() { }
}
