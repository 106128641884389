"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var UploadModalComponent = /** @class */ (function () {
    function UploadModalComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    UploadModalComponent.prototype.ngOnInit = function () { };
    UploadModalComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return UploadModalComponent;
}());
exports.UploadModalComponent = UploadModalComponent;
