<div class="pack-main-wrp2">
  <!-- <div class="pack-head">
    <div class="left"> <a href=""> <img src="assets/images/brand-img.png" /> </a></div>
    <div class="right"> <a class="cross-btn" [routerLink]="Routes.CONSIGNMENT_RECALL"> <img
          src="assets/images/edi-close.png" /> </a></div>
  </div> -->
  <div class="clear"></div>
  <div class="pack-box">
    <h2> Pack Item: <span class="red-txt"> Pack {{inventoryType | titlecase}} Inventory </span></h2>
    <ul class="process-btn">
      <li> <a href="" class="active"> 1. Scan Bag </a> </li>
      <li> <a href="" [ngClass]=""> 2. Scan Item </a> </li>
    </ul>
    <div class="clear"></div>
    <div class="input-srch-filed">
      <form>
        <!------------- Scan item input --------------->
        <!-- <input type="text" [formControl]="scannedBarcode" placeholder="Scan Item Barcode" #scanBarcodeEle
          (keyup.enter)="scanItem(scannedBarcode.value)" /> -->
        <!-- <app-scan-input [scannedBarcodeInput]="scannedBarcode.value" (scannedBarcodeOutput)="scanItem($event)">
      </app-scan-input> -->
        <!-- <div class="serch-data" *ngIf="scannedItemDetails">
          <div class="prod-img">
            <img
              [src]="(scannedItemDetails?.productImageUrl)?scannedItemDetails?.productImageUrl:'src/assets/images/pack-icon/alt-img.png'" />
          </div> -->
        <!---------- Display scanned item details -------------->
        <!-- <div class="prod-data">
            <table>
              <tr>
                <td> Item SKU </td>
                <td> {{scannedItemDetails?.itemSku}} </td>
              </tr>
              <tr>
                <td> Consignment No. </td>
                <td>
                  {{(inventoryType != 'qc rejected')?scannedItemDetails?.recallConsignmentNumber:scannedItemDetails?.purchaseOrderCode}}
                </td>
              </tr>
              <tr>
                <td> Vendor Code </td>
                <td> {{scannedItemDetails?.vendorCode}} </td>
              </tr>
              <tr>
                <td> Vendor Name </td>
                <td> {{scannedItemDetails?.vendorName}} </td>
              </tr>
              <tr>
                <td>Facility Code</td>
                <td>{{scannedItemDetails?.currentFacility}} </td>
              </tr>
              <tr>
                <td>Item Type </td>
                <td> {{inventoryType | titlecase }} </td>
              </tr>
              <tr>
                <td>Item Status</td>
                <td>{{scannedItemDetails?.status}} </td>
              </tr>
              <tr *ngIf="inventoryType == 'bad'">
                <td>Brand</td>
                <td>{{scannedItemDetails?.brand}} </td>
              </tr>
            </table>




            <div class="bag-detail">
              <ul>
                <li>
                  <a> <img src="assets/images/pack-icon/shop-box-icon.png" /> </a>
                  <ul>
                    <li>
                      <-------------- Display scanned item bag list --------------
                      <table cellspacing="0" cellpadding="0">
                        <thead>
                          <tr>
                            <th> Bag ID </th>
                            <th> QTY </th>
                            <th> STATUS </th>
                            <th class="action-btn"> &nbsp; </th>
                          </tr>
                        </thead>
                        <tbody *ngIf="scannedItemBagListCount > 0">
                          <tr *ngFor="let bag of scannedItemBagList">
                            <td> {{bag.bagNumber}} </td>
                            <td> {{bag.quantity || '0'}} </td>
                            <td> {{bag.status}} </td>
                            <td class="action-btn">
                              <a title="Pack Bag" (click)="updateBagStatus(bag?.bagNumber, 'COMPLETE')"><img
                                  src="../../../assets/images/pack-icon/check-icon.png"
                                  *ngIf="bag?.quantity > 0 && (bag?.status == 'PROCESSING' || bag?.status == 'CREATED')"></a>
                              <- <a title="Discard Bag"><img src="../../../assets/images/pack-icon/cross-icon.png"></a>  --
                            </td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="scannedItemBagListCount == 0">
                          <tr>
                            <td class="no-bag" colspan="4" align="center"> No bags available</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="num-of-item" *ngIf="scannedItemBagListCount > 0">
                        <mat-paginator [length]="scannedItemBagListCount" [pageSize]="10"
                          [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                      </div>
                      <div class="clear"></div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div> -->
        <div class="clear"></div>
        <!-------------- Scan bag input ------------>
        <div class="bag-id" *ngIf="true">
          <!--scannedItemBagListCount > 0-->
          <div class="wrp">
            <input type="text" [formControl]="scannedBagID" placeholder="Scan or enter bag id"
              (keyup.enter)="scanBag(scannedBagID.value)" [readonly]="isloadingBagList && !isloadedBagList"
              #scanBagEle />
            <a class="packbtn" title="Pack To Bag" *ngIf="!showFlag && BAG['status'] === 'PROCESSING'"
              (click)="printPackedBagPDF(BAG?.bagNumber)">
              <img src="../../../assets/images/pack-icon/check-icon.png">
            </a>
            <a class="resetbtn" title="Reset Bag" (click)="resetBag()"><img
                src="../../../assets/images/pack-icon/cross-icon.png"></a>
            <div class="data-loading" *ngIf="!isloadedBagList && isloadingBagList">
              <mat-spinner [diameter]="20" [strokeWidth]="2"></mat-spinner>
            </div>
            <!-- <p class="bag-id-error">  Invalid Bag ID. Please scan correct Bag ID </p> -->
          </div>
        </div>

        <div class="serch-data" *ngIf="!showFlag">
          <div class="prod-data" *ngFor="let bag of scannedBagged$ | async">
            <table>
              <tr>
                <td> Inventory Type </td>
                <td> {{bag?.refType}} </td>
              </tr>
              <tr>
                <td> Items Added </td>
                <td>
                  <!-- {{isItemAdded == true ? (bag?.quantity++) : (bag?.quantity}} -->
                  {{bgItemQuantity}}
                </td>
              </tr>
              <tr *ngIf="bag?.accountName">
                <td> Account </td>
                <td> {{bag?.accountName}} </td>
              </tr>
              <tr>
                <td> Created At </td>
                <td> {{bag?.createdAt | date}} </td>
              </tr>
              <tr>
                <td> Status </td>
                <td>
                  {{(bgItemQuantity > 0) && (bag?.status !="DISCARDED" && bag?.status !="COMPLETE" )? "PROCESSING": (bag?.status)}}
                </td>
              </tr>
            </table>

          </div>

          <div class="clear"></div>

        </div>

        <!-- <ng-container *ngIf = "showFlag && bgData.length === 0">
            <div class="serch-data no-found">
              <span> No Scanned Bag Found..</span>
              <img src="../../../assets/images/no-found.png" />
            </div>
          </ng-container> -->
        <ng-container *ngIf="showFlag">
          <div class="serch-data no-found">
            <span> No Scanned Bag Found..</span>
            <img src="../../../assets/images/no-found.png" />
          </div>
        </ng-container>

        <!-- scan item code goes here-->
        <div class="bag-id mrg-tp-15" *ngIf="isloadedBagList && bgData.length !==0 && BAG?.status != 'COMPLETE'">
          <div class="wrp">
            <input type="text" [formControl]="scannedBarcode" placeholder="Scan Item Barcode" #scanBarcodeEle
              (keyup.enter)="scanBagItem(scannedBarcode.value)" [readonly]="isItemAdding && !isItemAdded" />
            <div class="data-loading brcode" *ngIf="isItemAdding && !isItemAdded">
              <mat-spinner [diameter]="20" [strokeWidth]="2"></mat-spinner>
            </div>

            <!--
                   <a class="resetbtn" title="Reset Item"
                  (click)="resetItem()"><img
                    src="../../../assets/images/pack-icon/cross-icon.png">
                </a> -->
          </div>
        </div>




        <!-- </div> -->
      </form>
    </div>
    <div class="clear"></div>
  </div>
</div>

<!------------ Create bag form -------------->
<div class="popup" [ngClass]="actionPopup ? 'visible': ''">
  <div class="innerpopup">
    <a class="close" (click)="actionPopup = false"><img src="assets/images/edi-close.png" /></a>
    <h2>Create Bag</h2>
    <form [formGroup]="createFormGroup" (ngSubmit)="createFormSubmit()">
      <!-- Purpose -->
      <mat-form-field>
        <input matInput placeholder="Purpose" maxlength="100" formControlName="purpose">
      </mat-form-field>
      <!-- Inventory Type -->
      <mat-form-field>
        <input matInput placeholder="Inventory Type" formControlName="inventoryType">
      </mat-form-field>
      <!-- Brand -->
      <mat-form-field *ngIf="createFormGroup?.value?.inventoryType=='BAD'">
        <input matInput placeholder="Brand" formControlName="brandName">
      </mat-form-field>
      <!-- Consignment Number -->
      <mat-form-field *ngIf="createFormGroup?.value?.inventoryType == 'GOOD'">
        <input matInput placeholder="Recall Consignment Number" maxlength="40" formControlName="consignmentNumber">
      </mat-form-field>
      <mat-form-field *ngIf="createFormGroup?.value?.inventoryType == 'QC_REJECTED'">
        <input matInput placeholder="Inward Consignment Number" maxlength="40" formControlName="consignmentNumber">
      </mat-form-field>
      <!-- Number of Bags -->
      <mat-form-field *ngIf="createFormGroup?.value?.inventoryType">
        <span class="tooltip abs" data-tooltip="Scan the bag id if you want to copy details of any existing bag.">
          <img src="assets/images/info-icon.png" />
        </span>
        <input matInput placeholder="Number Of bags" type="number" min="0" max="40" maxlength="3"
          formControlName="numberOfBag">
      </mat-form-field>
      <!-- Submit -->
      <div class="btn-right">
        <button [disabled]="!createFormGroup.dirty || createFormGroup.invalid" type="submit"
          class="btn"><span>C</span>Create</button>
      </div>
    </form>
  </div>
</div>

<div class="matloader" *ngIf="showLoader">
  <mat-spinner [diameter]="25" [strokeWidth]="2.2"></mat-spinner>
  <span>Loading...</span>
</div>

<!-------------- Discard/Pack confirmation popup ---------------->
<div class="popup" [ngClass]="updateBagConfirmationPopup ? 'visible': ''">
  <div class="innerpopup">
    <a class="close" (click)="updateBagConfirmationPopup = false"><img src="assets/images/edi-close.png" /></a>
    <h2>Confirmation</h2>
    <!------ Discard ------->
    <span *ngIf="updateBagStatusAction == 'DISCARDED'">
      <p>Are you sure you want to discard the bag?</p>
      <p>Note: No items can be added once bag is discarded.</p>
    </span>
    <!------- Pack -------->
    <span *ngIf="updateBagStatusAction == 'COMPLETE'">
      <p>No items can be added once the bag is marked closed.</p>
      <p>Note: Label will be printed which need to be pasted on the box.</p>
    </span>
    <button (click)="updateBagStatusSubmit()">Yes, Go AHEAD</button>
    <a class="close-black" (click)="updateBagConfirmationPopup = false"><img src="assets/images/back-icon.png" /></a>
  </div>
</div>