"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ApiService = /** @class */ (function () {
    /**
     * Creates an instance of ApiService
     * @param http - HTTP service to call the APIS
     * */
    function ApiService(http) {
        this.http = http;
    }
    ApiService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = "operation"; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.log(operation + " failed: " + error.message);
            // Let the app keep running by returning an empty result.
            return rxjs_1.of(result);
        };
    };
    ApiService.prototype.get = function (url, options) {
        if (options === void 0) { options = {}; }
        return this.http
            .get(url, options)
            .pipe(operators_1.catchError(this.handleError("url", [])));
    };
    ApiService.prototype.post = function (url, data, httpOptions) {
        if (httpOptions === void 0) { httpOptions = {}; }
        return this.http.post(url, data, httpOptions);
    };
    ApiService.prototype.put = function (url, data, httpOptions) {
        if (httpOptions === void 0) { httpOptions = {}; }
        return this.http.put(url, data, httpOptions);
    };
    ApiService.prototype.delete = function (url, httpOptions) {
        if (httpOptions === void 0) { httpOptions = {}; }
        return this.http.delete(url, httpOptions);
    };
    ApiService.prototype.getFile = function (url) {
        return this.http.get(url, { responseType: "blob" });
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
exports.ApiService = ApiService;
