import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { AuthService, SharedService, AlertService } from "../services";

@Injectable({
  providedIn: "root"
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService,
    private sharedService: SharedService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.sharedService.showHideLoader(false);
            // do stuff with response if you want
            this.sharedService.showHideLoader(false);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const errorCode = err.error.error
              ? (err.error.error.message || "").split(":")[0]
              : "";
            this.sharedService.showHideLoader(false);
            if (err.status === 401 || errorCode === "401") {
              this.auth.logout();
              let errorMessage = err.error.error
                ? err.error.error.message
                : err.message;
              this.alertService.showError(errorMessage);
            } else if (err.status === 500) {
              this.alertService.showError(err.error.error);
            } else {
              let errorMessage = err.error.error
                ? err.error.error.message
                : err.message;
              // err.error.error.message || ""
              this.alertService.showError(errorMessage);
            }
          }
        }
      )
    );
  }
}

export let responseProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: ResponseInterceptor,
  multi: true
};
