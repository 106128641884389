"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var button_1 = require("@angular/material/button");
var datepicker_1 = require("@angular/material/datepicker");
var menu_1 = require("@angular/material/menu");
var material_1 = require("@angular/material");
var tabs_1 = require("@angular/material/tabs");
var paginator_1 = require("@angular/material/paginator");
var form_field_1 = require("@angular/material/form-field");
var icon_1 = require("@angular/material/icon");
var material_2 = require("@angular/material");
var sort_1 = require("@angular/material/sort");
var select_1 = require("@angular/material/select");
var chips_1 = require("@angular/material/chips");
var checkbox_1 = require("@angular/material/checkbox");
var expansion_1 = require("@angular/material/expansion");
var radio_1 = require("@angular/material/radio");
var ngx_mat_select_search_1 = require("ngx-mat-select-search");
var moduleList = [
    radio_1.MatRadioModule,
    expansion_1.MatExpansionModule,
    checkbox_1.MatCheckboxModule,
    icon_1.MatIconModule,
    form_field_1.MatFormFieldModule,
    datepicker_1.MatDatepickerModule,
    sort_1.MatSortModule,
    material_1.MatNativeDateModule,
    material_1.MatAutocompleteModule,
    chips_1.MatChipsModule,
    material_2.MatTableModule,
    material_1.MatInputModule,
    button_1.MatButtonModule,
    menu_1.MatMenuModule,
    select_1.MatSelectModule,
    paginator_1.MatPaginatorModule,
    tabs_1.MatTabsModule,
    dialog_1.MatDialogModule,
    material_1.MatProgressSpinnerModule,
    ngx_mat_select_search_1.NgxMatSelectSearchModule,
    dialog_1.MatDialogModule
];
var ɵ0 = [];
exports.ɵ0 = ɵ0;
var AppMaterialModule = /** @class */ (function () {
    function AppMaterialModule() {
    }
    return AppMaterialModule;
}());
exports.AppMaterialModule = AppMaterialModule;
