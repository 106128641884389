import { createSelector } from "@ngrx/store";
import { WholesaleState } from "./wholesale.state";
import { AppState } from "@app/app.state";

const getWholesaleState = (state: AppState) => state.wholesale;
const currentRouteSummary = (state: WholesaleState) => state.routeSummary;
const warehouseList = (state: WholesaleState) => state.warehouseList;

const currentDeliveryAssociates = (state: WholesaleState) => state.associates;
const currentSimulatedSummary = (state: WholesaleState) =>
  state.simulatedSummary;
const currentPicklistSummary = (state: WholesaleState) => state.picklistSummary;
const getAccountId = (state: WholesaleState) => state.accountId;
const getConsolidatedPicklist = (state: WholesaleState) =>
  state.consolidatedPicklist;
const getTitles = (state: WholesaleState) =>
  state.titles;

export const getCurrentRouteSummary = createSelector(
  getWholesaleState,
  currentRouteSummary
);

export const getWarehouseList = createSelector(
  getWholesaleState,
  warehouseList
);
export const getCurrentDeliveryAssociates = createSelector(
  getWholesaleState,
  currentDeliveryAssociates
);

export const getCurrentSimulatedSummary = createSelector(
  getWholesaleState,
  currentSimulatedSummary
);

export const getCurrentPicklistSummary = createSelector(
  getWholesaleState,
  currentPicklistSummary
);
export const getAccountIdStatus = createSelector(
  getWholesaleState,
  getAccountId
);
export const getConsolidatedStatus = createSelector(
  getWholesaleState,
  getConsolidatedPicklist
);

export const getProductTitles = createSelector(
  getWholesaleState,
  getTitles
);
