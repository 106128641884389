"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./login-layout.component.sass.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("./login-layout.component");
var styles_LoginLayoutComponent = [i0.styles];
var RenderType_LoginLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginLayoutComponent, data: {} });
exports.RenderType_LoginLayoutComponent = RenderType_LoginLayoutComponent;
function View_LoginLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoginLayoutComponent_0 = View_LoginLayoutComponent_0;
function View_LoginLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-layout", [], null, null, null, View_LoginLayoutComponent_0, RenderType_LoginLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoginLayoutComponent_Host_0 = View_LoginLayoutComponent_Host_0;
var LoginLayoutComponentNgFactory = i1.ɵccf("app-login-layout", i3.LoginLayoutComponent, View_LoginLayoutComponent_Host_0, {}, {}, []);
exports.LoginLayoutComponentNgFactory = LoginLayoutComponentNgFactory;
