"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DashboardActionTypes;
(function (DashboardActionTypes) {
    DashboardActionTypes["LOAD_DASHBOARD"] = "[Dashboard Page] Load Dashboard Shipment";
    DashboardActionTypes["LOAD_DASHBOARD_SUCCESS"] = "[Dashboard Page] Load Dashboard Shipment Complete";
    DashboardActionTypes["LOAD_DASHBOARD_FAILED"] = "[Dashboard Page] Load Dashboard Shipment Error";
    DashboardActionTypes["REMOVAL_TODO_LIST"] = "[Dashboard Removal] Get Removal TODO List";
    DashboardActionTypes["REMOVAL_TODO_LIST_SUCCESS"] = "[Dashboard Removal Success] Get Removal TODO List";
    DashboardActionTypes["REMOVAL_TODO_LIST_FAILED"] = "[Dashboard Removal Failure] Get Removal TODO List";
})(DashboardActionTypes = exports.DashboardActionTypes || (exports.DashboardActionTypes = {}));
var LoadDashboard = /** @class */ (function () {
    function LoadDashboard(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LOAD_DASHBOARD;
    }
    return LoadDashboard;
}());
exports.LoadDashboard = LoadDashboard;
var LoadDashboardComplete = /** @class */ (function () {
    function LoadDashboardComplete(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LOAD_DASHBOARD_SUCCESS;
    }
    return LoadDashboardComplete;
}());
exports.LoadDashboardComplete = LoadDashboardComplete;
var LoadDashboardError = /** @class */ (function () {
    function LoadDashboardError(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LOAD_DASHBOARD_FAILED;
    }
    return LoadDashboardError;
}());
exports.LoadDashboardError = LoadDashboardError;
/**
 * Removal
 */
var RemovalTODOListAction = /** @class */ (function () {
    function RemovalTODOListAction(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.REMOVAL_TODO_LIST;
    }
    return RemovalTODOListAction;
}());
exports.RemovalTODOListAction = RemovalTODOListAction;
var RemovalTODOListSuccessAction = /** @class */ (function () {
    function RemovalTODOListSuccessAction(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.REMOVAL_TODO_LIST_SUCCESS;
    }
    return RemovalTODOListSuccessAction;
}());
exports.RemovalTODOListSuccessAction = RemovalTODOListSuccessAction;
var RemovalTODOListFailureAction = /** @class */ (function () {
    function RemovalTODOListFailureAction(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.REMOVAL_TODO_LIST_FAILED;
    }
    return RemovalTODOListFailureAction;
}());
exports.RemovalTODOListFailureAction = RemovalTODOListFailureAction;
