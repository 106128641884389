import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-gatepass',
  templateUrl: './add-gatepass.component.html',
  styleUrls: ['./add-gatepass.component.scss']
})
export class AddGatepassComponent implements OnInit {
  baditemtable:boolean = false;
  constructor() { }
  baditemclick(){
    this.baditemtable = !this.baditemtable;
  }
  ngOnInit() {
  }

}
