"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".picklist-top-pane[_ngcontent-%COMP%]{padding-top:0}.picklist-associate[_ngcontent-%COMP%], .picklist-route[_ngcontent-%COMP%]{border-right:1px solid #000}.picklist-associate[_ngcontent-%COMP%], .picklist-route[_ngcontent-%COMP%], .picklist-timing[_ngcontent-%COMP%]{padding:10px;border-bottom:1px solid #000}.picklist-footer[_ngcontent-%COMP%]{border:0}"];
exports.styles = styles;
