<style>
    body {
        margin: 0;
        padding: 0;
    }

    table {
        font-family: Arial;
        min-width: 750px;
    }

    th,
    td {
        padding: 10px;
    }

    .brdr-tp {
        border-top: 1px solid #000;
    }

    .brdr-btm {
        border-bottom: 1px solid #000;
    }

    .brdr-lft {
        border-left: 1px solid #000;
    }

    .brdr-rgt {
        border-right: 1px solid #000;
    }

    p {
        margin: 0;
    }
</style>
<table class="table" width="100%" cellspacing="0" cellpadding="0">
    <thead>
        <tr>
            <th class="brdr-tp brdr-btm brdr-lft" colspan="3">
                <h2 style="font-weight: bold; font-size: 20px; text-align: left;  padding: 0;  margin: 0; ">
                    Packing List: {{ getInventoryType(bagDetails?.inventoryType) }} Inventory </h2>
            </th>
            <th class="brdr-tp brdr-rgt brdr-btm">
                <p style="text-align: right;">
                    <ngx-barcode [bc-font-size]="14" [bc-height]="40" [bc-value]="bagID" [bc-display-value]="true">
                    </ngx-barcode>
                </p>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style="padding: 0;" colspan="4">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td width="50%" style="font-size: 14px;" class="brdr-lft">
                                <p> Recall Consignment Number: {{ bagDetails?.refNumber }} </p>
                            </td>
                            <td width="50%" style="font-size: 14px;" class="brdr-rgt">
                                <p> Box Number: {{ bagID }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" style="font-size: 14px;" class="brdr-lft">
                                <p> Qty: {{ bagDetails?.quantity }} </p>
                            </td>
                            <td width="50%" style="font-size: 14px;" class="brdr-rgt">
                                <p> Inventory type: {{ getInventoryType(bagDetails?.refType) }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" style="font-size: 14px;" class="brdr-btm brdr-lft">
                                <p> Created by: {{ bagDetails?.createdBy }} </p>
                            </td>
                            <td width="50%" style="font-size: 14px;" class="brdr-btm brdr-rgt">
                                <p> Created at: {{ bagDetails?.updatedAt | date: 'medium' }} </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 0;" colspan="4">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td class="brdr-rgt brdr-btm brdr-lft"> <b> Sr No.</b> </td>
                            <td class="brdr-rgt brdr-btm"> <b> Item Barcode </b> </td>
                            <td class="brdr-rgt brdr-btm"> <b> Client SKU</b> </td>
                            <td class="brdr-rgt brdr-btm" width="15%"> <b> Quantity </b></td>
                        </tr>
                        <tr *ngFor="let item of bagItemDetails; let i = index">
                            <td class="brdr-rgt brdr-btm brdr-lft"> {{i+1}}. </td>
                            <td class="brdr-rgt brdr-btm"> {{ item?.itemSkuCode }} </td>
                            <td class="brdr-rgt brdr-btm"> {{ item?.clientSku }} </td>
                            <td class="brdr-rgt brdr-btm"> {{ item?.quantity }} </td>
                        </tr>
                        <tr>
                            <td class="brdr-btm brdr-lft"> &nbsp; </td>
                            <td class="brdr-btm"> &nbsp; </td>
                            <td class="brdr-btm brdr-rgt"> Total </td>
                            <td class="brdr-rgt brdr-btm"> {{ bagDetails?.quantity }} </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>