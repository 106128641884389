"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var OrderActions = require("../actions/order.actions");
exports.initialState = {
    loaded: false,
    loading: false,
    entities: {},
    selectedId: null,
    search_text: null,
    sku_details: null
};
function OrderReducer(state, action) {
    var _a;
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        case OrderActions.OrderActionTypes.SEARCH_COMPLETE: {
            var order = action.payload;
            if (order != null) {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: true, loading: false, selectedId: order.customerOrderNumber.toString(), entities: Object.assign({}, state.entities, (_a = {},
                        _a[order.customerOrderNumber.toString()] = order,
                        _a)) });
            }
            else {
                return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: false });
            }
        }
        case OrderActions.OrderActionTypes.SEARCH_ERROR: {
            return tslib_1.__assign(tslib_1.__assign({}, state), { loaded: false, loading: false });
        }
        default: {
            return state;
        }
    }
}
exports.OrderReducer = OrderReducer;
