"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var PrintService = /** @class */ (function () {
    function PrintService(router) {
        this.router = router;
        this.isPrinting = false;
    }
    PrintService.prototype.printDocument = function (documentName, documentData) {
        this.isPrinting = true;
        if (documentData) {
            this.router.navigate([
                "/",
                {
                    outlets: {
                        print: ["print", documentName, documentData.join()]
                    }
                }
            ]);
        }
        else {
            this.router.navigate([
                "/",
                {
                    outlets: {
                        print: ["print", documentName]
                    }
                }
            ]);
        }
    };
    PrintService.prototype.printConsignment = function (documentName, documentData) {
        this.isPrinting = true;
        if (documentData) {
            this.router.navigate([
                "/",
                {
                    outlets: {
                        print: ["print", documentName, documentData.join()]
                    }
                }
            ]);
        }
    };
    PrintService.prototype.onDataReady = function () {
        var _this = this;
        rxjs_1.of([])
            .pipe(operators_1.delay(100))
            .subscribe(function () {
            window.print();
            _this.isPrinting = false;
            _this.router.navigate([{ outlets: { print: null } }]);
        });
    };
    PrintService.ngInjectableDef = i0.defineInjectable({ factory: function PrintService_Factory() { return new PrintService(i0.inject(i1.Router)); }, token: PrintService, providedIn: "root" });
    return PrintService;
}());
exports.PrintService = PrintService;
