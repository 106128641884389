<div class="main-page">
  <div class="topbar">
    <a><img src="assets/images/brand-img.png" /></a>
    <a routerLink="/recall"><img src="assets/images/edi-close.png" /></a>
  </div>
  <div class="wrapper">
    <h2>Add Bad Item to Gatepaas</h2>
    <div class="item-barcode">
      <h3>Item Bar Code</h3>
      <form class="itembarcodeform">
        <div class="flex-row">
          <div class="block">
              <input placeholder="Scan Bar Code" value="">
          </div>
          <div class="block">
              <button mat-button value="" class="submit-btn"></button>
          </div>
        </div>          
      </form>        
    </div><!-- Item bar code -->

    <div class="item-barcode">
      <h3>Scan Tote</h3>
      <form class="itembarcodeform">
        <div class="flex-row">
          <div class="block">
              <input placeholder="Scan Bar Code" value="">
          </div>
          <div class="block">
              <button mat-button value="" class="submit-btn"></button>
          </div>
        </div>          
      </form>        
    </div><!-- Item bar code -->


    <div class="item-details-block">
      <h3>Massimo Italiano Casuals For men</h3>
      <div class="flex-row">
        <div class="item-img">
          <img src="https://rukminim1.flixcart.com/image/300/300/jtyouq80/shoe/b/w/x/ly5010-8-layasa-maron-original-imaff72b7thzfecq.jpeg" width="150" />
        </div>
        <div class="item-details">
          <ul>
            <li>
              <span>Item Code</span>
              <span>G017563</span>
            </li>
            <li>
              <span>Inv. Type</span>
              <span>Good Inventory</span>
            </li>
            <li>
              <span>Item SKU</span>
              <span>BBSHIOMI3265_6</span>
            </li>
            <li>
              <span>Vendor Code</span>
              <span>CLAK123</span>
            </li>
            <li>
              <span>Vendor Name</span>
              <span>Clarks Future Footwear P...</span>
            </li>
            <li>
              <span>Facility</span>
              <span>MJPAT_109</span>
            </li>
            <div class="btn-right">
              <a><span>A</span>ADD</a>
            </div>
          </ul>
        </div>
      </div>
      <p class="error-msg">This item bar code doesn't belong to bad inventory. Please scan the correct
          item bar code.</p>
    </div>
    <!-- ======== item details ======= -->

    <div class="item-table-block">
        <div class="topbar">
          <h3>Item Summary (130 qty)</h3>
          <div class="pagination">
              <mat-paginator [length]="100"
                  [pageSize]="10"
                  [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>

          </div>
        </div>
        <div class="table">
          <ul>
            <li class="thead">
              <span>Item Code <img src="assets/images/filter-icon.png" /></span>
              <span>item Sku<img src="assets/images/filter-icon.png" /></span>
              <span>inv. Type<img src="assets/images/filter-icon.png" /></span>
              <span>vendor code</span>
              <span>Vendor name</span>
              <span>Facility</span>
            </li>
            <li class="tbody" (click)="baditemclick()">
              <span>123456</span>
              <span>BBSHOM325665_6</span>
              <span>QC Rejected</span>
              <span>ABC123</span>
              <span>Eshopbox Ecommerce Pvt Ltd.</span>
              <span>MJPAT_109</span>
            </li>
            <div class="table" [ngClass]="baditemtable ? 'visible' : ''">
              <ul>
                <li class="thead">
                  <span>Gatepaas No. <img src="assets/images/filter-icon.png" /></span>
                  <span>item Code<img src="assets/images/filter-icon.png" /></span>
                  <span>Item Sku<img src="assets/images/filter-icon.png" /></span>
                  <span>Qty</span>
                </li>
                <li class="tbody">
                  <span>123456</span>
                  <span>BBSHOM325665_6</span>
                  <span>Eshopbox Ecommerce Pvt Ltd.</span>
                  <span>100</span>
                </li>
                <li class="tbody">
                  <span>123456</span>
                  <span>BBSHOM325665_6</span>
                  <span>Eshopbox Ecommerce Pvt Ltd.</span>
                  <span>100</span>
                </li>
                <li class="tbody">
                  <span>123456</span>
                  <span>BBSHOM325665_6</span>
                  <span>Eshopbox Ecommerce Pvt Ltd.</span>
                  <span>100</span>
                </li>
              </ul>
            </div>
            <li class="tbody">
              <span>123456</span>
              <span>BBSHOM325665_6</span>
              <span>QC Rejected</span>
              <span>ABC123</span>
              <span>Eshopbox Ecommerce Pvt Ltd.</span>
              <span>MJPAT_109</span>
            </li>
            <li class="tbody">
              <span>123456</span>
              <span>BBSHOM325665_6</span>
              <span>QC Rejected</span>
              <span>ABC123</span>
              <span>Eshopbox Ecommerce Pvt Ltd.</span>
              <span>MJPAT_109</span>
            </li>
          </ul>
        </div>

    </div>
    <!-- Item summery table -->
  </div>
</div>  