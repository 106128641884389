import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { PicklistsComponent } from "./components/picklists/picklists.component";
import { LoginLayoutComponent } from "./components/_layouts/login-layout/login-layout.component";
import { HomeLayoutComponent } from "./components/_layouts/home-layout/home-layout.component";
import { ConsignReceiveLayoutComponent } from "./components/_layouts/consign-receive-layout/consign-receive-layout.component";
import { AmazonWholesaleComponent } from './components/amazon-wholesale/amazon-wholesale.component';
import { SearchProductComponent } from './components/search-product/search-product.component';
import { PackItemComponent } from './consignment-recall/components/pack-item/pack-item.component';
import { RecallPackItemLayoutComponent } from './components/_layouts/recall-pack-item-layout/recall-pack-item-layout.component';


const routes: Routes = [
  {
    path: "",
    component: LoginLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./auth/auth.module#AuthModule"
      }
    ]
  },
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: "./dashboard/index#DashboardModule"
      },

      {
        path: "",
        redirectTo: "wholesale",
        pathMatch: "full"
      },
      {
        path: "wholesale",
        loadChildren: "./wholesale/index#WholesaleModule"
      },


      {
        path: "inbound",
        loadChildren: "./inbound/index#InboundModule"
      },

      {
        path: "manage-inventory",
        component: AmazonWholesaleComponent
      },
      {
        path: "picklist",
        component: PicklistsComponent
      },
      {
        path: "shipment",
        loadChildren: "./shipment/index#ShipmentModule"
      },
      {
        path: "user",
        loadChildren: "./user/index#UserModule"
      },
      {
        path: 'setting',
        loadChildren: './setting/setting.module#SettingModule',
        data: { title: 'Settings  | WMS' }
      },
      {
        path: "search-product",
        component: SearchProductComponent,
        data: { title: 'Search Product  | WMS' }
      },
      {
        path: "consignment-recall",
        // component: RecallListComponent,
        loadChildren: './consignment-recall/consignment-recall.module#ConsignmentRecallModule',
        data: { title: 'Recall  | WMS' }
      },
    ]
  },

  {
    path: "",
    component: ConsignReceiveLayoutComponent,
    children: [
      {
        path: "consignment-receive",
        loadChildren: "./consignment-receive/index#ConsignmentReceiveModule"
      }
    ]
  },

  {
    path: "",
    component: RecallPackItemLayoutComponent,
    children: [
      {
        path: "consignment-recall/pack-item",
        component: PackItemComponent
      }
    ]
  },



  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
