import * as BrandAction from './brand.action';
import { BrandState } from './brand.state';

export const initialState: BrandState = {
    brandList: null,
    loadedBrandList: false,
    loadingBrandList: false,
    brandListError: null
}

export function reducer(state: BrandState = initialState, action: any) {
    switch (action.type) {
        case BrandAction.GET_BRAND_LIST:
            return {
                ...state,
                brandList: null,
                loadingBrandList: true,
                loadedBrandList: false,
                brandListError: null
            };
            break;
        case BrandAction.GET_BRAND_LIST_SUCCESS:
            return {
                ...state,
                brandList: action.payload,
                loadingBrandList: false,
                loadedBrandList: true,
                brandListError: null
            };
            break;
        case BrandAction.GET_BRAND_LIST_FAILURE:
            return {
                ...state,
                brandList: null,
                loadingBrandList: false,
                loadedBrandList: true,
                brandListError: action.payload
            };
            break;
    }
}

export const brandList = (state: BrandState = initialState) => state.brandList;
export const loadingBrandList = (state: BrandState = initialState) => state.loadingBrandList;
export const loadedBrandList = (state: BrandState = initialState) => state.loadedBrandList;
export const brandListError = (state: BrandState = initialState) => state.brandListError;