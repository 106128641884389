"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var router_1 = require("@angular/router");
var services_1 = require("../services");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("../services/auth.service");
var i3 = require("../services/alert.service");
var i4 = require("../services/shared.service");
var ResponseInterceptor = /** @class */ (function () {
    function ResponseInterceptor(router, auth, alertService, sharedService) {
        this.router = router;
        this.auth = auth;
        this.alertService = alertService;
        this.sharedService = sharedService;
    }
    ResponseInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                _this.sharedService.showHideLoader(false);
                // do stuff with response if you want
                _this.sharedService.showHideLoader(false);
            }
        }, function (err) {
            if (err instanceof http_1.HttpErrorResponse) {
                var errorCode = err.error.error
                    ? (err.error.error.message || "").split(":")[0]
                    : "";
                _this.sharedService.showHideLoader(false);
                if (err.status === 401 || errorCode === "401") {
                    _this.auth.logout();
                    var errorMessage = err.error.error
                        ? err.error.error.message
                        : err.message;
                    _this.alertService.showError(errorMessage);
                }
                else if (err.status === 500) {
                    _this.alertService.showError(err.error.error);
                }
                else {
                    var errorMessage = err.error.error
                        ? err.error.error.message
                        : err.message;
                    // err.error.error.message || ""
                    _this.alertService.showError(errorMessage);
                }
            }
        }));
    };
    ResponseInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function ResponseInterceptor_Factory() { return new ResponseInterceptor(i0.inject(i1.Router), i0.inject(i2.AuthService), i0.inject(i3.AlertService), i0.inject(i4.SharedService)); }, token: ResponseInterceptor, providedIn: "root" });
    return ResponseInterceptor;
}());
exports.ResponseInterceptor = ResponseInterceptor;
exports.responseProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: http_1.HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
};
