"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var recall_list_component_1 = require("./components/recall-list/recall-list.component");
var recall_home_component_1 = require("./components/recall-home/recall-home.component");
var recall_details_component_1 = require("./components/recall-details/recall-details.component");
var add_gatepass_component_1 = require("./components/add-gatepass/add-gatepass.component");
/**
 * Define routes
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
var routes = [
    { path: '', component: recall_home_component_1.RecallHomeComponent },
    { path: 'list', component: recall_list_component_1.RecallListComponent },
    { path: 'add-gatepass', component: add_gatepass_component_1.AddGatepassComponent },
    { path: 'details/:accountId/:id', component: recall_details_component_1.RecallDetailsComponent },
];
var ConsignmentRecallRoutingModule = /** @class */ (function () {
    function ConsignmentRecallRoutingModule() {
    }
    return ConsignmentRecallRoutingModule;
}());
exports.ConsignmentRecallRoutingModule = ConsignmentRecallRoutingModule;
