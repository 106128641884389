import { Injectable } from "@angular/core";
import { ApiService } from "../../shared/services/api.service";
import { Configuration } from "../../shared/configuration/config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getAccountIdStatus } from "../../wholesale/reducers/selectors";
import { AppState } from "../../app.state";
@Injectable({
  providedIn: "root"
})
export class ConsignmentReceiveService {
  wareHouseId;
  constructor(
    private api: ApiService,
    private config: Configuration,
    private store: Store<AppState>
  ) {
    this.getWarehouseId();
  }
  getWarehouseId() {
    this.store.pipe(select(getAccountIdStatus)).subscribe(result => {
      this.wareHouseId = result;
    });
  }
  /**
    * @author Nidhi Raturi
    * @param Object - params
    * @description Api call to add Consignment REceive Details(Page 1 and 2)
    * @return Observable<any> -

    */
  addConsignReceiveDetails(params, requestType): Observable<any> {
    return this.api[requestType](
      this.config.UrlObj.addConsignmentReceiveDetail,
      params
    );
  }

  /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to add Consignment Box Details(Page 3)
  * @return Observable<any> -

  */
  addBoxDetails(params = {}): Observable<any> {
    return this.api.post(this.config.UrlObj.addConsignmentBoxDetails, params);
  }


  /**
 * @author Nidhi Raturi
 * @params Object - params
 * @description Api call to cancel Consignment
 * @return Observable<any> -

 */
  cancelConsignment(params = {}): Observable<any> {
    return this.api.put(this.config.UrlObj.cancelConsignment, params);
  }


  /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to get Bags Details(Page 4)
  * @return Observable<any> -

  */
  getBagsDetails(consignmentNo): Observable<any> {
    return this.api.get(this.config.UrlObj.getBagListInward + encodeURIComponent(encodeURIComponent(consignmentNo)) + '/boxDetails');
  }

  /**
 * @author Nidhi Raturi
 * @params Object - params
 * @description Api call to update Consignment Box Details(Page 3)
 * @return Observable<any> -

 */
  updateBoxDetails(params = {}): Observable<any> {
    return this.api.put(this.config.UrlObj.updateBoxDetails, params);
  }

  /**
* @author Nidhi Raturi
* @params Object - params
* @description Api call to update Image Url
* @return Observable<any> -

*/
  updateImageUrlDetails(params = {}): Observable<any> {
    return this.api.put(this.config.UrlObj.updateImageUrlInbound, params);
  }
  /**
* @author Nidhi Raturi
* @params Object - params
* @description Api call to add Consignment Box Details(Page 3)
* @return Observable<any> -

*/
  markConsignmntReceive(params = {}): Observable<any> {
    return this.api.post(this.config.UrlObj.markConsignmentReceive, params);
  }

  /**
* @author Nidhi Raturi
* @params Object - params
* @description Api call to get Barcode Details(Page 3)
* @return Observable<any> -

*/
  getBarcodeDetails(boxes): Observable<any> {
    return this.api.post(
      this.config.UrlObj.getBarcodeDetailsApi +
      boxes +
      "&warehouseId=" +
      this.wareHouseId,
      {}
    );
  }




  /**
* @author Nidhi Raturi
* @params Object - params
* @description get Account Name List
* @return Observable<any> -

*/
  getAccountNameList(): Observable<any> {
    return this.api.get(
      this.config.UrlObj.getAccountNameList + "page=1&perPage=1000"
    );
  }

  /**
* @author Nidhi Raturi
* @params Object - params
* @description get Consignmnt No by Account ID
* @return Observable<any> -

*/
  getConsignmentByAccountId(accountId, refConsignmentNo): Observable<any> {
    return this.api.get(
      this.config.UrlObj.getConsignmentByAccountId +
      accountId +
      "&refConsignmentNumber=" +
      encodeURIComponent(encodeURIComponent(refConsignmentNo))
    );
  }

  /**
* @author Nidhi Raturi
* @params Object - params
* @description get Consignmnt No by Account ID
* @return Observable<any> -

*/
  getConsignmentReceiveDetails(consignmnentNo): Observable<any> {
    return this.api.get(
      this.config.UrlObj.getConsignmentReceiveDetails +
      encodeURIComponent(encodeURIComponent(consignmnentNo)) +
      "/receiveDetails"
    );
  }
}
