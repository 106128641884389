"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../constants/constants");
var RecallPackItemHeaderComponent = /** @class */ (function () {
    function RecallPackItemHeaderComponent() {
        this.ROUTES = constants_1.ROUTES;
    }
    RecallPackItemHeaderComponent.prototype.ngOnInit = function () {
    };
    return RecallPackItemHeaderComponent;
}());
exports.RecallPackItemHeaderComponent = RecallPackItemHeaderComponent;
