"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var services_1 = require("../../services");
var moment = require("moment");
var ConsignmentDetailComponent = /** @class */ (function () {
    function ConsignmentDetailComponent(route, printService, service) {
        this.route = route;
        this.printService = printService;
        this.service = service;
        this.currentDate = moment().format('LLLL');
        this.consignmentDetail = [];
        this.moment = moment;
        this.totalCases = 0;
        this.totalLoose = 0;
        this.totalBundles = 0;
        this.cases = 0;
        this.loose = 0;
        this.bundles = 0;
        this.foodFilteredList = [];
        this.nonfoodFilteredList = [];
    }
    ConsignmentDetailComponent.prototype.ngOnInit = function () {
        this.consignmentNo = this.route.snapshot.params["consignmentNo"];
        this.getConsignmentDetail();
    };
    ConsignmentDetailComponent.prototype.getConsignmentDetail = function () {
        var _this = this;
        this.service.getConsignmentDetail(this.consignmentNo).subscribe(function (res) {
            _this.consignmentDetail = res['data'];
            if (_this.consignmentDetail.length) {
                _this.consignmentDetail.sort(function (a, b) {
                    return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
                });
                _this.foodFilteredList = _this.consignmentDetail.filter(function (resp) {
                    return resp.isFood === "1";
                });
                _this.nonfoodFilteredList = _this.consignmentDetail.filter(function (response) {
                    return response.isFood !== "1";
                });
                _this.calculateTotalRows(_this.consignmentDetail);
                _this.printService.onDataReady();
            }
        });
    };
    ConsignmentDetailComponent.prototype.calculateTotalRows = function (items) {
        var _this = this;
        items.map(function (item) {
            if (item.isFood === "1") {
                _this.totalCases += item.totalCases;
                _this.totalLoose += item.totalLoose;
                _this.totalBundles += item.bundles;
            }
            if (item.isFood !== "1") {
                _this.cases += item.totalCases;
                _this.loose += item.totalLoose;
                _this.bundles += item.bundles;
            }
        });
    };
    ConsignmentDetailComponent.prototype.trackByFn = function (index) {
        return index;
    };
    return ConsignmentDetailComponent;
}());
exports.ConsignmentDetailComponent = ConsignmentDetailComponent;
