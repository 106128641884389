"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var getWholesaleState = function (state) { return state.wholesale; };
var ɵ0 = getWholesaleState;
exports.ɵ0 = ɵ0;
var currentRouteSummary = function (state) { return state.routeSummary; };
var ɵ1 = currentRouteSummary;
exports.ɵ1 = ɵ1;
var warehouseList = function (state) { return state.warehouseList; };
var ɵ2 = warehouseList;
exports.ɵ2 = ɵ2;
var currentDeliveryAssociates = function (state) { return state.associates; };
var ɵ3 = currentDeliveryAssociates;
exports.ɵ3 = ɵ3;
var currentSimulatedSummary = function (state) {
    return state.simulatedSummary;
};
var ɵ4 = currentSimulatedSummary;
exports.ɵ4 = ɵ4;
var currentPicklistSummary = function (state) { return state.picklistSummary; };
var ɵ5 = currentPicklistSummary;
exports.ɵ5 = ɵ5;
var getAccountId = function (state) { return state.accountId; };
var ɵ6 = getAccountId;
exports.ɵ6 = ɵ6;
var getConsolidatedPicklist = function (state) {
    return state.consolidatedPicklist;
};
var ɵ7 = getConsolidatedPicklist;
exports.ɵ7 = ɵ7;
var getTitles = function (state) {
    return state.titles;
};
var ɵ8 = getTitles;
exports.ɵ8 = ɵ8;
exports.getCurrentRouteSummary = store_1.createSelector(getWholesaleState, currentRouteSummary);
exports.getWarehouseList = store_1.createSelector(getWholesaleState, warehouseList);
exports.getCurrentDeliveryAssociates = store_1.createSelector(getWholesaleState, currentDeliveryAssociates);
exports.getCurrentSimulatedSummary = store_1.createSelector(getWholesaleState, currentSimulatedSummary);
exports.getCurrentPicklistSummary = store_1.createSelector(getWholesaleState, currentPicklistSummary);
exports.getAccountIdStatus = store_1.createSelector(getWholesaleState, getAccountId);
exports.getConsolidatedStatus = store_1.createSelector(getWholesaleState, getConsolidatedPicklist);
exports.getProductTitles = store_1.createSelector(getWholesaleState, getTitles);
