"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form.form.transferForm[_ngcontent-%COMP%]   select.form-style[_ngcontent-%COMP%]{background:#fff;border:none;border-bottom:1px solid #c9c9c9;width:100%;outline:0;padding:6px 2px}form.form.transferForm[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{font-size:14px;color:#333;display:block;margin-bottom:10px}form.form.transferForm[_ngcontent-%COMP%]   button.btn-button[_ngcontent-%COMP%]{border:2px solid #333;background:#fff;padding:0 0 0 29px;width:100px;position:relative;height:33px;font-size:13px;font-weight:400;color:#666;font-family:latomedium;margin:10px 0 0 auto;display:block;cursor:pointer;text-transform:uppercase}form.form.transferForm[_ngcontent-%COMP%]   button.btn-button[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{background:#e9e9e9;color:#333;display:inline-block;padding:4px 10px;position:absolute;left:0;top:0;bottom:0;text-align:center;font-weight:700;line-height:22px}form.form.transferForm[_ngcontent-%COMP%]   button.btn-button[_ngcontent-%COMP%]:hover{background:#000;color:#fff}"];
exports.styles = styles;
