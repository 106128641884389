"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var auth_actions_1 = require("@app/auth/actions/auth.actions");
var wholesale_actions_1 = require("../../../wholesale/actions/wholesale.actions");
var services_1 = require("../../services");
var constants_1 = require("@app/shared/constants/constants");
var selectors_1 = require("@app/wholesale/reducers/selectors");
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, auth, store, 
    // private wareHouseStore : Store<{wholesale}>,
    wholesaleService, sharedService) {
        this.router = router;
        this.auth = auth;
        this.store = store;
        this.wholesaleService = wholesaleService;
        this.sharedService = sharedService;
        this.searchfield = [
            { value: "shipment", viewValue: "Shipment" },
            { value: "picklist", viewValue: "Picklist ID" },
            { value: "consignment-no", viewValue: "Consignment No." }
            // { value: "manifests", viewValue: "Manifests" },
            // { value: "awb-number", viewValue: "AWB Number" }
        ];
        this.selectedFilter = "shipment";
        this.warehouseFromStore = null;
        this.ROUTES = constants_1.ROUTES;
        this.showLoader = false;
        this.searchText = null;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getWarehouseList();
        this.sharedService.loaderListner.subscribe(function (data) {
            _this.showLoader = data.status;
        });
        this.getAccountIdStatus$ = this.store
            .pipe(store_1.select(selectors_1.getAccountIdStatus))
            .subscribe(function (result) {
            if (result) {
                _this.warehouseFromStore = result;
            }
        });
    };
    HeaderComponent.prototype.getWareHouseId = function () {
        this.filteredValue = this._filter(this.selectedWarehouse);
    };
    HeaderComponent.prototype.getAllFilteredList = function () {
        this.filteredValue = this.warehouseList;
    };
    HeaderComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.warehouseList.filter(function (option) {
            return option.externalWarehouseID.toLowerCase().indexOf(filterValue) === 0;
        });
    };
    HeaderComponent.prototype.setValueOnStore = function () {
        this.store.dispatch(new wholesale_actions_1.SetAccountId(this.selectedWarehouse));
        window.location.reload();
    };
    HeaderComponent.prototype.getWarehouseList = function () {
        var _this = this;
        // this.store.dispatch(new GetWarehouseList({}));
        this.wholesaleService.getWarehouseList().subscribe(function (res) {
            _this.warehouseList = res["data"];
            if (_this.warehouseList && _this.warehouseList.length) {
                _this.selectedWarehouse = _this.warehouseFromStore
                    ? _this.warehouseFromStore
                    : _this.warehouseList[0]["externalWarehouseID"];
                localStorage.setItem("selectedWarehouseID", _this.selectedWarehouse);
                if (!_this.warehouseFromStore) {
                    _this.store.dispatch(new wholesale_actions_1.SetAccountId(_this.selectedWarehouse));
                }
                _this.warehouseList.forEach(function (warehouse) {
                    if (warehouse.externalWarehouseID === _this.warehouseFromStore) {
                        _this.sharedService.selectedWarehouseId = warehouse.id;
                    }
                });
            }
        });
    };
    HeaderComponent.prototype.onFilterChange = function (selectedValue) {
        this.selectedFilter = selectedValue;
    };
    HeaderComponent.prototype.trackByFn = function (index) {
        return index;
    };
    HeaderComponent.prototype.addSearchText = function ($event) {
        var value = $event.target.value.trim();
        if (value.length > 0 &&
            this.router.url !== constants_1.ROUTES.MARK_CONSIGNMENT_COMPLETE) {
            switch (this.selectedFilter) {
                case "picklist":
                    this.router.navigate([constants_1.ROUTES.WHOLESALE_SHIPMENT], {
                        queryParams: { id: value }
                    });
                    break;
                case "shipment":
                    this.router.navigate([constants_1.ROUTES.SHIPMENT], {
                        queryParams: { id: value }
                    });
                    break;
                case "consignment-no":
                    this.router.navigate([constants_1.ROUTES.MARK_CONSIGNMENT_COMPLETE], {
                        queryParams: { id: value }
                    });
                    break;
                default:
                    break;
            }
            this.store.dispatch(new auth_actions_1.SetFilter(value));
            if (this.selectedFilter !== "consignment-no") {
                this.searchText = null;
            }
        }
        else {
            this.store.dispatch(new auth_actions_1.SetFilter(value));
            if (this.selectedFilter !== "consignment-no") {
                this.searchText = null;
            }
        }
    };
    HeaderComponent.prototype.clearFilter = function () {
        this.store.dispatch(new auth_actions_1.SetFilter(null));
        this.searchText = null;
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        if (this.getAccountIdStatus$) {
            this.getAccountIdStatus$.unsubscribe();
        }
    };
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
