import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-upload-modal",
  templateUrl: "./upload-modal.component.html",
  styleUrls: ["./upload-modal.component.sass"]
})
export class UploadModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UploadModalComponent>) {}
  ngOnInit() {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
