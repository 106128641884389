"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var services_1 = require("../services");
var constants_1 = require("@app/shared/constants/constants");
var config_1 = require("../configuration/config");
var i0 = require("@angular/core");
var i1 = require("../services/auth.service");
var i2 = require("../services/alert.service");
var i3 = require("../services/shared.service");
var i4 = require("../services/wholesale.service");
var i5 = require("../configuration/config");
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(auth, alertService, sharedService, wholesaleService, config) {
        this.auth = auth;
        this.alertService = alertService;
        this.sharedService = sharedService;
        this.wholesaleService = wholesaleService;
        this.config = config;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        if (!navigator.onLine) {
            this.alertService.showError(constants_1.CONSTANTS.ALERT_MESSAGES.NO_INTERNET_CONNECTION);
        }
        else {
            // todo hack to be removed
            // console.log("INSIDE INTERCEPTOR", request)
            // var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
            // console.log("1", request.url.split('/'))
            // console.log("API DETECTOR", apidetector)
            if (request.responseType !== "blob") {
                request = request.clone({
                    setHeaders: {
                        ProxyHost: "amazon",
                        Authorization: "Bearer " + this.auth.getAuthToken(),
                        "Access-Control-Allow-Origin": "*",
                        warehouseId: this.wholesaleService.wareHouseId || ""
                        // warehouseId: 'WBLS' || ""
                    }
                });
            }
            if (!request.url.includes(this.config.API_URL)) {
                this.sharedService.showHideLoader(true);
            }
            return next.handle(request);
        }
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.AuthService), i0.inject(i2.AlertService), i0.inject(i3.SharedService), i0.inject(i4.WholesaleService), i0.inject(i5.Configuration)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
exports.TokenInterceptor = TokenInterceptor;
exports.tokenProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: http_1.HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
};
