import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { map, take, takeLast, tap, delay, share } from "rxjs/operators";
import { Subscription, Observable } from "rxjs";

import {
  singleItemDetailsSelector,
  loadingSingleItemDetailsSelector,
  loadedSingleItemDetailsSelector,
  singleItemDetailsErrorSelector,
  bagListSelector,
  loadingBagListSelector,
  loadedBagListSelector,
  bagListErrorSelector,
  addItemSelector,
  addingItemSelector,
  addedItemSelector,
  addItemErrorSelector,
  createBagErrorSelector,
  createdBagSelector,
  creatingBagSelector,
  createBagSelector,
  updateBagSelector,
  updatingBagSelector,
  updatedBagSelector,
  updateBagErrorSelector,
  bagItemDetailsSelector
} from "../../../bags/bag-selectors";
import { ConsoleLoggerService } from "../../../shared/services/console-logger.service";
import {
  GetSingleItemDetails,
  GetBagList,
  AddItem,
  CreateBag,
  UpdateBag
} from "../../../bags/bag.action";
import { AlertService, PrintService } from "../../../shared/services";
import { ROUTES, CONSTANTS } from "../../../shared/constants/constants";

@Component({
  selector: "app-pack-item",
  templateUrl: "./pack-item.component.html",
  styleUrls: ["./pack-item.component.scss"]
})
export class PackItemComponent implements OnInit, OnDestroy, AfterViewInit {
  Routes = ROUTES;
  Messages = CONSTANTS;

  inventoryType: string = "good";
  scannedItemDetails: any;
  scannedItemBagList: any;
  scannedItemBagListCount: number = 0;
  isLoading: boolean = false;
  actionPopup: boolean = false;
  showLoader: Boolean = false;

  packItemSubscriptionList$: Subscription = new Subscription();
  getItemDetails$: Subscription;
  getItemDetailsLoading$: Subscription;
  getItemDetailsLoaded$: Subscription;
  getItemDetailsError$: Subscription;
  getBagList$: Subscription;
  getBagListLoading$: Subscription;
  getBagListLoaded$: Subscription;
  getBagListError$: Subscription;
  addItem$: Subscription;
  addingItem$: Subscription;
  addedItem$: Subscription;
  addItemError$: Subscription;

  scannedBagged$: Observable<any>;
  isloadingBagList: boolean;
  isloadedBagList: boolean;
  isItemAdded: boolean = true;
  isItemAdding: boolean = false;
  bagID: any;
  bgItemQuantity: number = 0;
  BAG: any = {};
  bgData: any = [];
  showFlag: boolean = false;
  subscriptions: Subscription;

  scannedBarcode = new FormControl("");
  scannedBagID = new FormControl("");
  createFormGroup = new FormGroup({
    purpose: new FormControl(""),
    inventoryType: new FormControl("", Validators.required),
    numberOfBag: new FormControl("", Validators.required),
    brandName: new FormControl(""),
    consignmentNumber: new FormControl("")
  });

  @ViewChild("scanBarcodeEle") private scanBarcodeEleRef: ElementRef;
  @ViewChild("scanBagEle") private scanBagEleRef: ElementRef;

  /**
   * @description Scan item's barcode functionality
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  scanItem(scannedItemValue) {
    if (this.getItemDetails$) {
      this.getItemDetails$.unsubscribe();
      this.packItemSubscriptionList$.remove(this.getItemDetails$);
    }
    if (this.getItemDetailsError$) {
      this.getItemDetailsError$.unsubscribe();
      this.packItemSubscriptionList$.remove(this.getItemDetailsError$);
    }

    if (!scannedItemValue) {
      this.alertService.showError("Invalid request");
      return;
    }

    if (scannedItemValue.length < 4) {
      return;
    }

    this.scannedItemDetails = null;
    this.scannedItemBagList = null;
    this.scannedItemBagListCount = 0;
    this.scannedBagID.reset();
    this.getItemDetails(scannedItemValue);
  }

  /**
   * @description Get item details for scanned item's barcode
   * @param barcode
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getItemDetails(barcode) {
    let requestBody = {
      barcode: barcode
    };

    this._store.dispatch(new GetSingleItemDetails(requestBody));

    this.packItemSubscriptionList$.add(
      this._store
        .pipe(select(loadingSingleItemDetailsSelector))
        .subscribe(isLoading => {
          if (isLoading) {
            this.isLoading = true;
            this.showLoader = true;
          }
        })
    );

    this.packItemSubscriptionList$.add(
      this._store
        .pipe(select(loadedSingleItemDetailsSelector))
        .subscribe(isLoaded => {
          if (isLoaded) {
            this.isLoading = false;
            this.showLoader = false;
          }
        })
    );

    this.getItemDetails$ = this._store
      .pipe(select(singleItemDetailsSelector))
      .subscribe(data => {
        this.loggerService.consoleLog(data);

        if ((!data || !data["code"]) && !this.isLoading) {
          this.alertService.showError(
            this.Messages.MESSAGES.BARCODE_DETAILS_NOT_FOUND + " " + barcode
          );
          return true;
          //this.getItemDetails$.unsubscribe();
        }

        if (this.validateScannedItemResponse(data)) {
          this.loggerService.consoleLog("invalid status");
          return true;
        }

        this.scannedItemDetails = data;

        // Get bag list for respective recall consignment number
        if (data["recallConsignmentNumber"]) {
          this.getBagList(data["recallConsignmentNumber"]);
        }
      });
    this.packItemSubscriptionList$.add(this.getItemDetails$);

    this.getItemDetailsError$ = this._store
      .pipe(select(singleItemDetailsErrorSelector))
      .subscribe(error => {
        if (error) {
          this.loggerService.consoleLog(error, "error");
        }
      });
    this.packItemSubscriptionList$.add(this.getItemDetailsError$);
  }

  /**
   * @description Validate scanned item response data
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  validateScannedItemResponse(data) {
    if (!data || !data["status"]) {
      return true;
    }

    // Validate status
    switch (this.inventoryType.toLowerCase()) {
      case "good":
        if (data["status"] != "ALLOCATED") {
          this.alertService.showError(
            CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]
          );
          return true;
        }
        break;
      case "bad":
        if (data["status"] != "BAD_INVENTORY") {
          this.alertService.showError(
            CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]
          );
          return true;
        }
        break;
      case "qc rejected":
        if (data["status"] != "QC_REJECTED") {
          this.alertService.showError(
            CONSTANTS.MESSAGES.INVALID_SCANNED_ITEM_STATUS + " " + data["code"]
          );
          return true;
        }
        break;
    }

    return false;
  }

  /**
   * @description Get bag list for requested recall consignment number
   * @param recallConsignmentNumber
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getBagList(recallConsignmentNumber) {
    this._store.dispatch(
      new GetBagList({
        page: 1,
        perPage: 10,
        recallConsignmentNumber: recallConsignmentNumber,
        status: "CREATED,PROCESSING"
      })
    );

    // Response handling
    this.packItemSubscriptionList$.add(
      this._store.pipe(select(bagListSelector)).subscribe(data => {
        if (!data || !data["data"]) {
          return;
        }

        // this.scannedItemBagList = data['data'].filter(bag => {
        //   return !['DISCARDED', 'PACKED'].includes(bag['status']);
        // });

        this.scannedItemBagList = data["data"];
        this.scannedItemBagListCount = data["total"];

        setTimeout(() => {
          this.scanBagEleRef.nativeElement.focus();
        }, 0);

        this.loggerService.consoleLog(this.scannedItemBagList);
      })
    );

    this.packItemSubscriptionList$.add(
      this._store.pipe(select(loadingBagListSelector)).subscribe(isLoading => {
        if (isLoading) {
          this.isLoading = true;
        }
      })
    );

    this.packItemSubscriptionList$.add(
      this._store.pipe(select(loadedBagListSelector)).subscribe(isLoaded => {
        if (isLoaded) {
          this.isLoading = false;
        }
      })
    );

    // Error handling
    this.packItemSubscriptionList$.add(
      this._store.pipe(select(bagListErrorSelector)).subscribe(error => {
        if (error) {
          this.loggerService.consoleLog(error, "error");
        }
      })
    );
  }

  /**
   * @description Scan bag and add item into it
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  scanBag(bagId) {
    this.bagID = bagId;
    this._store.dispatch(
      new GetBagList({
        bagCode: bagId
      })
    );
    this.scannedBagged$ = this._store.pipe(select(bagListSelector)).pipe(
      map(bagData => {
        console.log("BAGD", bagData)
        if (bagData != null) {
          this.bgData = bagData["data"];
          if (this.bgData.length !== 0) {
            setTimeout(() => {
              this.bgItemQuantity = this.bgData[0]["quantity"];
              this.BAG = bagData["data"][0]; // {}
            })
          } else {
            this.showFlag = true;
          }
          // console.log("BAG", this.BAG)
          return bagData["data"];
        }
      })
    );
    this._store.pipe(select(loadingBagListSelector)).subscribe(loading => {

      this.isloadingBagList = loading;
      // console.log("BAG LOADING ", this.isloadingBagList);
    });

    this._store.pipe(select(loadedBagListSelector)).subscribe(loaded => {

      this.isloadedBagList = loaded;
      // console.log("BAG LOADED ", this.isloadedBagList);
      setTimeout(() => {
        if (this.isloadedBagList) {
          this.scanBarcodeEleRef.nativeElement.focus();
        }
      }, 0);
    });

  }

  scanBagItem(item) {
    if (!this.isItemAdding && this.isItemAdded) {
      this._store.dispatch(new AddItem({ barcode: item, bagId: this.bagID }));
    }
    let temp = 0;
    temp = this.bgItemQuantity;
    this.subscriptions = this._store.pipe(select(addItemSelector)).pipe(tap()).subscribe((addItem) => {
      if (addItem !== null) {
        if (temp == this.bgItemQuantity) {
          this.bgItemQuantity += 1;
          this.resetItem();
        }
      }
    },
      (error) => {
        if (error) {
          this.subscriptions.unsubscribe();
        }
      }
    );
    this._store.pipe(select(addingItemSelector)).subscribe(adding => {
      this.isItemAdding = adding;
    });
    this._store.pipe(select(addedItemSelector)).subscribe(added => {
      this.isItemAdded = added;
    });
  }
  resetBag() {
    this.scannedBagID.reset();
  }
  resetItem() {
    this.scannedBarcode.reset();
  }
  //  printPackedBagPDF(bagID){
  //   this.printService.printDocument("packed-bag", [bagID]);
  //  }

  // scanBag() {
  //   //Unsubscribe observables
  //   if (this.addItem$) {
  //     this.addItem$.unsubscribe();
  //     this.packItemSubscriptionList$.remove(this.addItem$);
  //   }
  //   if (this.addItemError$) {
  //     this.addItemError$.unsubscribe();
  //     this.packItemSubscriptionList$.remove(this.addItemError$);
  //   }

  //   if (!this.scannedBagID.value) {
  //     this.alertService.showError('Invalid bag request');
  //     return;
  //   }

  //   if (this.scannedBagID.value.length < 16) {
  //     return;
  //   }

  //   // Add item into bag functionality
  //   let requestBody = this.scannedItemDetails;
  //   requestBody['bagNumber'] = this.scannedBagID.value;
  //   this._store.dispatch(new AddItem(requestBody));

  //   // Response handling
  //   this.addItem$ = this._store.pipe(select(addItemSelector)).subscribe(response => {
  //     this.loggerService.consoleLog(response);

  //     if (response && response['bagNumber']) {
  //       setTimeout(() => {
  //         this.scanBarcodeEleRef.nativeElement.focus();
  //       }, 0);

  //       this.alertService.showSuccess('Item added into bag ' + response['bagNumber'] + ' successfully');
  //       this.reset();
  //     }
  //   });
  //   this.packItemSubscriptionList$.add(this.addItem$);

  //   // Loader handling
  //   this.packItemSubscriptionList$.add(this._store.pipe(select(addingItemSelector)).subscribe(isAdding => {
  //     if (isAdding) {
  //       this.isLoading = true;
  //       this.showLoader = true;
  //     }
  //   }));
  //   this.packItemSubscriptionList$.add(this._store.pipe(select(addedItemSelector)).subscribe(isAdded => {
  //     if (isAdded) {
  //       this.isLoading = false;
  //       this.showLoader = false;
  //     }
  //   }));

  //   // Error Handling
  //   this.addItemError$ = this._store.pipe(select(addItemErrorSelector)).subscribe(error => {
  //     this.loggerService.consoleLog(error);
  //   });
  //   this.packItemSubscriptionList$.add(this.addItemError$);
  // }

  /**
   * @description Reset page after adding item
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  reset() {
    this.scannedBarcode.reset();
    this.scannedBagID.reset();
    this.scannedItemDetails = "";
    this.scannedItemBagList = "";
    this.scannedItemBagListCount = 0;
  }

  /**
   * @description
   * 1. Set default create foem values
   * 2. Open create bag form
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  openCreateBagForm() {
    this.createFormGroup.patchValue({
      inventoryType: this.inventoryType
        .toUpperCase()
        .split(" ")
        .join("_"),
      brand: this.scannedItemDetails["brand"]
    });

    this.actionPopup = true;
  }

  /**
   * @description Create bag functionality
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  createFormSubmit() {
    this._store.dispatch(new CreateBag(this.createFormGroup.value));

    this._store.pipe(select(createBagSelector)).subscribe(data => {
      this.loggerService.consoleLog(data);

      if (!data) {
        this.loggerService.consoleLog("No response found in create bag");
        return;
      }

      this.actionPopup = false;
      this.createFormGroup.reset();
      this.getBagList(this.scannedItemDetails["recallConsignmentNumber"]);
      this.alertService.showSuccess(
        this.Messages.MESSAGES.BAG_CREATION_SUCCESS
      );
    });

    this._store.pipe(select(creatingBagSelector)).subscribe(isCreating => {
      this.loggerService.consoleLog(isCreating);
      if (isCreating) {
        this.isLoading = true;
        this.showLoader = true;
      }
    });

    this._store.pipe(select(createdBagSelector)).subscribe(isCreated => {
      this.loggerService.consoleLog(isCreated);
      if (isCreated) {
        this.isLoading = false;
        this.showLoader = false;
      }
    });

    this._store.pipe(select(createBagErrorSelector)).subscribe(error => {
      this.loggerService.consoleLog(error, "error");
    });
  }

  updateBagStatusAction: string = "";
  updateBagStatusBagID: any;
  updateBagConfirmationPopup: boolean = false;
  updateBagStatus(bagID, actionName) {
    if ((actionName != "DISCARDED" && actionName != "COMPLETE") || !bagID) {
      this.alertService.showError("Please provide valid action and bag id");
    }

    this.updateBagStatusAction = actionName;
    this.updateBagStatusBagID = bagID;
    this.updateBagConfirmationPopup = true;
  }

  /**
   * @description Pack/Discard requested bag
   *
   * @author Chandrika Aggarwal
   */
  updateBag$: Subscription;
  updateBagError$: Subscription;
  updateBagStatusSubmit() {
    if (this.updateBag$) {
      this.updateBag$.unsubscribe();
    }
    if (this.updateBagError$) {
      this.updateBagError$.unsubscribe();
    }

    let requestBody = {
      bagID: this.updateBagStatusBagID,
      status: this.updateBagStatusAction
    };

    this._store.dispatch(new UpdateBag(requestBody));

    this.updateBag$ = this._store
      .pipe(select(updateBagSelector))
      .subscribe(data => {
        if (!data) {
          return;
        }

        this.showLoader = false;

        if (data.status == this.updateBagStatusAction) {
          this.updateBagConfirmationPopup = false;
          this.updateBagStatusAction = "";
          this.updateBagStatusBagID = "";

          if (data.status == "COMPLETE" && data.refNumber) {
            this.alertService.showSuccess(
              "Bag " + data.bagNumber + " packed successfully"
            );
            this.printPackedBagPDF(data["bagNumber"]);

            this._store.pipe(select(bagItemDetailsSelector)).subscribe(data => {
              if (data && data["data"]) {
                this.getBagList(data["data"]["recallConsignmentNumber"]);
              }
            });
          } else if (data.status == "DISCARDED") {
            this.alertService.showSuccess(
              "Bag " + data.bagNumber + " discard successfully"
            );
            this.getBagList(data["data"]["recallConsignmentNumber"]);
          }

          //this.getBagList();
        }
      });

    this._store.pipe(select(updatingBagSelector)).subscribe(isUpdating => {
      if (isUpdating) {
        this.showLoader = true;
      }
    });

    this._store.pipe(select(updatedBagSelector)).subscribe(isUpdated => {
      if (isUpdated) {
        this.showLoader = false;
      }
    });

    this.updateBagError$ = this._store
      .pipe(select(updateBagErrorSelector))
      .subscribe(error => {
        if (!error) {
          return;
        }
      });
  }

  /**
   * @description Print Pack List PDF functionality
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  printPackedBagPDF(bagID) {
    this.printService.printDocument("packed-bag", [bagID]);
  }

  constructor(
    protected _activatedRoute: ActivatedRoute,
    protected alertService: AlertService,
    protected _store: Store<any>,
    protected loggerService: ConsoleLoggerService,
    protected printService: PrintService
  ) {
    this.inventoryType = this._activatedRoute.snapshot.queryParamMap.get(
      "type"
    );
  }

  ngOnInit() {

    // this._store.pipe(select(loadingBagListSelector)).subscribe(loading => {

    //       this.isloadingBagList = loading;
    //       console.log("BAG LOADING ", this.isloadingBagList);


    // });

    // this._store.pipe(select(loadedBagListSelector)).subscribe(loaded => {

    //     this.isloadedBagList = loaded;
    //     console.log("BAG LOADED ", this.isloadedBagList);


    //   // setTimeout(() => {
    //   //     if(this.isloadedBagList){
    //   //       this.scanBarcodeEle.nativeElement.focus();
    //   //     }
    //   //   }, 0);
    // });
  }

  ngAfterViewInit() {
    // console.log("element called.")
    //this.scanBarcodeEle.nativeElement.focus();
  }

  /**
   * @description
   * 1. Destroy active subscriptions
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  ngOnDestroy() {
    this.packItemSubscriptionList$.unsubscribe();

  }
}
