"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var BagAction = require("./bag.action");
exports.initialState = {
    bagList: null,
    loadingBagList: false,
    loadedBagList: false,
    bagListError: null,
    bagListForPDF: null,
    loadingBagListForPDF: false,
    loadedBagListForPDF: false,
    bagListErrorForPDF: null,
    createBag: null,
    creatingBag: false,
    createdBag: false,
    createBagError: null,
    updateBag: null,
    updatingBag: false,
    updatedBag: false,
    updateBagError: null,
    bagItemDetails: null,
    loadingBagItemDetails: false,
    loadedBagItemDetails: false,
    bagItemDetailsError: null,
    singleItemDetails: null,
    loadingSingleItemDetails: false,
    loadedSingleItemDetails: false,
    singleItemDetailsError: null,
    addItem: null,
    addingItem: false,
    addedItem: false,
    addItemError: null
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialState; }
    switch (action.type) {
        // --------------------------------------------------------
        case BagAction.GET_BAG_LIST:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagList: null, loadingBagList: true, loadedBagList: false, bagListError: null });
            break;
        case BagAction.GET_BAG_LIST_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagList: action.payload, loadingBagList: false, loadedBagList: true, bagListError: null });
            break;
        case BagAction.GET_BAG_LIST_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagList: null, loadingBagList: false, loadedBagList: true, bagListError: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.GET_BAG_LIST_FOR_PDF:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagListForPDF: null, loadingBagListForPDF: true, loadedBagListForPDF: false, bagListErrorForPDF: null });
            break;
        case BagAction.GET_BAG_LIST_FOR_PDF_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagListForPDF: action.payload, loadingBagListForPDF: false, loadedBagListForPDF: true, bagListErrorForPDF: null });
            break;
        case BagAction.GET_BAG_LIST_FOR_PDF_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagListForPDF: null, loadingBagListForPDF: false, loadedBagListForPDF: true, bagListErrorForPDF: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.CREATE_BAG:
            return tslib_1.__assign(tslib_1.__assign({}, state), { createBag: null, creatingBag: true, createdBag: false, createBagError: null });
            break;
        case BagAction.CREATE_BAG_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { createBag: action.payload, creatingBag: false, createdBag: true, createBagError: null });
            break;
        case BagAction.CREATE_BAG_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { createBag: null, creatingBag: false, createdBag: true, createBagError: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.UPDATE_BAG:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateBag: null, updatingBag: true, updatedBag: false, updateBagError: null });
            break;
        case BagAction.UPDATE_BAG_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateBag: action.payload, updatingBag: false, updatedBag: true, updateBagError: null });
            break;
        case BagAction.UPDATE_BAG_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { updateBag: null, updatingBag: false, updatedBag: true, updateBagError: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.GET_BAG_ITEM_DETAILS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagItemDetails: null, loadingBagItemDetails: true, loadedBagItemDetails: false, bagItemDetailsError: null });
            break;
        case BagAction.GET_BAG_ITEM_DETAILS_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagItemDetails: action.payload, loadingBagItemDetails: false, loadedBagItemDetails: true, bagItemDetailsError: null });
            break;
        case BagAction.GET_BAG_ITEM_DETAILS_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { bagItemDetails: null, loadingBagItemDetails: false, loadedBagItemDetails: true, bagItemDetailsError: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.GET_SINGLE_ITEM_DETAILS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { singleItemDetails: null, loadingSingleItemDetails: true, loadedSingleItemDetails: false, singleItemDetailsError: null });
            break;
        case BagAction.GET_SINGLE_ITEM_DETAILS_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { singleItemDetails: action.payload, loadingSingleItemDetails: false, loadedSingleItemDetails: true, singleItemDetailsError: null });
            break;
        case BagAction.GET_SINGLE_ITEM_DETAILS_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { singleItemDetails: null, loadingSingleItemDetails: false, loadedSingleItemDetails: true, singleItemDetailsError: action.payload });
            break;
        // --------------------------------------------------------
        case BagAction.ADD_ITEM:
            return tslib_1.__assign(tslib_1.__assign({}, state), { addItem: null, addingItem: true, addedItem: false, addItemError: null });
            break;
        case BagAction.ADD_ITEM_SUCCESS:
            return tslib_1.__assign(tslib_1.__assign({}, state), { addItem: action.payload, addingItem: false, addedItem: true, addItemError: null });
            break;
        case BagAction.ADD_ITEM_FAILURE:
            return tslib_1.__assign(tslib_1.__assign({}, state), { addItem: null, addingItem: false, addedItem: true, addItemError: action.payload });
            break;
        default:
            return state;
    }
}
exports.reducer = reducer;
exports.bagList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagList;
};
exports.loadingBagList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadingBagList;
};
exports.loadedBagList = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadedBagList;
};
exports.bagListError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagListError;
};
exports.bagListForPDF = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagListForPDF;
};
exports.loadingBagListForPDF = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadingBagListForPDF;
};
exports.loadedBagListForPDF = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadedBagListForPDF;
};
exports.bagListErrorForPDF = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagListErrorForPDF;
};
exports.createBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.createBag;
};
exports.creatingBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.creatingBag;
};
exports.createdBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.createdBag;
};
exports.createBagError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.createBagError;
};
exports.updateBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.updateBag;
};
exports.updatingBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.updatingBag;
};
exports.updatedBag = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.updatedBag;
};
exports.updateBagError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.updateBagError;
};
exports.bagItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagItemDetails;
};
exports.loadingBagItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadingBagItemDetails;
};
exports.loadedBagItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadedBagItemDetails;
};
exports.bagItemDetailsError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.bagItemDetailsError;
};
exports.singleItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.singleItemDetails;
};
exports.loadingSingleItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadingSingleItemDetails;
};
exports.loadedSingleItemDetails = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.loadedSingleItemDetails;
};
exports.singleItemDetailsError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.singleItemDetailsError;
};
exports.addItem = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.addItem;
};
exports.addingItem = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.addingItem;
};
exports.addedItem = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.addedItem;
};
exports.addItemError = function (state) {
    if (state === void 0) { state = exports.initialState; }
    return state.addItemError;
};
