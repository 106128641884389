import * as RecallAction from "../actions/consignment-recall.action";
import { ConsignmentRecallState } from "./consignment-recall.state";

export const initialState: ConsignmentRecallState = {
  consignmentList: null,
  consignmentListError: null,
  loadingConsignmentList: false,
  loadedConsignmentList: false,

  updateConsignment: null,
  updateConsignmentError: null,
  updatingConsignment: false,
  updatedConsignment: false,

  getGRNItemList: null,
  getGRNItemListError: null,
  loadingGRNItemList: false,
  loadedGRNItemList: false
};


export function ConsignmentRecallReducer(
  state = initialState,
  action: RecallAction.RecallUnion
) {
  switch (action.type) {
    case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST:
      return {
        ...state,
        consignmentList: null,
        consignmentListError: null,
        loadingConsignmentList: true,
        loadedConsignmentList: false
      };

    case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE:
      return {
        ...state,
        consignmentList: null,
        consignmentListError: action.payload,
        loadingConsignmentList: false,
        loadedConsignmentList: true
      };

    case RecallAction.ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        consignmentList: action.payload,
        consignmentListError: null,
        loadingConsignmentList: false,
        loadedConsignmentList: true
      };

    case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT:
      return {
        ...state,
        updateConsignment: null,
        updateConsignmentError: null,
        updatingConsignment: true,
        updatedConsignment: false
      };

    case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE:
      return {
        ...state,
        updateConsignment: null,
        updateConsignmentError: action.payload,
        updatingConsignment: false,
        updatedConsignment: true
      };

    case RecallAction.ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        updateConsignment: action.payload,
        updateConsignmentError: null,
        updatingConsignment: false,
        updatedConsignment: true
      };

    case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS:
      return {
        ...state,
        getGRNItemList: null,
        getGRNItemListError: null,
        loadingGRNItemList: true,
        loadedGRNItemList: false
      };

    case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE:
      return {
        ...state,
        getGRNItemList: null,
        getGRNItemListError: action.payload,
        loadingGRNItemList: false,
        loadedGRNItemList: true
      };

    case RecallAction.ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS:
      return {
        ...state,
        getGRNItemList: action.payload,
        getGRNItemListError: null,
        loadingGRNItemList: false,
        loadedGRNItemList: true
      };

    default:
      return state;
  }
}

// Get consignment list
export const getConsignmentList = (recallState: ConsignmentRecallState) => recallState.consignmentList;
export const getConsignmentListError = (recallState: ConsignmentRecallState) => recallState.consignmentListError;
export const getConsignmentListLoading = (recallState: ConsignmentRecallState) => recallState.loadingConsignmentList;
export const getConsignmentListLoaded = (recallState: ConsignmentRecallState) => recallState.loadedConsignmentList;

// Update consignment
export const updateConsignment = (recallState: ConsignmentRecallState) => recallState.updateConsignment;
export const updateConsignmentError = (recallState: ConsignmentRecallState) => recallState.updateConsignmentError;
export const updatingConsignment = (recallState: ConsignmentRecallState) => recallState.updatingConsignment;
export const updatedConsignment = (recallState: ConsignmentRecallState) => recallState.updatedConsignment;

// Get grn item list
export const getGRNItemList = (recallState: ConsignmentRecallState) => recallState.getGRNItemList;
export const getGRNItemListError = (recallState: ConsignmentRecallState) => recallState.getGRNItemListError;
export const loadingGRNItemList = (recallState: ConsignmentRecallState) => recallState.loadingGRNItemList;
export const loadedGRNItemList = (recallState: ConsignmentRecallState) => recallState.loadedGRNItemList;