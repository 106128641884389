"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("./api.service");
var config_1 = require("../../shared/configuration/config");
var i0 = require("@angular/core");
var i1 = require("./api.service");
var i2 = require("../configuration/config");
var AdjustmentService = /** @class */ (function () {
    function AdjustmentService(api, config) {
        this.api = api;
        this.config = config;
    }
    /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to save manual adjust inventory form
  * @return Observable<any> -
  
  */
    AdjustmentService.prototype.saveAdjustmentForm = function (params) {
        if (params === void 0) { params = {}; }
        console.log(params);
        console.log(this.config.UrlObj);
        return this.api.post(this.config.UrlObj.saveAdjustmentInventoryForm, params);
    };
    AdjustmentService.ngInjectableDef = i0.defineInjectable({ factory: function AdjustmentService_Factory() { return new AdjustmentService(i0.inject(i1.ApiService), i0.inject(i2.Configuration)); }, token: AdjustmentService, providedIn: "root" });
    return AdjustmentService;
}());
exports.AdjustmentService = AdjustmentService;
