// import { EdiCreateManifestsDialog } from "./create-manifests-dialog";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  OnChanges
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import * as $ from "jquery";
import { AlertService, WholesaleService } from "../../services";

@Component({
  selector: "app-filterbar",
  styles: [":host{display: flex; flex: 1;}"],
  templateUrl: "./filterbar.component.html"
})
export class FilterbarComponent implements OnInit, OnChanges {
  @ViewChild("iframe") iframe: ElementRef;
  @Output() forEditEmit: EventEmitter<any> = new EventEmitter();
  @Output() createManifest: EventEmitter<any> = new EventEmitter();
  @Output() manifestClosed: EventEmitter<any> = new EventEmitter();
  @Output() saveShipmentClick: EventEmitter<any> = new EventEmitter();
  @Output() printManifest: EventEmitter<any> = new EventEmitter();
  @Output() printHTML: EventEmitter<any> = new EventEmitter();
  @Input() EditFilterActive;
  @Input() pageType;
  @Input() shipment;
  @Input() loading;
  @Input() loaded;
  @Input() Id;
  @Input() getMenifestSelectIdResults;
  @Input() getShipmentLabelLoading;

  title: String = "";
  constructor(
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private service: WholesaleService
  ) {
    this.route.queryParams.subscribe(params => {
      this.title = "";
      if (params["id"]) {
        this.title = params["id"];
      }
    });
  }

  ngOnChanges() {
    if (this.shipment && this.shipment.invoice_url) {
      //alert(this.shipment.invoice_url);
      $("#id-v").attr("src", this.shipment.invoice_url);
    }
  }
  ngOnInit() {
    // this.openPopup();
  }
  /*---- to get updated value on the bases of @Input value Update----*/

  /**
   * click to change edit shipment
   */
  goForEdit() {
    this.forEditEmit.emit(true);
  }
  /**
   * click to change from update page to previous Page
   */
  goForClose() {
    this.forEditEmit.emit(false);
  }
  /**
   * Open Dialog for create new manifest
   */
  OpenCreateManifestsDialog() {
    // let dialogRef = this.dialog.open(EdiCreateManifestsDialog, {
    //   width: "390px",
    //   height: "250px"
    // });
  }
  //selected value for page type like shipment/manifest/Reports etc
  get getSelected() {
    const value = this.pageType;
    const words = true;
    if (value) {
      if (words) {
        return value.replace(/\b\w/g, first => first.toLocaleUpperCase());
      } else {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    }
  }
  /**
   * close manifest
   * @param manifestId id
   */
  closeManifest(manifestId) {
    const person = prompt("Please enter LoadID:", "MH25AV9999");
    if (person != null && person !== "" && person !== "MH25AV9999") {
      this.manifestClosed.emit({ loadId: person, manifestId: manifestId });
      //alert("Hello Yes"+person);
    }
  }
  get getLabelCode() {
    let result = "";
    if (
      this.shipment &&
      this.shipment["labels"] &&
      this.shipment["labels"][0] &&
      this.shipment["labels"][0].labelName
    ) {
      result = atob(this.shipment["labels"][0].labelName);
    }
    return result;
  }

  printLabel(url, type) {
    if (url !== "") {
      this.service
        .printLabels("https://amzb2b.eshopbox.com" + url)
        .subscribe(response => {
          const blob = new Blob([response], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        });
    } else {
      this.alertService.showError(type + " URL is Blank!");
    }
    // this.printHTML.emit({ label: this.getLabelCode, status: status });
  }

  printInvoice(url) {
    if (url !== "") {
      this.iframe.nativeElement.focus();
      this.iframe.nativeElement.contentWindow.print();
    } else {
      this.alertService.showError("Invoice URL is Blank!");
    }
  }
}
