"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var i0 = require("@angular/core");
var AlertService = /** @class */ (function () {
    function AlertService() {
        this.alertMessageSubject = new Subject_1.Subject();
        this.alertMessageState = this.alertMessageSubject.asObservable();
        this.confirmationSource = new Subject_1.Subject();
        this.confirmationListner = this.confirmationSource.asObservable();
        this.confirmationAcceptReject = new Subject_1.Subject();
        this.confirmationAcceptRejectListner = this.confirmationAcceptReject.asObservable();
    }
    AlertService.prototype.showError = function (content) {
        this.alertMessageSubject.next({
            show: true,
            type: "error",
            content: content
        });
    };
    AlertService.prototype.showSuccess = function (content) {
        this.alertMessageSubject.next({
            show: true,
            type: "success",
            content: content
        });
    };
    AlertService.prototype.showInfo = function (content) {
        this.alertMessageSubject.next({
            show: true,
            type: "info",
            content: content
        });
    };
    AlertService.prototype.showConfirmation = function (title, subtitle, key) {
        this.confirmationSource.next({
            title: title,
            subtitle: subtitle,
            key: key
        });
    };
    AlertService.prototype.acceptReject = function (status, key) {
        this.confirmationAcceptReject.next({ status: status, key: key });
    };
    AlertService.prototype.findShowMessage = function (data) {
        if (data.messages.length > 0) {
            for (var error in data.messages[0]) {
                if (data.messages[0].hasOwnProperty(error)) {
                    this.showError(data.messages[0][error]);
                    break;
                }
            }
        }
        else {
            this.showError(data.message);
        }
    };
    AlertService.prototype.hideMessage = function () {
        this.alertMessageSubject.next({ show: false, type: "", content: "" });
    };
    AlertService.ngInjectableDef = i0.defineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
exports.AlertService = AlertService;
