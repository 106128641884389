import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
// import { environment } from "@env/environment";
import { Configuration } from "../configuration/config";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class InboundService {
  constructor(private api: ApiService, private config: Configuration) { }

  private messageSource = new BehaviorSubject('step1');
  currentMessage = this.messageSource.asObservable();


  /**
    * @author Nidhi Raturi
    * @params Object - params
    * @description Api call to  get Complete Consignment list
    * @return Observable<any> -

    */
  getCompleteConsignmentList(pageObj): Observable<any> {
    return this.api
      .get(
        this.config.UrlObj.completeConsignmentListApi, { params: pageObj }
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }


  changeStep(message: string) {
    this.messageSource.next(message)
  }
  /**
  * @author Nidhi Raturi
  * @params Object - params
  * @description Api call to mark Consignment Receive
  * @return Observable<any> -

  */
  markConsignmentComplete(params = {}): Observable<any> {
    return this.api.post(this.config.UrlObj.marConsignmentCompleteApi, params);
  }

  /**
 * @author Nidhi Raturi
 * @params Object - params
 * @description Api call to get SKU Details
 * @return Observable<any> -

 */

  public getConsignmentDetail(consignmentNo): Observable<any> {
    const consignmentNumber = (encodeURIComponent(encodeURIComponent(consignmentNo)));
    return this.api.get(`${this.config.UrlObj.getDetailByConsignmentNo}${consignmentNumber}/items?page=1&perPage=1000`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  editConsignmentDetail(params, consignmentNo) {
    const consignmentNumber = consignmentNo;
    return this.api.put(`${this.config.UrlObj.editConsignmentApi}items-adjustment?consignmentNumber=${consignmentNumber}`, params);
  }

  editMultipleConsignmentDetail(params) {
    return this.api.put(`${this.config.UrlObj.editConsignmentApi}multiple-items-adjustment`, params);
  }


}
