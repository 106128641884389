"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var service_worker_1 = require("@angular/service-worker");
var services_1 = require("./shared/services");
var AppComponent = /** @class */ (function () {
    function AppComponent(swUpdate, auth) {
        this.swUpdate = swUpdate;
        this.auth = auth;
        this.title = "eshopbox";
        this.counter = 0;
        this.auth.handleAuthenticationWithHash();
    }
    //code for service worker new version
    AppComponent.prototype.ngOnInit = function () {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(function () {
                if (confirm("New version available. Load new version?")) {
                    window.location.reload();
                }
            });
        }
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
