import { createSelector } from "@ngrx/store";
import * as Reducer from "./consignment-recall.reducer";
import { AppState } from "../../../app/app.state";

const getConsignmentRecallState = (state: AppState) => state.consignmentRecallState;

// Get consignment list
export const getConsignmentListSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getConsignmentList
);
export const getConsignmentListErrorSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getConsignmentListError
);
export const getConsignmentListLoadingSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getConsignmentListLoading
);
export const getConsignmentListLoadedSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getConsignmentListLoaded
);

// Update consignment
export const updateConsignmentSelector = createSelector(
  getConsignmentRecallState,
  Reducer.updateConsignment
);
export const updateConsignmentErrorSelector = createSelector(
  getConsignmentRecallState,
  Reducer.updateConsignmentError
);
export const updatingConsignmentSelector = createSelector(
  getConsignmentRecallState,
  Reducer.updatingConsignment
);
export const updatedConsignmentSelector = createSelector(
  getConsignmentRecallState,
  Reducer.updatedConsignment
);

// Get GRN item list
export const getGRNItemListSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getGRNItemList
);
export const getGRNItemListErrorSelector = createSelector(
  getConsignmentRecallState,
  Reducer.getGRNItemListError
);
export const getGRNItemListLoadingSelector = createSelector(
  getConsignmentRecallState,
  Reducer.loadingGRNItemList
);
export const getGRNItemListLoadedSelector = createSelector(
  getConsignmentRecallState,
  Reducer.loadedGRNItemList
);