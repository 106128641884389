import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { AuthService } from "./shared/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"]
})
export class AppComponent implements OnInit {
  title = "eshopbox";
  counter = 0;

  constructor(private swUpdate: SwUpdate, private auth: AuthService) {
    this.auth.handleAuthenticationWithHash();
  }

  //code for service worker new version
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load new version?")) {
          window.location.reload();
        }
      });
    }
  }
}
