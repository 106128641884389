"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../constants/constants");
var environment_1 = require("@env/environment");
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent() {
        this.panelOpenState = false;
        this.ROUTES = constants_1.ROUTES;
        this.warehouseHostName = environment_1.environment.production
            ? "warehouse.eshopbox.com"
            : "warehouse.auperator.co";
    }
    SidebarComponent.prototype.ngOnInit = function () {
        this.hostname = window.location.hostname;
    };
    return SidebarComponent;
}());
exports.SidebarComponent = SidebarComponent;
