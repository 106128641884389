import { Action } from "@ngrx/store";
import { Shipment, PackageType } from "@app/_models/shipment-models";

export enum ShipmentActionTypes {
  SEARCH = "[Shipment] Search Shipment",
  SEARCH_COMPLETE = "[Shipment] Search Shipment Complete",
  SEARCH_ERROR = "[Shipment] Search Shipment Error",
  TRACKING_ID_SEARCH = "[Shipment] Tracking Id Search Shipment",
  TRACKING_ID_SEARCH_COMPLETE = "[Shipment] Tracking Id Search Shipment Complete",
  TRACKING_ID_SEARCH_ERROR = "[Shipment] Tracking Id Search Shipment Error",
  CLEAR_SEARCH = "[Shipment] ClearSearch Shipment",
  LOAD_PACKAGE_TYPE = "[PackageType] Load",
  LOAD_PACKAGE_TYPE_SUCCESS = "[PackageType] Load Success",
  LOAD_PACKAGE_TYPE_ERROR = "[PackageType] Load Error",
  LOAD = "[Shipment] Load",
  LOAD_SUCCESS = "[Shipment] Load Success",
  LOAD_ERROR = "[Shipment] Load Error",
  UPDATE_SHIPMENT = "[Shipment] Update Shipment",
  UPDATE_SHIPMENT_COMPLETE = "[Shipment] Update Shipment Complete",
  UPDATE_SHIPMENT_ERROR = "[Shipment] Update Shipment Error",
  LABEL_LOADING = "[Shipment] Label Loading",
  CLEAR_ORDER_SEARCH = "[Shipment] Clear Order Search",
  PRINT_INVOICE = "[Shipment] Print Invoice",
  PRINT_INVOICE_COMPLETE = "[Shipment] Print Invoice Complete",
  PRINT_INVOICE_ERROR = "[Shipment] Print Invoice Error"
}
export class LoadPackageType implements Action {
  readonly type = ShipmentActionTypes.LOAD_PACKAGE_TYPE;
}

export class LoadPackageTypeSuccess implements Action {
  readonly type = ShipmentActionTypes.LOAD_PACKAGE_TYPE_SUCCESS;
  constructor(public payload: PackageType[]) {}
}

export class LoadPackageTypeFailure implements Action {
  readonly type = ShipmentActionTypes.LOAD_PACKAGE_TYPE_ERROR;
  constructor(public payload: string) {}
}
export class Load implements Action {
  readonly type = ShipmentActionTypes.LOAD;
  constructor(public payload: Shipment) {}
}

export class LoadSuccess implements Action {
  readonly type = ShipmentActionTypes.LOAD_SUCCESS;
  constructor(public payload: Shipment[]) {}
}

export class LoadFailure implements Action {
  readonly type = ShipmentActionTypes.LOAD_ERROR;
  constructor(public payload: string) {}
}

export class OrderSearch implements Action {
  readonly type = ShipmentActionTypes.SEARCH;
  constructor(public payload: string) {}
}

export class OrderSearchComplete implements Action {
  readonly type = ShipmentActionTypes.SEARCH_COMPLETE;
  constructor(public payload: Shipment) {}
}

export class OrderSearchError implements Action {
  readonly type = ShipmentActionTypes.SEARCH_ERROR;
  constructor(public payload: string) {}
}

export class UpdateShipment implements Action {
  readonly type = ShipmentActionTypes.UPDATE_SHIPMENT;
  constructor(public payload: any) {}
}

export class UpdateShipmentSuccess implements Action {
  readonly type = ShipmentActionTypes.UPDATE_SHIPMENT_COMPLETE;
  constructor(public payload: any) {}
}

export class UpdateShipmentFailure implements Action {
  readonly type = ShipmentActionTypes.UPDATE_SHIPMENT_ERROR;
  constructor(public payload: string) {}
}
export class ClearSearch implements Action {
  readonly type = ShipmentActionTypes.CLEAR_SEARCH;
}
export class LabelLoading implements Action {
  readonly type = ShipmentActionTypes.LABEL_LOADING;
  constructor(public payload: Boolean) {}
}

export class TrackingIdSearch implements Action {
  readonly type = ShipmentActionTypes.TRACKING_ID_SEARCH;
  constructor(public payload: string) {}
}

export class TrackingIdSearchComplete implements Action {
  readonly type = ShipmentActionTypes.TRACKING_ID_SEARCH_COMPLETE;
  constructor(public payload: Shipment) {}
}

export class TrackingIdSearchError implements Action {
  readonly type = ShipmentActionTypes.TRACKING_ID_SEARCH_ERROR;
  constructor(public payload: string) {}
}

export class PrintInvoice implements Action {
  readonly type = ShipmentActionTypes.PRINT_INVOICE;
  constructor(public payload: string) {}
}

export class PrintInvoiceComplete implements Action {
  readonly type = ShipmentActionTypes.PRINT_INVOICE_COMPLETE;
  constructor(public payload: Shipment) {}
}

export class PrintInvoiceError implements Action {
  readonly type = ShipmentActionTypes.PRINT_INVOICE_ERROR;
  constructor(public payload: string) {}
}
export class ClearOrderSearch implements Action {
  readonly type = ShipmentActionTypes.CLEAR_ORDER_SEARCH;
}
export type ShipmentUnion =
  | PrintInvoice
  | PrintInvoiceComplete
  | PrintInvoiceError
  | ClearOrderSearch
  | TrackingIdSearch
  | TrackingIdSearchComplete
  | TrackingIdSearchError
  | LabelLoading
  | ClearSearch
  | UpdateShipment
  | UpdateShipmentSuccess
  | UpdateShipmentFailure
  | LoadPackageType
  | LoadPackageTypeSuccess
  | LoadPackageTypeFailure
  | Load
  | LoadSuccess
  | LoadFailure
  | OrderSearch
  | OrderSearchComplete
  | OrderSearchError;
