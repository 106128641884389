<div class="picklist-pdf">
  <div class="picklist-header">
    <div class="picklist-top-pane justify-content-between align-items-center">
      <span class="picklist-route">
        STOCK TRANSFER SUMMARY-</span>

      <span class="picklist-timing">
        Date/Time: {{currentDate}}
      </span>
    </div>

    <div class="picklist-barcode-wrapper justify-content-between">
      <div class="picklist-barcode text-center">
        <ngx-barcode [bc-value]="consignmentDetail[0]?.challanNo" [bc-display-value]="true"></ngx-barcode>
      </div>

      <div class="text-center">
        <span class="invoice-qty">Challan No.: {{consignmentDetail[0]?.challanNo}}</span><br>
        <span class="invoice-qty">Departure Date/Time.:
          {{consignmentDetail[0]?.departureDate ? moment(consignmentDetail[0]?.departureDate).format('LLLL'):''}}</span><br>
        <span class="invoice-qty">Expected Date/Time:
          {{ consignmentDetail[0]?.scheduledArivalFrom ? moment(consignmentDetail[0]?.scheduledArivalFrom).format('LLLL') : ''}}
        </span>
      </div>


    </div>
  </div><br>


  <div class="picklist-body" *ngIf="foodFilteredList.length">

    <span>Location: Food</span>

    <table>
      <thead>
        <tr>
          <td>Sl.No</td>
          <td>SKU</td>
          <td>Title</td>
          <td>Uts/Cs</td>
          <td>MRP/Pc</td>
          <td>Total Cases</td>
          <td>Total Loose</td>
          <td>Total Bundles</td>

          <td>Comments</td>

        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let detail of foodFilteredList;
        let i = index;
        trackBy: trackByFn ">
          <td>{{i+1}}</td>
          <td>{{detail?.sku}}</td>
          <td>{{detail?.title}}</td>
          <td>{{detail?.uts}}</td>
          <td>{{detail?.mrp}}</td>
          <td>{{detail?.totalCases}}</td>
          <td>{{detail?.totalLoose}}</td>
          <td>{{detail?.bundles}}</td>

          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Grand Total</td>
          <td></td>
          <td></td>
          <td>{{totalCases}}</td>
          <td>{{totalLoose}}</td>
          <td>{{totalBundles}}</td>

          <td></td>
        </tr>
      </tbody>

    </table>
  </div>

  <div class="picklist-body" *ngIf="nonfoodFilteredList.length" [ngClass]="foodFilteredList.length?'break-page':''">

    <span>Location: Non-Food</span>

    <table>
      <thead>
        <tr>
          <td>Sl.No</td>
          <td>SKU</td>
          <td>Title</td>
          <td>Uts/Cs</td>
          <td>MRP/Pc</td>
          <td>Total Cases</td>
          <td>Total Loose</td>
          <td>Total Bundles</td>

          <td>Comments</td>

        </tr>
      </thead>
      <tbody>

        <tr *ngFor="
        let detail of nonfoodFilteredList;
        let i = index;
        trackBy: trackByFn
      ">
          <td>{{i+1}}</td>
          <td>{{detail?.sku}}</td>
          <td>{{detail?.title}}</td>
          <td>{{detail?.uts}}</td>
          <td>{{detail?.mrp}}</td>
          <td>{{detail?.totalCases}}</td>
          <td>{{detail?.totalLoose}}</td>
          <td>{{detail?.bundles}}</td>

          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Grand Total</td>
          <td></td>
          <td></td>
          <td>{{cases}}</td>
          <td>{{loose}}</td>
          <td>{{bundles}}</td>

          <td></td>
        </tr>
      </tbody>

    </table>
  </div>
  <!-- </div> -->
</div>
