import { OrderState } from "./order.state";
import * as OrderActions from "../actions/order.actions";

export const initialState: OrderState = {
  loaded: false,
  loading: false,
  entities: {},
  selectedId: null,
  search_text: null,
  sku_details: null
};

export function OrderReducer(
  state = initialState,
  action: OrderActions.OrderUnion
): OrderState {
  switch (action.type) {
    case OrderActions.OrderActionTypes.SEARCH_COMPLETE: {
      const order = action.payload;
      if (order != null) {
        return {
          ...state,
          loaded: true,
          loading: false,
          selectedId: order.customerOrderNumber.toString(),
          entities: Object.assign({}, state.entities, {
            [order.customerOrderNumber.toString()]: order
          })
        };
      } else {
        return {
          ...state,
          loaded: false,
          loading: false
        };
      }
    }
    case OrderActions.OrderActionTypes.SEARCH_ERROR: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
