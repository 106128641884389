"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var effects_1 = require("@ngrx/effects");
var rxjs_1 = require("rxjs");
var BagActions = require("./bag.action");
var bag_service_1 = require("./bag.service");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var BagEffects = /** @class */ (function () {
    function BagEffects(bagService, action$, store) {
        var _this = this;
        this.bagService = bagService;
        this.action$ = action$;
        this.store = store;
        // Get bag list effect
        this.getBagList$ = this.action$.pipe(effects_1.ofType(BagActions.GET_BAG_LIST), operators_1.mergeMap(function (action) {
            return _this.bagService.getBagListService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.GET_BAG_LIST_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.GET_BAG_LIST_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Get bag list for PDF effect
        this.getBagListForPDF$ = this.action$.pipe(effects_1.ofType(BagActions.GET_BAG_LIST_FOR_PDF), operators_1.mergeMap(function (action) {
            return _this.bagService.getBagListService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.GET_BAG_LIST_FOR_PDF_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.GET_BAG_LIST_FOR_PDF_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Create bag effect
        this.createBag$ = this.action$.pipe(effects_1.ofType(BagActions.CREATE_BAG), operators_1.mergeMap(function (action) {
            return _this.bagService.createBagService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.CREATE_BAG_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.CREATE_BAG_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Update bag effect
        this.updateBag$ = this.action$.pipe(effects_1.ofType(BagActions.UPDATE_BAG), operators_1.mergeMap(function (action) {
            return _this.bagService.updateBagService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.UPDATE_BAG_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.UPDATE_BAG_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Get bag item details effect
        this.getBagItemDetails$ = this.action$.pipe(effects_1.ofType(BagActions.GET_BAG_ITEM_DETAILS), operators_1.mergeMap(function (action) {
            return _this.bagService.getBagItemDetailsService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.GET_BAG_ITEM_DETAILS_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.GET_BAG_ITEM_DETAILS_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Get single item details effect
        this.getSingleItemDetails2$ = this.action$.pipe(effects_1.ofType(BagActions.GET_SINGLE_ITEM_DETAILS), operators_1.mergeMap(function (action) {
            return _this.bagService.getSingleItemDetailsService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.GET_SINGLE_ITEM_DETAILS_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.GET_SINGLE_ITEM_DETAILS_FAILURE,
                    payload: error['error']
                });
            }));
        }));
        // Add item details effect
        this.addItemIntoBag$ = this.action$.pipe(effects_1.ofType(BagActions.ADD_ITEM), operators_1.mergeMap(function (action) {
            return _this.bagService.addItemIntoBagService(action.payload).pipe(operators_1.map(function (data) { return ({
                type: BagActions.ADD_ITEM_SUCCESS,
                payload: data
            }); }), operators_1.catchError(function (error) {
                return rxjs_1.of({
                    type: BagActions.ADD_ITEM_FAILURE,
                    payload: error['error']
                });
            }));
        }));
    }
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "getBagList$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "getBagListForPDF$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "createBag$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "updateBag$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "getBagItemDetails$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "getSingleItemDetails2$", void 0);
    tslib_1.__decorate([
        effects_1.Effect(),
        tslib_1.__metadata("design:type", rxjs_1.Observable)
    ], BagEffects.prototype, "addItemIntoBag$", void 0);
    return BagEffects;
}());
exports.BagEffects = BagEffects;
