import { Injectable } from "@angular/core";
import { ApiService } from "../../shared/services/api.service";
import { Configuration } from "../../shared/configuration/config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getAccountIdStatus } from "../../wholesale/reducers/selectors";
import { AppState } from "../../app.state";
import { HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../shared/services/auth.service";

@Injectable({
  providedIn: "root"
})

export class ConsignmentRecallService {

  AuthorizationToken: any;
  Headers: any;
  wareHouseId;

  /**
   *
   * @param api
   * @param config
   * @param store
   * @param auth
   */
  constructor(
    private api: ApiService,
    private config: Configuration,
    private store: Store<AppState>,
    private auth: AuthService
  ) {
    // Set authorization token
    this.AuthorizationToken = this.auth.getAuthToken;
    this.AuthorizationToken = 'Bearer ' + this.AuthorizationToken;

    // Set headers
    this.Headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.AuthorizationToken)
      .set('warehouseId', "Warehouse");

    // Get warehouse id
    //    this.getWarehouseId();
  }

  /**
   * Get warehouse ID
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getWarehouseId() {
    this.store.pipe(select(getAccountIdStatus)).subscribe(result => {
      this.wareHouseId = result;
    });
  }

  /**
   * Get consignment list service
   * @param requestBody
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getConsignmentListService(requestBody) {
    // return this.api.get(this.config.UrlObj.getConsignmentRecallList, {
    //   headers: this.Headers,
    //   params: requestBody
    // });
    return this.api.get(this.config.UrlObj.getConsignmentRecallList, { params: requestBody });
  }

  /**
  * Update consignment service
  * @param requestBody
  *
  * @author Chandrika Aggarwal <chandrika@eshopbox.com>
  */
  updateConsignmentService(requestBody) {
    console.log("REQUEST BODY", requestBody)
    const apiUrl = this.config.UrlObj.updateConsignmentRecall + encodeURIComponent(encodeURIComponent(requestBody['consignmentNumber']));
    return this.api.put(apiUrl, requestBody, this.Headers);
  }

  /**
   * Get grn item list service
   * @param requestBody
   *
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getGRNItemListService(requestBody) {
    const apiUrl = this.config.inboundUrl + 'recall-consignment/' + encodeURIComponent(requestBody['consignmentNumber']) + '/' + this.config.UrlObj.getConsignmentRecallGRNItems;
    return this.api.get(apiUrl, { params: requestBody });
  }

  /**
   * @description Get todo list servie
   * @param requestBody 
   * 
   * @author Chandrika Aggarwal <chandrika@eshopbox.com>
   */
  getTODOListService(requestBody) {
    const apiUrl = this.config.UrlObj.getconsignmentRecallTODOList;
    return this.api.get(apiUrl, { params: requestBody });
  }

  getWarehouseDetails(accountId) {
    const queryParams = {
      'page': 1,
      'perPage': 50,
      'accountId': accountId
    }
    const apiUrl = this.config.UrlObj.wareHouseList;
    return this.api.get(apiUrl, { params: queryParams })
  }

  createRecallConsignmentTransfer(transferData) {
    const apiUrl = this.config.UrlObj.createRecallConsignmentTrasnfer;
    return this.api.post(apiUrl, transferData)
  }

}
