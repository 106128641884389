import { Action } from "@ngrx/store";

/**
 * Action types
 * 
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export enum ActionTypes {
  // Get List
  ACTION_GET_CONSIGNMENT_LIST = "[Consignment Recall] Get Consignment Recall List",
  ACTION_GET_CONSIGNMENT_LIST_SUCCESS = "[Consignment Recall] Get Consignment Recall List Success",
  ACTION_GET_CONSIGNMENT_LIST_FAILURE = "[Consignment Recall] Get Consignment Recall List Failure",

  // Update Consignment
  ACTION_UPDATE_CONSIGNMENT = "[Consignment Recall] Update Consignment Recall",
  ACTION_UPDATE_CONSIGNMENT_SUCCESS = "[Consignment Recall] Update Consignment Recall Success",
  ACTION_UPDATE_CONSIGNMENT_FAILURE = "[Consignment Recall] Update Consignment Recall Failure",

  // Get GRN Items
  ACTION_GET_GRN_ITEMS = "[Consignment Recall] Get GRN Items",
  ACTION_GET_GRN_ITEMS_SUCCESS = "[Consignment Recall] Get GRN Items Success",
  ACTION_GET_GRN_ITEMS_FAILURE = "[Consignment Recall] Get GRN Items Failure",
}

/**
 * Get consignment list action types definition
 * 
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class GetConsignmentListAction implements Action {
  readonly type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST

  constructor(public payload: object) { }
}
export class GetConsignmentListFailureAction implements Action {
  readonly type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST_FAILURE

  constructor(public payload: object) { }
}
export class GetConsignmentListSuccessAction implements Action {
  readonly type = ActionTypes.ACTION_GET_CONSIGNMENT_LIST_SUCCESS

  constructor(public payload: object) { }
}

/**
 * Update consignment action types definition
 * 
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class UpdateConsignmentAction implements Action {
  readonly type = ActionTypes.ACTION_UPDATE_CONSIGNMENT

  constructor(public payload: object) { }
}
export class UpdateConsignmentFailureAction implements Action {
  readonly type = ActionTypes.ACTION_UPDATE_CONSIGNMENT_FAILURE

  constructor(public payload: object) { }
}
export class UpdateConsignmentSuccessAction implements Action {
  readonly type = ActionTypes.ACTION_UPDATE_CONSIGNMENT_SUCCESS

  constructor(public payload: object) { }
}

/**
 * Get GRN items action types definition
 * 
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export class GetGRNItemsAction implements Action {
  readonly type = ActionTypes.ACTION_GET_GRN_ITEMS

  constructor(public payload: object) { }
}
export class GetGRNItemsFailureAction implements Action {
  readonly type = ActionTypes.ACTION_GET_GRN_ITEMS_FAILURE

  constructor(public payload: object) { }
}
export class GetGRNItemsSuccessAction implements Action {
  readonly type = ActionTypes.ACTION_GET_GRN_ITEMS_SUCCESS

  constructor(public payload: object) { }
}

export type RecallUnion =
  | GetConsignmentListAction
  | GetConsignmentListFailureAction
  | GetConsignmentListSuccessAction
  | UpdateConsignmentAction
  | UpdateConsignmentFailureAction
  | UpdateConsignmentSuccessAction
  | GetGRNItemsAction
  | GetGRNItemsSuccessAction
  | GetGRNItemsFailureAction;