import { Action } from "@ngrx/store";

export enum AuthActionTypes {
  LOGIN = "[Login Page] Login",
  LOGIN_COMPLETE = "[Callback Page] Login Complete",
  LOGIN_SUCCESS = "[Auth API] Login Success",
  LOGIN_FAILED = "[Auth API] Login Failure",
  CHECK_LOGIN = "[Auth] Check Login",
  LOGOUT = "[Auth] Confirm Logout",
  SET_SEARCH_FILTER = "[Header Search] Sets filter value",
  GET_CURRENT_USER = "[Create User] GET_CURRENT_USER"
  // LogoutCancelled = '[Auth] Logout Cancelled',
  // LogoutConfirmed = '[Auth] Logout Confirmed'
}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;
}

export class LoginComplete implements Action {
  readonly type = AuthActionTypes.LOGIN_COMPLETE;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILED;

  constructor(public payload: any) {}
}

export class CheckLogin implements Action {
  readonly type = AuthActionTypes.CHECK_LOGIN;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class GetCurrentUser implements Action {
  readonly type = AuthActionTypes.GET_CURRENT_USER;
}

export class SetFilter implements Action {
  readonly type = AuthActionTypes.SET_SEARCH_FILTER;

  constructor(public payload: string) {}
}

// export class LogoutConfirmed implements Action {
//   readonly type = AuthActionTypes.LogoutConfirmed;
// }

// export class LogoutCancelled implements Action {
//   readonly type = AuthActionTypes.LogoutCancelled;
// }

export type AuthUnion =
  | Login
  | LoginComplete
  | LoginSuccess
  | LoginFailure
  | CheckLogin
  | GetCurrentUser
  | Logout
  | SetFilter;
// | LogoutCancelled
// | LogoutConfirmed;
