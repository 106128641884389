import * as AuthActions from "../actions/auth.actions";
import { AuthState } from "./auth.state";

export const initialState: AuthState = {
  isLoggedIn: false,
  user: {},
  filter: null
};

export function AuthReducer(
  state = initialState,
  action: AuthActions.AuthUnion
) {
  switch (action.type) {
    case AuthActions.AuthActionTypes.LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true };

    case AuthActions.AuthActionTypes.LOGOUT:
      return initialState; // the initial state has isLoggedIn set to false

    case AuthActions.AuthActionTypes.SET_SEARCH_FILTER: {
      return { ...state, filter: action.payload };
    }

    // case AuthActions.AuthActionTypes.LOGIN: {
    //   return state;
    // }

    // case AuthActions.AuthActionTypes.LOGIN_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     user: action.payload
    //   };
    // }

    // case AuthActions.AuthActionTypes.LOGIN_FAILED: {
    //   return {
    //     ...state,
    //     ...action.payload
    //   };
    // }

    case AuthActions.AuthActionTypes.GET_CURRENT_USER: {
      return state.user;
    }

    // case AuthActions.AuthActionTypes.LOGOUT: {
    //   return {
    //     ...state,
    //     isLoggedIn: false,
    //     user: {}
    //   };
    // }

    default:
      return state;
  }
}
